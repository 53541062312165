import styled from 'styled-components';
import Styled from 'components/searchForm/SearchForm.styled';
import StyledField from 'components/searchForm/SuggestionField/SuggestionField.styled';
import { gray, veryLightPink } from 'style/variables';

const SuggestionPicker = styled(Styled.SearchCriteria)`
  flex: 1;
  min-width: 1px;
  width: 100%;
  display: inline-block;
  position: relative;
  max-width: 100%;
  margin: 0;

  ${StyledField.ReadOnlyField} {
    height: 50px;
    padding-left: 47px !important;
    padding-right: 6px !important;
    border: solid 1px ${gray};
  }

  ${StyledField.IconWrapper} {
    width: 44px;

    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

const SearchForm = styled(Styled.SearchForm)`
  font-size: 16px;

  ${Styled.DatePicker}, ${Styled.RoomPicker} {
    height: 50px;
    border-radius: 4px;
    border: solid 1px ${gray};
    width: 100%;
  }

  ${Styled.RoomPicker} {
    padding: 0 16px;
  }

  form {
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: 10px;
    }
  }
`;

const DatepickerWidget = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;

  & > * + * {
    border-left: solid 1px ${veryLightPink};
  }

  & > * {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 16px;
    flex-grow: 1;
  }
`;

export default {
  SuggestionPicker,
  SearchForm,
  DatepickerWidget,
};
