/**
 * @category Views
 * @packageDocumentation
 */
import { TFunction } from 'i18next';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OffscreenContext } from 'TopContexts';
import { Text } from 'components/common/Text/Text';
import { TextAlignment, TextWeight } from 'components/common/Text/Text.types';
import OffscreenMenu from 'components/offscreen/OffscreenMenu';
import OffscreenMode from 'components/offscreen/OffscreenMode';
import OffscreenSlideAnimation from 'components/offscreen/OffscreenSlideAnimation';
import SearchFormOffscreen from 'components/searchForm/SearchFormOffscreen';
import OffscreenHeader from 'views/offscreens/OffscreenHeader';

function getOffscreenTitle(mode: OffscreenMode, t: TFunction) {
  switch (mode) {
    case OffscreenMode.Occupancy:
      return t('offscreen.occupancy-title', 'Who’s coming?');
    case OffscreenMode.Datepicker:
      return t('offscreen.datepicker-title', 'When will you be there?');
    default:
      return '';
  }
}

const OffscreenSearchMenu: React.FC = () => {
  const [t] = useTranslation();
  const { offscreenMode, hideOffscreen } = useContext(OffscreenContext);
  const [animatedOffscreenMode, setAnimatedOffscreenMode] = useState<OffscreenMode>(offscreenMode);
  const [headerContent, setHeaderContent] = useState<HTMLDivElement>();

  // It is necessary that the view remains the same offscreenMode during the animation.
  useEffect(() => {
    if (offscreenMode !== animatedOffscreenMode && offscreenMode !== OffscreenMode.Hidden) {
      setAnimatedOffscreenMode(offscreenMode);
    }
  }, [animatedOffscreenMode, offscreenMode]);

  const onFinishAnimation = useCallback(() => {
    if (offscreenMode === OffscreenMode.Hidden) {
      setAnimatedOffscreenMode(offscreenMode);
    }
  }, [offscreenMode]);

  const header = useMemo(
    () => (
      <>
        <Text tag="h4" weight={TextWeight.Normal} alignment={TextAlignment.Center} className="uk-margin-remove">
          {getOffscreenTitle(animatedOffscreenMode, t)}
        </Text>
        <OffscreenHeader offscreenMode={animatedOffscreenMode} />
      </>
    ),
    [animatedOffscreenMode, t],
  );

  return (
    <OffscreenSlideAnimation
      show={
        offscreenMode === OffscreenMode.Occupancy ||
        offscreenMode === OffscreenMode.Datepicker ||
        offscreenMode === OffscreenMode.Destination
      }
      onFinishAnimation={onFinishAnimation}
    >
      <OffscreenMenu
        onClose={hideOffscreen}
        header={header}
        headerAtCenter={offscreenMode !== OffscreenMode.Destination}
        setHeaderContent={setHeaderContent}
      >
        <SearchFormOffscreen offscreenMode={animatedOffscreenMode} offscreenHeader={headerContent} />
      </OffscreenMenu>
    </OffscreenSlideAnimation>
  );
};

export default OffscreenSearchMenu;
