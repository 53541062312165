import styled, { css } from 'styled-components';
import StyledNavbarMobile from 'components/header-footer/NavbarMobile.styled';
import StyledCommon from 'style/Common.styled';
import { gunmetal } from 'style/variables';

const Navbar = styled.div<{ sticky?: boolean }>`
  padding: 12px 24px;
  transition:
    background-color 0.6s ease-in-out,
    box-shadow 0.2s ease-in-out;
  background-color: transparent !important;

  ${StyledNavbarMobile.Navbar} {
    padding: 0;
  }

  ${(props) =>
    props.sticky &&
    css`
      box-shadow:
        0 3px 7px 0 rgba(0, 0, 0, 0.06),
        0 1px 0 0 rgba(203, 203, 203, 0.5);
      background-color: #fff !important;
    `}
`;

const HeaderImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  overflow: hidden;

  section {
    margin-bottom: 42px;
  }

  ${StyledNavbarMobile.Navbar} {
    padding: 0;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 2.67px;
  margin-top: 92px;
  margin-bottom: 45px;
  max-width: 60%;
`;

const Point = styled.div`
  margin-bottom: 50px;

  & > h2 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.39;
    letter-spacing: 2.67px;
    text-transform: uppercase;
    margin-bottom: 12px;
  }

  & > div {
    font-size: 13px;
    line-height: 1.54;
    color: ${gunmetal};
  }
`;

const PointsImage = styled.div`
  position: relative;
  margin-bottom: 78px;
  transform: translateX(24px);
  align-self: flex-end;
  width: fit-content;
  height: fit-content;

  ${StyledCommon.Letter} {
    overflow: hidden;
    font-size: 14px;
    position: absolute;
    height: 114%;
    top: 50%;
    left: 60.5%;
    transform-origin: center;
    color: #bdbdbd;
  }
`;

const PointsSmallImage = styled.img`
  width: fit-content;
  margin-bottom: 34px;
  transform: translateX(-24px) rotateY(180deg);
`;

export default {
  Navbar,
  HeaderImage,
  Container,
  Title,
  Point,
  PointsImage,
  PointsSmallImage,
};
