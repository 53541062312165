/**
 * @category Search Form
 * @packageDocumentation
 */
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from 'components/Icon';
import { Text } from 'components/common/Text/Text';
import { TextColor, TextTransform, TextWeight } from 'components/common/Text/Text.types';
import { LayoutContext } from 'components/contexts/LayoutContext';
import StyledOccupancyPicker from 'components/searchForm/OccupancyPicker.styled';
import { env } from 'environments/environment';

interface PeoplePickerProps {
  adults: number;
  adultChanger: (increase: boolean) => void;
  childrenAges: number[];
  childrenChanger: (increase: boolean) => void;
  setAge: (index: number, age: string) => void;
}

/**
 * Allows to select a count of people.
 */
/* eslint-disable react/no-array-index-key */
const PeoplePicker: React.FC<PeoplePickerProps> = ({ adults, adultChanger, childrenAges, childrenChanger, setAge }) => {
  const [t] = useTranslation();
  const { isMobileLayout } = useContext(LayoutContext);

  return (
    <>
      <StyledOccupancyPicker.Row className="uk-flex uk-flex-middle uk-flex-between uk-width-1-1">
        <Text
          tag="div"
          weight={TextWeight.Normal}
          transform={TextTransform.Capitalize}
          className={`uk-width-1-2 ${isMobileLayout ? 'uk-heading-small uk-margin-remove' : ''}`}
        >
          <Trans i18nKey="search-bar.adults">Adults</Trans>
        </Text>
        <StyledOccupancyPicker.Changer className="uk-width-1-2 uk-flex uk-flex-middle uk-flex-right">
          <StyledOccupancyPicker.Button type="button" onClick={() => adultChanger(false)} disabled={adults === 1}>
            <Icon name={'minus'} />
          </StyledOccupancyPicker.Button>
          <Text tag="h2" weight={TextWeight.SemiBold} className="uk-margin-remove-vertical">
            {adults}
          </Text>
          <StyledOccupancyPicker.Button
            type="button"
            onClick={() => adultChanger(true)}
            disabled={adults === env.searchBar.maxAdults}
          >
            <Icon name={'plus'} />
          </StyledOccupancyPicker.Button>
        </StyledOccupancyPicker.Changer>
      </StyledOccupancyPicker.Row>

      <StyledOccupancyPicker.Row className="uk-flex uk-flex-middle uk-flex-between uk-width-1-1">
        <Text
          tag="div"
          weight={TextWeight.Normal}
          transform={TextTransform.Capitalize}
          className={`uk-width-1-2 ${isMobileLayout ? 'uk-heading-small uk-margin-remove' : ''}`}
        >
          <div>
            <Trans i18nKey="search-bar.children">Children</Trans>
          </div>
          <Text tag="div" color={TextColor.Meta}>
            <Trans
              i18nKey="search-bar.children-desc"
              defaults="({minChildAge}-{maxChildAge} years old)"
              values={{ minChildAge: env.searchBar.minChildAge, maxChildAge: env.searchBar.maxChildAge }}
            />
          </Text>
        </Text>
        <StyledOccupancyPicker.Changer className="uk-width-1-2 uk-flex uk-flex-middle uk-flex-right">
          <StyledOccupancyPicker.Button
            tabIndex={0}
            type="button"
            onClick={() => childrenChanger(false)}
            disabled={childrenAges.length === 0}
          >
            <Icon name={'minus'} />
          </StyledOccupancyPicker.Button>
          <Text tag="h2" weight={TextWeight.SemiBold} className="uk-margin-remove-vertical">
            {childrenAges.length}
          </Text>
          <StyledOccupancyPicker.Button
            tabIndex={0}
            type="button"
            onClick={() => childrenChanger(true)}
            disabled={childrenAges.length === env.searchBar.maxChildren}
          >
            <Icon name={'plus'} />
          </StyledOccupancyPicker.Button>
        </StyledOccupancyPicker.Changer>
      </StyledOccupancyPicker.Row>

      {childrenAges.length > 0 && (
        <StyledOccupancyPicker.Row>
          <div className="uk-width-1-1">
            <Trans i18nKey="search-bar.children-age">Children&apos;s age</Trans>
          </div>
          <div className="uk-flex uk-flex-middle uk-flex-wrap uk-flex-between">
            {childrenAges.map((age, index) => (
              <StyledOccupancyPicker.AgePicker key={index} value={age} onChange={(e) => setAge(index, e.target.value)}>
                {[...Array(env.searchBar.maxChildAge - env.searchBar.minChildAge + 1)].map((_, j) => (
                  <option key={j + env.searchBar.minChildAge} value={env.searchBar.minChildAge + j}>
                    {t('search-bar.child-years', '{ age, plural, =0 {Under 1} =1 {1 year} other {# years} }', {
                      age: env.searchBar.minChildAge + j,
                    })}
                  </option>
                ))}
              </StyledOccupancyPicker.AgePicker>
            ))}
          </div>
        </StyledOccupancyPicker.Row>
      )}
    </>
  );
};

export default PeoplePicker;
