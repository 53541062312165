import { atomWithQuery } from 'jotai-tanstack-query';
import { ClientLocation } from 'backend/api/client/clientModel';
import { getDataProvider } from 'backend/dataProvider';
import { countryStatesList } from 'utils/states';
import cookieBasedUserLocationStorage from 'utils/storage/cookie/CookieBasedUserLocationStorage';

export const locationAtom = atomWithQuery<ClientLocation>(() => ({
  queryKey: ['location'],
  initialData: {},
  queryFn: async () => {
    let location = cookieBasedUserLocationStorage.get();

    if (!location) {
      const dataProvider = await getDataProvider();

      location = await dataProvider.getClientLocation();
    }

    if (location) {
      const countryWithStates = countryStatesList.find((c) => c.countryCode === location?.countryCode);
      const states = countryWithStates?.statesCodes.map((_state) => _state.code) || [];
      const existingState = location.stateCode && states.includes(location.stateCode);

      if (!existingState) {
        location = { ...location, stateCode: undefined };
      }
    }

    cookieBasedUserLocationStorage.set(location);

    return location;
  },
}));
