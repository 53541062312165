import { TFunction } from 'i18next';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalAlertContext, ToastContext } from 'TopContexts';
import { ToastType } from 'components/Toast.styled';
import { env } from 'environments/environment';
import { ClientErrorCode } from 'errors/clientError';
import { AvailableErrors } from 'errors/errorData';
import { formatDate, formatTime } from 'utils/dateUtils';

const printClientTime = (t: TFunction, language: string) => {
  const now = new Date();

  return `<span style='color: grey'>${t('validation.client-time', 'Client time: {date} at {time} ({timezone})', {
    date: formatDate(now, language),
    time: formatTime(now, language),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  })}</span>`;
};

const useSearchFormParametersErrors = (): AvailableErrors => {
  const { t, i18n } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const { setAlert } = useContext(ModalAlertContext);

  return useMemo(
    () => ({
      known: [
        {
          clientCodes: [ClientErrorCode.DatesInPast],
          action: () => {
            const datesInPastMessage = `${t(
              'validation.dates-in-past',
              "Sorry, we can't find hotels in past dates. The previous values will be returned.",
            )}<br><br>${printClientTime(t, i18n.language)}`;

            setAlert(datesInPastMessage);

            return datesInPastMessage;
          },
        },
        {
          clientCodes: [ClientErrorCode.DatesEquals],
          action: () => {
            const datesEqualsMessage = t(
              'validation.dates-equals',
              'Sorry, the minimum length of stay is one night. The previous values will be returned.',
            );

            setAlert(datesEqualsMessage);

            return datesEqualsMessage;
          },
        },
        {
          clientCodes: [ClientErrorCode.InvalidLengthOfStay],
          action: () => {
            const invalidLengthOfStayMessage = t(
              'validation.stay-nights',
              'Sorry, bookings can only be made for a maximum of {maxNights} nights',
              {
                maxNights: env.searchBar.maxNights,
              },
            );

            setToast(invalidLengthOfStayMessage, ToastType.error);

            return invalidLengthOfStayMessage;
          },
        },
      ],
      default: {
        clientCodes: [ClientErrorCode.InvalidSearchParams],
        action: () => {
          const invalidSearchParamsMessage = `${t(
            'validation.failed',
            'The current link contains invalid parameters. The previous values will be returned.',
          )}<br><br>${printClientTime(t, i18n.language)}`;

          setAlert(invalidSearchParamsMessage);

          return invalidSearchParamsMessage;
        },
      },
    }),
    [setAlert, setToast, t, i18n],
  );
};

export default useSearchFormParametersErrors;
