import styled, { css, keyframes } from 'styled-components';

export const OFFSCREEN_SLIDE_ANIMATION_MS = 800;
export const OFFSCREEN_FADE_ANIMATION_MS = 250;

const moveUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const appear = keyframes`
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const AnimationOffscreen = styled.div<{ show: boolean; fade?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1011;
  display: flex;
  flex-direction: column;

  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  box-sizing: border-box;
  background: white;

  animation: ${moveUp} ${OFFSCREEN_SLIDE_ANIMATION_MS}ms ease-in;

  transition: all ${OFFSCREEN_SLIDE_ANIMATION_MS}ms ease-out;
  transform: ${({ show }) => (show ? '' : 'translateY(100%)')};

  ${({ fade, show }) =>
    fade &&
    css`
      animation: ${appear} ${OFFSCREEN_FADE_ANIMATION_MS}ms ease-in;

      transition: all ${OFFSCREEN_FADE_ANIMATION_MS}ms ease-out;
      transform: ${show ? '' : 'translateY(100px)'};
      opacity: ${show ? '' : '0'};
    `};
`;

export default {
  AnimationOffscreen,
};
