import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalAlertContext, SignInDialogContext } from 'TopContexts';
import { ServerErrorCode } from 'backend/serverError';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import { AvailableErrors } from 'errors/errorData';
import { showError } from 'errors/errorHandlers';

const useAuthenticatorErrors = (): AvailableErrors => {
  const [t] = useTranslation();
  const { setSignInDialogMode } = useContext(SignInDialogContext);
  const { setAlert } = useContext(ModalAlertContext);

  return useMemo(
    () => ({
      known: [
        {
          serverCodes: [ServerErrorCode.InvalidCredentials, ServerErrorCode.Unauthorized],
          action: () => {
            setAlert(
              t('login-popup.authenticator.error', 'You are no longer logged in, please log in and try again'),
              () => setSignInDialogMode(SignInDialogMode.SignIn),
            );
          },
        },
        {
          serverCodes: [ServerErrorCode.ItemNotFound],
          action: () => {
            setAlert(t('errors.user-not-found', 'User not found, please log in and try again'), () =>
              setSignInDialogMode(SignInDialogMode.SignIn),
            );
          },
        },
      ],
      default: {
        action: (errorId, status) =>
          showError(
            errorId,
            status,
            t('login-popup.authenticator.unknown', 'An error has occurred. Please try again later.'),
          ),
      },
    }),
    [setAlert, setSignInDialogMode, t],
  );
};

export default useAuthenticatorErrors;
