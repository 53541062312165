import { useEffect } from 'react';
import { getAppsFlyerIntegrationSnippet } from 'utils/appsFlyer/appsFlyerSnippet';
import { getScriptElement } from 'utils/scriptElementUtils';

declare global {
  interface Window {
    AF: (type: string, method: string, options?: { bannerZIndex: number }) => void;
  }
}

const APPSFLYER_SCRIPT_ID = 'appsFlyer';
const LOADED_APPSFLYER_SCRIPT_ID = 'loadedAppsFlyer';

export interface AppsFlyerBannerOptions {
  deep_link_value?: string;
  deep_link_sub1?: string;
  deep_link_sub2?: string;
}

export const useAppsFlyerBanner = (bannerId: string, additionalParams?: AppsFlyerBannerOptions, disable?: boolean) => {
  useEffect(() => {
    if (disable) {
      return undefined;
    }

    const parameters = {
      bannerZIndex: 1000,
      additionalParams,
    };

    const target = document.getElementsByTagName('script')[0];
    const textContent = getAppsFlyerIntegrationSnippet(bannerId, LOADED_APPSFLYER_SCRIPT_ID);
    const scriptElement = getScriptElement(APPSFLYER_SCRIPT_ID, textContent);

    if (target.parentNode) {
      target.parentNode.insertBefore(scriptElement, target);
      window.AF('banners', 'showBanner', parameters);
    }

    return () => {
      window.AF('banners', 'hideBanner');
      document.getElementById(LOADED_APPSFLYER_SCRIPT_ID)?.remove();
      scriptElement.remove();
    };
  }, [additionalParams, bannerId, disable]);
};
