/**
 * @category Errors
 * @packageDocumentation
 */

import { RawServerError, ServerError } from 'backend/serverError';
import { env } from 'environments/environment';
import { logError, UnknownError } from 'errors/errorUtils';
import { I18nError } from 'errors/i18nError';
import { trace } from 'utils/logger';

/**
 * Shows error with code and message
 * In the production mode redirects to the Error Page
 * In the development mode, logs the error and make no redirect
 * @param id error ID
 * @param code HTTP code
 * @param message Message for user
 */
export function showCodeError(id: string, code: number, message: string) {
  if (window.location.pathname.startsWith('/error')) {
    trace(`Error while showing error: ${id}/${code}/${message}`);
  } else if (env.developers.noRedirectOnError) {
    trace(`Error: ${id}/${code}/${message}`);
  } else {
    window.location.href = `/error/${id}/code/${code}/${encodeURIComponent(message)}`;
  }
}

/**
 * Shows error with message
 * In the production mode redirects to the Error Page
 * In the development mode, logs the error and make no redirect
 * @param id error ID
 * @param message Message for user
 * @param internalMessage Message for debug purposes
 */
export function showMessageError(id: string, message: string, internalMessage?: string) {
  if (window.location.pathname.startsWith('/error')) {
    trace(`Error while showing error: ${id}/${message}${internalMessage ? `/${internalMessage}` : ''}`);
  } else if (env.developers.noRedirectOnError) {
    trace(`Error: ${id}/${message}${internalMessage ? `/${internalMessage}` : ''}`);
  } else {
    window.location.href = `/error/${id}/msg/${encodeURIComponent(message)}${
      internalMessage ? `/${encodeURIComponent(internalMessage)}` : ''
    }`;
  }
}

/**
 * Shows error with code and message
 * In the production mode redirects to the Error Page
 * In the development mode, logs the error and make no redirect
 * @param id error ID
 * @param code HTTP code or `undefined`
 * @param message Message for user
 */
export function showError(id: string, code: number | undefined, message: string) {
  if (code === undefined) {
    showMessageError(id, message);
  } else {
    showCodeError(id, code, message);
  }
}

/**
 * The same as {@link showMessageError} but with i18n
 * @param id error ID
 * @param i18nkey translation key
 * @param i18nparam optional parameters for translation (if the phrase is parameter-dependent)
 */
export function showI18nError(id: string, i18nkey: string, i18nparam?: Record<string, unknown>) {
  if (window.location.pathname.startsWith('/error')) {
    trace(`Error while showing error: ${id}/${i18nkey}/${i18nparam ? JSON.stringify(i18nparam) : ''}`);
  } else if (env.developers.noRedirectOnError) {
    trace(`Error: ${id}/${i18nkey}/${JSON.stringify(i18nparam)}`);
  } else {
    window.location.href = `/error/${id}/key/${i18nkey}${
      i18nparam ? `/${encodeURIComponent(JSON.stringify(i18nparam))}` : ''
    }`;
  }
}

function isEmbedScriptError(e: ErrorEvent) {
  const error = e.error || e;

  return (
    error.message &&
    error.message === 'Script error.' &&
    !e.bubbles &&
    e.eventPhase === Event.AT_TARGET &&
    e.target === window
  );
}

function isAvuxiError(e: ErrorEvent) {
  return e.error && e.error.stack && e.error.stack.includes('avuxi');
}

const isLoadingChunkErrorRegex = /(Loading).+?(chunk)/;

const isLoadingChunkError = (error: ErrorEvent) => {
  const message = error?.message || '';

  return isLoadingChunkErrorRegex.test(message);
};

/**
 * Setups handler for all uncaught errors
 * If the error is instance of {@link I18nError} calls {@link showI18nError}
 * Otherwise calls {@link showCodeError}
 */
export function setupErrorHandlers() {
  window.addEventListener('error', (e) => {
    const error = e.error || e;

    // todo: added to prevent redirection to the error page on errors in embed scripts (see useScript.ts)
    if (isEmbedScriptError(e)) {
      return false;
    }

    // Ignore avuxi error when DOM has been unmounted, but the script has not loaded yet (see HotelMapPanel.tsx)
    if (isAvuxiError(e)) {
      return false;
    }

    // Ignore chunk loading error on navigations (in case of error navigation)
    if (isLoadingChunkError(e)) {
      return false;
    }

    if (error instanceof I18nError) {
      const errorId = logError(error);

      showI18nError(errorId, error.i18key, error.i18param);
    } else if (error instanceof ServerError || error instanceof RawServerError) {
      // when backend request error handling was forgotten
      const errorId = logError(error);

      showCodeError(errorId, error.getStatus(), error.message);
    } else {
      const handledError =
        error instanceof Error || typeof error === 'string' ? error : new UnknownError('Unknown error', error);
      const errorId = logError(handledError);

      showMessageError(errorId, 'Internal error', error.message);
    }

    return false;
  });
}
