import React, { PropsWithChildren } from 'react';
import Styled from 'components/hotelComponents/listElement/OptionalHrefWrap.styled';

export interface OptionalHrefWrapPrp extends PropsWithChildren<Record<string, unknown>> {
  href?: string;
  target?: string;
  onClick?: () => void;
  asLink?: boolean;
  className?: string;
  dataTestid?: string;
}

const OptionalHrefWrap = ({ children, href, target, onClick, asLink, className, dataTestid }: OptionalHrefWrapPrp) =>
  href ? (
    <Styled.LinkWrap
      data-testid={dataTestid}
      href={href}
      target={target}
      style={asLink ? undefined : { textDecoration: 'none', color: 'inherit' }}
      rel={target ? 'noopener noreferrer' : undefined}
      onClick={onClick}
      className={className}
    >
      {children}
    </Styled.LinkWrap>
  ) : (
    <>{children}</>
  );

export default OptionalHrefWrap;
