import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledNavbar from 'components/header-footer/NavbarDesktop.styled';
import AboutUsLink from 'components/links/AboutUsLink';
import CareersLink from 'components/links/CareersLink';
import Dropdown from 'components/widgets/Dropdown';

const CompanySelector: React.FC = () => {
  const [t] = useTranslation();
  const text = useMemo(() => t('common.company', 'Company'), [t]);
  const [open, setOpen] = useState(false);

  const onClick = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <>
      <input type="checkbox" hidden={true} checked={open} readOnly={true} />
      <StyledNavbar.NavbarItem type="button" onClick={onClick}>
        {text}
      </StyledNavbar.NavbarItem>
      <Dropdown opened={open} onClose={() => setOpen(false)}>
        <AboutUsLink target={'_self'} />
        <CareersLink />
      </Dropdown>
    </>
  );
};

export default CompanySelector;
