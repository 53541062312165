/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import tick from 'assets/images/icons/tick.png';
import { rem } from 'style/functions';
import { cornflower, formRadioBorder, white } from 'style/variables';

const InputCheckbox = styled.input`
  display: inline-block;
  height: ${rem(20)};
  width: ${rem(20)};
  overflow: hidden;
  margin-top: 0;
  vertical-align: middle;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: ${white};
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: ${rem(2)};
  border: 1px solid ${formRadioBorder};
  color: transparent;
  transition: 0.2s ease-in-out;
  transition-property: background-color, border;
  min-width: ${rem(20)};
  box-sizing: border-box;
  margin: 0;
  font: inherit;
  flex-shrink: 0;

  &:checked {
    background-color: ${cornflower};
    border-color: ${formRadioBorder};
    background-image: url(${tick});
  }

  &:hover {
    border: 2px solid ${cornflower};
  }

  &:not(:disabled) {
    cursor: pointer;
  }
`;

const Label = styled.label<{ active: boolean; isMobile?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px;
  position: relative;
  cursor: pointer;

  & > * {
    cursor: pointer;
  }

  & > input:not([hidden]) + span {
    margin-left: 8px;
  }

  & > span {
    padding-right: 40px;
  }

  & > svg {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
  }

  ${(props) =>
    props.active &&
    css`
      border-radius: 6px;
      background-color: rgba(106, 116, 246, ${props.isMobile ? '0.19' : '0.09'});
    `}
`;

export default {
  InputCheckbox,
  Label,
};
