import styled, { css } from 'styled-components';

const Placeholder = styled.div<{ stickyActive: boolean; height: number }>`
  display: ${(props) => (props.stickyActive ? 'block' : 'none')};
  visibility: hidden;
  height: ${(props) => props.height}px;
`;

const Sticky = styled.div<{ stickyActive: boolean }>`
  ${(props) =>
    props.stickyActive &&
    css`
      box-sizing: border-box;
      margin: 0 !important;
      backface-visibility: hidden;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 980;
    `}
`;

export default {
  Placeholder,
  Sticky,
};
