/**
 * @category Utils
 * @packageDocumentation
 */

import { TFunction } from 'i18next';
import { HotelDeal } from 'backend/api/hotel/dealModel';
import { Hotel } from 'backend/api/hotel/hotelModel';
import { HotelSearchResult } from 'backend/api/hotel/hotelResponse';
import { RefundableType } from 'backend/api/model';
import { RoomOccupancy } from 'backend/api/trip/tripModel';
import { DebugKeyValue } from 'components/DebugOverlay';
import { getFirstDeal } from 'utils/roomUtils';

export interface HotelViewQuery {
  checkin: string;
  checkout: string;
  occupancy: RoomOccupancy[];
}

const DIGITS_PRECISION = 2;

/**
 * Round a number to default precision = 2, which is used everywhere across the site
 * @param n
 */
export function formatNumber(n: number): number {
  return Number.parseFloat(n.toFixed(DIGITS_PRECISION));
}

/**
 * Converts DealData object into an array of debug values used to display in DebugOverlay
 * @param deal
 */
export function getSingleDealDebugValues(deal: HotelDeal): () => DebugKeyValue[] {
  return () => {
    const ret: DebugKeyValue[] = [];

    ret.push({
      key: 'Deal Type',
      value: deal.dealType,
    });
    ret.push({
      key: 'Night/Total Price',
      value: `${deal.nightlyPrice}/${deal.totalPrice}`,
    });
    ret.push({
      key: 'refundableType',
      value: RefundableType[deal.cancellation.type],
    });

    return ret;
  };
}

/**
 * Converts HotelSearchResultData object into an array of debug values used to display in DebugOverlay
 * @param hotel
 */
export function getSearchResultDebugValues(hotel: HotelSearchResult): () => DebugKeyValue[] {
  return () => {
    const ret: DebugKeyValue[] = [];

    ret.push({
      key: 'Hotel Id',
      value: hotel.id,
    });
    const firstDeal = getFirstDeal(hotel.deals);

    if (firstDeal) {
      ret.push(...getSingleDealDebugValues(firstDeal)());
    }

    return ret;
  };
}

export const getRandomElement = <T>(array: T[]): T => array[Math.floor(Math.random() * array.length)];

export function arrayOfFirstElement<T>(arr?: T[]): T[] {
  return arr && arr.length > 0 ? [arr[0]] : [];
}

export function copyOfArrayObjects<T extends Record<string, unknown>>(arr: T[]): T[] {
  return arr.map((v) => ({ ...v }));
}

export function toNumber(value: string | undefined) {
  return value ? +value : undefined;
}

export function toAddress(...values: (string | undefined)[]) {
  return values.filter((v) => !!v).join(', ');
}

export function sum(...values: (number | undefined)[]) {
  return values.reduce((s: number, v: number | undefined) => s + (v || 0), 0);
}

export function copyToClipboard(value: string): Promise<void> {
  return navigator.clipboard.writeText(value);
}

export function toDestinationLabel(hotel: Hotel) {
  return `${hotel.name}, ${hotel.location.cityName}, ${hotel.location.stateCode}, ${hotel.location.countryCode}`;
}

/**
 * Creates new random order of values
 * @param values
 */
export const shuffle = <T>(values: T[]) => {
  const tmp = [...values];
  const randomOrder: T[] = [];

  for (let i = 0; i < values.length; i++) {
    const randomPosition = Math.trunc(Math.random() * 1000) % tmp.length;
    const value = tmp.splice(randomPosition, 1)[0];

    randomOrder.push(value);
  }

  return randomOrder;
};

export const totalRoomsNightsLabel = (rooms: number, nights: number, t: TFunction) =>
  `${t('common.misc.rooms', '{ Rooms, plural, =1 {# Room} other {# Rooms} }', {
    rooms,
  })} x ${t('common.misc.nights', '{ Nights, plural, =1 {# Night} other {# Nights} }', { nights })}`;

export const mod = (x: number, y: number) => (x + y) % y;
