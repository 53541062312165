import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { OffscreenContext } from 'TopContexts';
import { Icon } from 'components/Icon';
import { IconXLargeWrapper } from 'components/Icon.styled';
import { Button } from 'components/common/Button/Button';
import { ButtonType } from 'components/common/Button/Button.types';
import Styled from 'components/header-footer/NavbarMobile.styled';
import BurgerMenu from 'components/offscreen/BurgerMenu';
import OffscreenMode from 'components/offscreen/OffscreenMode';
import OffscreenSlideAnimation from 'components/offscreen/OffscreenSlideAnimation';

interface NavbarMobileProps {
  onBack?: () => void;
  backHidden?: boolean;
  currencySelectorHidden?: boolean;
  gradientBackground?: boolean;
  children?: React.ReactNode;
}

const NavbarMobile: React.FC<NavbarMobileProps> = ({
  onBack,
  backHidden,
  currencySelectorHidden,
  gradientBackground,
  children,
}) => {
  const history = useHistory();
  const { offscreenMode, setOffscreenMode } = useContext(OffscreenContext);

  return (
    <Styled.Navbar gradientBackground={gradientBackground}>
      {!backHidden && (
        <Styled.Left
          styleType={ButtonType.Text}
          className="uk-button uk-button-text"
          onClick={onBack || (() => history.goBack())}
        >
          <IconXLargeWrapper>
            <Icon name={'back'} />
          </IconXLargeWrapper>
        </Styled.Left>
      )}
      <Styled.Logo id="logo-navbar-link" $isLeftPosition={backHidden} to="/">
        <Icon name={'logo'} />
      </Styled.Logo>
      <Styled.Right>
        {children || (
          <Button
            data-testid="burger-button"
            styleType={ButtonType.Text}
            onClick={() => setOffscreenMode(OffscreenMode.Burger)}
          >
            <IconXLargeWrapper>
              <Icon name={'menu'} />
            </IconXLargeWrapper>
          </Button>
        )}
      </Styled.Right>
      <OffscreenSlideAnimation
        show={
          offscreenMode === OffscreenMode.Burger ||
          offscreenMode === OffscreenMode.ManageProfile ||
          offscreenMode === OffscreenMode.ChangePassword
        }
      >
        <BurgerMenu currencySelectorHidden={currencySelectorHidden} />
      </OffscreenSlideAnimation>
    </Styled.Navbar>
  );
};

export default NavbarMobile;
