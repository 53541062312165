import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from 'TopContexts';
import { useSearchStateCallbacks } from 'atoms/hooks/useSearchStateCallbacks';
import {
  searchFormAutoPlaceAtom,
  searchFormCheckinAtom,
  searchFormCheckoutAtom,
  searchFormDestinationTermAtom,
  searchFormOccupancyAtom,
  searchFormPlaceAtom,
} from 'atoms/searchFormAtoms';
import { Place } from 'backend/api/place/placeModel';
import { HotjarEvent, hotjarEvent } from 'backend/hotjarTags';
import { ToastType } from 'components/Toast.styled';
import { env } from 'environments/environment';
import { ClientError, ClientErrorCode } from 'errors/clientError';
import { processError } from 'errors/errorUtils';
import { getPeriodOverMaxNightsMessage, isPeriodOverMaxNights, parseDate } from 'utils/dateUtils';

interface SearchFormHandlers {
  submitForm: () => void;
  submitDestinationForm: (place: Place) => void;
  submitDatesForm: (checkin: string, checkout: string) => void;
  onAutoSuggestion: (d: Place | undefined) => void;
}

export const useSearchFormHandlers = (): SearchFormHandlers => {
  const [t] = useTranslation();
  const { submit } = useSearchStateCallbacks();
  const { setToast } = useContext(ToastContext);
  const [place, setPlace] = useAtom(searchFormPlaceAtom);
  const [autoPlace, setAutoPlace] = useAtom(searchFormAutoPlaceAtom);
  const destinationTerm = useAtomValue(searchFormDestinationTermAtom);
  const occupancy = useAtomValue(searchFormOccupancyAtom);
  const checkin = useAtomValue(searchFormCheckinAtom);
  const checkout = useAtomValue(searchFormCheckoutAtom);

  const onAutoSuggestion = useCallback(
    (_place: Place | undefined) => {
      setAutoPlace(_place);
      if (_place) {
        setPlace(undefined);
      }
    },
    [setAutoPlace, setPlace],
  );

  const submitFormInternal = useCallback(
    (_checkin: string, _checkout: string, _place: Place | undefined) => {
      if (!_place) {
        const term = destinationTerm.trim();

        if (term.length === 0) {
          setToast(t('index.search.not-found', 'Please enter destination!'), ToastType.error);
        } else if (term.length < env.searchBar.minimalTermLength) {
          setToast(t('search-bar.destination-too-short', 'Please type more characters'), ToastType.error);
        } else {
          const message = t(
            'errors.hotels.destination-missing',
            'Destination not supported. We keep adding new destinations every day!',
          );
          const error = {
            clientCodes: [ClientErrorCode.UnsupportedDestination],
            action: () => setToast(message, ToastType.error),
          };

          processError(new ClientError(ClientErrorCode.UnsupportedDestination, [message]), {
            known: [error],
            default: error,
          });
        }

        return;
      }

      if (isPeriodOverMaxNights(parseDate(_checkin), parseDate(_checkout))) {
        setToast(getPeriodOverMaxNightsMessage(t), ToastType.error);

        return;
      }
      hotjarEvent(HotjarEvent.SearchClicked);

      submit(_place, undefined, _checkin, _checkout, occupancy);
    },
    [occupancy, submit, destinationTerm, setToast, t],
  );

  const submitDatesForm = useCallback(
    (_checkin: string, _checkout: string) => submitFormInternal(_checkin, _checkout, place || autoPlace),
    [autoPlace, place, submitFormInternal],
  );

  const submitDestinationForm = useCallback(
    (_place: Place) => submitFormInternal(checkin, checkout, _place),
    [checkin, checkout, submitFormInternal],
  );

  const submitForm = useCallback(
    () => submitFormInternal(checkin, checkout, place || autoPlace),
    [autoPlace, checkin, checkout, place, submitFormInternal],
  );

  return {
    onAutoSuggestion,
    submitForm,
    submitDestinationForm,
    submitDatesForm,
  };
};
