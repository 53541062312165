import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkProps } from 'components/links/LinkProps';
import Styled from 'components/links/PrivacyPolicyLink/PrivacyPolicyLink.styled';
import { PRIVACY_ROUTE } from 'routeList';

const PrivacyPolicyLink: React.FC<LinkProps> = ({ onClick, target = '_blank' }) => {
  const [t] = useTranslation();
  const linkText = useMemo(() => t('footer.privacy', 'Privacy Policy'), [t]);

  return (
    <Styled.Link
      to={PRIVACY_ROUTE}
      target={target}
      onClick={() => onClick?.(linkText)}
      data-testid="privacy-policy-link"
    >
      {linkText}
    </Styled.Link>
  );
};

export default PrivacyPolicyLink;
