import React, { ReactElement, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SignInDialogContext } from 'TopContexts';
import { useSession } from 'atoms/hooks/useSession';
import { HotjarEvent, hotjarEvent } from 'backend/hotjarTags';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import { BOOKINGS_ROUTE } from 'routeList';
import StyledCommon from 'style/Common.styled';

interface BookingLinkProps {
  render?: (text: string, onClick: () => void) => ReactElement;
}

const BookingLink: React.FC<BookingLinkProps> = ({ render }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const { session } = useSession();
  const { setSignInDialogMode } = useContext(SignInDialogContext);
  const text = useMemo(
    () => (session.hasUser ? t('common.menu.my-trips', 'My Trips') : t('my-booking.find-trip', 'Find my trips')),
    [session.hasUser, t],
  );

  const onClick = useCallback(() => {
    hotjarEvent(HotjarEvent.MyBookingClicked);
    if (!session.hasUser) {
      setSignInDialogMode(SignInDialogMode.ViewBooking);
    } else {
      history.push(BOOKINGS_ROUTE);
    }
  }, [session.hasUser, setSignInDialogMode, history]);

  if (render) {
    return render(text, onClick);
  }

  return <StyledCommon.ButtonLink onClick={onClick}>{text}</StyledCommon.ButtonLink>;
};

export default BookingLink;
