/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import { Button } from 'components/common/Button/Button';
import StyledSearchForm from 'components/searchForm/SearchForm.styled';
import { rem } from 'style/functions';
import media from 'style/media';
import { cornflower } from 'style/variables';

export const DAY_WIDTH = 47;

const Month = styled.div`
  margin: 0 auto 0 auto;

  & + & {
    padding-top: 24px;
  }
`;

const MonthLabel = styled.div`
  margin: 0 0 18px;
  letter-spacing: ${(props) => props.theme.letterSpacing};
  width: calc(${DAY_WIDTH}px * 7);
  font-weight: 700;

  ${media.desktop} {
    text-align: center !important;
  }
`;

const Weekdays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, ${DAY_WIDTH}px);
  justify-content: center;
  margin-bottom: 2px;
  font-size: 12px;
  line-height: 1.58;
  color: rgba(52, 72, 94, 0.54);
  font-weight: 500;

  div {
    text-align: center;
  }
`;

const DayLabel = styled.div`
  display: grid;
  grid-template-columns: repeat(7, ${DAY_WIDTH}px);
  justify-content: center;
`;

const DatePickerPanel = styled(StyledSearchForm.Dropdown)`
  padding: 45px 42px 32px 42px;
  right: 0;
`;

const NavButton = styled(Button)`
  position: absolute;
  top: 0;
  color: black;

  &:hover {
    color: ${cornflower};
  }

  svg {
    width: 24px;
    height: 24px;
  }

  transition: 0.2s;

  &[disabled] {
    filter: opacity(0);
    cursor: default;
  }
`;

const PrevNavButton = styled(NavButton)`
  left: 0;
`;

const NextNavButton = styled(NavButton)`
  right: 0;

  svg {
    transform: rotate(180deg);
  }
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(40)};
  width: ${rem(104)};
  margin-left: auto;
  margin-right: auto;
  background: #f9f0ff;
  border: 1px solid #d3adf7;
  border-radius: ${(props) => props.theme.borderRadius};
  color: #722ed1;
`;

const SelectedDate = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: ${rem(192)};
  height: ${rem(40)};
  padding: 0 ${rem(16)};
  background: #fff0f6;
  border: 1px solid #ffadd2;
  border-radius: ${(props) => props.theme.borderRadius};
  color: #620042;
  margin-left: ${rem(10)};
`;

const MobileDatepickerWidgetButtonsExpanded = styled.div`
  height: ${rem(52)};
`;

const MobileDatepickerWidgetButtons = styled.div`
  height: ${rem(80)};
`;

const DatepickerWidgetDivider = styled.hr`
  margin-top: 0;
  margin-bottom: 0;
  height: 70%;
  background: ${'#ec018c'};
`;

const DatepickerDate = styled.h4`
  margin-bottom: 0;
`;

const Months = styled.div`
  position: relative;
  margin-bottom: 31px;

  & > div + div {
    margin-left: 42px;
  }
`;

export default {
  SelectedDate,
  Duration,
  PrevNavButton,
  NextNavButton,
  DayLabel,
  DatePickerPanel,
  Month,
  MonthLabel,
  NavButton,
  Weekdays,
  MobileDatepickerWidgetButtons,
  MobileDatepickerWidgetButtonsExpanded,
  DatepickerWidgetDivider,
  DatepickerDate,
  Months,
};
