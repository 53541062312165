import { MonthType } from '@datepicker-react/hooks/lib/useDatepicker/useDatepicker.utils';

export const areDatesEqual = (date1: Date | null, date2: Date | null): boolean =>
  !!date1 &&
  !!date2 &&
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate();

export const generateKey = ({ year, month }: MonthType) => `${year}-${month}`;
