import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useSearchState } from 'atoms/hooks/useSearchState';
import {
  searchFormAutoPlaceAtom,
  searchFormCheckinAtom,
  searchFormCheckoutAtom,
  searchFormDestinationTermAtom,
  searchFormOccupancyAtom,
  searchFormPlaceAtom,
} from 'atoms/searchFormAtoms';

export const useWatchSearchForm = () => {
  const {
    occupancy: occupancyExternal,
    checkin: checkinExternal,
    checkout: checkoutExternal,
    destination: destinationExternal,
  } = useSearchState();
  const [place, setPlace] = useAtom(searchFormPlaceAtom);
  const setAutoPlace = useSetAtom(searchFormAutoPlaceAtom);
  const setDestinationTerm = useSetAtom(searchFormDestinationTermAtom);
  const setOccupancy = useSetAtom(searchFormOccupancyAtom);
  const setCheckin = useSetAtom(searchFormCheckinAtom);
  const setCheckout = useSetAtom(searchFormCheckoutAtom);

  useEffect(() => {
    setPlace(destinationExternal);
    setAutoPlace(undefined);
  }, [destinationExternal, setAutoPlace, setPlace]);

  useEffect(() => {
    setOccupancy(occupancyExternal);
  }, [occupancyExternal, setOccupancy]);

  useEffect(() => {
    setCheckin(checkinExternal);
  }, [checkinExternal, setCheckin]);

  useEffect(() => {
    setCheckout(checkoutExternal);
  }, [checkoutExternal, setCheckout]);

  useEffect(() => {
    if (place?.name) {
      setDestinationTerm(place.name);
    }
  }, [place, setDestinationTerm]);
};
