import styled from 'styled-components';
import Styled from 'components/searchForm/SearchForm.styled';
import StyledField from 'components/searchForm/SuggestionField/SuggestionField.styled';

const SearchForm = styled(Styled.SearchForm)`
  position: relative;
  font-size: 13px;
`;

const SuggestionPicker = styled(Styled.SearchCriteria)`
  font-size: 13px;
  line-height: normal;
  font-weight: normal;

  ${StyledField.ReadOnlyField} {
    height: 30px;
    padding-left: 24px !important;
    padding-right: 6px !important;
  }

  ${StyledField.IconWrapper} {
    width: 28px;

    svg {
      height: 12px;
      width: 12px;
    }
  }
`;

const Container = styled(Styled.Container)<{ sticky?: boolean }>`
  padding: ${(props) => (props.sticky ? '10px' : '8px')} 12px;

  ${Styled.DatePicker}, ${Styled.RoomPicker} {
    padding: 0 8px;
    height: 30px;
    width: auto;
  }
`;

export default {
  SearchForm,
  Container,
  SuggestionPicker,
};
