import React, { forwardRef } from 'react';
import error from 'assets/images/icons/info/error.svg';
import info from 'assets/images/icons/info/info.svg';
import ok from 'assets/images/icons/info/ok.svg';
import { Icon as StyledIcon } from 'components/StatusIcon.styled';

export type StatusIconName = 'success' | 'error' | 'info';

interface IconProps {
  name: StatusIconName;
  className?: string;
}

// eslint-disable-next-line react/display-name
export const StatusIcon = forwardRef<HTMLImageElement, IconProps>(({ name, className }, ref) => (
  <>
    {name === 'success' && <StyledIcon src={ok} alt="success" className={className} ref={ref} />}
    {name === 'error' && <StyledIcon src={error} alt="error" className={className} ref={ref} />}
    {name === 'info' && <StyledIcon src={info} alt="info" className={className} ref={ref} />}
  </>
));
