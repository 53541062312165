import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import image from 'assets/images/errors/mascot.svg';
import CustomAbstractModal from 'components/CustomAbstractModal';
import { Icon } from 'components/Icon';
import ModalErrorStyled from 'components/ModalError.styled';
import { LayoutContext } from 'components/contexts/LayoutContext';

interface ModalErrorProps {
  open: boolean;
  proceedButtonText?: string;
  cancelButtonText?: string;
  backgroundDisabled?: boolean;
  onProceed: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  children?: React.ReactNode;
}

const ModalError: React.FC<ModalErrorProps> = ({
  open,
  proceedButtonText,
  cancelButtonText,
  backgroundDisabled,
  onProceed,
  onCancel,
  onClose,
  children,
}) => {
  const [t] = useTranslation();
  const { isMobileLayout } = useContext(LayoutContext);

  return (
    <>
      <CustomAbstractModal
        visible={open}
        positionToCenter={!isMobileLayout}
        opacity={backgroundDisabled ? '0' : undefined}
        paddingNone={isMobileLayout}
      >
        {!isMobileLayout && <ModalErrorStyled.ImageStyle src={image} />}

        <ModalErrorStyled.Modal isMobile={isMobileLayout}>
          {onClose && (
            <div onClick={onClose}>
              <Icon name={'close'} />
            </div>
          )}
          <div data-testid="alert-modal-content">{children}</div>
          <ModalErrorStyled.ModalActions>
            {!isMobileLayout && onCancel && (
              <ModalErrorStyled.CancelButton fullWidth onClick={onCancel}>
                {cancelButtonText || t('modal-error.cancel', 'Cancel')}
              </ModalErrorStyled.CancelButton>
            )}
            <ModalErrorStyled.ProceedButton data-testid="alert-modal-button" fullWidth={!!onCancel} onClick={onProceed}>
              {proceedButtonText || t('modal-error.proceed', 'Yes, Proceed')}
            </ModalErrorStyled.ProceedButton>
            {isMobileLayout && onCancel && (
              <ModalErrorStyled.CancelButtonLink onClick={onClose}>
                {cancelButtonText || t('modal-error.cancel', 'Cancel')}
              </ModalErrorStyled.CancelButtonLink>
            )}
          </ModalErrorStyled.ModalActions>
        </ModalErrorStyled.Modal>
      </CustomAbstractModal>
    </>
  );
};

export default ModalError;
