import jsc from 'js-cookie';
import { ClientLocation } from 'backend/api/client/clientModel';
import { env } from 'environments/environment';

/**
 * Name of the cookie to store JWT token
 */
const USER_LOCATION = 'USER_LOCATION';

class CookieBasedUserLocationStorage {
  set(location: ClientLocation): void {
    try {
      jsc.set(USER_LOCATION, JSON.stringify(location), {
        expires: new Date(new Date().getTime() + env.times.locationMaxAge),
        sameSite: 'Lax',
      });
    } catch (e) {
      // do nothing
    }
  }

  remove(): void {
    jsc.remove(USER_LOCATION);
  }

  get(): ClientLocation | undefined {
    const location = jsc.get(USER_LOCATION);

    try {
      return location ? JSON.parse(location) : undefined;
    } catch (e) {
      return undefined;
    }
  }
}

const cookieBasedUserLocationStorage = new CookieBasedUserLocationStorage();

export default cookieBasedUserLocationStorage;
