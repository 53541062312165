import React, { useContext } from 'react';
import { ToastContext } from 'TopContexts';
import Toast from 'components/Toast';
import { LayoutContext } from 'components/contexts/LayoutContext';
import NavbarDesktop from 'components/header-footer/NavbarDesktop';
import NavbarMobile from 'components/header-footer/NavbarMobile';
import Styled from 'components/header-footer/PageWithHeader.styled';
import StickyPanel from 'components/header-footer/StickyPanel';
import SearchFormDefault from 'components/searchForm/SearchFormDefault';

interface PageWithHeaderProps {
  sticky?: boolean;
  backHidden?: boolean;
  navbarHidden?: boolean;
  navbarHiddenInShortView?: boolean;
  searchBarDefaultHidden?: boolean;
  currencySelectorHidden?: boolean;
  onBack?: () => void;
  mobileActions?: React.ReactNode;
  shadowDisabled?: boolean;
  headerChildren?: (stickyActive: boolean, stickyShortView: boolean) => React.ReactNode;
  children?: React.ReactNode;
}

const PageWithHeader: React.FC<PageWithHeaderProps> = ({
  sticky = false,
  backHidden,
  navbarHidden,
  searchBarDefaultHidden,
  currencySelectorHidden,
  onBack,
  mobileActions,
  shadowDisabled,
  headerChildren,
  navbarHiddenInShortView = !searchBarDefaultHidden || !!headerChildren,
  children,
}) => {
  const { toast } = useContext(ToastContext);
  const { isMobileLayout } = useContext(LayoutContext);

  return (
    <>
      <Styled.Header shadowDisabled={shadowDisabled}>
        <StickyPanel off={!sticky}>
          {(stickyActive, stickyShortView) => (
            <>
              {!navbarHidden && (
                <div hidden={navbarHiddenInShortView && stickyShortView}>
                  {isMobileLayout ? (
                    <NavbarMobile
                      onBack={onBack}
                      backHidden={backHidden}
                      currencySelectorHidden={currencySelectorHidden}
                    >
                      {mobileActions}
                    </NavbarMobile>
                  ) : (
                    <NavbarDesktop currencySelectorHidden={currencySelectorHidden} />
                  )}
                </div>
              )}
              {!searchBarDefaultHidden && <SearchFormDefault stickyShortView={stickyShortView} />}
              {headerChildren?.(stickyActive, stickyShortView)}
              {toast && (
                <Toast
                  message={toast.message}
                  clearMessage={toast.clearMessage}
                  type={toast.type}
                  title={toast.title}
                />
              )}
            </>
          )}
        </StickyPanel>
      </Styled.Header>
      <Styled.Main>{children}</Styled.Main>
    </>
  );
};

export default PageWithHeader;
