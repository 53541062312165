/**
 * @category Utils
 * @packageDocumentation
 */

import { useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Place } from 'backend/api/place/placeModel';
import { RoomOccupancy } from 'backend/api/trip/tripModel';
import SearchStorage from 'backend/searchStorage';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { SearchFormQuery, SearchFormUrlParameters } from 'components/searchForm/query';
import {
  createHotelDetailsUri,
  createHotelSearchUri,
  createReplaceSearchUri,
  HotelDetailsSearchUrlParameters,
  isHotelRoute,
} from 'utils/uriUtils';

/**
 * Hook which prepares callback to redirect to hotel list or hotel details page
 */
export default function useSearchFormCallback(placeId: string | undefined) {
  const history = useHistory();
  const location = useLocation();
  const hotelPath = isHotelRoute(location.pathname);
  const { isMobileLayout } = useContext(LayoutContext);

  const updateSearchQuery = useCallback(
    (checkin: string, checkout: string, occupancy: RoomOccupancy[], formParameters: SearchFormQuery) => {
      const newFormParameters: SearchFormQuery = {
        ...formParameters,
        checkin,
        checkout,
        occupancy: formParameters.occupancy || occupancy,
      };

      if (hotelPath) {
        history.replace({
          search: createReplaceSearchUri(location.search, {
            ...newFormParameters,
            dealKey: undefined,
            occupancy: JSON.stringify(newFormParameters.occupancy),
          } as HotelDetailsSearchUrlParameters),
        });
      } else {
        history.replace({
          search: createReplaceSearchUri(location.search, {
            ...newFormParameters,
            occupancy: JSON.stringify(newFormParameters.occupancy),
          } as SearchFormUrlParameters),
        });
      }

      return newFormParameters;
    },
    [history, hotelPath, location.search],
  );

  const onSearch = useCallback(
    (
      place: Place | undefined,
      bounds: string | undefined,
      checkin: string,
      checkout: string,
      occupancy: RoomOccupancy[],
    ) => {
      if (place && place.id === placeId && hotelPath) {
        history.push(
          createHotelDetailsUri(location.pathname, checkin, checkout, occupancy, undefined, undefined, undefined),
        );

        if (!isMobileLayout) {
          SearchStorage.instance.store(place, checkin, checkout, occupancy);
        }
      } else if (place || bounds) {
        history.push(createHotelSearchUri(place?.label, place?.id, bounds, checkin, checkout, occupancy));
      }

      if (place) {
        SearchStorage.instance.store(place, checkin, checkout, occupancy);
      }
    },
    [history, hotelPath, isMobileLayout, location.pathname, placeId],
  );

  return { onSearch, updateSearchQuery };
}
