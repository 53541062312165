import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from 'TopContexts';
import { ServerErrorCode } from 'backend/serverError';
import { AvailableErrors } from 'errors/errorData';

const useEncryptTripIdErrors = (): AvailableErrors => {
  const [t] = useTranslation();
  const { setToast } = useContext(ToastContext);

  return useMemo(
    () => ({
      known: [
        {
          serverCodes: [ServerErrorCode.Unauthorized, ServerErrorCode.NotFound],
          action: () =>
            setToast(
              t(
                'find-trip.errors.not-found',
                'We were unable to find your reservation. Please verify the email and reservation number and try again.',
              ),
            ),
        },
      ],
      default: {
        action: () => setToast(t('checkout.errors.invalidTrip', 'Invalid email or trip number')),
      },
    }),
    [setToast, t],
  );
};

export default useEncryptTripIdErrors;
