import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Icon = styled.img`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  fill: currentColor;
  transition: fill 0.15s;
`;
