import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { campaignAtom, campaignIsReadyAtom } from 'atoms/campaignAtom';
import { Campaign } from 'backend/api/general/generalModel';
import cookieBasedCampaignStorage from 'utils/storage/cookie/CookieBasedCampaignStorage';

export const useCampaign = () => {
  const campaign = useAtomValue<Campaign | undefined>(campaignAtom);
  const ready = useAtomValue(campaignIsReadyAtom);

  const origin = useMemo(
    () => (campaign?.name ? campaign.name === cookieBasedCampaignStorage.get()?.name : false),
    [campaign?.name],
  );

  return { campaign, ready, origin };
};
