import React, { useCallback, useContext } from 'react';
import { Trans } from 'react-i18next';
import { APP_PROMOTION_STATE } from 'backend/sessionStorageKeys';
import clientStore from 'backend/splittyClientStore';
import { Icon } from 'components/Icon';
import { LayoutContext } from 'components/contexts/LayoutContext';
import OptionalHrefWrap from 'components/hotelComponents/listElement/OptionalHrefWrap';
import OffscreenSlideAnimation from 'components/offscreen/OffscreenSlideAnimation';
import AppLandingImage from 'components/offscreen/app/AppLandingImage';
import Styled from 'components/offscreen/app/AppPromotionScreen.styled';
import { env } from 'environments/environment';
import StyledCommon from 'style/Common.styled';
import useSessionStorageWithObject from 'utils/useSessionStorage';

enum AppPromotionState {
  SCREEN,
  NOTHING,
}

const AppPromotionScreen: React.FC = () => {
  const { isMobileLayout } = useContext(LayoutContext);
  const [state, setState] = useSessionStorageWithObject<AppPromotionState>(
    APP_PROMOTION_STATE,
    AppPromotionState.SCREEN,
  );

  const onClose = useCallback(() => setState(AppPromotionState.NOTHING), [setState]);

  if (!isMobileLayout || state === AppPromotionState.NOTHING || clientStore.getDevice().mobileWebview) {
    return <React.Fragment />;
  }

  return (
    <>
      <OffscreenSlideAnimation show={state === AppPromotionState.SCREEN} fade={false}>
        <AppLandingImage />
        <Styled.Container>
          <Icon name={'logo'} />
          <Styled.Content>
            <Styled.Title>
              <Trans i18nKey="app.title">Unparalleled deals at your fingertips</Trans>
            </Styled.Title>
            <Styled.Text>
              <Trans i18nKey="app.desc">Download our app to Harness the revolutionary power of Holisto</Trans>
            </Styled.Text>
            <OptionalHrefWrap href={env.mobileApp.promoAppLink} target="_blank">
              <StyledCommon.GreenButton type="button" onClick={onClose}>
                <Trans i18nKey="app.download">Download App</Trans>
              </StyledCommon.GreenButton>
            </OptionalHrefWrap>
            <StyledCommon.ButtonLink type="button" onClick={onClose}>
              <Trans i18nKey="app.continue">Not Now, continue</Trans>
            </StyledCommon.ButtonLink>
          </Styled.Content>
        </Styled.Container>
      </OffscreenSlideAnimation>
    </>
  );
};

export default AppPromotionScreen;
