/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import chevron from 'assets/images/icons/chevron.png';
import StyledSearchForm from 'components/searchForm/SearchForm.styled';
import { rem } from 'style/functions';
import { white, black, pineGreen, veryLightPink, warmBlue, grayInsurance } from 'style/variables';

const Button = styled.button`
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  background: transparent;
  color: ${warmBlue};

  &:disabled > * {
    color: ${grayInsurance};
  }

  svg {
    width: 32px;
    height: 32px;
  }
`;

const Main = styled.div<{ isMobile?: boolean; $overflow?: boolean; stickyHeader?: boolean }>`
  padding: ${(props) => (props.isMobile ? '0' : '24px 22px 0')};
  max-height: ${({ $overflow, stickyHeader }) =>
    $overflow ? 'auto' : `calc(100vh - ${stickyHeader ? '250' : '170'}px)`};
`;

const Footer = styled.div<{ isMobile?: boolean }>`
  padding: ${(props) => (props.isMobile ? '25px 0 0' : '8px 0 10px')};
  margin: ${(props) => (props.isMobile ? '0' : '0 22px')};
  border-top: solid 1px ${veryLightPink};

  svg {
    width: 20px;
    height: 20px;
  }
`;

const Dropdown = styled(StyledSearchForm.Dropdown)<{ scrollWidth: number }>`
  width: 366px;
  left: calc(100% - 366px);

  font-size: 16px;
  line-height: 20px;
  color: ${pineGreen};

  .rcs-inner-container {
    margin-right: -${({ scrollWidth }) => scrollWidth}px !important;

    > div {
      margin-right: 0 !important;
    }
  }
`;

const Room = styled.div`
  padding-bottom: 18px;

  &:not(:last-child) {
    border-bottom: solid 1px ${veryLightPink};
  }

  h3 {
    letter-spacing: ${(props) => props.theme.letterSpacing};
  }

  & + & {
    margin-top: 30px;
  }

  & > * + * {
    margin-top: 22px;
  }
`;

const Changer = styled.div`
  h2 {
    width: 86px;
    text-align: center;
  }
`;

const Row = styled.div`
  & + & {
    margin-top: 22px;
  }
`;

const AgePicker = styled.select`
  margin: 0;
  width: 48%;
  margin-top: 10px;
  cursor: pointer;
  height: 40px;
  vertical-align: middle;
  display: inline-block;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: ${white};
  background-image: url(${chevron});
  background-repeat: no-repeat;
  background-position: 100% 50%;
  padding: 0 20px 0 10px;
  border-radius: ${rem(4)};
  border: solid 1px ${grayInsurance};
  padding-left: 10px;
  max-width: 100%;
  text-transform: none;
  box-sizing: border-box;
  font: inherit;
  outline: none;
  &:focus {
    border-color: ${black};
  }
`;

const MobileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: normal;

  svg {
    color: #9da3a8;
    width: 10px;
    height: 13px;
    margin-left: 4px;
  }

  & > * + * {
    margin-left: 10px;
  }
`;

export default {
  Button,
  Dropdown,
  Main,
  Room,
  Changer,
  Row,
  AgePicker,
  Footer,
  MobileInfo,
};
