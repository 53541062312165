import styled, { css } from 'styled-components';
import { flexGapRow } from 'style/polyfills/flexGap';
import { gunmetal } from 'style/variables';

const Cookie = styled.div<{ isMobile?: boolean }>`
  position: fixed;
  z-index: 9999;
  bottom: 12px;
  left: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  ${flexGapRow('12px')}
  max-width: 622px;
  box-sizing: border-box;
  padding: 16px 20px;
  color: black;

  border-radius: 3px;
  background-color: #e3e5ff;

  & > button {
    min-width: 144px;
  }

  ${(props) =>
    props.isMobile &&
    css`
      bottom: 0;
      left: 8px;
      right: 8px;
      padding: 16px;
      max-width: none;

      font-size: 12px;
      color: ${gunmetal};

      & > button {
        font-size: 13px;
        min-width: 77px;
        height: 38px;
        padding: 0;
      }
    `}
`;

export default {
  Cookie,
};
