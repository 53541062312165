import FacebookLogin from '@greatsumini/react-facebook-login';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import AppleLogin from 'react-apple-login';
import { useTranslation } from 'react-i18next';
import { SignInDialogContext, ToastContext } from 'TopContexts';
import appleIcon from 'assets/images/icons/socials/apple.svg';
import emailIcon from 'assets/images/icons/socials/email.svg';
import facebookIcon from 'assets/images/icons/socials/facebook.svg';
import googleIcon from 'assets/images/icons/socials/google.svg';
import { useSession } from 'atoms/hooks/useSession';
import { OauthProvider } from 'backend/api/user/userModel';
import { LoginOauthRequest } from 'backend/api/user/userRequest';
import { ServerError, ServerErrorCode } from 'backend/serverError';
import { Icon } from 'components/Icon';
import { ToastType } from 'components/Toast.styled';
import { getOauthProviderName, OAuthContext } from 'components/contexts/OAuthContext';
import Styled from 'components/signin/SignInDialog.styled';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import StyledForm from 'components/signin/SignInForm.styled';
import { env } from 'environments/environment';
import { processError } from 'errors/errorUtils';
import useOauthSignInErrors from 'errors/useOauthSignInErrors';
import guid from 'utils/guid';

export interface SignInFormOAuthProps {
  proceedWithEmail: () => void;
  working: boolean;
  setWorking: (working: boolean) => void;
  children?: React.ReactNode;
}

const SignInFormOAuth: React.FC<SignInFormOAuthProps> = ({ proceedWithEmail, working, setWorking, children }) => {
  const [t] = useTranslation();
  const title = useMemo(() => t('login.title', 'Sign in to discover the best deals'), [t]);

  const {
    onSuccessFacebook,
    onFailFacebook,
    googleLogin,
    googleOAuthLoaded,
    onCallbackApple,
    oauthPayload,
    clearOAuthPayload,
  } = useContext(OAuthContext);

  const { loginOauth } = useSession();
  const { setToast } = useContext(ToastContext);
  const { callbackSuccess, setSignInDialogMode } = useContext(SignInDialogContext);

  const setOauthSignUpMode = useCallback(() => {
    setSignInDialogMode(SignInDialogMode.SignUp);
  }, [setSignInDialogMode]);

  const errorsSignIn = useOauthSignInErrors(setOauthSignUpMode);

  useEffect(() => {
    if (oauthPayload) {
      const payload: LoginOauthRequest = {
        ...oauthPayload,
        recaptcha: guid(), // TODO
      };

      setWorking(true);
      loginOauth(payload)
        .then(() => {
          setToast(t('login-popup.successful-login', 'You have logged in successfully!'), ToastType.success);
          setSignInDialogMode(undefined);
          clearOAuthPayload();
          if (callbackSuccess) {
            callbackSuccess();
          }
        })
        .catch((reason) => {
          processError(reason, errorsSignIn);
          if (!(reason instanceof ServerError) || reason.getCode() !== ServerErrorCode.ItemNotFound) {
            setWorking(false);
            clearOAuthPayload();
          }
        });
    }
  }, [
    errorsSignIn,
    loginOauth,
    oauthPayload,
    setToast,
    t,
    callbackSuccess,
    setWorking,
    setSignInDialogMode,
    clearOAuthPayload,
  ]);

  const facebookOauth = (
    <FacebookLogin
      appId={env.oauth.facebookAppId}
      fields="name,email,picture"
      onSuccess={onSuccessFacebook}
      onFail={onFailFacebook}
      render={({ onClick }) => (
        <StyledForm.AuthButton onClick={onClick} disabled={working}>
          <StyledForm.AuthIcon>
            <img src={facebookIcon} alt="facebook icon" />
          </StyledForm.AuthIcon>
          <StyledForm.AuthText>
            {t('login-popup.social.desktop', 'Continue with {provider}', {
              provider: getOauthProviderName(OauthProvider.Facebook),
            })}
          </StyledForm.AuthText>
          <StyledForm.AuthIcon />
        </StyledForm.AuthButton>
      )}
    />
  );

  const googleOauth = (
    <StyledForm.AuthButton onClick={() => googleLogin()} disabled={!googleOAuthLoaded || working}>
      <StyledForm.AuthIcon>
        <img src={googleIcon} alt="google icon" />
      </StyledForm.AuthIcon>
      <StyledForm.AuthText>
        {t('login-popup.social.desktop', 'Continue with {provider}', {
          provider: getOauthProviderName(OauthProvider.Google),
        })}
      </StyledForm.AuthText>
      <StyledForm.AuthIcon />
    </StyledForm.AuthButton>
  );

  const appleOauth = (
    <AppleLogin
      clientId={env.oauth.appleClientId}
      redirectURI={`${window.location.protocol}//${window.location.host}/apple-login`}
      usePopup={true}
      scope="name email"
      responseType="code id_token"
      responseMode="form_post"
      render={({ onClick, disabled }) => (
        <StyledForm.AuthButton onClick={onClick} disabled={disabled || working}>
          <StyledForm.AuthIcon>
            <img src={appleIcon} alt="apple icon" />
          </StyledForm.AuthIcon>
          <StyledForm.AuthText>
            {t('login-popup.social.desktop', 'Continue with {provider}', {
              provider: getOauthProviderName(OauthProvider.Apple),
            })}
          </StyledForm.AuthText>
          <StyledForm.AuthIcon />
        </StyledForm.AuthButton>
      )}
      callback={onCallbackApple}
    />
  );

  return (
    <StyledForm.Form>
      <StyledForm.Logo>
        <Icon name={'logo'} />
      </StyledForm.Logo>

      <StyledForm.Title>{title}</StyledForm.Title>

      {appleOauth}
      {googleOauth}
      {facebookOauth}

      <StyledForm.AuthButton onClick={proceedWithEmail} disabled={working}>
        <StyledForm.AuthIcon>
          <img src={emailIcon} alt="google icon" />
        </StyledForm.AuthIcon>
        <StyledForm.AuthText>
          {t('login-popup.social.desktop', 'Continue with {provider}', {
            provider: 'Email',
          })}
        </StyledForm.AuthText>
        <StyledForm.AuthIcon />
      </StyledForm.AuthButton>

      <Styled.Footer>{children}</Styled.Footer>
    </StyledForm.Form>
  );
};

export default SignInFormOAuth;
