import qs from 'qs';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MetaContext } from 'TopContexts';

export const AUTH_3DS_COMPLETE = '3DS-authentication-complete';

const Stripe3DS: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const location = useLocation();
  const { isGoogleCrawler } = useContext(MetaContext);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const q: { payment_status?: string } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (q.payment_status && window.top) {
      window.top.postMessage({ type: AUTH_3DS_COMPLETE, paymentStatus: q.payment_status }, window.parent.origin);
    } else {
      setReady(true);
    }
  }, [location]);

  return <>{(ready || isGoogleCrawler) && children}</>;
};

export default Stripe3DS;
