import { useCallback, useMemo } from 'react';

interface UseFormatDateProps {
  lang: string;
  options: Intl.DateTimeFormatOptions;
}

const useDateCallback = ({ lang, options }: UseFormatDateProps) => {
  const dateFormat = useMemo(() => new Intl.DateTimeFormat(lang, options), [lang, options]);

  return useCallback((date: Date | undefined) => dateFormat.format(date), [dateFormat]);
};

export default useDateCallback;
