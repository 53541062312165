import {
  browserVersion,
  isAndroid,
  isBrowser,
  isChrome,
  isEdge,
  isFirefox,
  isIOS,
  isMacOs,
  isMobile,
  isSafari,
  isTablet,
  isWindows,
  osName,
  osVersion,
} from 'mobile-device-detect';

/* eslint-disable no-magic-numbers */
export enum BrowserType {
  chrome = 1,
  firefox = 2,
  safari = 3,
  edge = 4,
  other = 10,
}

export enum DeviceType {
  desktop = 1,
  tablet = 2,
  mobile = 3,
  other = 10,
}

export enum PlatformType {
  Windows = 1,
  Linux = 2,
  MacOSX = 3,
  iOS = 4,
  Android = 5,
  Other = 10,
}

export function getBrowserType() {
  if (isFirefox) {
    return BrowserType.firefox;
  }
  if (isSafari) {
    return BrowserType.safari;
  }
  if (isEdge) {
    return BrowserType.edge;
  }
  if (isChrome) {
    return BrowserType.chrome;
  }

  return BrowserType.other;
}

export function getDeviceType() {
  if (isTablet) {
    return DeviceType.tablet;
  }
  if (isMobile) {
    return DeviceType.mobile;
  }
  if (isBrowser) {
    return DeviceType.desktop;
  }

  return DeviceType.other;
}

export function getDeviceTypeName(type?: DeviceType) {
  switch (type || getDeviceType()) {
    case DeviceType.mobile:
      return 'Mobile';
    case DeviceType.tablet:
      return 'Tablet';
    case DeviceType.desktop:
      return 'Desktop';
    default:
      return 'Other';
  }
}

export function getPlatformType() {
  if (isWindows) {
    return PlatformType.Windows;
  }
  if (isMacOs) {
    return PlatformType.MacOSX;
  }
  if (isIOS) {
    return PlatformType.iOS;
  }
  if (isAndroid) {
    return PlatformType.Android;
  }
  if (/Linux/.test(osName)) {
    return PlatformType.Linux;
  }

  return PlatformType.Other;
}

export function getBrowserVersion() {
  return browserVersion;
}

export function getPlatformVersion() {
  return osVersion;
}
