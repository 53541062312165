import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useOnRouteScrollingRestore = () => {
  const history = useHistory();

  useEffect(() => {
    const unregisterCallback = history.listen((_, action) => {
      if (action === 'PUSH') {
        window.scrollTo(0, 0);
      }
    });

    return () => unregisterCallback();
  }, [history]);
};

export default useOnRouteScrollingRestore;
