import { TFunction } from 'i18next';

interface Point {
  title: string;
  content: string;
}

export const HomePoints = (t: TFunction): Point[] => [
  {
    title: t('home.main.point-1', 'TRAVEL, POWERED BY TECH'),
    content: t(
      'home.main.desc-1',
      'Finding you the best vacation deals is done thanks to 6 years of perfecting Holisto’s technology, ' +
        'allowing us to scan, combine, and build you the best price possible, and ensuring it remains so until check-in.',
    ),
  },
  {
    title: t('home.main.point-2', 'DRIVEN BY YOU'),
    content: t(
      'home.main.desc-2',
      "We're fellow travelers also frustrated by expensive hotels and lack of choice, which is why we're " +
        'sharing our Algo-travel technology and optimizing it continuously to keep finding you better vacation deals.',
    ),
  },
  {
    title: t('home.main.point-3', 'WE’RE PERSISTENT'),
    content: t(
      'home.main.desc-3',
      "Prices are constantly changing, making today's best deal a mediocre one tomorrow. Holisto knows " +
        'there’s always a better deal out there, making us relentless in our mission to maintain the best price even ' +
        'after you booked, so we continue working and looking to keep you saving.',
    ),
  },
];

export const Cities = (t: TFunction): string =>
  t(
    'home.cities.circle',
    'Las Vegas  New York  Paris  London  Rome  Orlando  Madrid  Toronto  Sydney  Bangkok  San Francisco',
  );

const CIRCLE_DEGREE = 360;

export const letteringCircle = (values: string, letterDegree: number, del = '  '): string[] => {
  let res = `${del}${values}`;
  const maxLetters = Math.floor(CIRCLE_DEGREE / letterDegree);

  while (res.length < maxLetters) {
    res += res;
  }

  return [...res.slice(0, maxLetters)];
};
