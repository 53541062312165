export function getScriptElement(id: string, textContent: string, async = true): HTMLScriptElement {
  const scriptElement = document.createElement('script');

  scriptElement.type = 'text/javascript';
  scriptElement.async = async;
  scriptElement.id = id;
  scriptElement.textContent = textContent;

  return scriptElement;
}
