import { createGlobalStyle } from 'styled-components';
import CurrentTheme from 'style/theme';
import { warmBlue } from 'style/variables';

const GlobalStyles = createGlobalStyle<{ theme: typeof CurrentTheme }>`
  body {
    font-family: ${(props) => props.theme.fontFamily};
  }

  a, a:hover {
    color: ${warmBlue}
  }
`;

export default GlobalStyles;
