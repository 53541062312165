import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useContext } from 'react';
import { SignInDialogContext, ToastContext } from 'TopContexts';
import { CampaignNameQuery } from 'atoms/hooks/useWatchCampaign';
import {
  forgotPasswordAtom,
  loginAtom,
  loginOauthAtom,
  logoutAtom,
  restoreUserDetailsAtom,
  sessionAtom,
  sessionForceUpdateFlagAtom,
  signUpAtom,
  signUpOauthAtom,
  updatePasswordAtom,
  updateUserDetailsAtom,
} from 'atoms/sessionAtom';
import { clearSessionInfo, hasCookieProblem, isAuthorizationError, setSessionInfo } from 'atoms/utils/session';
import { AuthProvider, SplittyAuthProvider } from 'backend/AuthProviderStorage';
import { User } from 'backend/api/user/userModel';
import {
  LoginOauthRequest,
  LoginRequest,
  SignUpOauthRequest,
  SignUpRequest,
  UpdatePasswordRequest,
} from 'backend/api/user/userRequest';
import SplittySession from 'backend/authModelSession';
import { RawServerError, ServerError, ServerErrorCode } from 'backend/serverError';
import clientStore from 'backend/splittyClientStore';
import { ToastType } from 'components/Toast.styled';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import { processError } from 'errors/errorUtils';
import useGetUserDetailsErrors from 'errors/useGetUserDetailsErrors';
import cookieBasedCampaignStorage from 'utils/storage/cookie/CookieBasedCampaignStorage';
import cookieBasedUserIdStorage from 'utils/storage/cookie/CookieBasedUserIdStorage';
import useSearchParameter from 'utils/useSearchParameter';

const SEARCH_PARAMETERS_OPTIONS = {
  raw: true,
  replace: true,
};

export const useSession = () => {
  const { setToast } = useContext(ToastContext);
  const { setSignInDialogMode } = useContext(SignInDialogContext);
  const [session, setSession] = useAtom<SplittySession>(sessionAtom);
  const updateUserDetails = useSetAtom(updateUserDetailsAtom);
  const restoreUserDetails = useSetAtom(restoreUserDetailsAtom);
  const login = useSetAtom(loginAtom);
  const signUp = useSetAtom(signUpAtom);
  const loginOauth = useSetAtom(loginOauthAtom);
  const signUpOauth = useSetAtom(signUpOauthAtom);
  const logout = useSetAtom(logoutAtom);
  const updatePassword = useSetAtom(updatePasswordAtom);
  const forgotPassword = useSetAtom(forgotPasswordAtom);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCampaignNameParam] = useSearchParameter<CampaignNameQuery, string>(
    'campaignName',
    SEARCH_PARAMETERS_OPTIONS,
  );
  const setDirty = useSetAtom(sessionForceUpdateFlagAtom);

  const clearCampaignNameCookie = useCallback(() => {
    cookieBasedCampaignStorage.remove();
    setCampaignNameParam(undefined);
  }, [setCampaignNameParam]);

  const baseErrorHandler = useCallback(
    (response: ServerError | RawServerError) => {
      if (isAuthorizationError(response)) {
        if (hasCookieProblem()) {
          clearSessionInfo();
          setSession(new SplittySession(undefined));
          setTimeout(() => setSignInDialogMode(SignInDialogMode.SignIn));
        } else {
          clientStore.setUserId(cookieBasedUserIdStorage.get());
          setDirty(true);
        }
      }

      return Promise.reject(response);
    },
    [setDirty, setSession, setSignInDialogMode],
  );

  const getUserDetailsErrorCallback = useCallback(
    (message: string) => {
      clearSessionInfo();
      setSession(new SplittySession(undefined));
      setToast(message, ToastType.error);
    },
    [setSession, setToast],
  );

  const errors = useGetUserDetailsErrors(getUserDetailsErrorCallback);

  const updateUserDetailsCb = useCallback(
    async (payload: User) => {
      try {
        await updateUserDetails(payload);
      } catch (err) {
        baseErrorHandler(err as ServerError | RawServerError);
      }
    },
    [baseErrorHandler, updateUserDetails],
  );

  const restoreUserDetailsCb = useCallback(
    async (provider?: AuthProvider) => {
      try {
        await restoreUserDetails(provider);
      } catch (err) {
        baseErrorHandler(err as ServerError | RawServerError).catch((reason) => processError(reason, errors));
      }
    },
    [baseErrorHandler, errors, restoreUserDetails],
  );

  const loginCb = useCallback(
    async (payload: LoginRequest) => {
      const userToken = await login(payload);

      clearCampaignNameCookie();
      setSessionInfo(userToken);
      await restoreUserDetailsCb(SplittyAuthProvider.Basic);
    },
    [clearCampaignNameCookie, login, restoreUserDetailsCb],
  );

  const signUpCb = useCallback(
    async (payload: SignUpRequest) => {
      const userToken = await signUp(payload);

      clearCampaignNameCookie();
      setSessionInfo(userToken);
      await restoreUserDetailsCb(SplittyAuthProvider.Basic);
    },
    [clearCampaignNameCookie, restoreUserDetailsCb, signUp],
  );

  const loginOauthCb = useCallback(
    async (payload: LoginOauthRequest) => {
      const userToken = await loginOauth(payload);

      clearCampaignNameCookie();
      setSessionInfo(userToken);
      await restoreUserDetailsCb(payload.provider);
    },
    [clearCampaignNameCookie, loginOauth, restoreUserDetailsCb],
  );

  const signUpOauthCb = useCallback(
    async (payload: SignUpOauthRequest) => {
      const userToken = await signUpOauth(payload);

      clearCampaignNameCookie();
      setSessionInfo(userToken);
      await restoreUserDetailsCb(payload.provider);
    },
    [clearCampaignNameCookie, restoreUserDetailsCb, signUpOauth],
  );

  const logoutCb = useCallback(async () => {
    try {
      await logout();

      clearCampaignNameCookie();
      clearSessionInfo();
      setSession(new SplittySession(undefined));
    } catch (err) {
      baseErrorHandler(err as ServerError | RawServerError);
    }
  }, [baseErrorHandler, clearCampaignNameCookie, logout, setSession]);

  const updatePasswordCb = useCallback(
    async (payload: UpdatePasswordRequest, userId?: number) => {
      try {
        await updatePassword({ payload, userId });

        return Promise.resolve();
      } catch (err) {
        if (err instanceof ServerError && err.getCode() === ServerErrorCode.InvalidCredentials) {
          return Promise.reject(err);
        }

        return baseErrorHandler(err as ServerError | RawServerError);
      }
    },
    [baseErrorHandler, updatePassword],
  );

  const forgotPasswordCb = useCallback((email: string) => forgotPassword(email), [forgotPassword]);

  return {
    session,
    login: loginCb,
    signUp: signUpCb,
    logout: logoutCb,
    updateUserDetails: updateUserDetailsCb,
    updatePassword: updatePasswordCb,
    forgotPassword: forgotPasswordCb,
    loginOauth: loginOauthCb,
    signUpOauth: signUpOauthCb,
  };
};
