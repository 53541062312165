import authProviderStorage from 'backend/AuthProviderStorage';
import { UserToken } from 'backend/api/user/userModel';
import { getHttpStatus, HttpStatus, RawServerError, ServerError, ServerErrorCode } from 'backend/serverError';
import clientStore from 'backend/splittyClientStore';
import cookieBasedTokenStorage from 'utils/storage/cookie/CookieBasedTokenStorage';
import cookieBasedUserIdStorage from 'utils/storage/cookie/CookieBasedUserIdStorage';

export const isAuthorizationError = (error: ServerError | RawServerError) => {
  const httpStatus = getHttpStatus(error);

  return (
    httpStatus === HttpStatus.Unauthorized ||
    httpStatus === HttpStatus.Forbidden ||
    (error instanceof ServerError && error.getCode() === ServerErrorCode.ItemNotFound)
  );
};

export const hasCookieProblem = () => {
  const cookieUserId = cookieBasedUserIdStorage.get();

  return !cookieUserId || clientStore.getUserId() === cookieUserId;
};

export const clearSessionInfo = () => {
  authProviderStorage.remove();
  cookieBasedTokenStorage.remove();
  clientStore.setUserId(undefined);
};

export const setSessionInfo = (user: UserToken) => {
  cookieBasedTokenStorage.set(user.token);
  clientStore.setUserId(user.id);
};
