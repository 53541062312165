import styled, { css } from 'styled-components';

const AppliedFilters = styled.div<{ isMapMode?: boolean }>`
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${(props) =>
    props.isMapMode &&
    css`
      margin-left: 34px;
    `}

  & > * {
    margin-top: 8px;
    margin-right: 8px;
  }
`;

export default {
  AppliedFilters,
};
