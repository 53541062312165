import styled, { css } from 'styled-components';
import { accessibleRedSmallText, blueBlue, cornflower, gray, kiwi, pineGreen, raven, warmBlue } from 'style/variables';

const RadioInput = styled.input`
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border-radius: 50%;
  margin: 0;
  overflow: hidden;
  -webkit-appearance: none;

  height: 20px;
  width: 20px;
  vertical-align: middle;
  color: transparent;
  transition: 0.2s ease-in-out;
  transition-property: background-color, border;
  outline: none;

  border: 1px solid #979797;
  background-color: white;

  &:focus:hover,
  &:hover {
    border: solid 2px ${cornflower};
    background-color: white;
  }

  &:checked:focus,
  &:checked {
    border: 1px solid #979797;
    background-color: white;

    &:hover {
      border-width: 2px;
    }
  }

  &:focus {
    outline: none;
  }

  &:checked:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    background-color: ${cornflower};
    width: 80%;
    height: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:not(:disabled) {
    cursor: pointer;
  }
`;

const Container = styled.div<{ mobile?: boolean }>`
  max-width: 1440px;
  padding: 0 30px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;

  ${(props) =>
    props.mobile &&
    css`
      padding: 12px 24px 24px;
    `}
`;

const Button = styled.button`
  height: 50px;
  padding: 0 24px;
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.borderRadius};
  border: 0;
  cursor: pointer;

  font-family: Poppins, sans-serif;
  text-align: center;
  font-weight: bold;
  font-size: 18px;

  &:focus {
    outline: 0;
  }
`;

const PrimaryButton = styled(Button)`
  background-color: ${cornflower};
  color: #fff;

  &:hover {
    background-color: ${warmBlue};
  }

  &:active {
    background-color: #3345c6;
  }

  &:disabled {
    background-color: ${raven};
  }
`;

const GreenButton = styled(Button)`
  background-color: ${kiwi};
  color: ${pineGreen};

  &:hover {
    background-color: #71cb1b;
  }

  &:active {
    background-color: #64b219;
  }
`;

const GhostButton = styled(Button)`
  border: solid 1px ${cornflower};
  color: ${pineGreen};
  background-color: transparent;

  &:hover {
    padding: 0 23px;
    border-width: 2px;
  }

  &:active {
    padding: 0 23px;
    border-width: 2px;
    background-color: #e2e4ff;
  }

  &:disabled {
    border: solid 1px ${gray};
    color: ${raven};
  }
`;

const ButtonText = styled(Button)`
  padding: 0;
  background: none;
  margin: 0;
  color: inherit;
  text-transform: none;
  border-radius: 0;
  height: auto;

  font-size: 14px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const ButtonLink = styled(Button)`
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  background: none;
  color: ${warmBlue};
  height: auto;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }
`;

const EllipsisMultiline = styled.p<{ lines: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
`;

const ErrorLabel = styled.div<{ hasBackground?: boolean }>`
  margin-top: 1px;
  font-size: 11px;
  line-height: 1;
  color: ${(props) => (props.hasBackground ? pineGreen : accessibleRedSmallText)};
  background-color: ${(props) => (props.hasBackground ? '#f26584' : 'none')};
`;

const ErrorLabelPlaceholder = styled.div`
  margin-top: 1px;
  height: 11px;
`;

interface LetterProps {
  index: number;
  degree: number;
  offset: number;
}

const Letter = styled.span.attrs<LetterProps>((props) => ({
  style: {
    transform: `translateX(-50%) translateY(-50%) rotate(${props.offset + props.index * props.degree}deg)`,
  },
}))<LetterProps>`
  font-family: monospace;
  text-transform: uppercase;
  font-size: 16px;
`;

const TextArea = styled.textarea`
  padding: 16px 12px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid ${gray};
  color: ${pineGreen};
  font-size: 16px;
  outline: 0;

  &:focus {
    border: 1px solid ${blueBlue};
  }
`;

const RelativeDiv = styled.div`
  position: relative;
`;

export default {
  Container,
  PrimaryButton,
  GreenButton,
  GhostButton,
  ButtonText,
  ButtonLink,
  RadioInput,
  EllipsisMultiline,
  ErrorLabel,
  ErrorLabelPlaceholder,
  Letter,
  TextArea,
  RelativeDiv,
};
