import styled, { css } from 'styled-components';
import StyledCommon from 'style/Common.styled';
import { fadeInAnimation, fadeOutAnimation } from 'style/animations.styled';
import { pear, pineGreen } from 'style/variables';

export enum ToastType {
  info,
  error,
  success,
}

export enum ToastState {
  opening,
  opened,
  closing,
  closed,
}

const Toast = styled.div<{ type: ToastType; isMobile?: boolean; state?: ToastState }>`
  position: fixed;
  z-index: 1040;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1220px;
  box-sizing: border-box;
  width: 100%;

  border-radius: 2px;
  margin: 0 auto;
  padding: 12px 24px;

  font-size: 13px;
  line-height: 1.15;
  text-align: center;
  color: ${pineGreen};

  ${({ state }) => {
    switch (state) {
      case ToastState.opening:
        return fadeInAnimation;
      case ToastState.closing:
        return fadeOutAnimation;
      default:
        return '';
    }
  }}

  & > header {
    font-weight: bold;
  }

  ${(props) =>
    props.isMobile &&
    css`
      top: 6px;
      left: 8px;
      right: 8px;
      width: auto;

      padding: 14px 24px;
      transform: none;
    `}

  ${(props) => {
    switch (props.type) {
      case ToastType.success:
        return css`
          background-color: ${pear} !important;
        `;
      case ToastType.info:
        return css`
          background-color: #ff9875 !important;
        `;
      case ToastType.error:
        return css`
          background-color: #f26584 !important;
        `;
      default:
        return '';
    }
  }}
`;

const CloseButton = styled(StyledCommon.ButtonText)`
  position: absolute;

  top: 6px;
  right: 6px;

  svg {
    width: 14px;
    height: 14px;
    opacity: 0.53;
  }
`;

export default {
  Toast,
  CloseButton,
};
