import React from 'react';
import Styled from 'components/ToggleInput.styled';

interface ToggleInputProps {
  toggled: boolean;
  setToggled: (toggled: boolean) => void;
  green?: boolean;
}

const ToggleInput: React.FC<ToggleInputProps> = ({ toggled, setToggled, green }) => (
  <Styled.Toggle green={green}>
    <input type="checkbox" checked={toggled} onChange={(e) => setToggled(e.target.checked)} />
    <Styled.ToggleBox />
  </Styled.Toggle>
);

export default ToggleInput;
