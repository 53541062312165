import React from 'react';
import { Trans } from 'react-i18next';
import iconSVG from 'assets/images/icons/specialPromo.svg';
import { useContactTranslations } from 'components/hooks/useContactTranslations';
import OptionalHrefWrap from 'components/hotelComponents/listElement/OptionalHrefWrap';
import { LinkProps } from 'components/links/LinkProps';
import Styled from 'components/links/SpecialDiscountLink.styled';

export const SpecialDiscountLink: React.FC<LinkProps> = ({ onClick, target = '_blank', asLink, className }) => {
  const contactTranslations = useContactTranslations();

  return (
    <Styled.Wrapper>
      <img src={iconSVG} />
      <span>
        <Trans i18nKey="hotel.special-discount">Special discount when you call</Trans>
      </span>
      <OptionalHrefWrap
        href={`tel:${contactTranslations.discountPhone}`}
        target={target}
        onClick={() => onClick?.(contactTranslations.discountPhone)}
        asLink={asLink}
        className={className}
      >
        {contactTranslations.discountPhone}
      </OptionalHrefWrap>
    </Styled.Wrapper>
  );
};
