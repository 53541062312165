// debug mode
export const DEBUG_MODE_KEY = 'SUPER_MODE_KEY_9537830';

// i18n - missing keys
export const I18N_MISSING = 'I18N_MISSING';
// i18n - where we took current language
export const LANGUAGE_STATE = 'LANGUAGE_STATE';
export const MEMORIZED_URL_LANGUAGE = 'MEMORIZED_URL_LANGUAGE';
export const CURRENCY_STATE = 'CURRENCY_STATE';

export const CURRENT_CURRENCY = 'CURRENT_CURRENCY';
export const CURRENT_COUNTRY = 'CURRENT_COUNTRY';
export const CURRENT_STATE = 'CURRENT_STATE';

export const SHOW_COOKIES_BANNER = 'SHOW_COOKIES_BANNER';

// State of the search form
export const SEARCH_OCCUPANCY = 'SEARCH_OCCUPANCY';
export const SEARCH_CHECKIN = 'SEARCH_CHECKIN';
export const SEARCH_CHECKOUT = 'SEARCH_CHECKOUT';
export const SEARCH_DESTINATION = 'SEARCH_DESTINATION';
export const SEARCH_DEAL_KEY = 'SEARCH_DEAL_KEY';

// It is necessary that you can change the search criteria on the hotel page (mobile)
export const HOTEL_PATH = 'HOTEL_PATH';

/**
 * Storage for last N queries
 */
export const QUERY_STORAGE = 'SEARCH_STORAGE';
