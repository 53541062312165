import styled, { css } from 'styled-components';
import StyledToast from 'components/Toast.styled';
import StyledStickyPanel from 'components/header-footer/StickyPanel.styled';

const Overlay = styled.div`
  position: absolute;
  top: -25px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  opacity: 0.75;
  background-color: #eaebed;
`;

const Header = styled.header<{ shadowDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;

  & > ${StyledStickyPanel.Sticky} {
    z-index: 1000;

    & > *:not(${StyledToast.Toast}) {
      background-color: white;
    }

    ${(props) =>
      !props.shadowDisabled &&
      css`
        box-shadow:
          0 3px 7px 0 rgba(0, 0, 0, 0.06),
          0 1px 0 0 rgba(203, 203, 203, 0.5);
      `}
  }

  ${StyledToast.Toast} {
    position: absolute;
    top: unset;
    bottom: -10px;
    transform: translateY(100%) translateX(-50%);
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export default {
  Overlay,
  Header,
  Main,
};
