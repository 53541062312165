/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import ModalInfoStyled from 'components/ModalInfo.styled';
import CommonStyled from 'style/Common.styled';
import media from 'style/media';
import { flexGapColumn, flexGapRow } from 'style/polyfills/flexGap';
import { gunmetal, pineGreen, warmBlue } from 'style/variables';

const Modal = styled(ModalInfoStyled.DefaultInfoModal)<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 475px;
  padding: 62px 44px 40px;

  svg {
    z-index: 1;
    position: absolute;
    top: 19px;
    right: 19px;
    width: ${(props) => (props.isMobile ? 24 : 20)}px;
    height: ${(props) => (props.isMobile ? 24 : 20)}px;
    cursor: pointer;
    color: ${(props) => (props.isMobile ? pineGreen : gunmetal)};

    &:hover {
      color: ${warmBlue};
    }
  }

  header {
    margin-bottom: ${(props) => (props.isMobile ? 28 : 14)}px;
    font-size: ${(props) => (props.isMobile ? 18 : 22)}px;
  }

  div {
    font-size: 14px;
  }

  ${(props) =>
    !!props.isMobile &&
    css`
      padding: 70px 24px 40px;
      border-radius: 0;
      min-width: auto;
      width: 100%;
      text-align: center;
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.31);
    `}
`;

const ModalActions = styled.div`
  display: flex;
  width: 100%;

  ${media.mobile} {
    flex-direction: column;
    ${flexGapColumn('42px')}
    margin-top: 42px;
  }

  ${media.desktop} {
    flex-direction: row;
    ${flexGapRow('15px')}
    margin-top: 60px;
    justify-content: flex-end;
  }
`;

const ImageStyle = styled.img`
  position: absolute;
  width: 150px;
  bottom: 0;
  right: 0;
  -webkit-user-drag: none;
`;

const ProceedButton = styled(CommonStyled.PrimaryButton)<{ fullWidth?: boolean }>`
  width: ${(props) => (props.fullWidth ? '100%' : '')};
  min-height: 50px;
  height: unset;
`;

const CancelButton = styled(CommonStyled.GhostButton)<{ fullWidth?: boolean }>`
  width: ${(props) => (props.fullWidth ? '100%' : '')};
  padding-top: 1px;
  padding-bottom: 1px;
  min-height: 50px;
  height: unset;
`;

const CancelButtonLink = styled(CommonStyled.ButtonLink)`
  text-align: center;
  min-height: 50px;
`;

export default {
  Modal,
  ModalActions,
  ProceedButton,
  CancelButton,
  CancelButtonLink,
  ImageStyle,
};
