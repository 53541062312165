import { useMemo } from 'react';
import { dayNumericFormat, monthShortFormat } from 'components/dates/dateFormats';
import useDateCallback from 'components/dates/useDateCallback';

interface UseFormatDatePrp {
  lang: string;
  date: Date | null;
}

export interface DatepickerDate {
  short: string;
  long: string;
}

const DATEPICKER_YEAR_SLICED = -2;
const useDatepickerDate = ({ lang, date }: UseFormatDatePrp): DatepickerDate => {
  const monthCallback = useDateCallback({ lang, options: monthShortFormat });
  const dayCallback = useDateCallback({ lang, options: dayNumericFormat });

  return useMemo(() => {
    if (!date) {
      return { long: ' ', short: ' ' };
    }

    const m = monthCallback(date);
    const da = dayCallback(date);
    const year = date.getFullYear().toString(10).slice(DATEPICKER_YEAR_SLICED);

    return {
      long: `${da} ${m} ${year}`,
      short: `${m} ${da}`,
    };
  }, [monthCallback, date, dayCallback]);
};

export default useDatepickerDate;
