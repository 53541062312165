export interface RouteData {
  path: string | string[];
  exact?: boolean;
  pageType: string;
  title?: string;
  webviewNoFooter?: boolean;
  noFooter?: boolean;
}

export type RouteList = {
  home: RouteData;
  destination: RouteData;
  booking: RouteData;
  faq: RouteData;
  aboutUs: RouteData;
  terms: RouteData;
  privacyPolicy: RouteData;
  priceGuarantee: RouteData;
  hotelsRedirect: RouteData;
  destinationsRedirect: RouteData;
  hotels: RouteData;
  checkout: RouteData;
  confirmation: RouteData;
  additionalPayment: RouteData;
  additionalPaymentConfirmation: RouteData;
  userProfile: RouteData;
  resetPassword: RouteData;
  errorWithId: RouteData;
  errorNotFound: RouteData;
  i18debug: RouteData;
};

export const DESTINATIONS_ROUTE = '/destinations/';
export const HOTELS_ROUTE = '/hotels/';
export const HOTELS_ROUTE_REDIRECT = '/hotels/redirect';
export const DESTINATIONS_ROUTE_REDIRECT = '/redirect';

export const ERRORS_ROUTE = '/error';
export const ERRORS_ROUTE_CODE = `${ERRORS_ROUTE}/:id/:type(code)/:code/:message?`;
export const ERRORS_ROUTE_MESSAGE = `${ERRORS_ROUTE}/:id/:type(msg)/:message?/:internalMessage?`;
export const ERRORS_ROUTE_KEY = `${ERRORS_ROUTE}/:id/:type(key)/:message?/:param?`;
export const ERRORS_ROUTE_NOT_FOUND = `${ERRORS_ROUTE}/404`;
export const DEBUG_I18N_ROUTE = '/debug/i18n';

export const ABOUT_US_ROUTE = '/about-us';
export const FAQ_ROUTE = '/faq';
export const TERMS_ROUTE = '/terms-and-conditions';
export const PRIVACY_ROUTE = '/privacy-policy';
export const PRICE_GUARANTEE_ROUTE = '/price-guarantee';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const BOOKINGS_ROUTE = '/bookings';
export const USER_PROFILE_ROUTE = '/account';
export const CHECKOUT_ROUTE = '/checkout';
export const CONFIRMATION_ROUTE = '/confirmation';
export const ADDITIONAL_PAYMENT_ROUTE = '/trips/:encryptedTripId/payment-requests/:paymentRequestId';
export const ADDITIONAL_PAYMENT_CONFIRMATION_ROUTE =
  '/trips/:encryptedTripId/payment-requests/:paymentRequestId/confirmation';

export const routes: RouteList = {
  home: {
    path: '/',
    exact: true,
    pageType: 'homePage',
    title: 'Holisto: Better Hotel Deals',
  },
  destination: {
    path: DESTINATIONS_ROUTE,
    pageType: 'destinationsPage',
  },
  booking: {
    path: BOOKINGS_ROUTE,
    exact: true,
    pageType: 'bookingsPage',
    title: 'My Trips page',
    noFooter: true,
  },
  faq: {
    path: FAQ_ROUTE,
    exact: true,
    pageType: 'faq',
    title: 'FAQ',
    webviewNoFooter: true,
  },
  aboutUs: {
    path: ABOUT_US_ROUTE,
    exact: true,
    pageType: 'aboutUs',
    title: 'About us',
    webviewNoFooter: true,
  },
  terms: {
    path: TERMS_ROUTE,
    exact: true,
    pageType: 'terms',
    title: 'Terms and conditions',
    webviewNoFooter: true,
  },
  privacyPolicy: {
    path: PRIVACY_ROUTE,
    exact: true,
    pageType: 'privacy',
    title: 'Privacy policy',
    webviewNoFooter: true,
  },
  priceGuarantee: {
    path: PRICE_GUARANTEE_ROUTE,
    exact: true,
    pageType: 'priceGuarantee',
    title: 'Price guarantee',
    webviewNoFooter: true,
  },
  hotelsRedirect: {
    path: HOTELS_ROUTE_REDIRECT,
    pageType: 'hotelsRedirect',
  },
  destinationsRedirect: {
    path: DESTINATIONS_ROUTE_REDIRECT,
    pageType: 'destinationsRedirect',
  },
  hotels: {
    path: HOTELS_ROUTE,
    pageType: 'hotels',
  },
  checkout: {
    path: `${CHECKOUT_ROUTE}/:checkoutId`,
    exact: true,
    pageType: 'checkout',
    title: 'Holisto: Review and Pay',
  },
  confirmation: {
    path: `${CONFIRMATION_ROUTE}/:encryptedTripId`,
    exact: true,
    pageType: 'confirmation',
    title: 'Confirmation page',
  },
  additionalPayment: {
    path: ADDITIONAL_PAYMENT_ROUTE,
    exact: true,
    pageType: 'additionalPayment',
    title: 'Additional payment page',
  },
  additionalPaymentConfirmation: {
    path: ADDITIONAL_PAYMENT_CONFIRMATION_ROUTE,
    exact: true,
    pageType: 'additionalPaymentConfirmation',
    title: 'Additional payment confirmation page',
  },
  userProfile: {
    path: USER_PROFILE_ROUTE,
    exact: true,
    pageType: 'userProfile',
    title: 'My account page',
  },
  resetPassword: {
    path: RESET_PASSWORD_ROUTE,
    exact: true,
    pageType: 'resetPassword',
    title: 'Holisto: Better Hotel Deals',
  },
  errorWithId: {
    path: [ERRORS_ROUTE_CODE, ERRORS_ROUTE_KEY, ERRORS_ROUTE_MESSAGE],
    exact: true,
    pageType: 'error',
  },
  errorNotFound: {
    path: ERRORS_ROUTE_NOT_FOUND,
    exact: true,
    pageType: 'error',
  },
  i18debug: {
    path: DEBUG_I18N_ROUTE,
    exact: true,
    pageType: 'debug',
  },
};
