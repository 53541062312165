import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

export const useSessionStorage = (
  key: string,
  initialValue = '',
): [string, Dispatch<SetStateAction<string>>, () => void] => {
  const [item, setItem] = useState(() => {
    const value = sessionStorage.getItem(key) || initialValue;

    sessionStorage.setItem(key, value);

    return value;
  });

  const clear = useCallback(() => {
    sessionStorage.removeItem(key);
  }, [key]);

  const setValue = useCallback(
    (value) => {
      setItem(value);
      sessionStorage.setItem(key, value);
    },
    [key],
  );

  return [item, setValue, clear];
};

const useSessionStorageWithObject = <T>(key: string, initialValue: T): [T, Dispatch<SetStateAction<T>>, () => void] => {
  const [state, setState, clear] = useSessionStorage(key, JSON.stringify(initialValue));
  const [item, setItem] = useState<T>(JSON.parse(state));

  useEffect(() => {
    setState(JSON.stringify(item));
  }, [item, setState]);

  return [item, setItem, clear];
};

export default useSessionStorageWithObject;
