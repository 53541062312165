import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from 'TopContexts';
import { ServerErrorCode } from 'backend/serverError';
import { ToastType } from 'components/Toast.styled';
import { AvailableErrors } from 'errors/errorData';

const useRecoveryPasswordErrors = (): AvailableErrors => {
  const [t] = useTranslation();
  const { setToast } = useContext(ToastContext);

  return useMemo(
    () => ({
      known: [
        {
          serverCodes: [ServerErrorCode.InactiveBlockedAccount],
          action: () => setToast(t('login-popup.recovery-account-blocked', 'This account is blocked'), ToastType.error),
        },
        {
          serverCodes: [ServerErrorCode.ItemNotFound],
          action: () =>
            setToast(t('login-popup.recovery-email-not-sent', 'This email is not registered'), ToastType.error),
        },
      ],
      default: {
        action: () => setToast(t('login-popup.recovery-email-error', 'Something went wrong'), ToastType.error),
      },
    }),
    [setToast, t],
  );
};

export default useRecoveryPasswordErrors;
