import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LinkProps } from 'components/links/LinkProps';
import { ABOUT_US_ROUTE } from 'routeList';

const AboutUsLink: React.FC<LinkProps> = ({ onClick, target = '_blank', dataTestid }) => {
  const [t] = useTranslation();
  const linkText = useMemo(() => t('common.menu.about-us', 'About us'), [t]);

  return (
    <Link to={ABOUT_US_ROUTE} target={target} onClick={() => onClick?.(linkText)} data-testid={dataTestid}>
      {linkText}
    </Link>
  );
};

export default AboutUsLink;
