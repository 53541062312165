import { LoginResponse } from '@greatsumini/react-facebook-login/dist/types/response.type';
import { GoogleOAuthProvider, NonOAuthError, TokenResponse, useGoogleLogin } from '@react-oauth/google';
import React, { useCallback, useState } from 'react';
import { OauthProvider } from 'backend/api/user/userModel';
import { AppleLoginError, AppleLoginResponse, isAppleLoginError } from 'components/signin/appleUtils';
import { env } from 'environments/environment';
import { ClientError, ClientErrorCode } from 'errors/clientError';
import { processError } from 'errors/errorUtils';
import useOauthErrors from 'errors/useOauthErrors';
import { log } from 'utils/logger';
import { notEmpty } from 'utils/stringUtils';

export interface OauthSignupPayload {
  token: string;
  provider: OauthProvider;
  user?: {
    firstName: string;
    lastName: string;
  };
}

interface Context {
  googleOAuthLoaded: boolean;
  googleLogin: () => void;
  onCallbackApple: (response: AppleLoginResponse | AppleLoginError) => void;
  onSuccessFacebook: (authResponse: LoginResponse['authResponse']) => void;
  onFailFacebook: (reason: { status: string }) => void;
  oauthPayload: OauthSignupPayload | undefined;
  clearOAuthPayload: () => void;
}

export const OAuthContext = React.createContext<Context>({
  googleOAuthLoaded: false,
  googleLogin: () => undefined,
  onCallbackApple: () => undefined,
  onSuccessFacebook: () => undefined,
  onFailFacebook: () => undefined,
  oauthPayload: undefined,
  clearOAuthPayload: () => undefined,
});

interface OAuthInternalProviderProps {
  googleOAuthLoaded: boolean;
  children?: React.ReactNode;
}

const OAuthInternalProvider: React.FC<OAuthInternalProviderProps> = ({ googleOAuthLoaded, children }) => {
  const [oauthPayload, setOauthPayload] = useState<OauthSignupPayload>();
  const errors = useOauthErrors();

  const onSuccessFacebook = useCallback((authResponse: LoginResponse['authResponse']) => {
    if (authResponse) {
      setOauthPayload({ token: authResponse.accessToken, provider: OauthProvider.Facebook });
    }
  }, []);

  const onSuccessGoogle = useCallback((response: TokenResponse) => {
    if (response) {
      setOauthPayload({ token: response.access_token, provider: OauthProvider.Google });
    }
  }, []);

  const onFailFacebook = useCallback(
    (reason: { status: string }) => {
      log(`oauth error: ${JSON.stringify(reason)}`);
      processError(new ClientError(reason.status as ClientErrorCode), errors);
    },
    [errors],
  );
  const onFailGoogle = useCallback(
    (reason: NonOAuthError & { message?: string }) => {
      log(`oauth error: ${JSON.stringify(reason)}`);
      processError(
        new ClientError(reason.type as ClientErrorCode, reason.message ? [reason.message] : undefined),
        errors,
      );
    },
    [errors],
  );
  const onFailGoogleResponse = useCallback(
    (reason: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
      log(`oauth error: ${reason.error} ${reason.error_description}`);
      const messages = [reason.error, reason.error_description].filter(notEmpty);

      processError(new ClientError(ClientErrorCode.GoogleResponseError, messages), errors);
    },
    [errors],
  );

  const googleLogin = useGoogleLogin({
    onSuccess: onSuccessGoogle,
    onError: onFailGoogleResponse,
    onNonOAuthError: onFailGoogle,
  });

  const onCallbackApple = useCallback((response: AppleLoginResponse | AppleLoginError) => {
    if (isAppleLoginError(response)) {
      log(`oauth error: ${JSON.stringify(response.error)}`);
    } else {
      setOauthPayload({
        token: response.authorization.id_token,
        provider: OauthProvider.Apple,
        user: response.user?.name,
      });
    }
  }, []);

  const clearOAuthPayload = useCallback(() => {
    setOauthPayload(undefined);
  }, []);

  return (
    <OAuthContext.Provider
      value={{
        googleOAuthLoaded,
        onSuccessFacebook,
        onFailFacebook,
        googleLogin,
        onCallbackApple,
        oauthPayload,
        clearOAuthPayload,
      }}
    >
      {children}
    </OAuthContext.Provider>
  );
};

export const OAuthProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [googleOAuthLoaded, setGoogleOAuthLoaded] = useState(false);

  return (
    <GoogleOAuthProvider clientId={env.oauth.googleAppId} onScriptLoadSuccess={() => setGoogleOAuthLoaded(true)}>
      <OAuthInternalProvider googleOAuthLoaded={googleOAuthLoaded}>{children}</OAuthInternalProvider>
    </GoogleOAuthProvider>
  );
};

export function getOauthProviderName(provider?: OauthProvider) {
  switch (provider) {
    case OauthProvider.Facebook:
      return 'Facebook';
    case OauthProvider.Google:
      return 'Google';
    case OauthProvider.Apple:
      return 'Apple';
    default:
      return '';
  }
}
