import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  boundsAtom,
  checkinFromStorageAtom,
  checkoutCheckinInnerAtom,
  checkoutFromStorageAtom,
  destinationAtom,
  occupancyStorageAtom,
  placeIdAtom,
} from 'atoms/searchStateAtoms';
import { calculateCheckinCheckoutValues } from 'atoms/utils/search';
import { Place } from 'backend/api/place/placeModel';
import { RoomOccupancy } from 'backend/api/trip/tripModel';
import { getDataProvider } from 'backend/dataProvider';
import { SEARCH_DESTINATION, SEARCH_OCCUPANCY } from 'backend/localStorageKeys';
import useSearchFormCallback from 'components/searchForm/useSearchFormCallback';
import useSearchFormParameters from 'components/searchForm/useSearchFormParameters';
import { env } from 'environments/environment';
import { processError } from 'errors/errorUtils';
import usePlaceErrors from 'errors/usePlaceErrors';
import { getInitialCheckin, getInitialCheckout } from 'utils/dateUtils';
import { defaultOccupancy } from 'utils/occupancyUtils';
import { createHotelSearchUri, isHotelOrDestinationRoute, isHotelRoute } from 'utils/uriUtils';
import useLocalStorage from 'utils/useLocalStorage';

export const useWatchSearchState = () => {
  const { pathname } = useLocation();
  const [placeId, setPlaceId] = useAtom(placeIdAtom);
  const formParameters = useSearchFormParameters();
  const [checkinFromStorage, setCheckinFromStorage] = useAtom(checkinFromStorageAtom);
  const [checkoutFromStorage, setCheckoutFromStorage] = useAtom(checkoutFromStorageAtom);
  const [innerCheckinCheckout, setInnerCheckinCheckout] = useAtom(checkoutCheckinInnerAtom);
  const { checkin = '', checkout = '' } = innerCheckinCheckout || {};
  const [occupancy, setOccupancy] = useAtom(occupancyStorageAtom);
  const setBounds = useSetAtom(boundsAtom);
  const [occupancyLocalStorage, setOccupancyLocalStorage] = useLocalStorage<RoomOccupancy[]>(
    SEARCH_OCCUPANCY,
    formParameters?.occupancy || defaultOccupancy(env.searchBar),
  );
  const [destination, setDestination] = useAtom(destinationAtom);
  const [backDestinationsUrl] = useState(() =>
    createHotelSearchUri(destination?.label, destination?.id, undefined, checkin, checkout, occupancy),
  );
  const clearPlaceId = useCallback(() => setPlaceId(undefined), [setPlaceId]);
  const placeErrors = usePlaceErrors(backDestinationsUrl, clearPlaceId);
  const loading = useRef(false);
  const isHotelPage = useMemo(() => isHotelRoute(pathname), [pathname]);

  useEffect(() => {
    const initialCheckin = getInitialCheckin(formParameters?.checkin);
    const initialCheckout = getInitialCheckout(initialCheckin, formParameters?.checkout);

    setCheckinFromStorage(initialCheckin);
    setCheckoutFromStorage(initialCheckout);
    setInnerCheckinCheckout({ checkin: initialCheckin, checkout: initialCheckout });
    setOccupancy(occupancyLocalStorage);
    setPlaceId(formParameters?.placeId);
    setBounds(formParameters?.bounds);

    try {
      const item = window.localStorage.getItem(SEARCH_DESTINATION);

      setDestination(item ? (JSON.parse(item) as Place) : undefined);
    } catch (e) {
      setDestination(undefined);
    }
    // only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { updateSearchQuery } = useSearchFormCallback(placeId);

  // formParameters need deep compare
  useDeepCompareEffect(() => {
    if (formParameters?.occupancy !== undefined) {
      setOccupancy(formParameters.occupancy);
      setOccupancyLocalStorage(formParameters.occupancy);
    }
    if (formParameters?.placeId !== undefined) {
      setPlaceId(formParameters.placeId);
      if (formParameters.placeId !== destination?.id) {
        setDestination(undefined);
      }
    }
    const { checkin: _checkin, checkout: _checkout } = calculateCheckinCheckoutValues(
      pathname,
      formParameters?.checkin,
      formParameters?.checkout,
      checkinFromStorage,
      checkoutFromStorage,
    );

    setInnerCheckinCheckout({ checkin: _checkin ?? '', checkout: _checkout ?? '' });
  }, [
    checkinFromStorage,
    checkoutFromStorage,
    destination?.id,
    formParameters,
    pathname,
    setDestination,
    setInnerCheckinCheckout,
    setOccupancy,
    setOccupancyLocalStorage,
    setPlaceId,
  ]);

  useEffect(() => {
    if (
      (!formParameters?.checkin || !formParameters.checkout || !formParameters.occupancy) &&
      formParameters?.placeId
    ) {
      if (isHotelOrDestinationRoute(pathname) && formParameters) {
        updateSearchQuery(checkin, checkout, occupancy, formParameters);
      }
    }
  }, [checkin, checkout, formParameters, pathname, occupancy, updateSearchQuery]);

  useEffect(() => {
    if (!loading.current && placeId && destination?.id !== placeId && (!isHotelPage || backDestinationsUrl)) {
      loading.current = true;
      setDestination(undefined);
      getDataProvider()
        .then((dataProvider) => dataProvider.getDestination(placeId))
        .then((place) => {
          setPlaceId(place?.id);
          setDestination(place);
        })
        .catch((reason) => processError(reason, placeErrors))
        .finally(() => {
          loading.current = false;
        });
    }
  }, [backDestinationsUrl, destination?.id, isHotelPage, loading, placeErrors, placeId, setDestination, setPlaceId]);
};
