import styled, { css } from 'styled-components';
import StyledOptionalHrefWrap from 'components/hotelComponents/listElement/OptionalHrefWrap.styled';
import CareersLinkComponent from 'components/links/CareersLink';
import StyledCommon from 'style/Common.styled';
import { flexGapColumn, flexGapRow } from 'style/polyfills/flexGap';
import { cornflower, gunmetal, veryDarkGray, veryLightPink } from 'style/variables';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${flexGapColumn('20px')}
  padding: 24px 0;
  border-bottom: solid 1px ${veryLightPink};
  font-size: 16px;
  color: ${veryDarkGray};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 24px;

  & > ${Section}:first-child {
    padding-top: 0;

    & > div:first-child {
      margin-top: 10px;
    }
  }
`;

const Title = styled.div<{ center?: boolean }>`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: ${(props) => props.theme.letterSpacing};
  text-transform: uppercase;

  & > ${StyledCommon.ButtonLink} {
    font-size: 12px;
    white-space: nowrap;
    margin-right: 6px;
  }

  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}

  ${(props) =>
    !props.center &&
    css`
      margin-left: 52px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      ${flexGapRow('12px')}
    `}
`;

const NavbarItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;

  & > svg,
  & > img {
    width: 24px;
    height: 24px;
    color: ${cornflower} !important;
    margin-right: 16px;
  }

  font-size: inherit;
  color: inherit;
`;

const CareersLink = styled(CareersLinkComponent)``;

const Links = styled(Section)`
  border-bottom: none;
  ${flexGapColumn('26px')}
  font-size: 14px;
  color: ${gunmetal};

  & > a {
    color: inherit !important;
    width: 100%;
  }

  ${CareersLink} {
    color: ${cornflower} !important;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 24px 0;
  font-size: 12px;
  color: ${gunmetal};

  ${NavbarItem} {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > svg,
    & > img {
      margin-right: 0;
      margin-bottom: 4px;
    }
  }

  & > ${StyledOptionalHrefWrap.LinkWrap} {
    min-width: 116px;
    flex-grow: 1;
  }

  & > ${StyledOptionalHrefWrap.LinkWrap} + ${StyledOptionalHrefWrap.LinkWrap} {
    border-left: solid 1px ${veryLightPink};
  }
`;

export default {
  Section,
  Container,
  Title,
  NavbarItem,
  Links,
  Footer,
  CareersLink,
};
