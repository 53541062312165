import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SignInDialogContext, ToastContext } from 'TopContexts';
import eyeOff from 'assets/images/icons/eye/icons-eye-off.svg';
import eyeOn from 'assets/images/icons/eye/icons-eye-on.svg';
import { useSession } from 'atoms/hooks/useSession';
import { LoginRequest } from 'backend/api/user/userRequest';
import InputField, { InputFieldRef } from 'components/InputField';
import { ToastType } from 'components/Toast.styled';
import { validationRule } from 'components/signin/PasswordValidationBox';
import Styled from 'components/signin/SignInDialog.styled';
import useValidationCallback from 'components/useValidationCallback';
import { processError } from 'errors/errorUtils';
import useSignInErrors from 'errors/useSignInErrors';
import guid from 'utils/guid';
import { emailValidationRule, passwordErrorMessage } from 'utils/validation';

export interface SignInFormEmailProps {
  onForgotPassword: (email: string) => void;
  working: boolean;
  setWorking: (value: boolean) => void;
  children?: React.ReactNode;
}

const SignInFormEmail: React.FC<SignInFormEmailProps> = ({ onForgotPassword, working, setWorking, children }) => {
  const [t] = useTranslation();
  const signInBtnText = useMemo(() => t('login-popup.signin-button', 'Sign In'), [t]);
  const emailRef = useRef<InputFieldRef>();
  const passwordRef = useRef<InputFieldRef>();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState<string>('');

  const [loginError, setLoginError] = useState<string>();
  const errors = useSignInErrors(setLoginError);

  const { validation } = useValidationCallback({ fields: [emailRef, passwordRef] });

  const { setToast } = useContext(ToastContext);
  const { login } = useSession();
  const { callbackSuccess, setSignInDialogMode } = useContext(SignInDialogContext);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = useCallback(() => setShowPassword(!showPassword), [showPassword]);

  const emailChanged = useCallback(
    (changedEmail: string) => {
      setLoginError(undefined);
      setEmail(changedEmail.trim());
    },
    [setLoginError, setEmail],
  );

  const passwordChanged = useCallback(
    (p: string) => {
      setLoginError(undefined);
      setPassword(p);
    },
    [setLoginError, setPassword],
  );

  const signIn = useCallback(() => {
    const errorMessages = validation(true);

    if (errorMessages.length > 0) {
      return;
    }

    setWorking(true);
    const payload: LoginRequest = {
      username: email,
      password,
      recaptcha: guid(), // TODO
    };

    login(payload)
      .then(() => {
        setSignInDialogMode(undefined);
        setToast(t('login-popup.successful-login', 'You have logged in successfully!'), ToastType.success);
        if (callbackSuccess) {
          callbackSuccess();
        }
      })
      .catch((reason) => {
        processError(reason, errors);
        setWorking(false);
      });
  }, [validation, setWorking, email, password, login, setSignInDialogMode, setToast, t, callbackSuccess, errors]);

  const eyeOnButton = (
    <Styled.EyeIcon onClick={toggleShowPassword}>
      {!showPassword && <img src={eyeOn} alt="eye-on" />}
      {showPassword && <img src={eyeOff} alt="eye-off" />}
    </Styled.EyeIcon>
  );

  return (
    <Styled.Form>
      <Styled.Title>{t('common.misc.welcome-back', 'Welcome back')}</Styled.Title>

      {loginError && <Styled.Error>{loginError}</Styled.Error>}

      <InputField
        id={'id-signin-email'}
        ref={emailRef}
        containerStyle={'uk-margin-top'}
        isRequired={true}
        inputType={'text'}
        inputMode={'email'}
        label={t('login-popup.email', 'Email Address')}
        value={email}
        onChange={emailChanged}
        errorMessage={t('login-popup.invalid-email', 'Invalid email')}
        autocomplete={'email'}
        doBasicValidation={true}
        validationRule={emailValidationRule}
        allowHotJarRecording={true}
        errorHasBackground
      />

      <InputField
        id={'id-signin-password'}
        ref={passwordRef}
        containerStyle={'uk-margin-top'}
        isRequired={true}
        inputType={showPassword ? 'text' : 'password'}
        label={t('login-popup.password', 'Password')}
        value={password}
        onChange={passwordChanged}
        autocomplete={'off'}
        doBasicValidation={false}
        onEnter={signIn}
        errorMessage={passwordErrorMessage(t)}
        validationRule={validationRule}
        allowHotJarRecording={false}
        innerButton={eyeOnButton}
        errorHasBackground
      />

      <Styled.Button onClick={signIn} disabled={working}>
        {signInBtnText}
      </Styled.Button>

      <Styled.Footer>
        <Styled.FooterLink
          onClick={() => {
            onForgotPassword(email);
          }}
        >
          <Trans i18nKey="login-popup.forgot-password">Forgot your password?</Trans>
        </Styled.FooterLink>
        {children}
      </Styled.Footer>
    </Styled.Form>
  );
};

export default SignInFormEmail;
