type EnumType = { [key: number]: string | number };

export function iterateOverEnum<T extends EnumType>(e: T, handler: (el: T[keyof T]) => void): void {
  const keys = Object.keys(e);

  for (let randomKeyIndex = 0; randomKeyIndex < keys.length; randomKeyIndex++) {
    const randomKey = keys[randomKeyIndex];

    // Numeric enums members also get a reverse mapping from enum values to enum names.
    // So, if a key is a number, actually it's a value of a numeric enum.
    // see https://www.typescriptlang.org/docs/handbook/enums.html#reverse-mappings
    const randomKeyNumber = Number(randomKey);

    if (Number.isNaN(randomKeyNumber)) {
      const el = e[randomKey as keyof T];

      handler(el);
    }
  }
}

export function listEnum<T extends EnumType>(e: T): T[keyof T][] {
  const ret: T[keyof T][] = [];

  iterateOverEnum(e, (el) => {
    ret.push(el);
  });

  return ret;
}

export function mapEnum<T extends EnumType, S>(e: T, mapper: (el: T[keyof T]) => S): S[] {
  const ret: S[] = [];

  iterateOverEnum(e, (el) => {
    ret.push(mapper(el));
  });

  return ret;
}

export class EnumIterator<T extends EnumType> {
  readonly list: T[keyof T][];

  constructor(e: T) {
    this.list = listEnum(e);
  }
}
