import styled, { css } from 'styled-components';
import { DAY_WIDTH } from 'components/datePicker/customDatepicker/CustomDatepicker.styled';
import { rem } from 'style/functions';
import { gray, gunmetal, kiwi, warmBlue, white } from 'style/variables';

const DayMeta = styled.button<{ isToday?: boolean }>`
  cursor: pointer;
  position: relative;
  height: ${rem(DAY_WIDTH)};
  border: 0;
  outline: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 0.88;
  margin: 0 !important;
  padding: 0;
  color: ${gunmetal};

  ${(props) =>
    props.isToday &&
    css`
      &:after {
        content: '';
        position: absolute;
        bottom: 5px;
        left: calc(50% - 3.5px);
        border-radius: 50%;
        width: 7px;
        height: 7px;
        background-color: ${kiwi};
      }
    `}
`;

const DayDefault = styled(DayMeta)<{ selected?: boolean }>`
  background: white;

  ${({ selected }) =>
    selected &&
    css`
      position: relative;
      z-index: 0;
      background: rgba(106, 116, 246, 0.2);
    `}
`;

const DaySelectedMeta = styled(DayMeta)`
  position: relative;
  z-index: 1;
  color: white;
  background: transparent;
  font-weight: 600;

  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
  }
`;

const DaySelectedFirst = styled(DaySelectedMeta)`
  &:after {
    width: ${rem(DAY_WIDTH)};
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%);
    background: ${warmBlue};
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 50%;
    background: rgba(106, 116, 246, 0.2);
  }
`;

const DaySelectedLast = styled(DaySelectedMeta)`
  &:after {
    width: ${rem(DAY_WIDTH)};
    right: 50%;
    border-radius: 50%;
    transform: translate(50%);
    background: ${warmBlue};
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 50%;
    width: 50%;
    background: rgba(106, 116, 246, 0.2);
  }
`;

const DayDisabled = styled(DayMeta)`
  color: ${gray};
  background: ${white};
`;

export default {
  DayDefault,
  DayDisabled,
  DaySelectedFirst,
  DaySelectedLast,
};
