import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { SHOW_COOKIES_BANNER } from 'backend/localStorageKeys';
import Styled from 'components/CookiePanel.styled';
import { LayoutContext } from 'components/contexts/LayoutContext';
import StyledCommon from 'style/Common.styled';
import useCookie from 'utils/useCookie';

const BANNER_COOKIE_LIFETIME_DAYS = 365;

const CookiePanel: React.FC = () => {
  const { isMobileLayout } = useContext(LayoutContext);
  const { cookie, updateCookie } = useCookie(SHOW_COOKIES_BANNER, 'none');

  if (cookie !== 'none') {
    return <React.Fragment />;
  }

  return (
    <Styled.Cookie isMobile={isMobileLayout}>
      <div>
        <Trans i18nKey="common.misc.cookie-panel">
          We use cookies on our site, to ensure you will get the best experience, click accept to continue.
        </Trans>
      </div>
      <StyledCommon.PrimaryButton
        data-testid="cookie-accept-button"
        id="cookieAccept"
        type="button"
        onClick={() => updateCookie(SHOW_COOKIES_BANNER, BANNER_COOKIE_LIFETIME_DAYS)}
      >
        <Trans i18nKey="common.misc.accept">Accept</Trans>
      </StyledCommon.PrimaryButton>
    </Styled.Cookie>
  );
};

export default CookiePanel;
