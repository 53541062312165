import { Place } from 'backend/api/place/placeModel';

export default class DestinationsList {
  readonly suggestions: Place[];

  readonly query: string;

  readonly hasSuggestions: boolean;

  readonly isQueryResult: boolean;

  constructor(destinations: Place[], query: string) {
    this.suggestions = destinations;
    this.query = query;
    this.hasSuggestions = destinations.length > 0;
    this.isQueryResult = query !== '';
  }
}
