import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from 'TopContexts';
import { ServerErrorCode } from 'backend/serverError';
import { ToastType } from 'components/Toast.styled';
import { AvailableErrors } from 'errors/errorData';

const useOauthSignInErrors = (setOauthSignUpMode: () => void): AvailableErrors => {
  const [t] = useTranslation();
  const { setToast } = useContext(ToastContext);

  return useMemo(
    () => ({
      known: [
        {
          serverCodes: [ServerErrorCode.InvalidCredentials],
          action: () =>
            setToast(t('oauth.invalid-credentials', 'Invalid credentials. Please try again.'), ToastType.error),
        },
        {
          serverCodes: [ServerErrorCode.InactiveBlockedAccount],
          action: () =>
            setToast(t('login-popup.blocked-account', 'Your account is inactive or blocked.'), ToastType.error),
        },
        {
          serverCodes: [ServerErrorCode.Forbidden],
          action: () =>
            setToast(
              t('login-popup.recaptcha-failed', 'Captcha verification failed. Please try again.'),
              ToastType.error,
            ),
        },
        {
          serverCodes: [ServerErrorCode.ItemNotFound],
          action: () => setOauthSignUpMode(),
        },
      ],
      default: {
        action: () => setToast(t('login-popup.signin-failed', 'Signing in failed. Please try again.'), ToastType.error),
      },
    }),
    [setOauthSignUpMode, setToast, t],
  );
};

export default useOauthSignInErrors;
