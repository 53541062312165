import jsc from 'js-cookie';
import cookieBasedUserIdStorage from 'utils/storage/cookie/CookieBasedUserIdStorage';

/**
 * Name of the cookie to store lead id
 */
const LEAD_ID = 'LEAD_ID';

interface LeadIdDependencies {
  leadId: number;
  campaignName: string;
  hotelId: number;
  userId: string | undefined;
}

class CookieBasedLeadStorage {
  getLeadId(_campaignName: string, _hotelId: number): number | undefined {
    try {
      const json = jsc.get(LEAD_ID);

      if (json) {
        const { leadId, campaignName, userId, hotelId } = JSON.parse(json) as LeadIdDependencies;
        const _userId = cookieBasedUserIdStorage.get();

        if (campaignName !== _campaignName || userId !== _userId || hotelId !== _hotelId) {
          jsc.remove(LEAD_ID);

          return undefined;
        }

        return leadId;
      }

      return undefined;
    } catch (ex) {
      jsc.remove(LEAD_ID);

      return undefined;
    }
  }

  setLeadId(leadId: number | undefined, campaignName: string, hotelId: number): void {
    if (leadId) {
      const userId = cookieBasedUserIdStorage.get();

      jsc.set(LEAD_ID, JSON.stringify({ leadId, campaignName, hotelId, userId } as LeadIdDependencies));
    }
  }
}

const cookieBasedLeadStorage = new CookieBasedLeadStorage();

export default cookieBasedLeadStorage;
