import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { FilterSortContext, SettingsContext } from 'TopContexts';
import { TopAmenity } from 'backend/dataModel';
import {
  BudgetBoundaries,
  fillStarFilter,
  fillTripAdvisorFilter,
  HotelSorting,
  StarRow,
} from 'components/hotelComponents/listFilters/filterUtils';
import { FilterAmenity, getFilterAmenities } from 'components/hotelComponents/utils/amenityUtils';

const FilterSortProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [t] = useTranslation();
  const location = useLocation();
  const { currencyCode } = useContext(SettingsContext);

  const [name, setName] = useState<string>('');
  const [stars, setStars] = useState<StarRow[]>(fillStarFilter());
  const [tripAdvisorStars, setTripAdvisorStars] = useState<StarRow[]>(fillTripAdvisorFilter());
  const [freeCancellationOnly, setFreeCancellationOnly] = useState(false);
  const amenities = useMemo<FilterAmenity[]>(() => getFilterAmenities(t), [t]);
  const [checkedAmenities, setCheckedAmenities] = useState<TopAmenity[]>([]);
  const [budget, setBudget] = useState<BudgetBoundaries>({ from: 0 });
  const [sorting, setSorting] = useState<HotelSorting>(HotelSorting.Popularity);
  const [filtering, setFiltering] = useState(false);
  const [currency, setCurrency] = useState(currencyCode);

  useEffect(() => {
    if (currency !== currencyCode) {
      setBudget({ from: 0 });
      setCurrency(currencyCode);
    }
  }, [currency, currencyCode]);

  const resetFilters = useCallback(() => {
    setName('');
    setStars(fillStarFilter());
    setTripAdvisorStars(fillTripAdvisorFilter());
    setFreeCancellationOnly(false);
    setCheckedAmenities([]);
    setBudget({ from: 0 });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(resetFilters, [location.pathname]);

  return (
    <FilterSortContext.Provider
      value={{
        name,
        stars,
        tripAdvisorStars,
        freeCancellationOnly,
        amenities,
        checkedAmenities,
        budget,
        sorting,
        filtering,
        resetFilters,
        setName,
        setStars,
        setTripAdvisorStars,
        setFreeCancellationOnly,
        setCheckedAmenities,
        setBudget,
        setSorting,
        setFiltering,
      }}
    >
      {children}
    </FilterSortContext.Provider>
  );
};

export default FilterSortProvider;
