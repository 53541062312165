import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SignInDialogContext, ToastContext, ToastProps } from 'TopContexts';
import Toast from 'components/Toast';
import { ToastType } from 'components/Toast.styled';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { isHomeRoute } from 'utils/uriUtils';

const ToastProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const location = useLocation();
  const { isMobileLayout } = useContext(LayoutContext);
  const { signInDialogMode } = useContext(SignInDialogContext);
  const [toast, setToastInternal] = useState<ToastProps>();
  const [underHeader, setUnderHeader] = useState(false);

  useEffect(() => {
    setUnderHeader(!isMobileLayout && !isHomeRoute(location.pathname) && signInDialogMode === undefined);
  }, [isMobileLayout, location.pathname, signInDialogMode]);

  const clearToastMessage = useCallback(() => {
    setToastInternal(undefined);
  }, []);

  const setToast = useCallback(
    (message: string | undefined, type?: ToastType, title?: string) => {
      setToastInternal({
        message,
        title,
        type,
        clearMessage: clearToastMessage,
      });
    },
    [clearToastMessage],
  );

  return (
    <ToastContext.Provider value={{ toast: underHeader ? toast : undefined, setToast }}>
      {!underHeader && toast && (
        <Toast message={toast.message} clearMessage={clearToastMessage} type={toast.type} title={toast.title} />
      )}
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
