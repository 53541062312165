/**
 * @category Search Form
 * @packageDocumentation
 */
import { useAtom, useSetAtom } from 'jotai';
import React, { FormEvent, useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { OffscreenContext } from 'TopContexts';
import logo from 'assets/images/logo-color.svg';
import { useSearchFormHandlers } from 'atoms/hooks/useSearchFormCallbacks';
import { searchFormDestinationTermAtom, searchFormOccupancyAtom, searchFormPlaceAtom } from 'atoms/searchFormAtoms';
import { Icon } from 'components/Icon';
import { LayoutContext } from 'components/contexts/LayoutContext';
import CustomDatepicker from 'components/datePicker/customDatepicker/CustomDatepicker';
import OffscreenMode from 'components/offscreen/OffscreenMode';
import OccupancyPicker from 'components/searchForm/OccupancyPicker';
import StyledOccupancy from 'components/searchForm/OccupancyPicker.styled';
import StyledSearchDesktop from 'components/searchForm/SearchForm.desktop.styled';
import StyledSearchMobile from 'components/searchForm/SearchForm.mobile.styled';
import StyledSearchForm from 'components/searchForm/SearchForm.styled';
import SuggestionField from 'components/searchForm/SuggestionField/SuggestionField';
import StyledCommon from 'style/Common.styled';
import { sumAdults, sumChildren, sumGuests } from 'utils/occupancyUtils';
import { isHomeRoute } from 'utils/uriUtils';
import AppliedFilters from 'views/hotels/AppliedFilters';

interface SearchFormDefaultProps {
  stickyShortView?: boolean;
}

const SearchFormDefault: React.FC<SearchFormDefaultProps> = ({ stickyShortView }) => {
  const { pathname } = useLocation();
  const { isMobileLayout } = useContext(LayoutContext);
  const { setOffscreenMode } = useContext(OffscreenContext);

  const { submitForm, onAutoSuggestion } = useSearchFormHandlers();
  const onSuggestion = useSetAtom(searchFormPlaceAtom);
  const [occupancy, setOccupancy] = useAtom(searchFormOccupancyAtom);
  const onSearchTermChange = useSetAtom(searchFormDestinationTermAtom);

  const homeRoute = useMemo(() => isHomeRoute(pathname), [pathname]);

  const searchButton = (
    <StyledCommon.GhostButton type="button" tabIndex={4} onClick={submitForm} data-testid="search-button">
      {homeRoute ? (
        <span>
          <Trans i18nKey="search-bar.search">Search</Trans>
        </span>
      ) : (
        <span>
          <Trans i18nKey="search-bar.update-search">Update Search</Trans>
        </span>
      )}
    </StyledCommon.GhostButton>
  );

  if (isMobileLayout) {
    return (
      <StyledSearchMobile.SearchForm>
        <StyledSearchMobile.Container sticky={stickyShortView}>
          <form className="uk-flex uk-flex-middle" onSubmit={(e) => e.preventDefault()}>
            <div className="uk-width-expand" onClick={() => setOffscreenMode(OffscreenMode.Destination)}>
              <StyledSearchMobile.SuggestionPicker>
                <SuggestionField
                  onEnter={submitForm}
                  onSearchTerm={onSearchTermChange}
                  onSelectPlace={onSuggestion}
                  onAutoPlace={onAutoSuggestion}
                  readonly={true}
                />
              </StyledSearchMobile.SuggestionPicker>
            </div>
            <CustomDatepicker>
              {(checkinDate, checkoutDate) => (
                <span>
                  {checkinDate.short} - {checkoutDate.short}
                </span>
              )}
            </CustomDatepicker>
            <div className="uk-position-relative uk-height-1-1">
              <div className="uk-height-1-1">
                <StyledSearchForm.RoomPicker
                  tabIndex={3}
                  onClick={() => setOffscreenMode(OffscreenMode.Occupancy)}
                  onFocus={() => setOffscreenMode(OffscreenMode.Occupancy)}
                  className="uk-flex uk-flex-middle uk-flex-left uk-width-expand uk-height-1-1"
                >
                  <StyledOccupancy.MobileInfo>
                    <div className="uk-flex uk-flex-middle">
                      <span>{occupancy.length}</span>
                      <Icon name={'rooms'} />
                    </div>
                    <div className="uk-flex uk-flex-middle">
                      <span>{sumGuests(occupancy)}</span>
                      <Icon name={'guests'} />
                    </div>
                  </StyledOccupancy.MobileInfo>
                </StyledSearchForm.RoomPicker>
              </div>
            </div>
          </form>
        </StyledSearchMobile.Container>
      </StyledSearchMobile.SearchForm>
    );
  }

  return (
    <StyledSearchDesktop.SearchForm>
      <StyledSearchDesktop.Container sticky={stickyShortView}>
        <StyledCommon.Container
          as="form"
          className="uk-flex uk-flex-middle"
          onSubmit={(e: FormEvent) => e.preventDefault()}
        >
          <div className="uk-flex uk-flex-middle uk-position-relative uk-width-expand">
            <Link hidden={!stickyShortView} to="/" className="uk-margin-auto-bottom">
              <StyledSearchDesktop.Logo src={logo} alt="Holisto logo" />
            </Link>
            <StyledSearchDesktop.SuggestionPicker>
              <SuggestionField
                onEnter={submitForm}
                onSearchTerm={onSearchTermChange}
                onSelectPlace={onSuggestion}
                onAutoPlace={onAutoSuggestion}
              />
            </StyledSearchDesktop.SuggestionPicker>
            <CustomDatepicker />
            <OccupancyPicker occupancy={occupancy} setOccupancy={setOccupancy} stickyHeader={!stickyShortView}>
              {(occupancies) => (
                <span data-testid="occupancy-text">
                  <Trans
                    i18nKey="search-bar.occupancy"
                    defaults={
                      '{ rooms, plural, =1 {# Room} other {# Rooms} }, ' +
                      '{ adults, plural, =1 {# Adult} other {# Adults} }' +
                      '{ children, plural, =0 {} =1 {, # Child} other {, # Children} }'
                    }
                    values={{
                      rooms: occupancies.length,
                      adults: sumAdults(occupancies),
                      children: sumChildren(occupancies),
                    }}
                  />
                </span>
              )}
            </OccupancyPicker>
          </div>
          <StyledSearchDesktop.SearchButton>{searchButton}</StyledSearchDesktop.SearchButton>
        </StyledCommon.Container>
        <AppliedFilters />
      </StyledSearchDesktop.Container>
    </StyledSearchDesktop.SearchForm>
  );
};

export default SearchFormDefault;
