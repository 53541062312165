import React from 'react';
import { useSearchFormHandlers } from 'atoms/hooks/useSearchFormCallbacks';
import OffscreenMode from 'components/offscreen/OffscreenMode';
import SuggestionField from 'components/searchForm/SuggestionField/SuggestionField';

interface SuggestionFieldMobileProps {
  offscreenMode?: OffscreenMode;
  offscreenHeader?: HTMLDivElement;
}

export const SuggestionFieldMobile = ({ offscreenMode, offscreenHeader }: SuggestionFieldMobileProps) => {
  const { onAutoSuggestion, submitForm, submitDestinationForm } = useSearchFormHandlers();

  return (
    <SuggestionField
      onEnter={submitForm}
      onSearchTerm={() => undefined}
      onSelectPlace={submitDestinationForm}
      onAutoPlace={onAutoSuggestion}
      offscreenMode={offscreenMode}
      offscreenHeader={offscreenHeader}
    />
  );
};
