import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { REDIRECT_COUNTER } from 'backend/sessionStorageKeys';
import useSessionStorageWithObject from 'utils/useSessionStorage';

const useRedirectCounter = () => {
  const history = useHistory();
  const [counter, setCounter] = useSessionStorageWithObject<number>(REDIRECT_COUNTER, 0);

  useEffect(() => {
    const unregisterCallback = history.listen((location, action) => {
      if (action === 'PUSH') {
        setCounter((prev) => prev + 1);
      }
      if (action === 'POP' && counter) {
        setCounter((prev) => prev - 1);
      }
    });

    return () => unregisterCallback();
  }, [history, counter, setCounter]);
};

export default useRedirectCounter;
