import { Place } from 'backend/api/place/placeModel';

export default class ApprovedSuggestionsList {
  readonly suggestions: Place[] | undefined;

  readonly hasDestinations: boolean;

  readonly isQueryResult: boolean;

  constructor(destinations: Place[] | undefined) {
    this.suggestions = destinations;
    this.hasDestinations = destinations ? destinations.length > 0 : false;
    this.isQueryResult = destinations !== undefined;
  }
}
