import { useState } from 'react';

const getItem = (key: string) =>
  document.cookie.split('; ').reduce((total, currentCookie) => {
    const item = currentCookie.split('=');
    const storedKey = item[0];
    const storedValue = item[1];

    return key === storedKey ? decodeURIComponent(storedValue) : total;
  }, '');

const HOURS_IN_DAY = 24;
const MIN_IN_HOUR = 60;
const SEC_IN_MIN = 60;
const MS_IN_SEC = 1000;

const setItem = (key: string, value: string, numberOfDays: number) => {
  const now = new Date();

  // set the time to be now + numberOfDays
  now.setTime(now.getTime() + numberOfDays * SEC_IN_MIN * MIN_IN_HOUR * HOURS_IN_DAY * MS_IN_SEC);

  document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/`;
};

/**
 *
 * @param {String} key The key to store our data to
 * @param {String} defaultValue The default value to return in case the cookie doesn't exist
 */
const useCookie = (key: string, defaultValue: string) => {
  const getCookie = () => getItem(key) || defaultValue;
  const [cookie, setCookie] = useState(getCookie());

  const updateCookie = (value: string, numberOfDays: number) => {
    setCookie(value);
    setItem(key, value, numberOfDays);
  };

  return { cookie, updateCookie };
};

export default useCookie;
