/**
 * @category Universal Components
 * @packageDocumentation
 */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ToastProps } from 'TopContexts';
import { MODAL_ANIMATION_MS } from 'components/CustomAbstractModal.styled';
import { Icon } from 'components/Icon';
import Styled, { ToastType, ToastState } from 'components/Toast.styled';
import { LayoutContext } from 'components/contexts/LayoutContext';
import useOutsideClick from 'utils/useOutsideClick';

const SHOW_TOAST_FOR_MILLIS = 5000;
const SHOW_ERROR_TOAST_FOR_MILLIS = 5000;

const determineToastLifetime = (type: ToastType | undefined) => {
  switch (type) {
    case ToastType.error:
      return SHOW_ERROR_TOAST_FOR_MILLIS;
    default:
      return SHOW_TOAST_FOR_MILLIS;
  }
};

const Toast: React.FC<ToastProps> = ({ message, clearMessage, type = ToastType.error, title }) => {
  const { isMobileLayout } = useContext(LayoutContext);
  const [state, setState] = useState(ToastState.closed);
  const toastRef = useRef<HTMLDivElement>(null);

  useOutsideClick(toastRef, () => clearMessage(), true);

  useEffect(() => {
    switch (state) {
      case ToastState.opening: {
        const handler = setTimeout(() => setState(ToastState.opened), MODAL_ANIMATION_MS);

        return () => {
          clearTimeout(handler);
        };
      }
      case ToastState.opened: {
        const lifetime = determineToastLifetime(type);

        if (lifetime !== undefined) {
          const handler = setTimeout(() => setState(ToastState.closing), lifetime);

          return () => {
            clearTimeout(handler);
          };
        }

        return () => {
          // we do nothing here, because there is no closing handler
        };
      }
      case ToastState.closing: {
        const handler = setTimeout(() => {
          setState(ToastState.closed);
          clearMessage();
        }, MODAL_ANIMATION_MS);

        return () => {
          clearTimeout(handler);
        };
      }
      default:
        return undefined;
    }
  }, [state, clearMessage, type]);

  const onClose = useCallback(() => {
    setState(ToastState.closing);
  }, []);

  useEffect(() => {
    setState(ToastState.opening);
  }, [message]);

  return (
    <Styled.Toast
      data-testid="toast"
      ref={toastRef}
      type={type}
      isMobile={isMobileLayout}
      state={state}
      hidden={state === ToastState.closed || (!message && !title)}
    >
      <Styled.CloseButton data-testid="toast-close-icon" onClick={onClose}>
        <Icon name={'close'} />
      </Styled.CloseButton>
      <header>{title}</header>
      <div>{message}</div>
    </Styled.Toast>
  );
};

export default Toast;
