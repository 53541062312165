import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { USER_PROFILE_ROUTE } from 'routeList';

const ChangePasswordLink: React.FC = () => (
  <Link to={{ pathname: USER_PROFILE_ROUTE, state: { section: 'password' } }}>
    <Trans i18nKey="common.menu.change-password">Change Password</Trans>
  </Link>
);

export default ChangePasswordLink;
