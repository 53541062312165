import qs from 'qs';
import React, { lazy, useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { DebugContext } from 'TopContexts';
import clientStore from 'backend/splittyClientStore';
import { ERRORS_ROUTE_NOT_FOUND, RouteData, routes } from 'routeList';
import HomeView from 'views/home/HomeView';

// Debug page
const DebugI18N = lazy(() => import('views/debug/DebugI18N'));

// Important pages where prefetching is turned on.
// NOTE: Order matters here as prefetching goes in the same order:
const HotelsDetailsView = lazy(() => import(/* webpackPrefetch:true */ 'views/hotelDetails/HotelsDetailsView'));
const CheckoutView = lazy(() => import(/* webpackPrefetch:true */ 'views/checkout/CheckoutView'));
const ConfirmationView = lazy(() => import(/* webpackPrefetch:true */ 'views/confirmation/ConfirmationView'));
const HotelsView = lazy(() => import(/* webpackPrefetch:true */ 'views/hotels/HotelsView'));

// Other pages
// NOTE: Do NOT include webpackPrefetch:true when lazy-import other pages!!!
// It is agreed to prefetch exactly these pages above (as they are used oftenly)
const ErrorWithIdView = lazy(() => import('views/errors/ErrorWithIdView'));
const ErrorNotFoundView = lazy(() => import('views/errors/ErrorNotFoundView'));
const StaticFAQPage = lazy(() => import('views/static/StaticFAQPage'));
const StaticTermsPage = lazy(() => import('views/static/StaticTermsPage/StaticTermsPage'));
const StaticPolicyPage = lazy(() => import('views/static/StaticPolicyPage/StaticPolicyPage'));
const StaticAboutUsPage = lazy(() => import('views/static/StaticAboutUsPage'));
const StaticPriceGuaranteePage = lazy(() => import('views/static/StaticPriceGuaranteePage/StaticPriceGuaranteePage'));
const BookingPage = lazy(() => import('views/myBookings/BookingPage'));
const AccountDetailsPage = lazy(() => import('./views/userProfile/AccountDetailsPage'));
const PageRedirect = lazy(() => import('views/redirect/Redirect'));
const AdditionalPaymentView = lazy(() => import('views/checkout/AdditionalPaymentView'));
const AdditionalPaymentConfirmationView = lazy(
  () => import('views/confirmation/AdditionalPaymentConfirmationView/AdditionalPaymentConfirmationView'),
);

const PageFooter = lazy(() => import('components/header-footer/PageFooter'));

interface DebugModeTurnOnAndOff {
  super?: string;
}

function isDebugModeInUrl(location: string): boolean | undefined {
  const on = (qs.parse(location, { ignoreQueryPrefix: true }) as DebugModeTurnOnAndOff).super;

  if (on !== undefined) {
    return on === 'true';
  }

  return undefined;
}

const WEBVIEW = clientStore.getDevice().mobileWebview;

/**
 * Extension of the standard Route, which looks for "super" paraemeter
 * and sets/unsets debug mode if it is true or false
 *
 */
const PageRoute: React.FC<RouteData> = ({ path, exact, webviewNoFooter, noFooter, children }) => {
  return (
    <>
      <Route path={path} exact={exact}>
        {children}
      </Route>
      {!((WEBVIEW && webviewNoFooter) || noFooter) && <PageFooter />}
    </>
  );
};

/**
 * Extension of the standard Route, which looks for "super" paraemeter
 * and sets/unsets debug mode if it is true or false
 *
 */
const DebuggableRoute: React.FC<RouteData> = (props) => {
  const location = useLocation();
  const { isDebugMode, setDebugMode } = useContext(DebugContext);

  useEffect(() => {
    const newMode = isDebugModeInUrl(location.search);

    if (newMode !== undefined) {
      setDebugMode(newMode);
    }
  }, [location, isDebugMode, setDebugMode]);

  return <PageRoute {...props} />;
};

/**
 * All the application routes
 */
const AppRoutes: React.FC = () => (
  <Switch>
    <DebuggableRoute {...routes.home}>
      <HomeView />
    </DebuggableRoute>
    <DebuggableRoute {...routes.destination}>
      <HotelsView />
    </DebuggableRoute>

    <PageRoute {...routes.booking}>
      <BookingPage />
    </PageRoute>

    <PageRoute {...routes.faq}>
      <StaticFAQPage />
    </PageRoute>
    <PageRoute {...routes.aboutUs}>
      <StaticAboutUsPage />
    </PageRoute>
    <PageRoute {...routes.terms}>
      <StaticTermsPage />
    </PageRoute>
    <PageRoute {...routes.privacyPolicy}>
      <StaticPolicyPage />
    </PageRoute>
    <PageRoute {...routes.priceGuarantee}>
      <StaticPriceGuaranteePage />
    </PageRoute>

    <PageRoute {...routes.hotelsRedirect} noFooter>
      <PageRedirect type="hotel" />
    </PageRoute>

    <PageRoute {...routes.destinationsRedirect} noFooter>
      <PageRedirect type="destination" />
    </PageRoute>

    <DebuggableRoute {...routes.hotels}>
      <HotelsDetailsView />
    </DebuggableRoute>

    <PageRoute {...routes.checkout}>
      <CheckoutView />
    </PageRoute>

    <PageRoute {...routes.confirmation}>
      <ConfirmationView />
    </PageRoute>
    <PageRoute {...routes.additionalPayment}>
      <AdditionalPaymentView />
    </PageRoute>
    <PageRoute {...routes.additionalPaymentConfirmation}>
      <AdditionalPaymentConfirmationView />
    </PageRoute>

    <PageRoute {...routes.userProfile}>
      <AccountDetailsPage />
    </PageRoute>

    <PageRoute {...routes.resetPassword}>
      <HomeView resetPassword={true} />
    </PageRoute>

    <PageRoute {...routes.errorWithId}>
      <ErrorWithIdView />
    </PageRoute>
    <PageRoute {...routes.errorNotFound}>
      <ErrorNotFoundView />
    </PageRoute>
    <PageRoute {...routes.i18debug}>
      <DebugI18N />
    </PageRoute>

    <PageRoute {...routes.errorNotFound}>
      <Redirect to={ERRORS_ROUTE_NOT_FOUND} />
    </PageRoute>
  </Switch>
);

export default AppRoutes;
