import React from 'react';
import styled from 'styled-components';
import starHalfIcon from 'assets/images/icons/rating/star-half.svg';
import starOffIcon from 'assets/images/icons/rating/star-off.svg';
import starOnIcon from 'assets/images/icons/rating/star-on.svg';
import { MAX_STAR_RATING } from 'components/hotelComponents/listFilters/filterUtils';

export interface StarRatingProps {
  starRating: number;
  onlyFull?: boolean;
}

const Star = styled.img`
  width: 16px;
  height: 16px;
`;

const StarRating: React.FC<StarRatingProps> = ({ starRating, onlyFull }) => (
  <div className="uk-flex uk-flex-middle">
    {[...Array(Math.floor(starRating))].map((_, index) => (
      /* eslint-disable react/no-array-index-key */
      <Star src={starOnIcon} key={index} alt="star-on" />
    ))}

    {!onlyFull && starRating !== Math.floor(starRating) && <Star src={starHalfIcon} alt="half-star" />}

    {!onlyFull &&
      [...Array(MAX_STAR_RATING - Math.ceil(starRating))].map((_, index) => (
        /* eslint-disable react/no-array-index-key */
        <Star src={starOffIcon} key={index} alt="star-off" />
      ))}
  </div>
);

export default StarRating;
