import styled, { css } from 'styled-components';
import { Icon } from 'components/Icon.styled';
import Styled from 'style/Common.styled';
import media from 'style/media';
import { flexGapRow } from 'style/polyfills/flexGap';
import { cornflower, pineGreen } from 'style/variables';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Modal = styled.div`
  background-color: ${cornflower};
  color: white;
  overflow: auto;

  ${media.mobile} {
    width: 100%;
    height: 100%;
  }

  ${media.desktop} {
    width: 415px;
  }

  .input-margin-top {
    margin-top: 16px;
  }
`;

const Form = styled.div`
  padding: 8px 44px 0;
  display: flex;
  flex-direction: column;
  align-content: start;
  justify-content: start;

  ${media.mobile} {
    padding: 8px 24px 0;
  }
`;

const TopControls = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 16px;
  color: white;
`;

const TopControl = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  cursor: pointer;

  & > ${Icon} {
    width: 24px;
    height: 24px;
  }
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: 3.85px;
  text-transform: uppercase;

  margin-bottom: 24px;
`;

const Text = styled.div`
  margin-bottom: 12px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: white;
`;

const Logo = styled.div`
  svg {
    height: 25px;
    width: 98px;
    color: white;
  }
`;

const footerText = css`
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 30px;
  padding-bottom: 40px;
  justify-items: center;
  justify-content: center;

  ${footerText};
`;

const FooterLink = styled.a`
  margin-left: 6px;
  text-decoration: underline;
  ${footerText};

  &:hover {
    color: #9dff3e;
    text-decoration: none;
  }
`;

const Button = styled(Styled.GreenButton)`
  margin-top: 20px;
`;

const EyeIcon = styled.div`
  position: absolute;
  right: 12px;
  top: calc(50% - 13px);
`;

const Error = styled.div`
  border-radius: 2px;
  background-color: #f26584;
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.15;
  text-align: center;
  color: ${pineGreen};
  padding: 14px 34px;
`;

const InfoBox = styled.div`
  padding: 16px 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.16);
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;
  ${flexGapRow('16px')}
`;

export default {
  Container,
  Modal,
  Form,
  TopControls,
  TopControl,
  Title,
  Text,
  Logo,
  Footer,
  FooterLink,
  Button,
  EyeIcon,
  InfoBox,
  Error,
};
