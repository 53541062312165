import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface ContactTranslations {
  emailUs: string;
  contactUs: string;
  phone: string;
  career: string;
  [key: string]: string;
}

// eslint-disable-next-line import/prefer-default-export
export const useContactTranslations = (): ContactTranslations => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      emailUs: t('contact.emailUs', 'support@holisto.com'),
      contactUs: t('contact.contactUs', 'contactus@holisto.com'),
      phone: t('contact.phone', '+1 (888) 556-8357'),
      discountPhone: t('contact.discountPhone', '+1 (844) 735-2069'),
      career: t('contact.career', 'https://www.comeet.com/jobs/holisto/76.001'),
    }),
    [t],
  );
};
