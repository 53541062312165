/**
 * @category Utils
 * @packageDocumentation
 */
import { env } from 'environments/environment';

export default function getCurrencyByCode(code: string | undefined) {
  if (!code) {
    return undefined;
  }

  return env.currencies.currencies.find((cur) => cur.code === code);
}

export function getDefaultCurrency() {
  const ret = getCurrencyByCode(env.currencies.defaultCurrencyCode);

  if (!ret) {
    throw new Error(`Can't find config for default currency ${env.currencies.defaultCurrencyCode}`);
  }

  return ret;
}

export function getCurrencyByCodeOrDefault(code?: string) {
  if (code) {
    const currency = getCurrencyByCode(code);

    if (currency) {
      return currency;
    }
  }

  return getDefaultCurrency();
}
