import { useCallback, useEffect } from 'react';
import { useCampaign } from 'atoms/hooks/useCampaign';
import { useUserLocation } from 'atoms/hooks/useUserLocation';
import { CURRENCY_STATE, CURRENT_CURRENCY } from 'backend/localStorageKeys';
import { ChangeMode } from 'contexts/settingsHooks/ChangeModeModel';
import { env } from 'environments/environment';
import getCurrencyByCode from 'utils/currencyUtils';
import { AmplitudeLogging } from 'utils/logging/AmplitudeLogging';
import useLocalStorage from 'utils/useLocalStorage';
import useSearchParameter from 'utils/useSearchParameter';

type UrlParams = {
  currency?: string;
};

const urlParamOption = { raw: true, replace: true };

export const useCurrencySettings = () => {
  const { campaign } = useCampaign();
  const { data: location } = useUserLocation();

  const [currencyUrlParam, setCurrencyUrlParam] = useSearchParameter<UrlParams, string | undefined>(
    'currency',
    urlParamOption,
  );

  const [currencyState, setCurrencyState] = useLocalStorage(CURRENCY_STATE, ChangeMode.Automatically, true);
  const [currencyCode, setCurrencyToStorage] = useLocalStorage<string>(
    CURRENT_CURRENCY,
    getCurrencyByCode(currencyUrlParam)?.code ||
      getCurrencyByCode(campaign?.currencyCode)?.code ||
      getCurrencyByCode(location.currencyCode)?.code ||
      env.currencies.defaultCurrencyCode,
    true,
  );

  const resetCurrencyState = useCallback(() => {
    setCurrencyState(ChangeMode.Automatically);
  }, [setCurrencyState]);

  const setCurrencyCode = useCallback(
    (currency: string, manually?: boolean) => {
      if (currency !== currencyCode) {
        setCurrencyToStorage(currency);

        if (currencyUrlParam && currencyUrlParam !== currency) {
          setCurrencyUrlParam(currency);
        }

        if (manually) {
          setCurrencyState(ChangeMode.Manually);
          AmplitudeLogging.pushCurrencyModifiedEvent(currency);
        }
      }
    },
    [currencyCode, setCurrencyToStorage, currencyUrlParam, setCurrencyUrlParam, setCurrencyState],
  );

  // applies whenever if url contains currency parameter different from current
  // and user has changed the currency manually at least once.
  // it's a separate effect since we don't want to be depended on campaign or location.currencyCode
  // and trigger it every time them are changed.
  useEffect(() => {
    if (currencyState !== ChangeMode.Automatically && currencyUrlParam && currencyUrlParam !== currencyCode) {
      const currency = getCurrencyByCode(currencyUrlParam)?.code;

      if (currency) {
        setCurrencyCode(currency);
      } else {
        setCurrencyUrlParam(undefined);
      }
    }
  }, [currencyCode, currencyState, currencyUrlParam, setCurrencyCode, setCurrencyUrlParam]);

  // applies only if user has never changed currency manually
  // the currency is selected automatically by priority: URL > Local storage >  Campaign > Location > default
  useEffect(() => {
    if (currencyState === ChangeMode.Automatically) {
      setCurrencyCode(
        getCurrencyByCode(currencyUrlParam)?.code ||
          getCurrencyByCode(currencyCode)?.code ||
          getCurrencyByCode(campaign?.currencyCode)?.code ||
          getCurrencyByCode(location.currencyCode)?.code ||
          env.currencies.defaultCurrencyCode,
      );
    }
  }, [campaign, currencyCode, currencyState, currencyUrlParam, location.currencyCode, setCurrencyCode]);

  return { currencyCode, setCurrencyCode, resetCurrencyState };
};
