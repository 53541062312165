import { RoomOccupancy } from 'backend/api/trip/tripModel';
import { SearchBarConf } from 'environments/configuration';
import { env } from 'environments/environment';

export interface OccupancyProperties {
  rooms: number;
  adults: number;
  children: number;
}

export function defaultOccupancy(conf: SearchBarConf): RoomOccupancy[] {
  return [
    {
      adults: conf.defaultFirstAdultsCount,
      children: [],
    },
    ...Array(conf.defaultOccupancyCount - 1).fill({ adults: conf.defaultAdultsCount, children: [] }),
  ];
}

export const createOccupancyOrDefault = (adults?: number, children?: number[]): RoomOccupancy => ({
  adults: adults || env.searchBar.defaultAdultsCount,
  children: children || [],
});

export function occupancyAreEqual(o1?: RoomOccupancy[], o2?: RoomOccupancy[]): boolean {
  if (o1 && o2) {
    return (
      o1.length === o2.length &&
      o1.every((v, i) => {
        const children1 = v.children || [];
        const children2 = o2[i].children || [];

        return (
          v.adults === o2[i].adults &&
          children1.length === children2.length &&
          children2.every((age, idx) => age === children1[idx])
        );
      })
    );
  }

  return !o1 && !o2;
}

export function sumGuests(occupancy: RoomOccupancy[]): number {
  return occupancy.reduce((guests, o) => guests + o.adults + (o.children || []).length, 0);
}

export function sumAdults(occupancy: RoomOccupancy[]): number {
  return occupancy.reduce((adults, o) => adults + o.adults, 0);
}

export function sumChildren(occupancy: RoomOccupancy[]): number {
  return occupancy.reduce((children, o) => children + (o.children || []).length, 0);
}
