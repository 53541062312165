import React, { useEffect, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { routes } from 'routeList';
import { LogInfoMemoryStorage } from 'utils/storage/memory/LogInfoMemoryStorage';

interface Context {
  title: string;
  pageType: string;
}

export const RouteDataContext = React.createContext<Context>({
  title: window.location.href,
  pageType: routes.home.pageType,
});

export const RouteDataProvider: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const currentRouteData = useMemo(
    () => Object.values(routes).find((routeData) => !!matchPath(pathname, routeData)),
    [pathname],
  );

  const title = useMemo(() => currentRouteData?.title || window.location.href, [currentRouteData]);
  const pageType = useMemo(() => currentRouteData?.pageType || routes.home.pageType, [currentRouteData]);

  useEffect(() => {
    LogInfoMemoryStorage.pageType = pageType;
  }, [pageType]);

  return (
    <RouteDataContext.Provider
      value={{
        title,
        pageType,
      }}
    >
      {children}
    </RouteDataContext.Provider>
  );
};
