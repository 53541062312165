import styled from 'styled-components';
import StyledCheckbox from 'components/InputCheckbox.styled';
import { flexGapColumn } from 'style/polyfills/flexGap';
import { veryDarkGray } from 'style/variables';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGapColumn('8px')}
  padding: 16px;

  ${StyledCheckbox.Label} {
    padding: 10px;
    font-size: 16px;
    line-height: 1.5;
    color: ${veryDarkGray};
  }
`;

export default {
  Container,
};
