import React from 'react';
import styled from 'styled-components';
import Loader from 'components/Loader';

export const Screen = styled.div`
  z-index: 1040;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;
`;

const LoadingScreen: React.FC = () => (
  <Screen>
    <Loader size={80} />
  </Screen>
);

export default LoadingScreen;
