import React, { FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import useDatepickerDate, { DatepickerDate } from 'components/dates/useDatepickerDate';
import StyledSearch from 'components/searchForm/SearchForm.styled';

export const DATEPICKER_CHECKIN_ID = 'dpCheckinLink';

interface DatepickerWidgetButtonProps {
  active: boolean;
  startDate: Date | null;
  endDate: Date | null;
  onFocus: (e: FocusEvent<HTMLDivElement>) => void;
  widgetRef: React.RefObject<HTMLDivElement>;
  children?: (checkin: DatepickerDate, checkout: DatepickerDate) => React.ReactNode;
}

const DatepickerWidgetButton: React.FC<DatepickerWidgetButtonProps> = ({
  startDate,
  endDate,
  onFocus,
  active,
  widgetRef,
  children,
}) => {
  const [, i18n] = useTranslation();

  const strStart = useDatepickerDate({
    lang: i18n.language,
    date: startDate,
  });
  const strEnd = useDatepickerDate({
    lang: i18n.language,
    date: endDate,
  });

  return (
    <StyledSearch.DatePicker
      active={active}
      id={DATEPICKER_CHECKIN_ID}
      ref={widgetRef}
      tabIndex={2}
      className="uk-flex uk-flex-center uk-flex-middle uk-height-1-1"
      onFocus={onFocus}
    >
      {children?.(strStart, strEnd) || (
        <span data-testid="datepicker-value">
          {strStart.long} - {strEnd.long}
        </span>
      )}
    </StyledSearch.DatePicker>
  );
};

export default DatepickerWidgetButton;
