import styled, { css } from 'styled-components';
import StyledDropdown from 'components/widgets/Dropdown.styled';
import { warmBlue } from 'style/variables';

const Dropdown = styled(StyledDropdown.Dropdown)``;

const Item = styled(StyledDropdown.Item)<{ selected?: boolean }>`
  cursor: pointer;
  padding: 0 32px;

  ${(props) =>
    props.selected &&
    css`
      &:before {
        content: '';
        position: absolute;
        left: 17px;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: ${warmBlue};
      }
    `}
`;

const Content = styled(StyledDropdown.Content)``;

export default {
  Dropdown,
  Item,
  Content,
};
