/**
 * @category Utils
 * @packageDocumentation
 */

import { TFunction } from 'i18next';
import { env } from 'environments/environment';
import { ClientError, ClientErrorCode } from 'errors/clientError';
import { calculateNightsBetweenDates, parseDate, startOfToday } from 'utils/dateUtils';

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 16;
export const AT_LEAST_ONE_NUMBER = /^.*\d.*$/;
export const AT_LEAST_ONE_LOWERCASE = /^.*[a-z].*$/;
export const AT_LEAST_ONE_UPPERCASE = /^.*[A-Z].*$/;

const DATE_FORMAT = /^(\d{4})-(\d{2})-(\d{2})$/;
const MAX_NAME_LENGTH = 50;

/**
 * Checks if the passed parameter is a number within given boundaries
 * @param n - value to check
 * @param min - minimum (inclusive)
 * @param max - maximum (inclusive)
 * @returns true if the condition met
 */
export function validateNumber(n: number | undefined, min?: number, max?: number): boolean {
  if ((n !== 0 && !n) || Number.isNaN(n)) {
    return false;
  }
  if (min !== undefined && n < min) {
    return false;
  }

  return !(max !== undefined && n > max);
}

/**
 * Checks if the passed parameter is integer within given boundaries
 * @param s - value to check
 * @param min - minimum (inclusive)
 * @param max - maximum (inclusive)
 * @returns true if the condition met
 */
export function validateInt(s: string, min?: number, max?: number): boolean {
  return validateNumber(Number.parseInt(s, 10), min, max);
}

/**
 * Checks if the passed parameter is float within given boundaries
 * @param s - value to check
 * @param min - minimum (inclusive)
 * @param max - maximum (inclusive)
 * @returns true if the condition met
 */
export function validateFloat(s: string, min?: number, max?: number): boolean {
  return validateNumber(parseFloat(s), min, max);
}

/**
 * Checks if passed parameter is a date
 * @param s
 * @returns true if so
 */
export function validateDate(s: string): boolean {
  return parseDate(s) !== undefined;
}

export function validateDateStrong(date: string, offsetHours?: number) {
  if (!DATE_FORMAT.test(date)) {
    throw new ClientError(ClientErrorCode.InvalidSearchParams);
  }

  const d = parseDate(date);
  const now = startOfToday(offsetHours);

  if (!d) {
    throw new ClientError(ClientErrorCode.InvalidSearchParams);
  }

  if (d < now) {
    throw new ClientError(ClientErrorCode.DatesInPast);
  }

  return true;
}

export function validateDates(checkin: string, checkout: string) {
  if (checkin === checkout) {
    throw new ClientError(ClientErrorCode.DatesEquals);
  }

  const checkinDate = parseDate(checkin);
  const checkoutDate = parseDate(checkout);

  if (!checkinDate || !checkoutDate || checkoutDate < checkinDate) {
    throw new ClientError(ClientErrorCode.InvalidSearchParams);
  }

  const nights = calculateNightsBetweenDates(checkinDate, checkoutDate);

  if (!nights || nights > env.searchBar.maxNights) {
    throw new ClientError(ClientErrorCode.InvalidLengthOfStay);
  }

  return true;
}

export const basicInputValidation = (value: string) => value && value.trim().length >= env.inputs.basicMinLength;

/**
 * ^ asserts the start of the string.
 * (?!.*\.{2,}) is a negative lookahead assertion to ensure there are no consecutive periods. .*\.{2,} matches any character followed by two or more periods.
 * [A-Z0-9_%+-]+ matches one or more characters.
 * (?:\.[A-Z0-9_%+-]+)* matches zero or more occurrences of a period followed by one or more alphabetic characters.
 * $ asserts the end of the string.
 */
const MAIL_NAME_REGEXP = /^(?!.*\.{2,})[A-Z0-9_%+-]+(?:\.[A-Z0-9_%+-]+)*$/i;

/**
 *  any special characters if within quotes
 */
const MAIL_NAME_WITH_QUOTES_REGEXP = /^".+"$/i;

/**
 * ^ asserts the start of the string.
 * (?!.*\.{2,}) is a negative lookahead assertion to ensure there are no consecutive periods. .*\.{2,} matches any character followed by two or more periods.
 * (?!.*--) ensures that there are no consecutive hyphens anywhere in the string.
 * [A-Z0-9] matches any alphanumeric character.
 * (?:[A-Z0-9-]{0,61}[A-Z0-9])? matches a substring consisting of alphanumeric characters and hyphens, where the length is between 1 and 63 characters.
 * (?:\.[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?)+ matches one or more occurrences of a period followed by a substring as described above.
 * $ asserts the end of the string.
 */
const DOMAIN_REGEXP =
  /^(?!.*\.{2,})(?!.*--)[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?(?:\.[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?)+$/i;

/**
 * The top-level domain must not be all numeric.
 */
const TOP_DOMAIN_REGEXP = /[a-z]+/i;

const MAX_EMAIL_NAME_LENGTH = 64;
const MAX_EMAIL_DOMAIN_LENGTH = 252;

export const emailValidationRule = (value: string) => {
  const [name, domain] = value.split('@');
  const subDomains = domain?.split('.');
  const topDomain = subDomains?.at(-1);

  if (
    name?.length < 1 ||
    domain?.length < 1 ||
    name?.length > MAX_EMAIL_NAME_LENGTH ||
    domain?.length > MAX_EMAIL_DOMAIN_LENGTH
  ) {
    return false;
  }

  return (
    (MAIL_NAME_WITH_QUOTES_REGEXP.test(name) || MAIL_NAME_REGEXP.test(name)) &&
    DOMAIN_REGEXP.test(domain) &&
    TOP_DOMAIN_REGEXP.test(topDomain || '')
  );
};

export const nameValidationRule = (value: string) => !!value && value.length <= MAX_NAME_LENGTH;

export function passwordValidationRule(password: string) {
  return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$*-]{8,16}$/.test(password);
}

export const tripNumberValidationRule = (p: string) => p.length >= 0;

export function passwordErrorMessage(t: TFunction) {
  return t(
    'login-popup.invalid-password',
    'The password must contain 8-16 alphanumeric characters, at least 1 lower case, 1 upper case and 1 digit',
  );
}

export function isNumber(value: string) {
  return /^[\d\s]+$/.test(value);
}
