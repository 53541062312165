import styled from 'styled-components';

const EmailSentImgBox = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;

  width: 100%;
  margin-top: 12px;
  margin-bottom: 40px;

  .img {
    width: 191px;
    height: 99px;
  }
`;

export default { EmailSentImgBox };
