export const weekdayShortFormat = { weekday: 'short' } as Intl.DateTimeFormatOptions;
export const monthShortFormat = { month: 'short' } as Intl.DateTimeFormatOptions;
export const dayNumericFormat = { day: 'numeric' } as Intl.DateTimeFormatOptions;
export const yearNumericFormat = { year: 'numeric' } as Intl.DateTimeFormatOptions;
export const monthLongFormat = { month: 'long' } as Intl.DateTimeFormatOptions;
export const weekdayNarrowFormat = { weekday: 'narrow' } as Intl.DateTimeFormatOptions;
export const dayNumericMonthShortOptions = { day: 'numeric', month: 'short' } as Intl.DateTimeFormatOptions;
export const dayNumericMonthLongYearNumericOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
} as Intl.DateTimeFormatOptions;
