import styled, { css } from 'styled-components';
import Styled from 'components/signin/SignInDialog.styled';

const HidingBox = styled.div<{ visible: boolean }>`
  overflow: hidden;
  height: ${(props) => (props.visible ? 'auto' : 0)};
`;

const agreementText = css`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #fff;
`;

const AgreementText = styled.div`
  margin-bottom: 24px;
  ${agreementText};

  a {
    text-decoration: underline;
    ${agreementText};
  }
`;

const SubscribeTextBox = styled.div`
  margin-right: 5px;
`;

const Inputs = styled.div`
  margin-bottom: 24px;
`;

const Title = styled(Styled.Title)`
  margin-bottom: 10px;
`;

const PasswordValidationBox = styled(Styled.InfoBox)`
  margin: 8px 0 -8px;
`;

export default {
  Modal: Styled.Modal,
  HidingBox,
  TopControls: Styled.TopControls,
  TopControl: Styled.TopControl,
  Logo: Styled.Logo,
  Form: Styled.Form,
  Title,
  InfoBox: Styled.InfoBox,
  Button: Styled.Button,
  Footer: Styled.Footer,
  FooterLink: Styled.FooterLink,
  AgreementText,
  SubscribeTextBox,
  EyeIcon: Styled.EyeIcon,
  Inputs,
  PasswordValidationBox,
};
