import qs from 'qs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function useSearchParameter<X, T>(
  paramName: keyof X,
  initialOptions?: { defaultValue?: T; raw?: boolean; replace?: boolean },
) {
  const history = useHistory();
  const location = useLocation();
  const [options] = useState(initialOptions || {});

  const setParameter = useCallback(
    (opened: T | undefined) => {
      const currentSearch = history.location.search;
      const query = qs.parse(currentSearch, { ignoreQueryPrefix: true });

      const newParam = options.raw ? opened : JSON.stringify(opened);

      if (query[paramName as string] !== newParam) {
        query[paramName as string] = newParam as string;

        const search = qs.stringify(query);

        if (options.replace) {
          history.replace({ search });
        } else {
          history.push({ search });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, options, paramName],
  );

  const getParameter = useMemo(() => {
    const currentSearch = location.search;
    const query = qs.parse(currentSearch, { ignoreQueryPrefix: true });

    const val = query[paramName as string];

    if (val) {
      if (options.raw) {
        return val as T;
      }

      return JSON.parse(val as string) as T;
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, options, paramName]);

  useEffect(() => {
    if (options?.defaultValue !== undefined) {
      setParameter(options.defaultValue);
    }
  }, [options, setParameter]);

  const ret: [T | undefined, (value: T | undefined) => void] = [getParameter, setParameter];

  return ret;
}

export default useSearchParameter;
