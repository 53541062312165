/**
 * @category Utils
 * @packageDocumentation
 */
import assert from 'assert';
import qs from 'qs';
import { matchPath } from 'react-router-dom';
import { RoomOccupancy } from 'backend/api/trip/tripModel';
import { SearchFormUrlParameters } from 'components/searchForm/query';
import {
  ADDITIONAL_PAYMENT_CONFIRMATION_ROUTE,
  BOOKINGS_ROUTE,
  CHECKOUT_ROUTE,
  CONFIRMATION_ROUTE,
  DESTINATIONS_ROUTE,
  HOTELS_ROUTE,
  routes,
} from 'routeList';
import { UrlParameters } from 'utils/useQuery';

export interface ConfirmationQueryParameters extends UrlParameters {
  email: string | undefined;
}

export interface BookingParameters extends UrlParameters {
  email: string;
  trip: string;
}

/**
 * Removes invalid characters from URL fragments
 * @param {string} text
 * @returns {string}
 */
export function clean(text: string): string {
  return text.replace(/[/&?.',+~()@]/g, '');
}

/**
 * Encodes pathname
 * @param {string} text
 * @returns {string} encoded pathname
 */
export function encode(text: string): string {
  return text
    .toLocaleLowerCase()
    .replace(/\s*,\s*/g, '_')
    .replace(/\s+/g, '-')
    .replace(/^(\W-)/g, '');
}

/**
 * Reverse label
 * @param {string} text
 * @returns {string} reversed label
 */
export function reverseLabel(text: string): string {
  return text.split('_').reverse().join('_');
}

/**
 * Encodes the destination's or hotel's address to be used in URL path
 * @param {string} country Country name
 * @param {string} state State code
 * @param {string} cityName Destination name
 * @param {number} hotelId Hotel ID
 * @param {string} hotelName Hotel name
 * @returns {string} URL path
 */
export function buildHotelPathname(
  country: string,
  state: string,
  cityName: string,
  hotelId: number,
  hotelName: string,
): string {
  return encode(`${HOTELS_ROUTE}${country}_${state}_${cityName}/${hotelId}_${clean(hotelName)}`);
}

export function isHotelRoute(pathname: string) {
  return !!matchPath(pathname, routes.hotels) && !matchPath(pathname, routes.hotelsRedirect);
}

/**
 * Extracts hotel ID out of current URL
 * @param {string} pathName Current URL
 * @returns {number} hotelId
 */
export function extractHotelId(pathName: string): number | null {
  if (pathName && isHotelRoute(pathName)) {
    const pathSegments = pathName.split('/');
    const encoded = pathSegments[pathSegments.length - 1].split('_');

    if (encoded.length) {
      return parseInt(encoded[0], 10);
    }
  }

  return null;
}

export function createBookingWeakAuthUri(email: string, encryptedTripId: string) {
  const query = qs.stringify({ email, trip: encryptedTripId } as BookingParameters);

  return `${encode(`${BOOKINGS_ROUTE}`)}?${query}`;
}

interface HotelSearchStateParameters {
  map?: string;
  filter?: string;
}

export function createHotelSearchRedirectUri(
  path: string,
  placeId: string | undefined,
  bounds: string | undefined,
  checkin: string,
  checkout: string,
  occupancy: RoomOccupancy[],
  stateParams?: HotelSearchStateParameters,
): string {
  const query: SearchFormUrlParameters & HotelSearchStateParameters = {
    placeId,
    checkin,
    checkout,
    occupancy: JSON.stringify(occupancy),
    ...stateParams,
  };

  return `${encode(`${DESTINATIONS_ROUTE}${path}`)}?${qs.stringify(query, {
    arrayFormat: 'brackets',
  })}`;
}

export function createHotelSearchUri(
  placeLabel: string | undefined,
  placeId: string | undefined,
  bounds: string | undefined,
  checkin: string,
  checkout: string,
  occupancy: RoomOccupancy[],
): string {
  const prevQuery = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  const stateParams: HotelSearchStateParameters = {
    map: prevQuery.map as string,
    filter: prevQuery.filter as string,
  };

  const label = placeLabel ? reverseLabel(encode(placeLabel)) : 'map';

  return createHotelSearchRedirectUri(label, placeId, bounds, checkin, checkout, occupancy, stateParams);
}

export function createCheckoutUri(checkoutId: string): string {
  return `${CHECKOUT_ROUTE}/${checkoutId}`;
}

export function createConfirmationUri(encryptedTripId: string, email: string): string {
  const query = qs.stringify({ email } as ConfirmationQueryParameters);

  return `${CONFIRMATION_ROUTE}/${encodeURIComponent(encryptedTripId)}?${query}`;
}

export function createMyTripUri(email: string, trip: string): string {
  const query = qs.stringify({ email, trip }, { arrayFormat: 'brackets' });

  return `${BOOKINGS_ROUTE}?${query}`;
}

export function createAdditionalPaymentConfirmationUri(
  encryptedTripId: string,
  paymentRequestId: string,
  email?: string,
) {
  const query = qs.stringify({ email } as ConfirmationQueryParameters);

  return `${ADDITIONAL_PAYMENT_CONFIRMATION_ROUTE.replace(
    ':encryptedTripId',
    encodeURIComponent(encryptedTripId),
  ).replace(':paymentRequestId', paymentRequestId)}?${query}`;
}

export function isHomeRoute(pathname: string) {
  return !!matchPath(pathname, routes.home);
}

export function isDestinationRoute(pathname: string) {
  return !!matchPath(pathname, routes.destination);
}

export function isHotelOrDestinationRoute(pathname: string) {
  return isHotelRoute(pathname) || isDestinationRoute(pathname);
}

export function createReplaceSearchUri(query: string, params: UrlParameters) {
  const search = qs.parse(query, { ignoreQueryPrefix: true });

  return qs.stringify({ ...search, ...params });
}

export interface GoogleHotelParameters extends UrlParameters {
  hotelId: string;
  campaignName: string;
  numOfRooms?: string;
  priceDisplayedTotal?: string;
  priceDisplayedTax?: string;
  device?: string;
  currency?: string;
  userCountry?: string;
  userLanguage?: string;
  site?: string;
  rule?: string;
  externalCampaignId?: string;
  promoted?: string;
  defaultDate?: string;
  adType?: string;
  utm_source?: string;
}

export interface HotelDetailsSearchUrlParameters extends Omit<SearchFormUrlParameters, 'placeId'> {
  dealKey?: string;
  rate?: string;
}

export function createHotelDetailsUri(
  path: string,
  checkin: string,
  checkout: string,
  occupancy: RoomOccupancy[],
  rate: number | undefined,
  dealKey: string | undefined,
  params: GoogleHotelParameters | undefined,
): string {
  assert(isHotelRoute(path), `Path must start with the route: ${path}, ${HOTELS_ROUTE}`);

  const query: HotelDetailsSearchUrlParameters = {
    checkin,
    checkout,
    occupancy: JSON.stringify(occupancy),
    dealKey,
    rate: rate ? rate.toString() : undefined,
    ...params,
  };

  return `${path}?${qs.stringify(query, { arrayFormat: 'brackets' })}`;
}

export const generateGoogleMapsLink = (lg: number, lt: number) => `https://www.google.com/maps/?q=${lt},${lg}`;
