/**
 * @category Utils
 * @packageDocumentation
 */

import { useCallback, useState } from 'react';
import { log } from 'utils/logger';

/**
 * Hook which stores its values in the local storage.
 *
 * Attention!!! it does not mean the stored values become global states!
 * They still local states, it only duplicates them to the local storage and initializes them from it.
 * If the value in the local storage changes
 * (from outside component, which uses this hook)
 * it does NOT mean this hook would react!!!
 * @param key - local storage key
 * @param initialValue - initial value
 * @param storeInitialValue - store initialValue to the local storage if true
 */
const useLocalStorage = <T>(key: string, initialValue: T, storeInitialValue?: boolean) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);

      // Parse stored json or if none return initialValue
      const value = item ? (JSON.parse(item) as T) : initialValue;

      try {
        if (!item && storeInitialValue) {
          window.localStorage.setItem(key, JSON.stringify(value));
        }
      } catch (error) {
        /* empty */
      }

      return value;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T) => {
      try {
        setStoredValue(value);

        window.localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        // A more advanced implementation would handle the error case
        log(error);
      }

      return true;
    },
    [setStoredValue, key],
  );

  const ret: [T, (value: T) => boolean] = [storedValue, setValue];

  return ret;
};

export default useLocalStorage;
