import styled, { css } from 'styled-components';
import {
  TextAlignment,
  TextColor,
  TextProps,
  TextSize,
  TextTransform,
  TextWeight,
  TextWrap,
} from 'components/common/Text/Text.types';
import {
  fontSizeLarge,
  fontSizeMeta,
  fontSizeSmall,
  gunmetal,
  lineHeightLarge,
  lineHeightMeta,
  lineHeightSmall,
  pineGreen,
  textDangerColor,
  textMutedColor,
  textPrimaryColor,
} from 'style/variables';

const Text = styled.span<Omit<TextProps, 'children'>>`
  font-size: ${({ size }) => {
    switch (size) {
      case TextSize.Small:
        return fontSizeSmall;
      case TextSize.Large:
        return fontSizeLarge;
      default:
        return '';
    }
  }};

  line-height: ${({ size }) => {
    switch (size) {
      case TextSize.Small:
        return lineHeightSmall;
      case TextSize.Large:
        return lineHeightLarge;
      default:
        return '';
    }
  }};

  color: ${({ color }) => {
    switch (color) {
      case TextColor.Muted:
        return textMutedColor;
      case TextColor.Emphasis:
        return pineGreen;
      case TextColor.Primary:
        return textPrimaryColor;
      case TextColor.Secondary:
        return gunmetal;
      case TextColor.Danger:
        return textDangerColor;
      default:
        return '';
    }
  }} !important;

  text-align: ${({ alignment }) => {
    switch (alignment) {
      case TextAlignment.Left:
        return 'left';
      case TextAlignment.Right:
        return 'right';
      case TextAlignment.Center:
        return 'center';
      case TextAlignment.Justify:
        return 'justify';
      default:
        return '';
    }
  }};

  ${({ wrap }) => {
    switch (wrap) {
      case TextWrap.Truncate:
        return css`
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        `;

      case TextWrap.Nowrap:
        return css`
          white-space: nowrap;
        `;

      default:
        return '';
    }
  }}

  font-weight: ${({ weight }) => {
    switch (weight) {
      case TextWeight.Bold:
        return '700';
      case TextWeight.SemiBold:
        return '600';
      case TextWeight.Medium:
        return '500';
      case TextWeight.Normal:
        return '400';
      default:
        return '';
    }
  }};

  text-transform: ${({ transform }) => {
    switch (transform) {
      case TextTransform.Uppercase:
        return 'uppercase';
      case TextTransform.Lowercase:
        return 'lowercase';
      case TextTransform.Capitalize:
        return 'capitalize';
      default:
        return '';
    }
  }};

  ${({ size }) =>
    size === TextSize.Large
      ? css`
          margin-bottom: 4px;
        `
      : ''};

  ${({ color }) =>
    color === TextColor.Meta
      ? css`
          font-size: ${fontSizeMeta};
          line-height: ${lineHeightMeta};
          color: ${gunmetal};
        `
      : ''}
`;

export default {
  Text,
};
