import styled from 'styled-components';
import { gunmetal, pineGreen } from 'style/variables';

const DefaultInfoModal = styled.div<{ isMobile?: boolean }>`
  box-sizing: border-box;
  max-width: ${(props) => (props.isMobile ? 'auto' : '554px')};
  padding: ${(props) => (props.isMobile ? '24px' : '43px 91px 60px 46px')};
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  text-transform: none;

  & > header {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: ${(props) => props.theme.letterSpacing};
    margin-bottom: ${(props) => (props.isMobile ? '19px' : '35px')};
    color: ${pineGreen};
  }

  & > div {
    font-size: ${(props) => (props.isMobile ? '14px' : '16px')};
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${gunmetal};
  }

  & > footer {
    margin-top: ${(props) => (props.isMobile ? '22px' : '32px')};

    h5 {
      font-size: ${(props) => (props.isMobile ? '16px' : '15px')};
      font-weight: 500;
      line-height: 1.33;
      margin-bottom: ${(props) => (props.isMobile ? '5px' : '8px')};
    }
  }

  & + & {
    margin-top: ${(props) => (props.isMobile ? '-10px' : '-60px')};
  }
`;

export default {
  DefaultInfoModal,
};
