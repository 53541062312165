import { atom } from 'jotai';
import { Place } from 'backend/api/place/placeModel';
import { RoomOccupancy } from 'backend/api/trip/tripModel';

export const searchFormOccupancyAtom = atom<RoomOccupancy[]>([]);
export const searchFormCheckinAtom = atom<string>('');
export const searchFormCheckoutAtom = atom<string>('');
export const searchFormPlaceAtom = atom<Place | undefined>(undefined);
export const searchFormAutoPlaceAtom = atom<Place | undefined>(undefined);
export const searchFormDestinationTermAtom = atom<string>('');

export const searchFormDatesAtom = atom(
  (get) => {
    const checkin = get(searchFormCheckinAtom);
    const checkout = get(searchFormCheckoutAtom);

    return { checkin, checkout };
  },
  (get, set, { checkin, checkout }: { checkin: string | undefined; checkout: string | undefined }) => {
    const prevCheckIn = get(searchFormCheckinAtom);
    const prevCheckOut = get(searchFormCheckoutAtom);

    set(searchFormCheckinAtom, checkin || prevCheckIn);
    set(searchFormCheckoutAtom, checkout || prevCheckOut);
  },
);
