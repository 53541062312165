/**
 * @category Utils
 * @packageDocumentation
 */
import { TFunction } from 'i18next';
import { env } from 'environments/environment';

enum DatePart {
  year,
  month,
  day,
}

// Intl.DateTimeFormat may affect performance if used too much responsively;
// use with useMemo when possible
export function getDayTranslated(date: Date, lang: string): string {
  return new Intl.DateTimeFormat(lang, { day: 'numeric' }).format(date);
}

export function getYearTranslated(date: Date, lang: string): string {
  return new Intl.DateTimeFormat(lang, { year: 'numeric' }).format(date);
}

export function getMonthTranslated(date: Date, lang: string): string {
  return new Intl.DateTimeFormat(lang, { month: 'short' }).format(date);
}

export function formatDate(date: Date | undefined, lang: string): string {
  if (!date) {
    return 'n/a';
  }

  const m = getMonthTranslated(date, lang);
  const da = getDayTranslated(date, lang);
  const ye = getYearTranslated(date, lang);

  return `${m} ${da}, ${ye}`;
}

export function formatTime(date: Date | undefined, lang: string): string {
  if (!date) {
    return 'n/a';
  }

  return date.toLocaleTimeString(lang);
}

export function formatDateMonthAndDate(d: Date | undefined, lang: string): string {
  if (!d) {
    return 'n/a';
  }

  const m = getMonthTranslated(d, lang);
  const da = getDayTranslated(d, lang);

  return `${m} ${da}`;
}

/**
 * Tries to parse date.
 * @param s - string representation of a date
 * @returns undefined if date is unparseable
 */
export function parseDate(s: string | undefined | null): Date | undefined {
  if (!s) {
    return undefined;
  }
  try {
    const parts = s.split('-');
    let d: Date;

    if (parts[DatePart.year] && parts[DatePart.month] && parts[DatePart.day]) {
      d = new Date();
      d.setHours(0);
      d.setMinutes(0);
      d.setSeconds(0);
      d.setFullYear(Number.parseInt(parts[DatePart.year], 10));
      d.setMonth(Number.parseInt(parts[DatePart.month], 10) - 1, Number.parseInt(parts[DatePart.day], 10));
    } else {
      d = new Date(s);
    }

    if (!Number.isNaN(d.getTime())) {
      return d;
    }

    return undefined;
  } catch (e) {
    return undefined;
  }
}

const DATE_MIN_NUMERIC = 2;

export function startOfToday(offsetHours?: number) {
  const start = new Date();

  if (offsetHours) {
    start.setHours(start.getHours() - offsetHours, start.getMinutes() + start.getTimezoneOffset());
  }
  start.setHours(0, 0, 0, 0);

  return start;
}

export function startOfMonth(date: Date) {
  const returnDate = new Date(date);

  returnDate.setDate(1);
  returnDate.setHours(0, 0, 0, 0);

  return returnDate;
}

export function endOfToday() {
  const end = new Date();
  /* eslint-disable no-magic-numbers */

  end.setHours(23, 59, 59, 999);

  return end;
}

export function isToday(date: Date) {
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export function isInPast(date: string | undefined, offsetHours?: number): boolean {
  const d = parseDate(date);

  return !!d && startOfToday(offsetHours) > d;
}

export function isInFuture(date: string): boolean | undefined {
  const d = parseDate(date);

  if (!d) {
    return undefined;
  }

  return startOfToday() <= d;
}

/**
 * Converts date to "YYYY-MM-DD"
 * @param d date
 */
export function stringifyDate(d: Date): string {
  let month: string = (d.getMonth() + 1).toString();
  let day: string = d.getDate().toString();
  const year: string = d.getFullYear().toString();

  if (month.length < DATE_MIN_NUMERIC) {
    month = `0${month}`;
  }
  if (day.length < DATE_MIN_NUMERIC) {
    day = `0${day}`;
  }

  return [year, month, day].join('-');
}

export function shiftDays(date: Date, daysShift: number) {
  const d = new Date(date);

  d.setDate(d.getDate() + daysShift);

  return d;
}

export function getInitialCheckin(value?: string, ttt = env.searchBar.checkinShiftDays) {
  const parsedDate = parseDate(value);

  if (parsedDate) {
    return stringifyDate(parsedDate);
  }

  return stringifyDate(shiftDays(startOfToday(), ttt));
}

export function getInitialCheckout(checkin: string | undefined, value?: string, los = env.searchBar.lengthOfStay) {
  const date = parseDate(value);

  if (date) {
    return stringifyDate(date);
  }

  const parsedCheckin = parseDate(checkin);

  if (parsedCheckin) {
    return stringifyDate(shiftDays(parsedCheckin, los));
  }

  return stringifyDate(shiftDays(new Date(), env.searchBar.checkinShiftDays + los));
}

/**
 * Amount of milliseconds in a day, used in calculateNights
 */
const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;
const SECONDS_IN_MINUTE = 60;
const MS_IN_SECOND = 1000;

export const MS_IN_HOUR = MS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR;
export const MS_IN_DAY = MS_IN_HOUR * HOURS_IN_DAY;

const safariSafeDateParse = (strDate: string) => {
  const tmp = strDate
    .split(/[^0-9]/)
    .map((el) => Number.parseInt(el, 10))
    .slice(0, 6);

  if (tmp.length > 0 && tmp.length < 8) {
    return new Date(tmp[0], tmp[1] - 1, tmp[2], 0, 0, 0).getTime();
  }

  return NaN;
};

/**
 * Calculates amount of nights between two set dates, passed as date strings
 * @param firstDate
 * @param secondDate
 */
export const calculateNightCount = (firstDate: string | undefined, secondDate: string | undefined) => {
  if (!firstDate || !secondDate) {
    return 0;
  }

  const result: number = Math.round((safariSafeDateParse(secondDate) - safariSafeDateParse(firstDate)) / MS_IN_DAY);

  // may be NaN if something is parsed incorrectly
  return result > 0 ? result : 0;
};
/**
 * Calculates amount of nights between two set dates, passed as date strings
 * @param firstDate
 * @param secondDate
 */
export const calculateNightsBetweenDates = (
  firstDate: Date | null | undefined,
  secondDate: Date | null | undefined,
) => {
  if (!firstDate || !secondDate) {
    return undefined;
  }
  const result = Math.round((secondDate.getTime() - firstDate.getTime()) / MS_IN_DAY);

  return Math.max(result, 0);
};

export const isPeriodOverMaxNights = (start: Date | null | undefined, end: Date | null | undefined) => {
  const nights = calculateNightsBetweenDates(start, end);

  return !!nights && nights > env.searchBar.maxNights;
};

export const getPeriodOverMaxNightsMessage = (t: TFunction) =>
  t(
    'errors.search.invalid-length-of-stay',
    'Reservation can only be made for a maximum of 30 nights. Please enter different dates and try again.',
  );
