import { css } from 'styled-components';

let _detectedFlexGapSupported = false;
let _isFlexGapSupported = false;

const checkFlexGapSupported = (): boolean => {
  if (_detectedFlexGapSupported) {
    return _isFlexGapSupported;
  }

  const flex = document.createElement('div');

  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px';

  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));

  // append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex);
  // flex container should be 1px high from the row-gap
  const isSupported = flex.scrollHeight === 1;

  flex.remove();

  _detectedFlexGapSupported = true;
  _isFlexGapSupported = isSupported;

  return isSupported;
};

export const flexGapRow = (gap: string) => {
  if (checkFlexGapSupported()) {
    return css`
      gap: ${gap};
    `;
  }

  return css`
    & > * + * {
      margin-left: ${gap};
    }
  `;
};

export const flexGapRowReverse = (gap: string) => {
  if (checkFlexGapSupported()) {
    return css`
      gap: ${gap};
    `;
  }

  return css`
    & > * + * {
      margin-right: ${gap};
    }
  `;
};

export const flexGapColumn = (gap: string) => {
  if (checkFlexGapSupported()) {
    return css`
      gap: ${gap};
    `;
  }

  return css`
    & > * + * {
      margin-top: ${gap};
    }
  `;
};

export const flexGapColumnReverse = (gap: string) => {
  if (checkFlexGapSupported()) {
    return css`
      gap: ${gap};
    `;
  }

  return css`
    & > * + * {
      margin-bottom: ${gap};
    }
  `;
};
