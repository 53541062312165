import styled from 'styled-components';
import { gunmetal } from 'style/variables';

const Dropdown = styled.div<{ positionStart: boolean }>`
  position: absolute;
  z-index: 1020;
  width: max-content;
  top: 100%;
  ${(props) => (props.positionStart ? 'left: 0' : 'right: 0')};
  animation: slide-down 0.3s;

  border-radius: 9px;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.11);
  background-color: white;
  padding: 8px 0;

  color: ${gunmetal};
  overflow: hidden;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  position: relative;

  * {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 22px;
    cursor: pointer;
  }

  &:hover {
    color: black;
    background-color: #ecedf9;
  }

  & > * {
    color: inherit !important;
  }

  a:hover,
  button:hover {
    text-decoration: none;
  }
`;

const Content = styled.div`
  max-height: 40vh;
  width: 100%;
`;

export default {
  Dropdown,
  Item,
  Content,
};
