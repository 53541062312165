/**
 * @category Hotel Components
 * @packageDocumentation
 */
import { Replacement, useMask } from '@react-input/mask';
import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder/lib';
import { Icon } from 'components/Icon';
import Styled from 'components/InputField.styled';
import { Text } from 'components/common/Text/Text';
import { TextColor, TextSize } from 'components/common/Text/Text.types';
import { env } from 'environments/environment';
import StyledCommon from 'style/Common.styled';
import { placeholderInput } from 'style/placeholderStyles';
import { basicInputValidation, isNumber } from 'utils/validation';

interface InputFieldProps {
  /**
   * Label above the input field
   */
  label?: string;
  /**
   * Unique id. Required for autocomplete
   */
  id: string;
  /**
   * Name
   */
  name?: string;
  /**
   * Value
   */
  value?: string;
  /**
   * The value for which validation is ignored
   */
  ignoredValidationValue?: string;
  /**
   * Callback to trigger on every value change
   * @param str
   */
  onChange?: (str: string) => void;
  /**
   * Optional error message to display if validation rule fails
   */
  errorMessage?: string;
  /**
   * Optional validation rule (feel free to use regexp here)
   * @param str
   */
  validationRule?: (str: string) => boolean;
  /**
   * Optional style of parent container
   */
  containerStyle?: string;
  /**
   * If true, will display a pink star near the label
   */
  isRequired?: boolean;
  /**
   * Hide colon
   */
  colon?: boolean;
  /**
   * Optional message, which, if provided is used instead of standard '{fieldname} is required' message
   */
  requireMessage?: string;
  /**
   * Html input type. Text or email, for example
   */
  inputType?: string;
  /**
   * The input mode content attribute is an enumerated attribute that specifies
   * what kind of input mechanism would be most helpful for users entering content.
   */
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  /**
   * Pass an input autocomplete value here. Set explicitly to 'off' if you are sure autocomplete is not required
   * Values reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  autocomplete: string;
  /**
   * Basic validation just checks that input is at least two symbols long
   */
  doBasicValidation: boolean;
  /**
   * If provided, called when user press enter while the field is focused
   */
  onEnter?: () => void;
  /**
   * If provided, called on input focus
   */
  onFocus?: (event?: FocusEvent) => void;
  /**
   * If provided, called on input blur
   */
  onBlur?: (str: string) => void;
  disabled?: boolean;
  /**
   * Optional message to be shown below the field
   */
  helpMessage?: string;
  /**
   * Optional. If true, the placeholder is being drawn instead of the input.
   */
  isLoadingExternal?: boolean;

  allowHotJarRecording: boolean;

  maxLength?: number;

  clearButton?: boolean;

  autoFocus?: boolean;

  reserveErrorLine?: boolean;

  innerButton?: ReactElement;

  errorHasBackground?: boolean;

  dataTestid?: string;

  mask?: string;

  maskReplacement?: Replacement;
}

enum FieldState {
  basic = 'basic',
  error = 'error',
  basicError = 'basicError',
  emptyError = 'emptyError',
}

export interface AbstractValidatedField {
  invalidate: (focus: boolean, checkForEmpty?: boolean) => string | undefined;
  clear: () => void;
}

export interface InputFieldRef extends AbstractValidatedField {
  setIsError: (i18nMessage?: string) => void;
}

/**
 * Displays a (after design - styled) input field that can show the error messages if validationRule() returns false
 */
// eslint-disable-next-line react/display-name
const InputField = forwardRef(
  (
    {
      id,
      label,
      name,
      value,
      ignoredValidationValue,
      onChange,
      doBasicValidation,
      errorMessage,
      containerStyle,
      isRequired,
      inputType,
      inputMode,
      autocomplete,
      validationRule,
      onEnter,
      onFocus,
      onBlur,
      disabled,
      helpMessage,
      requireMessage,
      isLoadingExternal,
      allowHotJarRecording,
      maxLength,
      clearButton,
      autoFocus,
      reserveErrorLine,
      innerButton,
      errorHasBackground,
      colon = true,
      dataTestid,
      mask,
      maskReplacement,
    }: InputFieldProps,
    ref,
  ) => {
    const [t] = useTranslation();
    const [focused, setFocused] = useState(false);
    const [state, setState] = useState<FieldState>(FieldState.basic);
    const [dirty, setDirty] = useState<boolean | null>(null);
    const [errorText, setErrorText] = useState<string | undefined>(undefined);

    const commonRef = useRef<HTMLInputElement>(null);
    const maskRef = useMask({ mask, replacement: maskReplacement });
    const inputRef = mask && maskReplacement ? maskRef : commonRef;

    const innerValidation = useCallback(
      (checkForEmpty: boolean) => {
        let _errorText;

        if (!ignoredValidationValue || value !== ignoredValidationValue) {
          if (value && value.length > 0) {
            if (doBasicValidation && !basicInputValidation(value)) {
              setState(FieldState.basicError);
              _errorText = t('validation.oneSymbolError', '{field} must be longer than 1 letter', {
                field: label,
              });
            }
            if (validationRule && !validationRule(value)) {
              setState(FieldState.error);
              _errorText = errorMessage;
            }
          } else if (isRequired && checkForEmpty) {
            setState(FieldState.emptyError);
            _errorText = requireMessage || t('validation.emptyField', '{field} is required', { field: label });
          }
        } else {
          setState(FieldState.basic);
        }

        setErrorText(_errorText);

        return _errorText;
      },
      [
        doBasicValidation,
        ignoredValidationValue,
        isRequired,
        validationRule,
        value,
        t,
        errorMessage,
        label,
        requireMessage,
      ],
    );

    useEffect(() => {
      setState(FieldState.basic);
      setErrorText(undefined);
    }, [value, isRequired]);

    // if dirty === null then dirty uninitialized
    // revalidation if validationRule changed
    useEffect(() => {
      setDirty((prev) => prev !== null);
    }, [validationRule]);

    useEffect(() => {
      if (dirty && !focused) {
        innerValidation(false);
        setDirty(false);
      }
    }, [dirty, focused, innerValidation]);

    useImperativeHandle(ref, () => ({
      invalidate(focus: boolean, checkForEmpty?: boolean) {
        // if false, we set more general error to true and display meta error info instead
        const _errorMessage = innerValidation(checkForEmpty ?? true);

        if (_errorMessage) {
          if (inputRef?.current && focus) {
            inputRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
            inputRef.current.focus();
          }

          return _errorMessage;
        }

        return undefined;
      },
      setIsError(i18nMessage?: string) {
        setState(FieldState.error);
        setErrorText(i18nMessage || errorMessage);
        if (inputRef.current) {
          inputRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
          inputRef.current.focus();
        }
      },
      clear() {
        setState(FieldState.basic);
        setErrorText(undefined);
      },
    }));

    const onChangeValue = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        if (onChange && (newValue.length === 0 || inputType !== 'number' || isNumber(newValue))) {
          onChange(newValue);
        }

        setErrorText(undefined);
      },
      [inputType, onChange],
    );

    const onFocusInput = useCallback(
      (event: FocusEvent) => {
        setFocused(true);
        if (onFocus) {
          onFocus(event);
        }
      },
      [onFocus],
    );

    const onBlurInput = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        setFocused(false);
        innerValidation(true);
        if (onBlur) {
          onBlur(event.target.value);
        }
      },
      [innerValidation, onBlur],
    );

    const onKeyDown = useCallback(
      (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && onEnter) {
          onEnter();
        }
      },
      [onEnter],
    );

    const clear = useCallback(() => {
      if (onChange && inputRef.current) {
        onChange('');
        inputRef.current.focus();
      }
    }, [inputRef, onChange]);

    return (
      <Styled.InputField className={`uk-width-1-1 ${containerStyle}`} clearButton={clearButton}>
        <ReactPlaceholder type="rect" ready={!isLoadingExternal} showLoadingAnimation style={placeholderInput}>
          <>
            <Styled.InputBlock>
              <Styled.Input
                id={id}
                data-testid={dataTestid}
                ref={inputRef}
                name={name}
                onFocus={onFocusInput}
                onBlur={onBlurInput}
                className={`${errorText ? 'danger' : ''} ${
                  allowHotJarRecording ? 'data-hj-allow' : 'data-hj-suppress'
                }`}
                type={inputType === 'number' ? 'text' : inputType}
                inputMode={inputMode}
                autoCapitalize={inputMode === 'email' ? 'off' : undefined}
                value={value}
                onChange={onChangeValue}
                autoComplete={autocomplete}
                placeholder=" "
                maxLength={maxLength || (doBasicValidation ? env.inputs.basicMaxLength : undefined)}
                onKeyDown={onKeyDown}
                disabled={disabled}
                autoFocus={autoFocus}
                hasInnerButton={!!innerButton}
              />
              {!!label && (
                <Styled.InputLabel htmlFor={id}>{`${label}${isRequired && colon ? '*' : ''}`}</Styled.InputLabel>
              )}
              {innerButton}
            </Styled.InputBlock>
            <StyledCommon.ButtonText type="button" hidden={!clearButton || !onChange || !value} onClick={clear}>
              <Icon name={'delete'} />
            </StyledCommon.ButtonText>

            {!!helpMessage && (
              <Text tag="div" size={TextSize.Small} color={TextColor.Muted}>
                {helpMessage}
              </Text>
            )}
            {!!errorText && (
              <StyledCommon.ErrorLabel hasBackground={errorHasBackground} id={`${id}-${state}`}>
                {errorText}
              </StyledCommon.ErrorLabel>
            )}
            {!errorText && reserveErrorLine && <StyledCommon.ErrorLabelPlaceholder />}
          </>
        </ReactPlaceholder>
      </Styled.InputField>
    );
  },
);

export default InputField;
