/**
 * Source from where current property is set
 */
export enum ChangeMode {
  /**
   * It was loaded from campaign or location or default
   */
  Automatically = 'ChangedAutomatically',
  /**
   * It was set manually by user
   */
  Manually = 'ChangedManually',
}
