/**
 * @category i18n
 * @packageDocumentation
 */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import { TranslationResponse } from 'backend/api/general/generalResponse';
import { LanguageCode } from 'backend/api/model';
import { ApiResponse, ResponseMeta } from 'backend/api/response';
import getSplittyClient from 'backend/clientInit';
import { I18N_MISSING } from 'backend/localStorageKeys';
import { getHttpStatus, HttpStatus } from 'backend/serverError';
import { env } from 'environments/environment';
import { convertLangCode, convertLangCodeForTranslations } from 'utils/languageUtils';
import { log } from 'utils/logger';

export interface SupportedLanguage {
  name: string;
  countryCode: string;
  countryName: string;
  key: string;
  code: LanguageCode;
}

const I18N = env.i18n;

// const whitelist = I18N.languages.map(l => l.key);

/**
 * All the supported languages
 */
export const SUPPORTED_LANGUAGES: SupportedLanguage[] = I18N.languages.map((l) => ({
  ...l,
  code: convertLangCode(l.key),
}));

export function supportsLanguage(key: string): SupportedLanguage | undefined {
  const code = convertLangCode(key);

  return SUPPORTED_LANGUAGES.find((l) => l.code === code);
}

/**
 * @param key Gets supported language by key
 */
export function getLanguage(key: string): SupportedLanguage {
  const lang = supportsLanguage(key);

  return lang || getLanguage(I18N.fallbackLanguage);
}

export function isCurrentLanguage(lang: string) {
  return convertLangCode(lang) === convertLangCode(i18n.language);
}

export interface MissingKeys {
  lang: string;
  keys: {
    key: string;
    value: string;
  }[];
}

class MissingKeyManager {
  static readonly instance = new MissingKeyManager();

  private readonly missingKeys: MissingKeys[] = [];

  private constructor() {
    const value = window.localStorage.getItem(I18N_MISSING);

    if (value) {
      this.missingKeys = JSON.parse(value) as MissingKeys[];
    }
  }

  updateMissingKeys(key: string, value: string) {
    const lang = i18n.language;

    const languageSections = this.missingKeys.filter((languageSection) => languageSection.lang === lang);
    let languageSection: MissingKeys;

    if (languageSections.length === 0) {
      languageSection = { lang, keys: [] };
      this.missingKeys.push(languageSection);
    } else {
      languageSection = languageSections[0];
    }

    const records = languageSection.keys.filter((record) => record.key === key);
    let record: { key: string; value: string };

    if (records.length === 0) {
      record = { key, value };
      languageSection.keys.push(record);
      window.localStorage.setItem(I18N_MISSING, JSON.stringify(this.missingKeys));
    }
  }
}

/**
 * load can be called in race because useTranslation hook can be called on each re-render
 * so, we must get sure we do not initiate loading the same file more than once
 */
const loaders = new Map<string, Promise<ApiResponse<TranslationResponse, ResponseMeta>>>();
const loaded = new Map<string, ApiResponse<TranslationResponse, ResponseMeta>>();

async function load(
  languageCode: string,
  onOk: (resp: ApiResponse<TranslationResponse, ResponseMeta>) => void,
  onErr: (code: number) => void,
) {
  function getKey(code: string) {
    return code.toLowerCase();
  }

  const splittyClient = await getSplittyClient();

  try {
    const key = getKey(languageCode);

    const existing = loaded.get(key);

    if (existing) {
      onOk(existing);
    } else {
      let loader = loaders.get(key);

      if (!loader) {
        loader = splittyClient.getTranslations({
          languageCode: convertLangCodeForTranslations(languageCode),
          packs: env.i18n.packs,
          all: env.i18n.loadNotConfirmed,
        });

        loaders.set(key, loader);
        try {
          const ret = await loader;

          loaded.set(key, ret);
          onOk(ret);
        } finally {
          loaders.delete(key);
        }
      } else {
        onOk(await loader);
      }
    }
  } catch (e) {
    onErr(getHttpStatus(e) || HttpStatus.InternalServerError);
  }
}

/**
 * Initializes i18n
 */
i18n
  .use(Backend) // load resources from the back

  .use(LanguageDetector) // detect user language

  .use(initReactI18next) // pass the i18n instance to react-i18next.

  .use(ICU)

  .init({
    // This parameter guarantee than i18n will grasefully fallback only one line of text, not whole languege on the website
    fallbackLng: [env.i18n.fallbackLanguage],
    returnEmptyString: false,
    // whitelist,
    debug: I18N.debug,
    saveMissing: true,

    backend: {
      reloadInterval: false,

      loadPath(lngs: Record<string, unknown>) {
        const lng = lngs ? lngs.toString() : '';

        log(`i18n. Requesting resources for language ${lng}`, I18N.debug);

        const matchedLanguage = supportsLanguage(lng);

        if (matchedLanguage) {
          log(`Returning language for ${lng}`, I18N.debug);

          return matchedLanguage.key;
        }

        return env.i18n.fallbackLanguage;
      },

      request(
        _: Record<string, unknown>,
        langCode: string,
        payload: Record<string, unknown> | undefined,
        callback: (err: Record<string, unknown> | null, data: Record<string, unknown>) => void,
      ) {
        if (langCode) {
          if (!payload) {
            // loading resources from the server
            load(
              langCode,
              (resp) => {
                callback(null, { status: 200, data: resp.data.translations[0].dictionary });
              },
              (code) => {
                callback(null, { status: code });
              },
            );
          } else {
            // skip sending missing resource info to the server
            // We needed it only to collect the missed keys and show them on the i18nDebug page
            callback(null, { status: 200, data: {} });
          }
        } else {
          // It is important to have 4XX here, as otherwise the backend makes a lot of retries
          // The url is undefined for the cases when i18n make requests for non existing languages (as we do not use whitelist)
          callback(null, { status: 404 });
        }
      },

      parse(data: Record<string, unknown>): Record<string, unknown> {
        log('Language data loaded ', I18N.debug);

        return data;
      },

      parsePayload(namespace: string, key: string, fallbackValue: string) {
        MissingKeyManager.instance.updateMissingKeys(key, fallbackValue);

        return { key, fallbackValue };
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
