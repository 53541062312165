import React, { useRef } from 'react';
import styled from 'styled-components';
import { BodyContext } from 'TopContexts';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
`;

const BodyProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <BodyContext.Provider value={{ body: ref.current || document.body }}>
      <Body ref={ref}>{children}</Body>
    </BodyContext.Provider>
  );
};

export default BodyProvider;
