import jsc from 'js-cookie';
import { env } from 'environments/environment';

const USER_ID = 'USER_ID';

class CookieBasedUserIdStorage {
  set(userId: string): void {
    jsc.set(USER_ID, userId, { expires: new Date(new Date().getTime() + env.times.userIdMaxAge), sameSite: 'Lax' });
  }

  remove(): void {
    jsc.remove(USER_ID);
  }

  get(): string | undefined {
    return jsc.get(USER_ID);
  }
}

const cookieBasedUserIdStorage = new CookieBasedUserIdStorage();

export default cookieBasedUserIdStorage;
