import React, { useRef } from 'react';
import CustomScroll from 'react-custom-scroll';
import Styled from 'components/header-footer/SelectDropdown.styled';
import { Item } from 'components/widgets/useGroupsBySymbol';
import useOutsideClick from 'utils/useOutsideClick';

interface SelectDropdownProps {
  opened: boolean;
  values: Item[];
  selected: string;
  onSelect: (key: string) => void;
  onClose: () => void;
  hasScroll?: boolean;
  positionStart?: boolean;
  testId?: string;
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  opened,
  values,
  selected,
  onSelect,
  onClose,
  hasScroll = true,
  positionStart = true,
  testId,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, onClose);

  const content = (
    <Styled.Content>
      {values.map(({ key, value }) => (
        <Styled.Item
          id={`${testId}-${key}`}
          data-testid={key === selected ? testId : ''}
          key={key}
          selected={key === selected}
          onClick={() => {
            onSelect(key);
            onClose();
          }}
        >
          {value}
        </Styled.Item>
      ))}
    </Styled.Content>
  );

  return (
    <Styled.Dropdown hidden={!opened} ref={ref} positionStart={positionStart}>
      {hasScroll && <CustomScroll addScrolledClass={true}>{content}</CustomScroll>}
      {!hasScroll && content}
    </Styled.Dropdown>
  );
};

export default SelectDropdown;
