import React, { useCallback, useState } from 'react';
import { ModalAlertContext, ModalAlertProps } from 'TopContexts';
import ModalError from 'components/ModalError';
import { SanitizedHTML } from 'components/widgets/SanitizedHTML';

const ModalAlertProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [showAlert, setShowAlert] = useState<ModalAlertProps>();

  const closeModal = useCallback(() => {
    setShowAlert(undefined);
  }, []);

  const setAlert = useCallback(
    (message: string, afterClose?: () => void) => {
      setShowAlert({
        message,
        close: () => {
          closeModal();
          if (afterClose) {
            afterClose();
          }
        },
      });
    },
    [closeModal],
  );

  return (
    <ModalAlertContext.Provider value={{ alert: showAlert, setAlert }}>
      {showAlert && (
        <ModalError open={true} onProceed={showAlert.close}>
          <SanitizedHTML tag="div" rawHTML={showAlert.message} />
        </ModalError>
      )}
      {children}
    </ModalAlertContext.Provider>
  );
};

export default ModalAlertProvider;
