import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { StatusIcon } from 'components/StatusIcon';
import Styled, { Theme } from 'components/signin/PasswordValidationBox.styled';
import {
  AT_LEAST_ONE_LOWERCASE,
  AT_LEAST_ONE_NUMBER,
  AT_LEAST_ONE_UPPERCASE,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  validateNumber,
} from 'utils/validation';

export interface PasswordValidation {
  length?: boolean;
  atLeastOneNumber?: boolean;
  atLeastOneLowercase?: boolean;
  atLeastOneUppercase?: boolean;
}

interface PasswordValidationBoxProps {
  password: string;
  theme: Theme;
}

const getIconByStatus = (success: boolean | undefined) => {
  if (success === undefined) {
    return 'info';
  }

  return success ? 'success' : 'error';
};

const getValidation = (password: string): PasswordValidation => {
  if (password) {
    return {
      length: validateNumber(password.length, PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH),
      atLeastOneNumber: AT_LEAST_ONE_NUMBER.test(password),
      atLeastOneLowercase: AT_LEAST_ONE_LOWERCASE.test(password),
      atLeastOneUppercase: AT_LEAST_ONE_UPPERCASE.test(password),
    };
  }

  return {};
};

export const validationRule = (password: string) => {
  const passwordValidation = getValidation(password);

  return (
    !!passwordValidation.length &&
    !!passwordValidation.atLeastOneNumber &&
    !!passwordValidation.atLeastOneLowercase &&
    !!passwordValidation.atLeastOneUppercase
  );
};

const PasswordValidationBox = ({ password, theme }: PasswordValidationBoxProps) => {
  const [passwordValidation, setPasswordValidation] = useState<PasswordValidation>({});

  useEffect(() => {
    setPasswordValidation(getValidation(password));
  }, [password]);

  return (
    <Styled.Rules>
      <Styled.Rule containerTheme={theme} muted={passwordValidation.length}>
        <Styled.IconBox containerTheme={theme}>
          <StatusIcon name={getIconByStatus(passwordValidation.length)} />
        </Styled.IconBox>
        <Trans i18nKey="account.password.rule1">Between 8 and 16 characters</Trans>
      </Styled.Rule>
      <Styled.Rule containerTheme={theme} muted={passwordValidation.atLeastOneNumber}>
        <Styled.IconBox containerTheme={theme}>
          <StatusIcon name={getIconByStatus(passwordValidation.atLeastOneNumber)} />
        </Styled.IconBox>
        <Trans i18nKey="account.password.rule2">Contains at least one number</Trans>
      </Styled.Rule>
      <Styled.Rule containerTheme={theme} muted={passwordValidation.atLeastOneLowercase}>
        <Styled.IconBox containerTheme={theme}>
          <StatusIcon name={getIconByStatus(passwordValidation.atLeastOneLowercase)} />
        </Styled.IconBox>
        <Trans i18nKey="account.password.rule3">Contains at least one lowercase letter</Trans>
      </Styled.Rule>
      <Styled.Rule containerTheme={theme} muted={passwordValidation.atLeastOneUppercase}>
        <Styled.IconBox containerTheme={theme}>
          <StatusIcon name={getIconByStatus(passwordValidation.atLeastOneUppercase)} />
        </Styled.IconBox>
        <Trans i18nKey="account.password.rule4">Contains at least one uppercase letter</Trans>
      </Styled.Rule>
    </Styled.Rules>
  );
};

export default PasswordValidationBox;
