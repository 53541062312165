import React, { useCallback, useContext, useState } from 'react';
import { SignInDialogContext } from 'TopContexts';
import { Icon } from 'components/Icon';
import Styled from 'components/signin/SignInDialog.styled';
import SignInFormEmail from 'components/signin/SignInFormEmail';
import SignInFormOAuth from 'components/signin/SignInFormOAuth';

export enum SignInFormStep {
  oauth = 'oauth',
  email = 'email',
}

export interface SignInFormProps {
  onForgotPassword: (email: string) => void;
  step: SignInFormStep;
  setStep: (step: SignInFormStep) => void;
  children?: React.ReactNode;
}

const SignInForm: React.FC<SignInFormProps> = ({ onForgotPassword, children, step, setStep }) => {
  const { setSignInDialogMode } = useContext(SignInDialogContext);
  const [working, setWorking] = useState(false);

  const close = useCallback(() => {
    setSignInDialogMode(undefined);
  }, [setSignInDialogMode]);

  const back = useCallback(() => setStep(SignInFormStep.oauth), [setStep]);

  const proceedWithEmail = useCallback(() => setStep(SignInFormStep.email), [setStep]);

  return (
    <Styled.Modal>
      <Styled.TopControls>
        <Styled.TopControl onClick={back}>{step === 'email' && <Icon name={'back'} />}</Styled.TopControl>

        <Styled.TopControl onClick={close}>
          <Icon name={'close'} />
        </Styled.TopControl>
      </Styled.TopControls>

      {step === 'oauth' && (
        <SignInFormOAuth proceedWithEmail={proceedWithEmail} working={working} setWorking={setWorking}>
          {children}
        </SignInFormOAuth>
      )}

      {step === 'email' && (
        <SignInFormEmail onForgotPassword={onForgotPassword} working={working} setWorking={setWorking}>
          {children}
        </SignInFormEmail>
      )}
    </Styled.Modal>
  );
};

export default SignInForm;
