/**
 * @category Utils
 * @packageDocumentation
 */
// according to the old website, grouping by rooms is performed by deal.rateDescription field
import { DealType, HotelDeal, HotelDealExtended, HotelDeals } from 'backend/api/hotel/dealModel';
import { HotelRoomInfo, HotelDifferentRoomDealExtended } from 'backend/dataModel';

export const getFirstDeal = (deals?: HotelDeal[]) => (deals && deals.length > 0 ? deals[0] : undefined);

const dealSorter = (a: HotelDealExtended, b: HotelDealExtended) => a.nightlyPrice - b.nightlyPrice;

/**
 * Looks through the deals array and returns the one with the lowest rate value
 * @param deals
 */
export const findCheapestDeal = <T extends HotelDeal>(deals: T[]): T | undefined =>
  deals.reduce((prev, curr) => {
    if (prev.nightlyPrice > curr.nightlyPrice) {
      return curr;
    }

    return prev;
  }, deals[0]);

/**
 * Hotel room comparator by price, that uses the cheapest deal.rate as a comparison value
 * @param a
 * @param b
 */
const roomSorter = (a: HotelRoomInfo, b: HotelRoomInfo) => {
  const cheapestA = findCheapestDeal(a.deals);
  const cheapestB = findCheapestDeal(b.deals);

  if (!cheapestA && !cheapestB) {
    return 0;
  }
  if (!cheapestA) {
    return 1;
  }
  if (!cheapestB) {
    return -1;
  }

  return cheapestA.nightlyPrice - cheapestB.nightlyPrice;
};

/**
 * According to the old website data, deals should be grouped by deal.rateDescription into 'room' objects
 * and displayed as rooms which may contain several deals
 * @param hotelDeals
 */
export const groupByRooms = (hotelDeals?: HotelDeals): HotelRoomInfo[] => {
  if (!hotelDeals) {
    return [];
  }

  const map = hotelDeals.deals.reduce((prev, current) => {
    if (current.dealType === DealType.DifferentRoom) {
      return prev;
    }

    const deals = prev.get(current.roomName);

    if (deals) {
      deals.push(current);
    } else {
      prev.set(current.roomName, [current]);
    }

    return prev;
  }, new Map<string, HotelDealExtended[]>());

  const toReturn: HotelRoomInfo[] = [];

  map.forEach((value, key) => {
    toReturn.push({
      deals: value.sort(dealSorter),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      room: hotelDeals.hotelRooms.find((hotelRoom) => hotelRoom.roomName === key)!,
    });
  });

  return toReturn.sort(roomSorter);
};

/**
 * Returns a deal in array that has the lowest deal.rate
 * @param dealsArray
 */
export const findDifferentRoomDeal = (dealsArray: HotelDealExtended[]): HotelDifferentRoomDealExtended | undefined => {
  const sortedDeals = dealsArray.sort(dealSorter);

  const cheapestDeal = sortedDeals[0];

  if (cheapestDeal && cheapestDeal.dealType === DealType.DifferentRoom) {
    const secondDeal = sortedDeals[1];

    if (secondDeal) {
      return {
        ...cheapestDeal,
        discount: (secondDeal.nightlyPrice - cheapestDeal.nightlyPrice) / secondDeal.nightlyPrice,
      };
    }

    return cheapestDeal;
  }

  return undefined;
};
