export interface AppleLoginResponse {
  authorization: {
    state?: string;
    code: string;
    id_token: string;
  };
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
}

export interface AppleLoginError {
  error: string;
}

export const isAppleLoginError = (obj: unknown): obj is AppleLoginError => !!(obj as AppleLoginError).error;
