import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SignInDialogContext } from 'TopContexts';
import group from 'assets/images/icons/group.svg';
import { useSession } from 'atoms/hooks/useSession';
import { Icon } from 'components/Icon';
import InputField, { InputFieldRef } from 'components/InputField';
import StyledForm from 'components/signin/RecoverPasswordForm.styled';
import Styled from 'components/signin/SignInDialog.styled';
import useValidationCallback from 'components/useValidationCallback';
import { processError } from 'errors/errorUtils';
import useRecoveryPasswordErrors from 'errors/useRecoveryPasswordErrors';
import { emailValidationRule } from 'utils/validation';

export interface RecoverPasswordFormProps {
  initialEmail: string;
  back: (event: React.MouseEvent) => void;
}

const RecoverPasswordForm = ({ initialEmail, back }: RecoverPasswordFormProps) => {
  const { t } = useTranslation();
  const [emailSent, setEmailSent] = useState(false);
  const emailRef = useRef<InputFieldRef>();
  const errors = useRecoveryPasswordErrors();

  const [email, setEmail] = useState<string>(initialEmail);

  const [working, setWorking] = useState(false);

  const { validation } = useValidationCallback({ fields: [emailRef] });
  const { forgotPassword } = useSession();
  const { setSignInDialogMode } = useContext(SignInDialogContext);

  const emailChanged = useCallback(
    (changedEmail: string) => {
      setEmail(changedEmail);
    },
    [setEmail],
  );

  useEffect(() => {
    setEmail(initialEmail);
  }, [initialEmail, setEmail]);

  const passwordRecovery = useCallback(() => {
    if (validation(true).length > 0) {
      return;
    }
    setWorking(true);

    forgotPassword(email)
      .then(() => {
        setEmailSent(true);
      })
      .catch((reason) => {
        setWorking(false);
        processError(reason, errors);
      });
  }, [email, errors, forgotPassword, validation]);

  return (
    <Styled.Modal>
      <Styled.TopControls>
        <Styled.TopControl onClick={back}>
          <Icon name={'back'} />
        </Styled.TopControl>
        <Styled.TopControl onClick={() => setSignInDialogMode(undefined)}>
          <Icon name={'close'} />
        </Styled.TopControl>
      </Styled.TopControls>

      <Styled.Form>
        <Styled.Title>
          <Trans i18nKey="login-popup.forgot-password">Forgot your password?</Trans>
        </Styled.Title>

        <Styled.Text>
          <Trans i18nKey="login-popup.forgot-password-text">
            Please enter your email and we will send you a link to access your account.
          </Trans>
        </Styled.Text>

        {!emailSent && (
          <>
            <InputField
              id={'id-signin-email'}
              ref={emailRef}
              containerStyle="uk-margin-top"
              label={t('login-popup.email', 'Email Address')}
              isRequired={true}
              inputType={'text'}
              value={email}
              inputMode="email"
              onChange={emailChanged}
              errorMessage={t('login-popup.invalid-email', 'Invalid email')}
              autocomplete={'email'}
              doBasicValidation={true}
              validationRule={emailValidationRule}
              onEnter={passwordRecovery}
              allowHotJarRecording={true}
              errorHasBackground
            />

            <Styled.Button onClick={passwordRecovery} disabled={working}>
              <Trans i18nKey="login-popup.recovery-button">Reset password</Trans>
            </Styled.Button>
          </>
        )}

        {emailSent && (
          <>
            <StyledForm.EmailSentImgBox>
              <img src={group} alt="eye-on" />
            </StyledForm.EmailSentImgBox>

            <Styled.Text>
              <Trans
                i18nKey="login-popup.email-sent"
                defaults="We sent a link to reset your password to {email}<br/> Please check your inbox."
                values={{ email }}
              />
            </Styled.Text>
          </>
        )}

        <Styled.Footer />
      </Styled.Form>
    </Styled.Modal>
  );
};

export default RecoverPasswordForm;
