import React from 'react';
import { ThemeProvider } from 'styled-components';
import { LayoutProvider } from 'components/contexts/LayoutContext';
import FilterSortProvider from 'contexts/FilterSortProvider';
import MetaProvider from 'contexts/MetaProvider';
import ModalAlertProvider from 'contexts/ModalAlertProvider';
import SettingsProvider from 'contexts/SettingsProvider';
import SignInDialogProvider from 'contexts/SigninDialogProvider';
import ToastProvider from 'contexts/ToastProvider';
import GlobalStyles from 'style/main';
import theme from 'style/theme';

const TopProviders: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => (
  <MetaProvider>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <LayoutProvider>
        <SignInDialogProvider>
          <ToastProvider>
            <ModalAlertProvider>
              <SettingsProvider>
                <FilterSortProvider>{children}</FilterSortProvider>
              </SettingsProvider>
            </ModalAlertProvider>
          </ToastProvider>
        </SignInDialogProvider>
      </LayoutProvider>
    </ThemeProvider>
  </MetaProvider>
);

export default TopProviders;
