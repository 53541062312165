import { log } from 'utils/logger';

declare global {
  interface Window {
    // We have no idea of its real type, it is hotjar's staff
    /* eslint-disable @typescript-eslint/no-explicit-any */
    hj: any;
  }
}

class HotjarService {
  public getInstance() {
    window.hj = window.hj || this.getHotjarQueue();

    return window.hj;
  }

  public tag(tags: string[]): void {
    try {
      this.getInstance()('tagRecording', tags);
    } catch (e) {
      log(`Error while tagging hotjar ${e}`);
    }
  }

  public event(event: string): void {
    try {
      this.getInstance()('event', event);
    } catch (e) {
      log(`Error while tagging hotjar ${e}`);
    }
  }

  public user(userId: string | null): void {
    this.getInstance()('identify', userId, {});
  }

  private getHotjarQueue() {
    return (...rest: string[]) => {
      (window.hj.q = window.hj.q || []).push(rest);
    };
  }
}

const hjService = new HotjarService();

export enum HotjarEvent {
  SearchClicked = 'search_clicked',
  MyBookingClicked = 'my_booking_clicked',
  BookNowClicked = 'book_now_clicked',
  MapViewClicked = 'mapview_clicked',
  CompleteBookingClicked = 'complete_booking_clicked',
  SignInClicked = 'signin_clicked',
  SignUpClicked = 'signup_clicked',
}

export function hotjarEvent(event: HotjarEvent) {
  hjService.event(event);
}

export function hotjarTag(
  type: 'checkout' | 'hotel' | 'dummy' | 'confirmation' | 'booking_submit' | 'error',
  value: string,
) {
  switch (type) {
    case 'checkout':
      hjService.tag([`checkout_${value}`]);
      break;
    case 'dummy':
    case 'confirmation':
    case 'booking_submit':
      hjService.tag([`${value}`]);
      break;
    case 'hotel':
      hjService.tag([`h_${value}`]);
      break;
    case 'error':
      hjService.tag([`error_${value}`]);
      break;
    default:
      break;
  }
}

export function hotjarUser(userId: string | null) {
  hjService.user(userId);
}
