/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import { Button } from 'components/common/Button/Button';
import { rem } from 'style/functions';
import media from 'style/media';
import { textMutedColor, blueBlue, pineGreen, gray, veryLightPink } from 'style/variables';

const Container = styled.div`
  ${media.mobile} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const Content = styled.div`
  position: relative;
`;

const ReadOnlyField = styled.input`
  box-sizing: border-box;
  margin: 0;
  border-radius: 0;
  font: inherit;
  overflow: visible;
  -webkit-appearance: none;
  vertical-align: middle;
  width: 100%;
  border: none;
  color: ${pineGreen};

  border: 1px solid ${veryLightPink};
  border-radius: ${rem(4)} 0 0 ${rem(4)};

  padding-left: ${rem(48)};
  height: ${rem(50)};
  padding-right: ${rem(51)};

  &:focus {
    outline: none;
  }

  &:read-only {
    pointer-events: none;
  }

  &::placeholder {
    font-weight: normal;
    color: ${pineGreen};
  }
`;

const OffscreenSuggestionPicker = styled.div`
  width: 100%;
  display: inline-block;
  position: relative;
  max-width: 100%;
  margin: 0;

  font-size: 16px;

  ${ReadOnlyField} {
    height: 44px;
    border-radius: 4px;
    font-weight: 500;

    &:hover,
    &:focus,
    &:active {
      border: solid 1px ${blueBlue};
    }
  }
`;

const IconWrapper = styled.span`
  align-items: center;
  width: ${rem(51)};
  bottom: 0;
  color: ${gray};
  display: inline-flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const EmptyButton = styled(Button)`
  width: ${rem(51)};
  left: auto;
  right: 0;
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  top: 0;
  cursor: pointer;
  color: ${textMutedColor};
`;

export default { Container, Content, ReadOnlyField, OffscreenSuggestionPicker, IconWrapper, EmptyButton };
