import DOMPurify from 'dompurify';
import React, { memo } from 'react';
import { unescapeHTML } from 'utils/stringUtils';

interface SanitizedHTMLProps {
  tag: React.ElementType;
  rawHTML?: string;
  className?: string;
  style?: Record<string, string>;
  props?: Record<string, unknown>;
  purifyOptions?: Record<string, unknown>;
}

const purify = DOMPurify(window);

const SanitizedHTML = memo(function SanitizedHTML({
  tag,
  rawHTML,
  className,
  style,
  props,
  purifyOptions,
}: SanitizedHTMLProps) {
  const sanitizedContent = purifyOptions
    ? purify.sanitize(unescapeHTML(rawHTML), purifyOptions)
    : purify.sanitize(unescapeHTML(rawHTML));

  return React.createElement(tag, {
    dangerouslySetInnerHTML: { __html: sanitizedContent },
    className,
    style,
    ...props,
  });
});

export { SanitizedHTML };
