import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ServerErrorCode } from 'backend/serverError';
import { AvailableErrors } from 'errors/errorData';

const useGetUserDetailsErrors = (getUserDetailsErrorCallback: (message: string) => void): AvailableErrors => {
  const [t] = useTranslation();

  return useMemo(
    () => ({
      known: [
        {
          serverCodes: [ServerErrorCode.InvalidCredentials, ServerErrorCode.Unauthorized, ServerErrorCode.ItemNotFound],
        },
      ],
      default: {
        action: () =>
          getUserDetailsErrorCallback(
            t(
              'errors.personal-details',
              'Error occurred while getting your personal details. Please try again later or contact support.',
            ),
          ),
      },
    }),
    [getUserDetailsErrorCallback, t],
  );
};

export default useGetUserDetailsErrors;
