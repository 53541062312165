import React, { useMemo } from 'react';
import styled from 'styled-components';
import imagesJson from 'components/offscreen/app/images.json';
import { getRandomElement } from 'utils/utils';

interface LandingImage {
  x1: string;
  x2: string;
  x3: string;
}

const images: LandingImage[] = imagesJson;

const getImagePath = (image: string) => `${process.env.PUBLIC_URL}/images/app/${image}`;

const StyledImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
`;

const AppLandingImage: React.FC = () => {
  const img = useMemo(() => getRandomElement(images), []);

  return (
    <StyledImg src={getImagePath(img.x1)} srcSet={`${getImagePath(img.x2)} 2x, ${getImagePath(img.x3)} 3x`} alt="app" />
  );
};

export default AppLandingImage;
