import jsc from 'js-cookie';
import { env } from 'environments/environment';

/**
 * Name of the cookie to store JWT token
 */
const TOKEN = 'JWT_TOKEN';

class CookieBasedTokenStorage {
  set(token: string): void {
    jsc.set(TOKEN, token, { expires: new Date(new Date().getTime() + env.times.tokenMaxAge), sameSite: 'Lax' });
  }

  remove(): void {
    jsc.remove(TOKEN);
  }

  get(): string | undefined {
    return jsc.get(TOKEN);
  }
}

const cookieBasedTokenStorage = new CookieBasedTokenStorage();

export default cookieBasedTokenStorage;
