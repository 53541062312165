import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { USER_PROFILE_ROUTE } from 'routeList';

const ManageProfileLink: React.FC = () => (
  <Link to={{ pathname: USER_PROFILE_ROUTE, state: { section: 'manage' } }} data-testid="manage-profile-link">
    <Trans i18nKey="common.menu.manage-profile">Manage Profile</Trans>
  </Link>
);

export default ManageProfileLink;
