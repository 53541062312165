/* eslint-disable @typescript-eslint/no-empty-function */
/**
 * @category Search Form
 * @packageDocumentation
 */

import { TFunction } from 'i18next';
import { useAtom } from 'jotai';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useUserLocation } from 'atoms/hooks/useUserLocation';
import { searchFormDestinationTermAtom, searchFormPlaceAtom } from 'atoms/searchFormAtoms';
import { Place } from 'backend/api/place/placeModel';
import { getDataProvider } from 'backend/dataProvider';
import { Icon } from 'components/Icon';
import { IconLargeWrapper } from 'components/Icon.styled';
import { ButtonType } from 'components/common/Button/Button.types';
import { LayoutContext } from 'components/contexts/LayoutContext';
import OffscreenMode from 'components/offscreen/OffscreenMode';
import Styled from 'components/searchForm/SuggestionField/SuggestionField.styled';
import SuggestionList, { getPlaceName } from 'components/searchForm/SuggestionList/SuggestionsList';
import ApprovedSuggestionsList from 'components/searchForm/approvedSuggestionsList';
import DestinationsList from 'components/searchForm/destinationsList';
import { env } from 'environments/environment';
import useDebounce from 'utils/useDebounce';
import useOutsideClick from 'utils/useOutsideClick';
import { mod } from 'utils/utils';

interface SuggestionFieldProps {
  /**
   * Callback which is called when the suggestion is already here but user presses Enter while focus is within this component.
   * It is for form to be able to submit itself on that case
   */
  onEnter(): void;

  /**
   Callback which is called every time the user changes the search term.
   Debounce is applied (when user types fast, we do not call it till she stops)
   It is called even when no actual search will happen.
   You could consider the term passed as one currently displayed at the search field

   Cases when this is called while dataProvider.getDestinations not called:
   1. When the current term is shorter than env.searchBar.minimalSearchTermLength
   2. When the current term just changed as a result of selecting a suggestion
   */
  onSearchTerm(term: string): void;

  /**
   * Callback which is called when user selects the suggestion
   * @param place name and id of the selected suggestion
   */
  onSelectPlace(place: Place): void;

  /**
   * Use to set a predicted destination when user didn't pick anything with onSuggestion
   * @param place name and id of the selected suggestion
   */
  onAutoPlace(place: Place | undefined): void;

  offscreenMode?: OffscreenMode;

  offscreenHeader?: HTMLDivElement;

  readonly?: boolean;
}

function calculateStatus(t: TFunction, suggestions: ApprovedSuggestionsList): string | undefined {
  if (!suggestions.hasDestinations && suggestions.isQueryResult) {
    return t('search-bar.destination-missing', 'Destination not supported. We keep adding new destinations every day!');
  }

  return undefined;
}

const PortalWrapper: React.FC<{
  header: HTMLDivElement | undefined;
  children?: React.ReactNode;
}> = ({ header, children }) => {
  if (header) {
    return createPortal(<Styled.OffscreenSuggestionPicker>{children}</Styled.OffscreenSuggestionPicker>, header);
  }

  return <>{children}</>;
};

interface SearchTerm {
  term: string;
  initiatedByUser: boolean;
  delay?: number;
}

/**
 * General purposes suggestion field.
 * Used by the SearchForm for suggesting destinations, but can be used to suggest any {@link Place}
 * Suggested items can be selected by mouse or by keyboard
 */
const SuggestionField: React.FC<SuggestionFieldProps> = ({
  onEnter,
  onSelectPlace,
  onAutoPlace,
  onSearchTerm,
  offscreenMode,
  offscreenHeader,
  readonly,
}) => {
  const [t] = useTranslation();
  const {
    data: { city },
  } = useUserLocation();
  const { isMobileLayout } = useContext(LayoutContext);
  const [place, onSuggestion] = useAtom(searchFormPlaceAtom);
  const [destinationTerm, onSearchTermChange] = useAtom(searchFormDestinationTermAtom);
  const { pathname } = useLocation();

  const [destinations, setDestinations] = useState(new DestinationsList([], ''));
  const [approvedSuggestions, setApprovedSuggestions] = useState(new ApprovedSuggestionsList(undefined));
  const [searchTerm, setSearchTerm] = useState<SearchTerm>({ term: destinationTerm || '', initiatedByUser: false });

  const delay = useMemo(
    () => (searchTerm.delay !== undefined ? searchTerm.delay : env.times.suggestionsDebounce),
    [searchTerm.delay],
  );
  const [debouncedSearchTerm] = useDebounce<SearchTerm>(searchTerm, delay);

  const [selectedDestination, setSelectedDestination] = useState<Place | undefined>(place);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number | undefined>(undefined);
  const [isFocused, setFocused] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSearchTerm((prev) => ({ term: destinationTerm, initiatedByUser: prev.initiatedByUser }));
  }, [destinationTerm]);

  useEffect(() => {
    setSelectedDestination(place);
  }, [place]);

  useEffect(() => {
    if (offscreenMode === OffscreenMode.Destination) {
      setFocused(true);
    }
  }, [offscreenMode]);

  useEffect(() => {
    if (destinations.hasSuggestions) {
      onAutoPlace(destinations.suggestions[0]);
    } else {
      onAutoPlace(undefined);
    }
  }, [destinations.hasSuggestions, destinations.suggestions, isMobileLayout, onAutoPlace, pathname]);

  const removeSelection = useCallback(() => {
    setSelectedSuggestionIndex(undefined);
    setSelectedDestination(undefined);
  }, []);

  const onSelectCurrentLocation = useCallback(() => {
    setSearchTerm({ term: city || '', initiatedByUser: false, delay: 0 });
    removeSelection();
  }, [city, removeSelection]);

  const onSelection = useCallback(
    (dest: Place, focus?: boolean) => {
      setSearchTerm({ term: getPlaceName(dest), initiatedByUser: false, delay: 0 });
      setSelectedDestination(dest);
      onSelectPlace(dest);
      setFocused(!!focus);
    },
    [onSelectPlace],
  );

  const onFieldFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const onFieldClick = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.select();
  }, []);

  const clear = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();
      setSearchTerm({ term: '', initiatedByUser: false });
      removeSelection();
      onSuggestion(undefined);
      onSearchTermChange('');
      setDestinations(new DestinationsList([], ''));
      setFocused(true);
    },
    [onSearchTermChange, onSuggestion, removeSelection],
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // clear the place if empty
      if (e.target.value === '') {
        onSuggestion(undefined);
        onSearchTermChange('');
      }
      setFocused(true);
      setSearchTerm({ term: e.target.value, initiatedByUser: true });
      removeSelection();
    },
    [onSearchTermChange, onSuggestion, removeSelection],
  );

  useEffect(() => {
    if (inputRef.current) {
      if (isFocused) {
        inputRef.current.select();
      } else {
        inputRef.current.blur();
      }
    }
  }, [isFocused]);

  const keyDownCallback = useCallback(
    (e: React.KeyboardEvent) => {
      switch (e.key) {
        case 'Enter':
          if (selectedDestination !== undefined) {
            onEnter();
            setFocused(false);
          }
          if (destinations.hasSuggestions) {
            let index: number;

            if (selectedSuggestionIndex === undefined) {
              setSelectedSuggestionIndex(0);
              index = 0;
            } else {
              index = selectedSuggestionIndex;
            }
            if (destinations.suggestions.length > index) {
              const destination = destinations.suggestions[index];

              onSelection(destination, true);
            }

            setFocused(false);
          } else {
            removeSelection();
          }
          break;
        case 'ArrowDown': {
          const i = selectedSuggestionIndex !== undefined ? selectedSuggestionIndex + 1 : 0;

          setSelectedSuggestionIndex(mod(i, destinations.suggestions.length));
          break;
        }
        case 'ArrowUp': {
          const i = selectedSuggestionIndex !== undefined ? selectedSuggestionIndex - 1 : -1;

          setSelectedSuggestionIndex(mod(i, destinations.suggestions.length));
          break;
        }
        default:
      }
    },
    [
      onEnter,
      selectedDestination,
      destinations.hasSuggestions,
      destinations.suggestions,
      onSelection,
      removeSelection,
      selectedSuggestionIndex,
    ],
  );

  useDeepCompareEffect(() => {
    if (
      destinations.query === searchTerm.term ||
      (destinations.query === '' && searchTerm.term.length < env.searchBar.minimalTermLength)
    ) {
      setApprovedSuggestions(
        new ApprovedSuggestionsList(destinations.suggestions.length === 0 ? undefined : destinations.suggestions),
      );

      if (inputRef.current && offscreenMode === OffscreenMode.Destination) {
        inputRef.current.focus();
      }

      removeSelection();
    }
  }, [destinations.suggestions, destinations.query, offscreenMode, removeSelection, searchTerm.term]);

  useEffect(() => {
    const fetchSuggestions = (query: string) => {
      getDataProvider()
        .then((dataProvider) => dataProvider.getDestinations({ term: query }))
        .then((newDestinations) => setDestinations(new DestinationsList(newDestinations, query)))
        .catch(() => {});
    };

    // Do not fetch data if we already have selected suggestion.
    // Otherwise, setting the suggestion from the list changes the list itself, which looks ugly
    if (
      selectedDestination === undefined &&
      debouncedSearchTerm.initiatedByUser &&
      debouncedSearchTerm.term.length >= env.searchBar.minimalTermLength
    ) {
      fetchSuggestions(debouncedSearchTerm.term);
    } else {
      setDestinations(new DestinationsList([], ''));
    }
  }, [debouncedSearchTerm.initiatedByUser, debouncedSearchTerm.term, selectedDestination]);

  useEffect(() => {
    if (debouncedSearchTerm.initiatedByUser) {
      // final stage of renewing state of suggestion. always should be performed here, from debounced state only
      onSearchTerm(debouncedSearchTerm.term);
    }
  }, [debouncedSearchTerm.initiatedByUser, debouncedSearchTerm.term, debouncedSearchTerm.delay, onSearchTerm]);

  useOutsideClick(ref, () => setFocused(false), true);

  const clearButtonHidden = useMemo(() => {
    if (isMobileLayout) {
      return offscreenMode !== OffscreenMode.Destination || !searchTerm.term;
    }

    return !searchTerm.term || (!isHovered && !isFocused);
  }, [isFocused, isHovered, isMobileLayout, offscreenMode, searchTerm.term]);

  return (
    <Styled.Container ref={ref} onFocus={onFieldFocus}>
      <PortalWrapper header={offscreenHeader}>
        <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
          <Styled.Content>
            <Styled.IconWrapper>
              <Icon name={'search'} />
            </Styled.IconWrapper>
            <Styled.EmptyButton hidden={clearButtonHidden} styleType={ButtonType.Text} onMouseDown={clear}>
              <IconLargeWrapper>
                <Icon name={'delete'} />
              </IconLargeWrapper>
            </Styled.EmptyButton>

            <Styled.ReadOnlyField
              id="searchInput"
              data-testid="search-input"
              tabIndex={1}
              ref={inputRef}
              value={searchTerm.term}
              onChange={onChange}
              onFocus={onFieldFocus}
              onClick={onFieldClick}
              onKeyDown={keyDownCallback}
              readOnly={readonly}
              type="text"
              autoComplete="off"
              className="data-hj-allow"
              placeholder={t('search-bar.placeholder', 'Where are you going?')}
            />
          </Styled.Content>
        </div>
      </PortalWrapper>
      {!readonly && (
        <SuggestionList
          suggestions={approvedSuggestions.suggestions}
          onSelection={onSelection}
          onSelectCurrentLocation={onSelectCurrentLocation}
          selectedIndex={selectedSuggestionIndex}
          hide={!isFocused}
          additionalHint={calculateStatus(t, approvedSuggestions)}
          offscreenMode={offscreenMode}
        />
      )}
    </Styled.Container>
  );
};

export default SuggestionField;
