import { keyframes, css } from 'styled-components';

export const ANIMATION_MS = 200;
/*
 * Fade
 */
export const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeInAnimation = css`
  animation-name: ${fade};
  animation-duration: ${ANIMATION_MS}ms;
  animation-timing-function: linear;
`;

export const fadeOutAnimation = css`
  animation-name: ${fade};
  animation-duration: ${ANIMATION_MS}ms;
  animation-direction: reverse;
  animation-timing-function: ease-in;
`;
