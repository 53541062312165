import React, { EventHandler, MouseEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SignInDialogContext, ToastContext } from 'TopContexts';
import { useCampaign } from 'atoms/hooks/useCampaign';
import { useSession } from 'atoms/hooks/useSession';
import { OauthProvider } from 'backend/api/user/userModel';
import { SignUpRequest } from 'backend/api/user/userRequest';
import { Icon } from 'components/Icon';
import { InputFieldRef } from 'components/InputField';
import { ToastType } from 'components/Toast.styled';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { OAuthContext } from 'components/contexts/OAuthContext';
import Styled from 'components/signin/SignupForm.styled';
import SignupFormFirstStep from 'components/signin/SignupFormFirstStep';
import SignupFormSecondStep from 'components/signin/SignupFormSecondStep';
import useOauthSignUp from 'components/signin/useOauthSignUp';
import useValidationCallback from 'components/useValidationCallback';
import { processError } from 'errors/errorUtils';
import useSignUpErrors from 'errors/useSignUpErrors';
import guid from 'utils/guid';
import { AmplitudeLogging } from 'utils/logging/AmplitudeLogging';

export enum SignUpStep {
  first,
  second,
}

export interface SignupFormProps {
  signInMode: EventHandler<MouseEvent<HTMLAnchorElement | HTMLDivElement>>;
}

const SignupForm: React.FC<SignupFormProps> = ({ signInMode }) => {
  const [t] = useTranslation();

  const { setToast } = useContext(ToastContext);
  const { signUp } = useSession();
  const { isMobileLayout } = useContext(LayoutContext);
  const { campaign, origin: campaignOrigin } = useCampaign();
  const { setSignInDialogMode } = useContext(SignInDialogContext);
  const { oauthPayload, clearOAuthPayload } = useContext(OAuthContext);

  const firstNameRef = useRef<InputFieldRef>();
  const lastNameRef = useRef<InputFieldRef>();
  const emailRef = useRef<InputFieldRef>();
  const passwordRef = useRef<InputFieldRef>();
  const repeatPasswordRef = useRef<InputFieldRef>();

  const firstStepRefs = useMemo(() => [emailRef, passwordRef, repeatPasswordRef], []);
  const secondStepRefs = useMemo(() => [firstNameRef, lastNameRef], []);

  const [email, setEmail] = useState('');
  const [step, setStep] = useState(SignUpStep.first);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [agreeMarketing, setAgreeMarketing] = useState(false);
  const [emailBusy, setEmailBusy] = useState(false);

  const { signUpOauth, working } = useOauthSignUp(firstName, lastName, agreeMarketing);

  const { validation } = useValidationCallback({ fields: secondStepRefs });
  const { invalidate, fieldsValidation } = useValidationCallback({
    fields: [firstNameRef, lastNameRef, emailRef, passwordRef, repeatPasswordRef],
  });

  const recordAlreadyExistErrorCallback = useCallback(() => {
    setEmailBusy(true);
    invalidate(emailRef, true);
  }, [invalidate]);
  const errors = useSignUpErrors(recordAlreadyExistErrorCallback);

  const register = useCallback(() => {
    const { errorMessages, failedFields } = fieldsValidation(true);

    if (errorMessages.length > 0) {
      if (firstStepRefs.find((field) => failedFields.find((failed) => failed === field))) {
        setStep(SignUpStep.first);
      } else {
        setStep(SignUpStep.second);
      }

      return;
    }

    const payload: SignUpRequest = {
      username: email,
      password,
      recaptcha: guid(), // TODO
      firstName,
      lastName,
      agreeTerms: true,
      agreePrivacy: true,
      agreeMarketing,
      originCampaign: campaignOrigin ? campaign?.name : undefined,
    };

    signUp(payload)
      .then(() => {
        setSignInDialogMode(undefined);
        setToast(t('login-popup.signed-up', 'You have successfully signed up!'), ToastType.success);
        AmplitudeLogging.pushAccountCreatedEvent('email');
      })
      .catch((reason) => {
        processError(reason, errors);
        setStep(SignUpStep.first);
      });
  }, [
    fieldsValidation,
    email,
    password,
    firstName,
    lastName,
    agreeMarketing,
    campaignOrigin,
    campaign,
    signUp,
    errors,
    firstStepRefs,
    setSignInDialogMode,
    setToast,
    t,
  ]);

  const signUpOauthInternal = useCallback(() => {
    if (validation(true).length === 0) {
      signUpOauth();
    }
  }, [signUpOauth, validation]);

  const proceed = useCallback(() => setStep(SignUpStep.second), []);
  const back = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (step === SignUpStep.first || !!oauthPayload) {
        clearOAuthPayload();
        signInMode(event);
      } else {
        setStep(SignUpStep.first);
      }
    },
    [clearOAuthPayload, oauthPayload, signInMode, step],
  );

  useEffect(() => {
    if (oauthPayload) {
      setStep(SignUpStep.second);
    }
  }, [oauthPayload]);

  const onClose = useCallback(() => {
    setSignInDialogMode(undefined);
    clearOAuthPayload();
  }, [clearOAuthPayload, setSignInDialogMode]);

  return (
    <Styled.Modal>
      <Styled.TopControls>
        <Styled.TopControl onClick={back}>
          <Icon name={'back'} />
        </Styled.TopControl>

        {isMobileLayout && (
          <Styled.Logo>
            <Icon name={'logo'} />
          </Styled.Logo>
        )}

        <Styled.TopControl onClick={onClose}>
          <Icon name={'close'} />
        </Styled.TopControl>
      </Styled.TopControls>
      <Styled.HidingBox visible={step === SignUpStep.first}>
        <SignupFormFirstStep
          email={email}
          setEmail={setEmail}
          emailBusy={emailBusy}
          setEmailBusy={setEmailBusy}
          password={password}
          setPassword={setPassword}
          repeatPassword={repeatPassword}
          setRepeatPassword={setRepeatPassword}
          inputRefs={firstStepRefs}
          proceed={proceed}
        />
      </Styled.HidingBox>
      {step === SignUpStep.second && (
        <SignupFormSecondStep
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          inputRefs={secondStepRefs}
          agreeMarketing={agreeMarketing}
          setAgreeMarketing={setAgreeMarketing}
          signUp={oauthPayload ? signUpOauthInternal : register}
          working={working}
          onlyAgreement={oauthPayload && (oauthPayload.provider !== OauthProvider.Apple || !!oauthPayload.user)}
        />
      )}

      <Styled.Footer>
        {step === SignUpStep.first && (
          <div>
            <Trans i18nKey="login.has-account">Already a member?</Trans>{' '}
            <Styled.FooterLink href="/" onClick={signInMode}>
              <Trans i18nKey="login.signin-now">Sign-in!</Trans>
            </Styled.FooterLink>
          </div>
        )}
      </Styled.Footer>
    </Styled.Modal>
  );
};

export default SignupForm;
