import React, { MutableRefObject } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InputField, { InputFieldRef } from 'components/InputField';
import ToggleInput from 'components/ToggleInput';
import Styled from 'components/signin/SignupForm.styled';
import { SanitizedHTML } from 'components/widgets/SanitizedHTML';
import { PRIVACY_ROUTE, TERMS_ROUTE } from 'routeList';
import { nameValidationRule } from 'utils/validation';

interface SignupFormSecondStepProps {
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  inputRefs: MutableRefObject<InputFieldRef | undefined>[];
  agreeMarketing: boolean;
  setAgreeMarketing: React.Dispatch<React.SetStateAction<boolean>>;
  signUp: () => void;
  working: boolean;
  onlyAgreement?: boolean;
}

const SignupFormSecondStep: React.FC<SignupFormSecondStepProps> = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  inputRefs,
  agreeMarketing,
  setAgreeMarketing,
  signUp,
  working,
  onlyAgreement,
}) => {
  const [t] = useTranslation();
  const [firstNameRef, lastNameRef] = inputRefs;

  return (
    <Styled.Modal>
      <Styled.Form>
        <Styled.Title>
          <Trans i18nKey="login.signup-email">Finish Signing In</Trans>
        </Styled.Title>
        {!onlyAgreement && (
          <Styled.Inputs>
            <InputField
              id={'id-first-name'}
              dataTestid="first-name-input"
              ref={firstNameRef}
              containerStyle="input-margin-top"
              label={t('login-popup.first-name', 'First Name')}
              isRequired={true}
              inputType={'text'}
              value={firstName}
              onChange={setFirstName}
              allowHotJarRecording={true}
              autocomplete={'given-name'}
              validationRule={nameValidationRule}
              errorMessage={t('login-popup.invalid-name-error', 'The name must contain no more than 50 characters.')}
              doBasicValidation={true}
              errorHasBackground
            />
            <InputField
              id={'id-last-name'}
              dataTestid="last-name-input"
              ref={lastNameRef}
              containerStyle="input-margin-top"
              label={t('login-popup.last-name', 'Last Name')}
              isRequired={true}
              inputType={'text'}
              value={lastName}
              onChange={setLastName}
              allowHotJarRecording={true}
              autocomplete={'family-name'}
              validationRule={nameValidationRule}
              errorMessage={t('login-popup.invalid-name-error', 'The name must contain no more than 50 characters.')}
              doBasicValidation={true}
              errorHasBackground
            />
          </Styled.Inputs>
        )}

        <SanitizedHTML
          tag={Styled.AgreementText}
          rawHTML={t(
            'login-popup.accept-policy',
            'By creating an account, I have read and accept the ' +
              '<a href="{termsRoute}" rel="noopener noreferrer" target="_blank">Terms of use</a> ' +
              'and the <a href="{privacyRoute}" rel="noopener noreferrer" target="_blank">Privacy policy</a>',
            { termsRoute: TERMS_ROUTE, privacyRoute: PRIVACY_ROUTE },
          )}
        />

        <Styled.InfoBox>
          <Styled.SubscribeTextBox>
            <Trans i18nKey="common.misc.subscription">
              I agree to receive promotions from Holisto Ltd and its associated brands
            </Trans>
          </Styled.SubscribeTextBox>
          <ToggleInput toggled={agreeMarketing} setToggled={setAgreeMarketing} green />
        </Styled.InfoBox>

        <Styled.Button onClick={signUp} disabled={working} data-testid="signup-button">
          <Trans i18nKey="login.continue">Agree and Continue</Trans>
        </Styled.Button>
      </Styled.Form>
    </Styled.Modal>
  );
};

export default SignupFormSecondStep;
