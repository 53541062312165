import styled from 'styled-components';

export const Icon = styled.svg`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  fill: currentColor;
  transition: fill 0.15s;
`;

export const IconLargeWrapper = styled.div`
  ${Icon} {
    width: 20px;
    height: 20px;
  }
`;

export const IconXLargeWrapper = styled.div`
  ${Icon} {
    width: 24px;
    height: 24px;
  }
`;
