import { SplittyClient } from 'backend/splittyClient';
import clientStore from 'backend/splittyClientStore';
import { env } from 'environments/environment';

let client: Promise<SplittyClient> | null;

/**
 * @returns {@link SplittyClient} implementstion - either production or mock, depending on the environment
 * It is async because mock client is not a part of the distributive and lazy-loads only in the mock env.
 */
function getSplittyClient(): Promise<SplittyClient> {
  if (client) {
    return client;
  }

  if (env.api) {
    env.api.proxy = process.env.REACT_APP_PROXY === 'true';
  }

  switch (env.name) {
    case 'production':
      client = Promise.resolve(import('backend/production/productionClient')).then(
        ({ default: SplittyClientProd }) => new SplittyClientProd(clientStore),
      );

      return client;
    case 'development':
      client = Promise.resolve(import('backend/development/developmentClient')).then(
        ({ default: SplittyClientDev }) => new SplittyClientDev(clientStore),
      );

      return client;
    case 'swagger':
      client = Promise.resolve(import('backend/swagger/swaggerClient')).then(
        ({ default: SplittyClientSwagger }) => new SplittyClientSwagger(clientStore),
      );

      return client;
    case 'test':
      client = Promise.resolve(import('backend/mock/testClient')).then(
        ({ SplittyClientTest }) => new SplittyClientTest(clientStore),
      );

      return client;
    default:
      client = Promise.resolve(import('backend/mock/mockClient')).then(
        ({ SplittyClientMock }) => new SplittyClientMock(clientStore),
      );

      return client;
  }
}

export default getSplittyClient;
