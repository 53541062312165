import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { RoomOccupancy } from 'backend/api/trip/tripModel';
import { SearchFormQuery, SearchFormUrlParameters } from 'components/searchForm/query';
import { env } from 'environments/environment';
import { processError } from 'errors/errorUtils';
import useSearchFormParametersErrors from 'errors/useSearchFormParametersErrors';
import { isHotelOrDestinationRoute, isHotelRoute } from 'utils/uriUtils';
import useQuery from 'utils/useQuery';
import { validateDates, validateDateStrong, validateNumber } from 'utils/validation';

const validateSearchQuery = (q: SearchFormUrlParameters, path: string): SearchFormQuery & { error: unknown } => {
  let occupancy: RoomOccupancy[] | undefined;
  let occupancyValid = false;
  let checkinValid = false;
  let checkoutValid = false;
  let error;

  try {
    occupancy = q.occupancy ? JSON.parse(q.occupancy) : undefined;
    occupancyValid =
      !occupancy ||
      (validateNumber(occupancy.length, 1, env.searchBar.maxOccupancy) &&
        occupancy.every((o) => {
          if (
            !validateNumber(o.adults, 1, env.searchBar.maxAdults) ||
            (o.children || []).length > env.searchBar.maxChildren
          ) {
            return false;
          }

          return (o.children || []).every((age) =>
            validateNumber(age, env.searchBar.minChildAge, env.searchBar.maxChildAge),
          );
        }));
  } catch (err) {
    if (isHotelOrDestinationRoute(path)) {
      error = err;
    }
  }

  try {
    const offsetHours = isHotelRoute(path) ? env.times.offsetTimezoneHours : undefined;

    if (q.checkin && validateDateStrong(q.checkin, offsetHours)) {
      checkinValid = true;

      if (q.checkout && validateDateStrong(q.checkout) && validateDates(q.checkin, q.checkout)) {
        checkoutValid = true;
      }
    }
  } catch (err) {
    if (occupancyValid && isHotelOrDestinationRoute(path)) {
      error = err;
    }
  }

  return {
    occupancy: occupancyValid ? occupancy : undefined,
    checkin: checkinValid ? q.checkin : undefined,
    checkout: checkoutValid ? q.checkout : undefined,
    placeId: q.placeId,
    bounds: q.bounds,
    error,
  };
};

const useSearchFormParameters = () => {
  const location = useLocation();
  const errors = useSearchFormParametersErrors();
  const query = useQuery<SearchFormUrlParameters>();

  const validatedSearchQuery = useMemo(() => validateSearchQuery(query, location.pathname), [location.pathname, query]);

  useEffect(() => {
    if (validatedSearchQuery.error) {
      processError(validatedSearchQuery.error, errors);
    }
  }, [errors, validatedSearchQuery.error]);

  return validatedSearchQuery;
};

export default useSearchFormParameters;
