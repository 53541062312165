import { Link as LinkComponent } from 'react-router-dom';
import styled from 'styled-components';
import { textPrimaryColor } from 'style/variables';

const Link = styled(LinkComponent)`
  color: ${textPrimaryColor};
`;

export default {
  Link,
};
