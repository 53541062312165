import { useCallback, useEffect, useState } from 'react';

export enum ScrollDirection {
  UP,
  DOWN,
}

const useScrollListener = () => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(ScrollDirection.UP);
  const [scrollTop, setScrollTop] = useState<number>(window.pageYOffset || document.documentElement.scrollTop);

  const onScroll = useCallback(() => {
    requestAnimationFrame(() => {
      const newScrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (newScrollTop > scrollTop) {
        setScrollDirection(ScrollDirection.DOWN);
      } else {
        setScrollDirection(ScrollDirection.UP);
      }

      setScrollTop(newScrollTop);
    });
  }, [scrollTop]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return scrollDirection;
};

export default useScrollListener;
