import styled, { css } from 'styled-components';
import { warmBlue } from 'style/variables';

const ModalWithClose = styled.div<{ smallCloseIcon?: boolean }>`
  position: relative;

  #close-button {
    svg {
      z-index: 1;
      position: absolute;
      top: 19px;
      right: 19px;
      width: 24px;
      height: 24px;
      cursor: pointer;

      &:hover {
        color: ${warmBlue};
      }
    }

    ${(props) =>
      props.smallCloseIcon &&
      css`
        top: 14px;
        right: 14px;
      `}
  }
`;

export default {
  ModalWithClose,
};
