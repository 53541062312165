import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SignInDialogContext, ToastContext } from 'TopContexts';
import eyeOff from 'assets/images/icons/eye/icons-eye-off.svg';
import eyeOn from 'assets/images/icons/eye/icons-eye-on.svg';
import { useSession } from 'atoms/hooks/useSession';
import { UpdatePasswordRequest } from 'backend/api/user/userRequest';
import { Icon } from 'components/Icon';
import InputField, { InputFieldRef } from 'components/InputField';
import { ToastType } from 'components/Toast.styled';
import { LayoutContext } from 'components/contexts/LayoutContext';
import PasswordValidationBox from 'components/signin/PasswordValidationBox';
import { Theme } from 'components/signin/PasswordValidationBox.styled';
import Styled from 'components/signin/SignInDialog.styled';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import useValidationCallback from 'components/useValidationCallback';
import { ClientError, ClientErrorCode } from 'errors/clientError';
import { showMessageError } from 'errors/errorHandlers';
import { logError, processError } from 'errors/errorUtils';
import useUpdatePasswordErrors from 'errors/useUpdatePasswordErrors';
import useQuery, { UrlParameters } from 'utils/useQuery';
import useSearchParameter from 'utils/useSearchParameter';
import { passwordErrorMessage, passwordValidationRule } from 'utils/validation';

interface ResetPasswordParams extends UrlParameters {
  access_token?: string;
  userId?: string;
}

interface ResetPasswordFormProps {
  back: () => void;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ back }) => {
  const { t } = useTranslation();
  const errors = useUpdatePasswordErrors(undefined);

  const passwordRef = useRef<InputFieldRef>();
  const repeatPasswordRef = useRef<InputFieldRef>();

  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  const repeatPasswordValidationRule = useCallback((p: string) => p === password, [password]);

  const { setSignInDialogMode } = useContext(SignInDialogContext);
  const { updatePassword } = useSession();
  const { isMobileLayout } = useContext(LayoutContext);

  const [working, setWorking] = useState(false);

  const { userId, access_token: accessToken } = useQuery<ResetPasswordParams>();
  const [, setUserIdParam] = useSearchParameter<ResetPasswordParams, string>('userId', { raw: true, replace: true });
  const [, setAccessTokenParam] = useSearchParameter<ResetPasswordParams, string>('access_token', {
    raw: true,
    replace: true,
  });

  useEffect(() => {
    if (!userId || !accessToken) {
      const errorMessage = t('login-popup.reset-password.invalid-link', 'Reset password link is invalid');
      const errorId = logError(new ClientError(ClientErrorCode.InvalidLink, [errorMessage]));

      showMessageError(errorId, errorMessage);
    }
  }, [accessToken, t, userId]);

  const { setToast } = useContext(ToastContext);

  const { validation } = useValidationCallback({ fields: [passwordRef, repeatPasswordRef] });

  const handleClearUrlParams = useCallback(() => {
    setSignInDialogMode(SignInDialogMode.SignIn);
    setUserIdParam(undefined);
    setAccessTokenParam(undefined);
  }, [setAccessTokenParam, setSignInDialogMode, setUserIdParam]);

  const doReset = useCallback(() => {
    if (validation(true).length > 0) {
      return;
    }

    setWorking(true);
    const payload: UpdatePasswordRequest = {
      token: accessToken,
      password,
    };

    updatePassword(payload, Number(userId))
      .then(() => {
        setToast(t('login-popup.reset-password-changed', 'Password updated successfully!'), ToastType.success);
        handleClearUrlParams();
        back();
      })
      .catch((reason) => {
        setWorking(false);
        processError(reason, errors);
      });
  }, [validation, accessToken, password, updatePassword, userId, setToast, t, handleClearUrlParams, back, errors]);

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = useCallback(() => setShowPassword(!showPassword), [showPassword]);

  const eyeButton = (
    <Styled.EyeIcon onClick={toggleShowPassword}>
      {showPassword && <img src={eyeOn} alt="eye-on" />}
      {!showPassword && <img src={eyeOff} alt="eye-off" />}
    </Styled.EyeIcon>
  );

  const content = (
    <Styled.Modal>
      <Styled.TopControls>
        <Styled.TopControl />
        <Styled.TopControl onClick={() => setSignInDialogMode(undefined)}>
          <Icon name={'close'} />
        </Styled.TopControl>
      </Styled.TopControls>

      <Styled.Form>
        <Styled.Title>
          <Trans i18nKey="login-popup.reset-password-title">
            Forgot
            <br />
            password?
          </Trans>
        </Styled.Title>

        <Trans>Please enter your new password</Trans>

        <InputField
          id={'id-signin-password'}
          ref={passwordRef}
          containerStyle="input-margin-top"
          label={t('login-popup.password', 'Password')}
          isRequired={true}
          inputType={showPassword ? 'text' : 'password'}
          value={password}
          onChange={setPassword}
          errorMessage={passwordErrorMessage(t)}
          autocomplete={'off'}
          doBasicValidation={false}
          validationRule={passwordValidationRule}
          allowHotJarRecording={false}
          errorHasBackground
          innerButton={eyeButton}
        />
        <Styled.InfoBox className="input-margin-top">
          <PasswordValidationBox password={password} theme={Theme.dark} />
        </Styled.InfoBox>
        <InputField
          id={'id-signin-repeat-password'}
          ref={repeatPasswordRef}
          containerStyle="input-margin-top"
          label={t('login-popup.repeat-password', 'Repeat password')}
          isRequired={true}
          inputType={showPassword ? 'text' : 'password'}
          value={repeatPassword}
          onChange={setRepeatPassword}
          errorMessage={t('login-popup.passwords-do-not-match', 'Passwords do not match')}
          autocomplete={'off'}
          doBasicValidation={false}
          validationRule={repeatPasswordValidationRule}
          allowHotJarRecording={false}
          errorHasBackground
          innerButton={eyeButton}
        />

        <Styled.Button onClick={doReset} disabled={working}>
          <Trans i18nKey="login-popup.reset-button">Reset password</Trans>
        </Styled.Button>
      </Styled.Form>

      <Styled.Footer />
    </Styled.Modal>
  );

  return isMobileLayout ? <Styled.Container>{content}</Styled.Container> : content;
};

export default ResetPasswordForm;
