import styled from 'styled-components';
import { Icon } from 'components/Icon.styled';
import StyledCommon from 'style/Common.styled';

const Title = styled.h1`
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: ${(props) => props.theme.letterSpacing};
  text-align: center;
  color: white;
  text-transform: uppercase;
  margin: 0;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  color: white;
  margin: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;

  ${Text} {
    margin-top: 3px;
    margin-bottom: 22px;
  }

  ${StyledCommon.GreenButton} {
    width: 214px;
  }

  ${StyledCommon.ButtonLink} {
    margin-top: 34px;
    line-height: 1;
    color: white;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 58px 38px 110px;

  ${Icon} {
    color: white;
    width: 100%;
    height: 78px;
  }
`;

export default {
  Title,
  Text,
  Content,
  Container,
};
