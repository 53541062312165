import styled, { css } from 'styled-components';
import StyledCommon from 'style/Common.styled';

const HeaderContent = styled.div`
  padding: 12px 12px 12px 4px;
  flex-grow: 1;
`;

const Header = styled.div<{ shadow?: boolean; headerAtCenter?: boolean }>`
  z-index: 1010;
  display: flex;
  position: relative;
  transition: box-shadow 300ms ease;

  ${StyledCommon.ButtonText} {
    z-index: 1011;
    padding: 12px 0 12px 12px;

    ${(props) =>
      props.headerAtCenter &&
      css`
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
      `}
  }

  ${HeaderContent} {
    ${(props) =>
      props.headerAtCenter &&
      css`
        padding: 12px;
      `}
  }

  ${(props) =>
    props.headerAtCenter &&
    css`
      justify-content: center;
    `}

  ${(props) =>
    props.shadow &&
    css`
      box-shadow:
        0 3px 7px 0 rgba(0, 0, 0, 0.06),
        0 1px 0 0 rgba(203, 203, 203, 0.5);
    `}
`;

const Content = styled.div`
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export default {
  Header,
  HeaderContent,
  Content,
};
