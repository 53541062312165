import styled from 'styled-components';
import StyledCommon from 'style/Common.styled';
import { gunmetal, white } from 'style/variables';

const NavbarContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${white};
`;

const Navbar = styled(StyledCommon.Container)`
  display: flex;
  justify-content: space-between;
  padding-top: 17px;
  padding-bottom: 16px;

  & > a {
    padding: 0;
  }
`;

const NavbarList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavbarListItem = styled.li`
  display: flex;
  position: relative;

  & + & {
    margin-left: 5px;
  }

  & > a,
  & > button {
    padding: 12px 20px 6px 9px;
    font-size: 14px;
    font-weight: normal;
    color: ${gunmetal};
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
`;

const DropDownList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const DropDownListItem = styled.li`
  padding: 4px 16px;

  a,
  button {
    color: ${gunmetal} !important;
  }

  &:hover {
    background-color: #ecedf9;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 21px;
  margin: auto 13px auto 2px;
  border-right: #c2c2c2 solid 1px;
  align-self: center;
`;

const NavbarItem = styled(StyledCommon.ButtonText)`
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    color: #848dff;
  }

  input:checked + & {
    color: black;
    background-color: #f7f7ff;
  }
`;

const Logo = styled.img`
  height: 45px; // reserve space for logo
`;

export default {
  NavbarContainer,
  Navbar,
  Divider,
  NavbarList,
  NavbarListItem,
  DropDownList,
  DropDownListItem,
  NavbarItem,
  Logo,
};
