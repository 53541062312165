/**
 * @category Utils
 * @packageDocumentation
 */

const HEX_BASE = 16;
const BITMASK = 0x10000;

/**
 * generates random guid
 */
export default function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * BITMASK)
      .toString(HEX_BASE)
      .substring(1);
  }

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}
