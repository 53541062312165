/**
 * @category Views
 * @packageDocumentation
 */
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import StyledNavbar from 'components/header-footer/NavbarDesktop.styled';
import ChangePasswordLink from 'components/links/ChangePasswordLink';
import ManageProfileLink from 'components/links/ManageProfileLink';
import SignOutLink from 'components/links/SignOutLink';
import Dropdown from 'components/widgets/Dropdown';

const AccountMenuSelector: React.FC = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <input type="checkbox" hidden={true} checked={open} readOnly={true} />
      <StyledNavbar.NavbarItem
        type="button"
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
        data-testid="my-account-button"
      >
        <Trans i18nKey="common.menu.my-account">My Account</Trans>
      </StyledNavbar.NavbarItem>
      <Dropdown opened={open} onClose={() => setOpen(false)} positionStart={false}>
        <ManageProfileLink />
        <ChangePasswordLink />
        <SignOutLink />
      </Dropdown>
    </>
  );
};

export default AccountMenuSelector;
