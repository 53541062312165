import styled, { css } from 'styled-components';
import StyledNavbar from 'components/header-footer/NavbarDesktop.styled';
import StyledCommon from 'style/Common.styled';
import { blueMagenta, pineGreen, cornflower, gunmetal, veryLightPink } from 'style/variables';

const Navbar = styled.div<{ sticky?: boolean }>`
  transition:
    background-color 0.6s ease-in-out,
    box-shadow 0.2s ease-in-out;

  ${StyledNavbar.NavbarContainer} {
    box-shadow:
      0 3px 7px 0 rgba(0, 0, 0, 0.06),
      0 1px 0 0 rgba(203, 203, 203, 0.5);
  }

  ${StyledNavbar.Navbar} {
    padding-bottom: 16px;
    margin-bottom: 0;
  }

  ${(props) =>
    !props.sticky &&
    css`
      ${StyledNavbar.Navbar} {
        a,
        button {
          color: ${gunmetal};
          line-height: 1.5;
          font-size: 14px;

          svg {
            opacity: 0.8;
          }
        }

        input:checked + ${StyledNavbar.NavbarItem} {
          background-color: ${blueMagenta};
        }
      }
    `}

  ${(props) =>
    props.sticky &&
    css`
      box-sizing: border-box;
      box-shadow:
        0 3px 7px 0 rgba(0, 0, 0, 0.06),
        0 1px 0 0 rgba(203, 203, 203, 0.5);
      background-color: rgba(255, 255, 255, 0.96) !important;
    `}
`;

const HeaderImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  width: 58%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
  max-width: 1440px;
  padding: 0 30px;
  margin: 0 auto;
  & > & {
    padding: 0;
  }
`;

const Title = styled.h1`
  font-size: 56px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: 6.22px;
  margin-top: 62px;
  margin-bottom: 60px;
  max-width: 748px;

  flex-grow: 0;
  font-stretch: normal;
  font-style: normal;
  color: ${pineGreen};
`;

const SearchForm = styled.div`
  ${StyledCommon.GhostButton} {
    border-color: white;
    color: white;
    width: 120px;
    background-color: ${cornflower};

    &:active {
      background-color: #a5abf9;
    }
  }

  form {
    padding: 0;
  }
`;

const Point = styled.div`
  max-width: 425px;
  padding-bottom: 80px;

  & > h2 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 28px;
  }

  & > div {
    line-height: 1.71;
    color: ${gunmetal};
  }
`;

const FirstPoint = styled(Point)`
  margin-top: 0;
  align-self: flex-start;
  padding-bottom: 50px;
  padding-right: 150px;
  border-bottom: solid 1px ${veryLightPink};
`;

const LastPoints = styled.div`
  display: flex;
  flex-direction: column;

  & > ${Point} {
    padding-top: 80px;
    align-self: flex-end;
    border-top: solid 1px ${veryLightPink};
  }

  & > ${Point}:last-child {
    padding-bottom: 50px;
  }
`;

const PointsContainer = styled.div`
  display: flex;
  margin-top: 155px;
  margin-bottom: 65px;
  position: relative;

  & > div:first-child {
    margin-right: 105px;
    position: relative;
    width: 44%;
    height: fit-content;

    img {
      width: 100%;
    }

    ${StyledCommon.Letter} {
      position: absolute;
      height: 110%;
      top: 50%;
      left: 46.2%;
      transform-origin: center;
      color: #bdbdbd;
    }
  }

  & > ${LastPoints} {
    align-self: flex-end;
  }

  & > img {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(22%);
    width: 15%;
    z-index: -1;
  }
`;

export default {
  Navbar,
  HeaderImage,
  Container,
  Title,
  SearchForm,
  LastPoints,
  Point,
  FirstPoint,
  PointsContainer,
};
