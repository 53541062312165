export const placeholderAddress = { maxWidth: '22.5rem', marginBottom: '0.21rem' };
export const placeholderTitle = { maxWidth: '35rem', height: '2rem' };
export const placeholderOverview = { height: '342px' };
export const placeholderTopAmenities = { height: '120px', marginTop: '54px' };
export const dealImage = { maxWidth: '24rem', height: '20rem', width: '100%' };
export const defaultContainer = { width: '100%', height: '100%' };
export const checkoutHotelInfoHeader = { width: '15rem', height: '2rem' };
export const checkoutHotelInfoText = { width: '13.2rem' };
export const placeholderInput = { height: '50px', width: '100%', borderRadius: '4px' };
export const placeholderImage = { minHeight: 'inherit', margin: 0 };
