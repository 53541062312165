import { ClientLocation } from 'backend/api/client/clientModel';

export const LogInfoMemoryStorage: {
  currencyCode?: string;
  languageCode?: string;
  campaignName?: string;
  countryCode?: string;
  stateCode?: string;
  userId?: number;
  clientLocation?: ClientLocation;
  pageType?: string;
} = {};
