import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SignInDialogContext } from 'TopContexts';
import { getDataProvider } from 'backend/dataProvider';
import { HotjarEvent, hotjarEvent } from 'backend/hotjarTags';
import InputField, { InputFieldRef } from 'components/InputField';
import ModalInfo from 'components/ModalInfo';
import Styled from 'components/signin/SignInByTripForm.styled';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import useValidationCallback from 'components/useValidationCallback';
import { processError } from 'errors/errorUtils';
import useEncryptTripIdErrors from 'errors/useEncryptTripIdErrors';
import StyledCommon from 'style/Common.styled';
import { createMyTripUri } from 'utils/uriUtils';
import { emailValidationRule, tripNumberValidationRule } from 'utils/validation';

const SignInByTripForm: React.FC = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const errors = useEncryptTripIdErrors();

  const { signInDialogMode, setSignInDialogMode } = useContext(SignInDialogContext);

  const [email, setEmail] = useState<string>('');
  const [trip, setTrip] = useState<string>('');

  const [working, setWorking] = useState(false);

  const tripRef = useRef<InputFieldRef>();
  const emailRef = useRef<InputFieldRef>();

  const { clear, validation } = useValidationCallback({ fields: [emailRef, tripRef] });

  const title = useMemo(() => t('my-booking.find-trip', 'Find my trip'), [t]);
  const action = useMemo(() => t('find-trip.action', 'Find my reservation'), [t]);

  useEffect(() => {
    if (signInDialogMode !== SignInDialogMode.ViewBooking) {
      setEmail('');
      setTrip('');
      clear();
    }
  }, [clear, signInDialogMode, title]);

  const findTrip = useCallback(() => {
    if (validation(true).length > 0) {
      return;
    }

    setWorking(true);
    getDataProvider()
      .then((dataProvider) => dataProvider.encryptTripId(trip, email))
      .then((encryptedTripId) => {
        hotjarEvent(HotjarEvent.MyBookingClicked);
        history.push(createMyTripUri(email, encryptedTripId));
      })
      .catch((reason) => processError(reason, errors))
      .finally(() => setWorking(false));
  }, [email, errors, history, trip, validation]);

  return (
    <ModalInfo
      open={signInDialogMode === SignInDialogMode.ViewBooking}
      onOpenChange={() => setSignInDialogMode(undefined)}
      smallCloseIcon={true}
    >
      <Styled.Container>
        <Styled.Header>{title}</Styled.Header>

        <Styled.Text>
          <Trans i18nKey="find-trip.instructions">Enter your email and reservation number.</Trans>
        </Styled.Text>

        <Styled.Inputs>
          <InputField
            id="id-trip-email"
            ref={emailRef}
            label={t('checkout.guest.email', 'Email')}
            isRequired={true}
            inputType="text"
            inputMode="email"
            value={email}
            onChange={setEmail}
            errorMessage={t('find-trip.invalid-email', 'Please enter the email used for this reservation')}
            autocomplete="email"
            doBasicValidation={true}
            validationRule={emailValidationRule}
            allowHotJarRecording={true}
          />

          <InputField
            id="id-signin-tripnumber"
            ref={tripRef}
            label={t('confirmation.reservation-number', 'Reservation number')}
            isRequired={true}
            inputType="text"
            value={trip}
            onChange={setTrip}
            errorMessage={t('find-trip.invalid-trip-number', 'Please verify the reservation number is correct')}
            autocomplete="off"
            doBasicValidation={false}
            validationRule={tripNumberValidationRule}
            onEnter={findTrip}
            allowHotJarRecording={true}
          />
        </Styled.Inputs>

        <StyledCommon.PrimaryButton type="button" onClick={findTrip} disabled={working}>
          {action}
        </StyledCommon.PrimaryButton>

        <Styled.Footer>
          <StyledCommon.ButtonLink type="button" onClick={() => setSignInDialogMode(SignInDialogMode.SignIn)}>
            <Trans i18nKey="find-trip.or-login">or sign to your account</Trans>
          </StyledCommon.ButtonLink>
        </Styled.Footer>
      </Styled.Container>
    </ModalInfo>
  );
};

export default SignInByTripForm;
