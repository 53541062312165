import { RateDisplayModel } from 'backend/api/general/generalModel';
import { HotelAmenity, HotelImage } from 'backend/api/hotel/hotelModel';
import { BoardCode, CurrencyCode, PaymentType, RefundableType } from 'backend/api/model';

export interface HotelDeal {
  dealKey: string;
  dealType: DealType;
  boardCode: BoardCode;
  boardDescription: string;
  paymentType: PaymentType;
  currencyCode: CurrencyCode;
  nightlyPrice: number;
  /**
   * Average price per room for all nights, excluding taxes
   */
  roomPrice: number;
  /**
   * Price for all rooms and all nights, excluding taxes
   */
  totalRoomsPrice: number;
  totalTaxes: number;
  totalFees: number;
  totalPrice: number;
  totalHotelFees: number;
  totalPriceWithHotelFees?: number;
  totalRoomsPriceWithHotelFees: number;
  cancellation: CancellationPolicy;
}

export interface CancellationPolicy {
  type: RefundableType;
  deadline?: string | null;
}

/* eslint-disable no-magic-numbers */
export enum DealType {
  Simple = 1,
  SameRoom = 2,
  DifferentRoom = 3,
}

export interface HotelRoom {
  roomCode: string;
  roomName: string;
  maxOccupancy?: number;
  smoking?: boolean;
  amenities?: HotelAmenity[];
  images: HotelImage[];
}

export interface HotelDealExtended extends HotelDeal {
  dummy?: boolean;
  roomCode: string;
  roomName: string;
  checkoutId?: string;
  checkin: string;
  checkout: string;
  cancellation: CancellationPolicyExtended;
  fees?: FeeTaxBreakdown[];
  taxes?: FeeTaxBreakdown[];
  hotelFees?: FeeTaxBreakdown[];
  rates?: DealRate[];
}

export interface CancellationPolicyExtended extends CancellationPolicy {
  penalties: Penalty[];
  description: string;
}

export interface Penalty {
  from?: string;
  to?: string;
  amount: number;
}

export interface FeeTaxBreakdown {
  code: number;
  description: string;
  amount: number;
  currencyCode: CurrencyCode;
}

export interface DealRate {
  roomCode: string;
  roomName: string;
  boardCode: BoardCode;
  boardDescription: string;
  checkin: string;
  checkout: string;
}

export interface HotelDeals {
  deals: HotelDealExtended[];
  hotelRooms: HotelRoom[];
  rateModel?: RateDisplayModel;
}
