import React from 'react';
import Lottie from 'react-lottie-player';
import loadingAnimation from 'assets/lotties/loading.json';

interface LoaderProps {
  size?: number;
}

const DEFAULT_SIZE = 40;

const Loader: React.FC<LoaderProps> = ({ size = DEFAULT_SIZE }) => (
  <Lottie
    animationData={loadingAnimation}
    loop
    play
    style={{
      width: size,
      height: size,
      background: 'white',
      borderRadius: '50%',
      zIndex: 1,
    }}
  />
);

export default Loader;
