/**
 * @category i18n
 * @packageDocumentation
 */

import { useTranslation } from 'react-i18next';
import { env } from 'environments/environment';
import { getLanguage } from 'i18n';

/**
 * Hook which returns current language with all its properties
 * It is here to prevent calling getLanguage in a lot of places - just call the hook and have all the required variables
 * to use everywhere in your component.
 */
const useCurrentLanguage = () => {
  const [, i18n] = useTranslation();

  return getLanguage(i18n.language ? i18n.language : env.i18n.fallbackLanguage);
};

export default useCurrentLanguage;
