import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkProps } from 'components/links/LinkProps';
import Styled from 'components/links/TermsAndConditionsLink/TermsAndConditionsLink.styled';
import { TERMS_ROUTE } from 'routeList';

const TermsAndConditionsLink: React.FC<LinkProps> = ({ onClick, target = '_blank' }) => {
  const [t] = useTranslation();
  const linkText = useMemo(() => t('footer.terms', 'Terms and Conditions'), [t]);

  return (
    <Styled.Link
      to={TERMS_ROUTE}
      target={target}
      onClick={() => onClick?.(linkText)}
      data-testid="terms-and-conditions-link"
    >
      {linkText}
    </Styled.Link>
  );
};

export default TermsAndConditionsLink;
