import React from 'react';
import InputCheckbox from 'components/InputCheckbox';
import StyledBurger from 'components/offscreen/BurgerMenu.styled';
import OffscreenMenu from 'components/offscreen/OffscreenMenu';
import Styled from 'components/offscreen/OffscreenSelect.styled';
import OffscreenSlideAnimation from 'components/offscreen/OffscreenSlideAnimation';
import { Item } from 'components/widgets/useGroupsBySymbol';

interface OffscreenSelectProps {
  opened: boolean;
  title: string;
  values: Item[];
  selected: string;
  onSelect: (key: string) => void;
  onClose: () => void;
}

const OffscreenSelect: React.FC<OffscreenSelectProps> = ({ opened, title, values, selected, onSelect, onClose }) => (
  <OffscreenSlideAnimation show={opened}>
    <OffscreenMenu
      onClose={onClose}
      headerAtCenter={true}
      header={<StyledBurger.Title center>{title}</StyledBurger.Title>}
    >
      <Styled.Container>
        {values.map((v) => (
          <InputCheckbox key={v.key} checked={v.key === selected} setChecked={() => onSelect(v.key)} shortMode={true}>
            {v.value}
          </InputCheckbox>
        ))}
      </Styled.Container>
    </OffscreenMenu>
  </OffscreenSlideAnimation>
);

export default OffscreenSelect;
