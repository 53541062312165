import { LanguageCode } from 'backend/api/model';

const LANGUAGE_CODE_LEN = 2;

/**
 * @param languageCode - like 'en-us' format
 * @return 'EN'
 */
export function convertLangCode(languageCode: string): LanguageCode {
  return languageCode.slice(0, LANGUAGE_CODE_LEN).toUpperCase() as LanguageCode;
}

/**
 * @param languageCode - like 'en-us' format
 * @return 'en-US'
 */
export function convertLangCodeForTranslations(languageCode: string) {
  const parts = languageCode.split('-');

  return `${parts[0]}-${parts[1].toUpperCase()}`;
}
