/**
 * @category Views
 * @packageDocumentation
 */
import React, { useContext, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MetaContext, SignInDialogContext } from 'TopContexts';
import headerMobileImg from 'assets/images/home/home-header-mobile.svg';
import pointsMobileImg from 'assets/images/home/home-points-mobile.svg';
import pointsSmallImg from 'assets/images/home/home-points-small.svg';
import pointsImg from 'assets/images/home/home-points.svg';
import { LayoutContext } from 'components/contexts/LayoutContext';
import NavbarDesktop from 'components/header-footer/NavbarDesktop';
import NavbarMobile from 'components/header-footer/NavbarMobile';
import PageWithHeader from 'components/header-footer/PageWithHeader';
import { Cities, HomePoints, letteringCircle } from 'components/home/points';
import SearchFormDefault from 'components/searchForm/SearchFormDefault';
import SearchFormHomeMobile from 'components/searchForm/SearchFormHomeMobile';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import { DEFAULT_DESCRIPTION, DEFAULT_KEYWORDS } from 'contexts/MetaProvider';
import { env } from 'environments/environment';
import StyledCommon from 'style/Common.styled';
import Styled from 'views/home/HomeView.desktop.styled';
import StyledMobile from 'views/home/HomeView.mobile.styled';
import { useAppsFlyerBanner } from 'views/hooks/useAppsFlyerBanner';

const RealDeals = React.lazy(() => import('components/home/deals/RealDeals'));
const AppPromotion = React.lazy(() => import('components/home/AppPromotion'));

interface HomeViewProps {
  resetPassword?: boolean;
}

const LETTER_DEGREE_DESKTOP = 2;
const LETTER_DEGREE_MOBILE = 3;

const LETTER_OFFSET_DESKTOP = -27;
const LETTER_OFFSET_MOBILE = 47;

const FIRST_POINT_INDEX = 0;
const SECOND_POINT_INDEX = 1;
const THIRD_POINT_INDEX = 2;

const useHomeMetadata = () => {
  const [t] = useTranslation();
  const { setDescription, setKeywords } = useContext(MetaContext);

  useEffect(() => {
    setDescription(
      t(
        'metadata.home.description',
        'Real deals with real savings. Finding you the best deals thanks to our technology, allowing us to scan, combine, and build you the best price possible.',
      ),
    );
    setKeywords([
      t('metadata.home.keyword.hotel', 'hotel'),
      t('metadata.home.keyword.hotel-deals', 'hotel deals'),
      t('metadata.home.keyword.hotel-rooms', 'hotel rooms'),
      t('metadata.home.keyword.hotels', 'hotels'),
      t('metadata.home.keyword.lodging', 'lodging'),
      t('metadata.home.keyword.accommodation', 'accommodation'),
      t('metadata.home.keyword.special-deals', 'special deals'),
      t('metadata.home.keyword.weekend-breaks', 'weekend breaks'),
      t('metadata.home.keyword.city-breaks', 'city breaks'),
      t('metadata.home.keyword.deals', 'deals'),
      t('metadata.home.keyword.budget', 'budget'),
      t('metadata.home.keyword.cheap', 'cheap'),
      t('metadata.home.keyword.discount', 'discount'),
      t('metadata.home.keyword.savings', 'savings'),
      t('metadata.home.keyword.hotels-near', 'hotels near'),
      t('metadata.home.keyword.near-me-hotel', 'near me hotel'),
      t('metadata.home.keyword.bookings', 'bookings'),
      t('metadata.home.keyword.reservations', 'reservations'),
      t('metadata.home.keyword.hotels-near', 'hotels near'),
    ]);

    return () => {
      setDescription(DEFAULT_DESCRIPTION);
      setKeywords(DEFAULT_KEYWORDS);
    };
  }, [setDescription, setKeywords, t]);
};

/* eslint-disable react/no-array-index-key */
const HomeView: React.FC<HomeViewProps> = ({ resetPassword }) => {
  const [t] = useTranslation();
  const { isMobileLayout } = useContext(LayoutContext);
  const { setSignInDialogMode } = useContext(SignInDialogContext);
  const points = useMemo(() => HomePoints(t), [t]);
  const cities = useMemo(
    () => letteringCircle(Cities(t), isMobileLayout ? LETTER_DEGREE_MOBILE : LETTER_DEGREE_DESKTOP),
    [isMobileLayout, t],
  );

  useHomeMetadata();
  useAppsFlyerBanner(env.mobileApp.appsFlyer.appId);

  useEffect(() => {
    if (resetPassword) {
      setSignInDialogMode(SignInDialogMode.ResetPassword);
    }
  }, [resetPassword, setSignInDialogMode]);

  if (isMobileLayout) {
    return (
      <PageWithHeader
        searchBarDefaultHidden={true}
        navbarHidden={true}
        shadowDisabled={true}
        headerChildren={(active) => (
          <StyledMobile.Navbar sticky={active}>
            <NavbarMobile backHidden={true} />
          </StyledMobile.Navbar>
        )}
      >
        <StyledMobile.Container>
          <StyledMobile.HeaderImage src={headerMobileImg} alt="header" />

          <StyledMobile.Title>
            <Trans i18nKey="home.main.title">ALWAYS GETTING YOU A BETTER HOTEL DEAL</Trans>
          </StyledMobile.Title>

          <SearchFormHomeMobile />

          <StyledMobile.Point>
            <h2>{points[FIRST_POINT_INDEX].title}</h2>
            <div>{points[FIRST_POINT_INDEX].content}</div>
          </StyledMobile.Point>

          <StyledMobile.PointsImage>
            <img src={pointsMobileImg} alt="points" />
            {cities.map((l, index) => (
              <StyledCommon.Letter
                index={index}
                degree={LETTER_DEGREE_MOBILE}
                offset={LETTER_OFFSET_MOBILE * LETTER_DEGREE_MOBILE}
                key={index}
              >
                {l}
              </StyledCommon.Letter>
            ))}
          </StyledMobile.PointsImage>

          <StyledMobile.Point>
            <h2>{points[SECOND_POINT_INDEX].title}</h2>
            <div>{points[SECOND_POINT_INDEX].content}</div>
          </StyledMobile.Point>

          <StyledMobile.PointsSmallImage src={pointsSmallImg} alt="points-small" />

          <StyledMobile.Point>
            <h2>{points[THIRD_POINT_INDEX].title}</h2>
            <div>{points[THIRD_POINT_INDEX].content}</div>
          </StyledMobile.Point>

          <RealDeals />
        </StyledMobile.Container>
      </PageWithHeader>
    );
  }

  return (
    <PageWithHeader
      searchBarDefaultHidden={true}
      navbarHidden={true}
      shadowDisabled={true}
      headerChildren={(active) => (
        <Styled.Navbar sticky={active}>
          <NavbarDesktop />
        </Styled.Navbar>
      )}
    >
      <Styled.Container>
        <Styled.Title>
          <Trans i18nKey="home.main.title">ALWAYS GETTING YOU A BETTER HOTEL DEAL</Trans>
        </Styled.Title>

        <Styled.SearchForm>
          <SearchFormDefault />
        </Styled.SearchForm>

        <Styled.Container>
          <AppPromotion />
        </Styled.Container>

        <Styled.Container>
          <RealDeals />
        </Styled.Container>

        <Styled.FirstPoint>
          <h2>{points[0].title}</h2>
          <div>{points[0].content}</div>
        </Styled.FirstPoint>
      </Styled.Container>

      <Styled.PointsContainer>
        <div>
          <img src={pointsImg} alt="points" />
          {cities.map((l, index) => (
            <StyledCommon.Letter
              index={index}
              degree={LETTER_DEGREE_DESKTOP}
              offset={LETTER_OFFSET_DESKTOP * LETTER_DEGREE_DESKTOP}
              key={index}
            >
              {l}
            </StyledCommon.Letter>
          ))}
        </div>
        <Styled.LastPoints>
          {points.slice(1).map(({ title, content }) => (
            <Styled.Point key={title}>
              <h2>{title}</h2>
              <div>{content}</div>
            </Styled.Point>
          ))}
        </Styled.LastPoints>
        <img src={pointsSmallImg} alt="points-small" />
      </Styled.PointsContainer>
    </PageWithHeader>
  );
};

export default HomeView;
