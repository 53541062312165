import styled from 'styled-components';
import { warmBlue } from 'style/variables';

const Badge = styled.div`
  border-radius: 3px;
  border: solid 1px ${warmBlue};
  background-color: #ebedfc;

  color: #2439ca;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  padding: 6px 6px 6px 8px;
  white-space: nowrap;
  cursor: pointer;

  span * {
    max-height: 14px;
  }

  svg {
    width: 14px;
    height: 14px;
    margin-left: 9px;
    color: black;
  }

  &:hover {
    background-color: #f6f7ff;
    color: #2439ca;
    svg {
      color: #2642d1;
    }
  }
`;

export default {
  Badge,
};
