import React from 'react';
import Styled from 'components/common/Text/Text.styled';
import { TextProps } from 'components/common/Text/Text.types';

export const Text = ({ children, tag, ...other }: TextProps) => {
  return (
    <Styled.Text {...other} tag={tag} {...(tag && { as: tag })}>
      {children}
    </Styled.Text>
  );
};
