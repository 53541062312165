export enum PlaceType {
  Hotel = 'hotel',
  City = 'city',
  Airport = 'airport',
  Area = 'area',
  Neighborhood = 'neighborhood',
  PointOfInterest = 'point_of_interest',
  TrainStation = 'train_station',
  MetroStation = 'metro_station',
}

export interface Place {
  id: string;
  type: PlaceType;
  name: string;
  label: string;
  latitude?: number;
  longitude?: number;
}
