import React, { useCallback, useRef } from 'react';
import { SignInDialogContext } from 'TopContexts';
import { useUserLocation } from 'atoms/hooks/useUserLocation';
import { HotjarEvent, hotjarEvent } from 'backend/hotjarTags';
import LoadingScreen from 'components/LoadingScreen';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import useSearchParameter from 'utils/useSearchParameter';

interface SignInParams {
  'sign-mode': SignInDialogMode;
}

const SignInDialogProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [signInDialogMode, setDialogMode] = useSearchParameter<SignInParams, SignInDialogMode>('sign-mode');
  const callbackSuccess = useRef<() => void>();
  const { isPending: isLoadingLocation } = useUserLocation();

  const setSignInDialogMode = useCallback(
    (mode: SignInDialogMode | undefined, callback?: () => void) => {
      setDialogMode(mode);
      callbackSuccess.current = callback;
      switch (mode) {
        case SignInDialogMode.SignIn:
          hotjarEvent(HotjarEvent.SignInClicked);
          break;
        case SignInDialogMode.SignUp:
          hotjarEvent(HotjarEvent.SignUpClicked);
          break;
        default:
      }
    },
    [setDialogMode],
  );

  if (isLoadingLocation) {
    return <LoadingScreen />;
  }

  return (
    <SignInDialogContext.Provider
      value={{ signInDialogMode, setSignInDialogMode, callbackSuccess: callbackSuccess.current }}
    >
      {children}
    </SignInDialogContext.Provider>
  );
};

export default SignInDialogProvider;
