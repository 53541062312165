/**
 * @category Search Form
 * @packageDocumentation
 */
import { TFunction } from 'i18next';
import React, { useCallback, useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { OffscreenContext } from 'TopContexts';
import locationIcon from 'assets/images/icons/location.svg';
import { useSearchStateCallbacks } from 'atoms/hooks/useSearchStateCallbacks';
import { Place, PlaceType } from 'backend/api/place/placeModel';
import SearchStorage, { StoredQuery } from 'backend/searchStorage';
import { Button } from 'components/common/Button/Button';
import { ButtonType } from 'components/common/Button/Button.types';
import { Text } from 'components/common/Text/Text';
import { TextAlignment, TextColor, TextWeight, TextWrap } from 'components/common/Text/Text.types';
import OffscreenMode from 'components/offscreen/OffscreenMode';
import Styled from 'components/searchForm/SuggestionList/SuggestionsList.styled';
import { env } from 'environments/environment';
import { formatDateMonthAndDate, parseDate } from 'utils/dateUtils';
import { sumGuests } from 'utils/occupancyUtils';

interface SuggestionsListProps {
  /**
   * list of suggestions to show.
   */
  suggestions?: Place[];

  /**
   * Callback to be called when user selects an item
   */
  onSelection(suggestion: Place): void;

  onSelectCurrentLocation(): void;

  /**
   * Initial selected index (optional)
   */
  selectedIndex?: number;

  /**
   * If this dropdown should be hidden
   */
  hide: boolean;

  additionalHint?: string;

  offscreenMode?: OffscreenMode;
}

export function getPlaceName(place: Place) {
  return place.name;
}

function getPlaceLabel(place: Place, t: TFunction) {
  let label = '';

  switch (place.type) {
    case PlaceType.Airport:
      label = t('search-bar.place.airport', 'Airport in');
      break;
    case PlaceType.Area:
      label = t('search-bar.place.area', 'Area in');
      break;
    case PlaceType.City:
      label = t('search-bar.place.city', 'City in');
      break;
    case PlaceType.Hotel:
      label = t('search-bar.place.hotel', 'Property in');
      break;
    case PlaceType.Neighborhood:
      label = t('search-bar.place.neighborhood', 'Neighborhood in');
      break;
    case PlaceType.PointOfInterest:
      label = t('search-bar.place.point-of-interest', 'Point of interest in');
      break;
    default:
  }

  return label + place.label.split(',').slice(1).join(',');
}

function getRecentSearchDesc(rs: StoredQuery, t: TFunction, lang: string) {
  return `${formatDateMonthAndDate(parseDate(rs.checkin), lang)} - ${formatDateMonthAndDate(
    parseDate(rs.checkout),
    lang,
  )} | ${t(
    'offscreen.occupancy-desc',
    '{ rooms, plural, =1 {# Room} other {# Rooms} } {guests, plural, =1 {# Guest} other {# Guests}}',
    {
      rooms: rs.occupancy.length,
      guests: sumGuests(rs.occupancy),
    },
  )}`;
}

/**
 * Dropdown list of suggested {@link Place}s
 * @param param {@link SuggestionsListProps}
 */
const SuggestionList: React.FC<SuggestionsListProps> = ({
  suggestions,
  onSelection,
  hide,
  additionalHint,
  onSelectCurrentLocation,
  selectedIndex,
  offscreenMode,
}) => {
  const [t, i18n] = useTranslation();
  const { hideOffscreen } = useContext(OffscreenContext);
  const { submit } = useSearchStateCallbacks();
  const showRecentSearches = useMemo(() => !suggestions || suggestions.length === 0, [suggestions]);
  const recentSearches = showRecentSearches ? SearchStorage.instance.getRecentSearches() : [];

  const visibility = useMemo(() => !showRecentSearches || !!additionalHint, [additionalHint, showRecentSearches]);

  const onClickRecentSearch = useCallback(
    (rs: StoredQuery) => {
      submit(rs.place, undefined, rs.checkin, rs.checkout, rs.occupancy);
      hideOffscreen();
    },
    [hideOffscreen, submit],
  );

  const content = suggestions && (
    <>
      {offscreenMode === OffscreenMode.Destination && (
        <Styled.CurrentLocation>
          <Button styleType={ButtonType.Link} onClick={onSelectCurrentLocation}>
            <img src={locationIcon} alt="location" className="uk-margin-right" />
            <span>
              <Trans i18nKey="search-bar.location">Current location</Trans>
            </span>
          </Button>
        </Styled.CurrentLocation>
      )}

      {offscreenMode === OffscreenMode.Destination && recentSearches.length > 0 && (
        <Styled.Searches>
          <div>
            <Trans i18nKey="search-bar.recent-searches">Recent searches</Trans>
          </div>
          {recentSearches.map((rs) => (
            <Styled.Destination key={rs.expires} onClick={() => onClickRecentSearch(rs)}>
              <Text
                tag="h4"
                weight={TextWeight.Medium}
                wrap={TextWrap.Truncate}
                className="uk-padding-remove uk-margin-remove"
              >
                {getPlaceName(rs.place)}
              </Text>
              <Text weight={TextWeight.Normal} color={TextColor.Meta} className="uk-margin-small-top">
                {getRecentSearchDesc(rs, t, i18n.language)}
              </Text>
            </Styled.Destination>
          ))}
        </Styled.Searches>
      )}

      {!showRecentSearches &&
        suggestions.map(
          (dest, index) =>
            index < env.searchBar.maxSuggestions && (
              <Styled.Destination
                key={dest.id}
                selected={selectedIndex === index}
                onClick={() => onSelection(dest)}
                data-testid="suggestion-list-item"
              >
                <Text weight={TextWeight.Medium} className="uk-flex uk-flex-column uk-flex-top uk-width-expand">
                  <Text
                    tag="h4"
                    weight={TextWeight.Medium}
                    wrap={TextWrap.Truncate}
                    alignment={TextAlignment.Left}
                    className="uk-margin-remove"
                  >
                    {getPlaceName(dest)}
                  </Text>
                  <Text weight={TextWeight.Normal} color={TextColor.Meta} alignment={TextAlignment.Left}>
                    {getPlaceLabel(dest, t)}
                  </Text>
                </Text>
              </Styled.Destination>
            ),
        )}

      {(recentSearches.length === 0 || offscreenMode !== OffscreenMode.Destination) && additionalHint && (
        <Styled.Hint>{additionalHint}</Styled.Hint>
      )}
    </>
  );

  if (offscreenMode === OffscreenMode.Destination) {
    return (
      <div className="uk-padding-large uk-height-1-1">
        <Styled.Searches>{content}</Styled.Searches>
      </div>
    );
  }

  return (
    <div hidden={hide || !visibility}>
      <Styled.Dropdown className="uk-width-1-1">{content}</Styled.Dropdown>
    </div>
  );
};

export default SuggestionList;
