import styled, { css } from 'styled-components';
import toggleCheckedIcon from 'assets/images/icons/toggle-checked.svg';
import { cornflower, grayInsurance, veryLightPink2 } from 'style/variables';

const ToggleBox = styled.span`
  position: relative;
  display: block;
  width: 50px;
  height: 20px;
  border-radius: 10px;
  border: solid 1px ${grayInsurance};
  background-color: ${veryLightPink2};
  padding: 2px;
  box-sizing: border-box;
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover {
    &:after {
      border-color: ${cornflower};
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 24px;
    height: 24px;
    border: solid 1px ${grayInsurance};
    border-radius: 50%;
    background: #fff;
    transform: translate(-2px, -50%);
    transition: transform 0.3s;
  }
`;

const Toggle = styled.label<{ green?: boolean }>`
  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    z-index: -1;
  }

  input:checked + ${ToggleBox} {
    background: ${(props) => (props.green ? '#8cdb40' : cornflower)};

    &:after {
      transform: translate(26px, -50%);
    }
  }

  &:focus-within > ${ToggleBox} {
    &:after {
      border-color: ${cornflower};
    }
  }

  ${(props) =>
    props.green &&
    css`
      input:checked + ${ToggleBox}:after {
        background-image: url(${toggleCheckedIcon});
        background-repeat: no-repeat;
        background-position: center;
      }
    `}
`;

export default {
  ToggleBox,
  Toggle,
};
