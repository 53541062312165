/**
 * @category Utils
 * @packageDocumentation
 */
import { RefObject, useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 * @param ref - a reference for div element, outside which we should listen for clicks
 * @param onOutsideClick - callback to call when user clicks outside this component
 * @param withFocus - add listener on focus change
 * @param disabled - state when the hook is disabled
 */
const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  onOutsideClick: () => void,
  withFocus?: boolean,
  disabled?: boolean,
) => {
  useEffect(() => {
    if (disabled) {
      return () => null;
    }

    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      const target = event.target;
      let outside = false;

      if (target instanceof Element) {
        if (ref.current && !ref.current.contains(target)) {
          outside = true;
        }
      } else {
        outside = true;
      }

      if (outside) {
        onOutsideClick();
      }
    }

    function handleFocusOutside(event: KeyboardEvent) {
      if (event.code !== 'Tab') {
        if (event.key === 'Escape') {
          onOutsideClick();
        }

        return;
      }

      const target = event.target;
      let outside = false;

      if (target instanceof Element) {
        if (ref.current && ref.current.contains(target)) {
          outside = true;
        }
      } else {
        outside = true;
      }

      if (outside) {
        onOutsideClick();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    if (withFocus) {
      document.addEventListener('keydown', handleFocusOutside);
    }

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      if (withFocus) {
        document.removeEventListener('keydown', handleFocusOutside);
      }
    };
  }, [ref, onOutsideClick, withFocus, disabled]);
};

export default useOutsideClick;
