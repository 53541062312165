import React, { useRef } from 'react';
import CustomAbstractModal from 'components/CustomAbstractModal';
import { Icon } from 'components/Icon';
import Styled from 'components/ModalWithClose.styled';
import useOutsideClick from 'utils/useOutsideClick';

interface ModalWithCloseProps {
  visible: boolean;
  onClose: () => void;
  smallCloseIcon?: boolean;
  children?: React.ReactNode;
  paddingNone?: boolean;
  className?: string;
}

const ModalWithClose: React.FC<ModalWithCloseProps> = ({
  visible,
  onClose,
  smallCloseIcon,
  children,
  paddingNone,
  className,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, onClose, false, !visible);

  return (
    <CustomAbstractModal paddingNone={paddingNone} visible={visible} positionToCenter={true}>
      <Styled.ModalWithClose ref={ref} smallCloseIcon={smallCloseIcon} className={className}>
        <div id={'close-button'} onClick={onClose}>
          <Icon name={smallCloseIcon ? 'x' : 'close'} />
        </div>
        {children}
      </Styled.ModalWithClose>
    </CustomAbstractModal>
  );
};

export default ModalWithClose;
