import { MutableRefObject, useCallback, useState } from 'react';
import { AbstractValidatedField } from 'components/InputField';

interface UseValidationCallbackProps {
  fields: MutableRefObject<AbstractValidatedField | null | undefined>[];
}

const useValidationCallback = ({ fields }: UseValidationCallbackProps) => {
  const [validatedFields] = useState(fields);

  const clear = useCallback(() => {
    validatedFields.forEach((ref) => {
      if (ref.current) {
        ref.current.clear();
      }
    });
  }, [validatedFields]);

  const invalidate = useCallback(
    (
      refObject: MutableRefObject<AbstractValidatedField | null | undefined> | undefined,
      doFocus: boolean,
      checkForEmpty?: boolean,
    ) => {
      if (refObject?.current) {
        return refObject.current.invalidate(doFocus, checkForEmpty);
      }

      return undefined;
    },
    [],
  );

  const validation = useCallback(
    (initialFocus: boolean) => {
      let toReturn = initialFocus;
      const errorMessages: string[] = [];

      validatedFields.forEach((el) => {
        const message = invalidate(el, toReturn);

        toReturn = !message && toReturn;
        if (message) {
          errorMessages.push(message);
        }
      });

      return errorMessages;
    },
    [validatedFields, invalidate],
  );

  const fieldsValidation = useCallback(
    (initialFocus: boolean) => {
      let toReturn = initialFocus;
      const errorMessages: string[] = [];
      const failedFields: MutableRefObject<AbstractValidatedField | null | undefined>[] = [];

      validatedFields.forEach((el) => {
        const message = invalidate(el, toReturn);

        toReturn = !message && toReturn;
        if (message) {
          errorMessages.push(message);
          failedFields.push(el);
        }
      });

      return { errorMessages, failedFields };
    },
    [validatedFields, invalidate],
  );

  return { clear, invalidate, validation, fieldsValidation };
};

export default useValidationCallback;
