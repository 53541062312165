import React from 'react';
import Styled from 'components/Badge.styled';
import { Icon } from 'components/Icon';

interface BadgeProps {
  onRemove: () => void;
  hidden?: boolean;
  children?: React.ReactNode;
}

const Badge: React.FC<BadgeProps> = ({ onRemove, hidden, children }) => (
  <Styled.Badge className="uk-flex uk-flex-middle" onClick={onRemove} hidden={hidden}>
    <span className="uk-flex uk-flex-middle uk-flex-nowrap">{children}</span>
    <Icon name={'x'} />
  </Styled.Badge>
);

export default Badge;
