import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ServerErrorCode } from 'backend/serverError';
import { AvailableErrors } from 'errors/errorData';

const useSignInErrors = (loginErrorCallback: (message: string) => void): AvailableErrors => {
  const [t] = useTranslation();

  return useMemo(
    () => ({
      known: [
        {
          serverCodes: [ServerErrorCode.InvalidCredentials],
          action: () =>
            loginErrorCallback(
              t('login-popup.invalid-credentials', 'Incorrect username or password. Please try again'),
            ),
        },
        {
          serverCodes: [ServerErrorCode.InactiveBlockedAccount],
          action: () => loginErrorCallback(t('login-popup.blocked-account', 'Your account is inactive or blocked')),
        },
        {
          serverCodes: [ServerErrorCode.Forbidden],
          action: () =>
            loginErrorCallback(t('login-popup.recaptcha-failed', 'Captcha verification failed. Please try again')),
        },
      ],
      default: {
        action: () => loginErrorCallback(t('login-popup.signin-failed', 'Signing in failed. Please try again')),
      },
    }),
    [loginErrorCallback, t],
  );
};

export default useSignInErrors;
