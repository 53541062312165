/**
 * @category Views
 * @packageDocumentation
 */
import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo-color.svg';
import { useSession } from 'atoms/hooks/useSession';
import { RouteDataContext } from 'components/contexts/RouteDataContext';
import AccountMenuSelector from 'components/header-footer/AccountMenuSelector';
import CompanySelector from 'components/header-footer/CompanySelector';
import LanguageSelector from 'components/header-footer/LanguageSelector';
import Styled from 'components/header-footer/NavbarDesktop.styled';
import BookingLink from 'components/links/BookingLink';
import SignInLink from 'components/links/SignInLink';
import { SpecialDiscountLink } from 'components/links/SpecialDiscountLink';
import { routes } from 'routeList';

const CurrencySelector = React.lazy(() => import('components/header-footer/CurrencySelector'));

interface NavbarDesktopProps {
  currencySelectorHidden?: boolean;
}

const NavbarDesktop: React.FC<NavbarDesktopProps> = ({ currencySelectorHidden }) => {
  const { session } = useSession();

  const { pageType } = useContext(RouteDataContext);
  const isSpecialDiscountLinkVisible = useMemo<boolean>(() => {
    return [routes.destination.pageType, routes.hotels.pageType, routes.checkout.pageType].includes(pageType);
  }, [pageType]);

  return (
    <Styled.NavbarContainer>
      <Styled.Navbar>
        <Link to="/">
          <Styled.Logo src={logo} alt="Holisto logo" />
        </Link>
        <div>
          <Styled.NavbarList>
            {isSpecialDiscountLinkVisible && (
              <Styled.NavbarListItem>
                <SpecialDiscountLink />
              </Styled.NavbarListItem>
            )}
            <Styled.NavbarListItem>
              <CompanySelector />
            </Styled.NavbarListItem>
            <Styled.NavbarListItem>
              <Styled.Divider />
            </Styled.NavbarListItem>
            <Styled.NavbarListItem>
              <BookingLink />
            </Styled.NavbarListItem>
            <Styled.NavbarListItem>
              <LanguageSelector />
            </Styled.NavbarListItem>
            {!currencySelectorHidden && (
              <Styled.NavbarListItem>
                <CurrencySelector />
              </Styled.NavbarListItem>
            )}
            {!session.hasUser && (
              <Styled.NavbarListItem>
                <SignInLink />
              </Styled.NavbarListItem>
            )}
            {session.hasUser && (
              <Styled.NavbarListItem>
                <AccountMenuSelector />
              </Styled.NavbarListItem>
            )}
          </Styled.NavbarList>
        </div>
      </Styled.Navbar>
    </Styled.NavbarContainer>
  );
};

export default NavbarDesktop;
