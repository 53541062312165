/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import { Icon } from 'components/Icon.styled';
import { fadeInAnimation, fadeOutAnimation } from 'style/animations.styled';
import { rem } from 'style/functions';
import media from 'style/media';
import { buttonBackground, warmBlue } from 'style/variables';

export const MODAL_ANIMATION_MS = 200;

const AnimatedModal = styled.div`
  animation-duration: ${MODAL_ANIMATION_MS}ms;
`;

const AbstractModalVeil = styled(AnimatedModal)<{
  omitPadding?: boolean;
  positionToCenter?: boolean;
  visible?: boolean;
  innerVisible?: boolean;
  opacity?: string;
}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1011;
  overflow-y: auto;
  background: ${(props) => `rgba(234, 235, 237, ${props.opacity || '.75'})`};

  ${({ omitPadding }) =>
    !omitPadding &&
    css`
      padding: ${rem(20)};
      /* Desktop and bigger */
      ${media.desktop} {
        & {
          padding: 40px;
        }
      }
    `}

  ${({ positionToCenter }) =>
    positionToCenter &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  ${({ visible, innerVisible }) =>
    !visible &&
    !innerVisible &&
    css`
      display: none !important;
    `}

  ${({ visible, innerVisible }) => visible && !innerVisible && fadeInAnimation}

  ${({ visible, innerVisible }) => !visible && innerVisible && fadeOutAnimation}
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  padding: 4px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  background-color: ${buttonBackground};

  & > ${Icon} {
    color: ${warmBlue};
    width: 24px;
    height: 24px;
  }
`;

export { AbstractModalVeil, AnimatedModal, CloseButton };
