import React, { Suspense, useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { OffscreenContext, SignInDialogContext } from 'TopContexts';
import appIcon from 'assets/images/icons/nav/app.svg';
import callIcon from 'assets/images/icons/nav/call.svg';
import emailIcon from 'assets/images/icons/nav/email.svg';
import passwordIcon from 'assets/images/icons/nav/password.svg';
import profileIcon from 'assets/images/icons/nav/profile.svg';
import tripsIcon from 'assets/images/icons/nav/trips.svg';
import { useSession } from 'atoms/hooks/useSession';
import { UserDetails } from 'backend/api/user/userModel';
import { useContactTranslations } from 'components/hooks/useContactTranslations';
import OptionalHrefWrap from 'components/hotelComponents/listElement/OptionalHrefWrap';
import AboutUsLink from 'components/links/AboutUsLink';
import BookingLink from 'components/links/BookingLink';
import PriceGuaranteeLink from 'components/links/PriceGuaranteeLink';
import PrivacyPolicyLink from 'components/links/PrivacyPolicyLink/PrivacyPolicyLink';
import TermsAndConditionsLink from 'components/links/TermsAndConditionsLink/TermsAndConditionsLink';
import Styled from 'components/offscreen/BurgerMenu.styled';
import OffscreenMenu from 'components/offscreen/OffscreenMenu';
import OffscreenMode from 'components/offscreen/OffscreenMode';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import useSignOutCallback from 'components/useSignOutCallback';
import { env } from 'environments/environment';
import StyledCommon from 'style/Common.styled';

const OffscreenManageProfile = React.lazy(() => import('components/offscreen/OffscreenManageProfile'));
const OffscreenChangePassword = React.lazy(() => import('components/offscreen/OffscreenChangePassword'));
const CurrencySelector = React.lazy(() => import('components/header-footer/CurrencySelector'));
const LanguageSelector = React.lazy(() => import('components/header-footer/LanguageSelector'));

interface BurgerMenuProps {
  currencySelectorHidden?: boolean;
}

const BurgerMenu: React.FC<BurgerMenuProps> = ({ currencySelectorHidden }) => {
  const [t] = useTranslation();
  const contactTranslations = useContactTranslations();
  const signOut = useSignOutCallback();
  const { session } = useSession();
  const { hideOffscreen, setOffscreenMode } = useContext(OffscreenContext);
  const { setSignInDialogMode } = useContext(SignInDialogContext);

  const user = useMemo<UserDetails | undefined>(() => session.principal?.hasUser(), [session.principal]);
  const header = useMemo(
    () => (
      <Styled.Title center={!user}>
        {user ? `${user.firstName} ${user.lastName}` : t('mobile-menu.title', 'Profile')}
        {user && (
          <StyledCommon.ButtonLink type="button" onClick={signOut}>
            <Trans i18nKey="mobile-menu.sign-out">Sign out</Trans>
          </StyledCommon.ButtonLink>
        )}
      </Styled.Title>
    ),
    [signOut, t, user],
  );

  return (
    <OffscreenMenu onClose={hideOffscreen} headerAtCenter={true} header={header}>
      <Styled.Container>
        {!user && (
          <Styled.Section>
            <StyledCommon.ButtonLink
              data-testid="join-sign-in-link"
              type="button"
              onClick={() => setSignInDialogMode(SignInDialogMode.SignIn)}
            >
              <Trans i18nKey="mobile-menu.join">Join / Sign in</Trans>
            </StyledCommon.ButtonLink>
          </Styled.Section>
        )}
        <Styled.Section>
          {!!user && (
            <>
              <Styled.NavbarItem
                data-testid="manage-profile-link"
                onClick={() => setOffscreenMode(OffscreenMode.ManageProfile)}
              >
                <img src={profileIcon} alt="profile" />
                <span>
                  <Trans i18nKey="mobile-menu.profile">Manage Profile</Trans>
                </span>
              </Styled.NavbarItem>
              <Styled.NavbarItem onClick={() => setOffscreenMode(OffscreenMode.ChangePassword)}>
                <img src={passwordIcon} alt="password" />
                <span>
                  <Trans i18nKey="account.change-password">Change Password</Trans>
                </span>
              </Styled.NavbarItem>
              <Suspense fallback={null}>
                <OffscreenManageProfile user={user} />
                <OffscreenChangePassword />
              </Suspense>
            </>
          )}
          <Suspense fallback={null}>
            <LanguageSelector />
          </Suspense>
          {!currencySelectorHidden && (
            <Suspense fallback={null}>
              <CurrencySelector />
            </Suspense>
          )}
          <BookingLink
            render={(text, onClick) => (
              <Styled.NavbarItem onClick={onClick}>
                <img src={tripsIcon} alt="trips" />
                {text}
              </Styled.NavbarItem>
            )}
          />
        </Styled.Section>
        <Styled.Links>
          <AboutUsLink target="_self" />
          <Styled.CareersLink asLink />
          <PrivacyPolicyLink target="_self" />
          <TermsAndConditionsLink target="_self" />
          <PriceGuaranteeLink target="_self" />
        </Styled.Links>
        <Styled.Footer>
          <OptionalHrefWrap href={`tel:${contactTranslations.phone}`} target="_blank" asLink>
            <Styled.NavbarItem>
              <img src={callIcon} alt="call" />
              <span>
                <Trans i18nKey="mobile-menu.call">Call us</Trans>
              </span>
            </Styled.NavbarItem>
          </OptionalHrefWrap>
          <OptionalHrefWrap href={`mailto:${contactTranslations.emailUs}`} target="_blank" asLink>
            <Styled.NavbarItem>
              <img src={emailIcon} alt="email" />
              <span>
                <Trans i18nKey="mobile-menu.email">Email us</Trans>
              </span>
            </Styled.NavbarItem>
          </OptionalHrefWrap>
          <OptionalHrefWrap href={env.mobileApp.menuAppLink} target="_blank" asLink>
            <Styled.NavbarItem>
              <img src={appIcon} alt="app" />
              <span>
                <Trans i18nKey="mobile-menu.app">Holisto App</Trans>
              </span>
            </Styled.NavbarItem>
          </OptionalHrefWrap>
        </Styled.Footer>
      </Styled.Container>
    </OffscreenMenu>
  );
};

export default BurgerMenu;
