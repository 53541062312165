/**
 * @category Utils
 * @packageDocumentation
 */
import { env } from 'environments/environment';

/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
function devLog(mes: any, condition?: boolean) {
  if (condition === undefined || condition) {
    console.log(mes);
  }
}

/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
function devTrace(mes: any, condition?: boolean) {
  if (condition === undefined || condition) {
    console.trace(mes);
  }
}

function noLog() {
  // does nothing
}

export function format(...params: (Record<string, unknown> | string | number | boolean | undefined)[]) {
  return params
    .map((obj) => {
      if (typeof obj === 'object') {
        return JSON.stringify(obj);
      }

      return obj;
    })
    .join(',');
}

export const log = env.developers.logging ? devLog : noLog;
export const trace = env.developers.logging ? devTrace : noLog;
