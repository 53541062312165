import React, { useContext } from 'react';
import { Icon } from 'components/Icon';
import Styled from 'components/InputCheckbox.styled';
import { Text } from 'components/common/Text/Text';
import { TextColor, TextSize } from 'components/common/Text/Text.types';
import { LayoutContext } from 'components/contexts/LayoutContext';

interface InputCheckboxProps {
  id?: string;
  checked: boolean;
  setChecked: (value: boolean) => void;
  error?: string;
  message?: string;
  disabled?: boolean;
  shortMode?: boolean;
  children?: React.ReactNode;
}

const InputCheckbox: React.FC<InputCheckboxProps> = ({
  id,
  checked,
  setChecked,
  error,
  disabled,
  message,
  shortMode,
  children,
}) => {
  const { isMobileLayout } = useContext(LayoutContext);

  return (
    <div id={id}>
      <Styled.Label active={checked} isMobile={isMobileLayout}>
        <Styled.InputCheckbox
          hidden={shortMode}
          type="checkbox"
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
          disabled={disabled}
        />
        <span>{children}</span>
        {shortMode && checked && <Icon name={'check'} />}
      </Styled.Label>
      {error && (
        <Text tag="div" size={TextSize.Small} color={TextColor.Danger}>
          {error}
        </Text>
      )}
      {message && (
        <Text tag="div" size={TextSize.Small} color={TextColor.Muted}>
          {message}
        </Text>
      )}
    </div>
  );
};

export default InputCheckbox;
