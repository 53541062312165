import styled from 'styled-components';
import { pineGreen, veryLightPink, white } from 'style/variables';

const Dropdown = styled.ul`
  cursor: default;
  margin-top: 10px;
  border-radius: 9px;
  border: none;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.11);
  animation: slide-down 0.3s;
  list-style-type: none;
  margin-bottom: 0;
  padding: 0;
  z-index: 1020;
  box-sizing: border-box;
  min-width: 200px;
  position: absolute;
  background: ${white};
`;

const SearchForm = styled.section`
  z-index: 3;
  color: ${pineGreen};
`;

const SearchCriteria = styled.div<{ active?: boolean }>`
  cursor: pointer;
  background-color: ${(props) => (props.active ? '#f7f7ff' : 'white')};
  outline: none;

  & * {
    outline: none;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: #f7f7ff;
  }
`;

const DatePicker = styled(SearchCriteria)`
  border: solid 1px ${veryLightPink};
  border-left: none;
  border-right: none;
  white-space: nowrap;
  text-align: center !important;
`;

const RoomPicker = styled(SearchCriteria)`
  width: 298px;
  border: solid 1px ${veryLightPink};
  border-radius: ${(props) => `0 ${props.theme.borderRadius} ${props.theme.borderRadius} 0`};
`;

const Container = styled.div`
  z-index: 10;

  &:after {
    content: '';
    height: 3px;
    width: 100%;
    position: absolute;
    top: -3px;
    left: 0;
    background: inherit;
    z-index: 2;
  }
`;

export default {
  Dropdown,
  SearchForm,
  SearchCriteria,
  Container,
  DatePicker,
  RoomPicker,
};
