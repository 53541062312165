import { FirstDayOfWeek, useMonth } from '@datepicker-react/hooks';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import CustomDatepickerStyle from 'components/datePicker/customDatepicker/CustomDatepicker.styled';
import { DatepickerContext } from 'components/datePicker/customDatepicker/DatepickerContext';
import Day from 'components/datePicker/customDatepicker/Day/Day';
import {
  dayNumericFormat,
  monthLongFormat,
  weekdayNarrowFormat,
  yearNumericFormat,
} from 'components/dates/dateFormats';
import useDateCallback from 'components/dates/useDateCallback';
import useCurrentLanguage from 'components/header-footer/useCurrentLanguage';

interface MonthProps {
  year: number;
  month: number;
  firstDayOfWeek: FirstDayOfWeek;
  setStartMonthRef: (startMonth: HTMLDivElement | undefined) => void;
}

/* eslint-disable react/no-array-index-key */
const Month: React.FC<MonthProps> = ({ year, month, firstDayOfWeek, setStartMonthRef }) => {
  const { isStartMonthSelected } = useContext(DatepickerContext);
  const lang = useCurrentLanguage();
  const weekdayCallback = useDateCallback({ lang: lang.key, options: weekdayNarrowFormat });
  const monthCallback = useDateCallback({ lang: lang.key, options: monthLongFormat });
  const yearCallback = useDateCallback({ lang: lang.key, options: yearNumericFormat });
  const dayCallback = useDateCallback({ lang: lang.key, options: dayNumericFormat });
  const formatMonth = useCallback(
    (date: Date) => {
      const m = monthCallback(date);
      const y = yearCallback(date);

      return `${m} ${y}`;
    },
    [monthCallback, yearCallback],
  );
  const ref = useRef<HTMLDivElement>(null);

  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
    monthLabelFormat: formatMonth,
    weekdayLabelFormat: weekdayCallback,
    dayLabelFormat: dayCallback,
  });

  useEffect(() => {
    if (isStartMonthSelected(year, month) && ref.current) {
      setStartMonthRef(ref.current);

      return () => setStartMonthRef(undefined);
    }

    return undefined;
  }, [isStartMonthSelected, month, setStartMonthRef, year]);

  return (
    <div className="uk-flex uk-flex-column uk-flex-middle" ref={ref}>
      <CustomDatepickerStyle.MonthLabel as="h3">{monthLabel}</CustomDatepickerStyle.MonthLabel>
      <CustomDatepickerStyle.Weekdays>
        {weekdayLabels.map((dayLabel, index) => (
          <div key={`${dayLabel}-${index}`}>{dayLabel}</div>
        ))}
      </CustomDatepickerStyle.Weekdays>
      <CustomDatepickerStyle.DayLabel>
        {days.map((day, index) => {
          if (typeof day === 'object') {
            return <Day date={day.date} key={`datepickerDayObj-${day.date.toString()}`} dayLabel={day.dayLabel} />;
          }

          // we have nothing better than an array index for the empty placeholders
          // eslint-disable-next-line react/no-array-index-key
          return <div key={`datepickerDay-${month}-${index}`} />;
        })}
      </CustomDatepickerStyle.DayLabel>
    </div>
  );
};

export default Month;
