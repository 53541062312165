import styled, { css } from 'styled-components';
import { cornflower, white, gray, grayInsurance, pinkishRed, raven } from 'style/variables';

const InputBlock = styled.div`
  position: relative;
`;

const INLINE_INPUT_PADDING = 12;
const ICON_SIZE = 24;

const Input = styled.input<{ hasInnerButton?: boolean }>`
  font: inherit;
  display: block;
  height: 50px;
  border-radius: ${(props) => props.theme.borderRadius};
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: ${INLINE_INPUT_PADDING}px;
  padding-right: ${(props) =>
    props.hasInnerButton ? `${INLINE_INPUT_PADDING + ICON_SIZE}px` : `${INLINE_INPUT_PADDING}px`};
  border: 1px solid ${grayInsurance};
  color: black;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  outline: 0;
  cursor: text;

  max-width: 100%;
  width: 100%;
  background: ${white};
  -webkit-appearance: none;
  overflow: visible;
  box-sizing: border-box;
  margin: 0;
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  &.danger {
    border: solid 1px ${pinkishRed} !important;
  }
`;

const InputLabel = styled.label`
  font-size: 16px;
  padding-left: 14px;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 30px);
  overflow: hidden;

  color: ${raven};
`;

const InputField = styled.div<{ clearButton?: boolean }>`
  position: relative;

  ${Input}:focus {
    border: 1px solid ${cornflower};
  }

  ${Input}:focus + ${InputLabel},
  ${Input}:-webkit-autofill:hover + ${InputLabel},
  ${Input}:-webkit-autofill:focus + ${InputLabel},
  ${Input}:-webkit-autofill:active + ${InputLabel} {
    font-size: 11px;
    top: 0;
    transform: translateY(0);
    color: ${cornflower};
  }

  input:not(:placeholder-shown) + ${InputLabel} {
    font-size: 11px;
    top: 0;
    transform: translateY(0);
  }

  & > button {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 24px;
      height: 24px;
      color: ${gray};
    }
  }

  ${(props) =>
    props.clearButton &&
    css`
      ${Input} {
        padding-right: 48px;
      }
    `}
`;

export default {
  Input,
  InputBlock,
  InputLabel,
  InputField,
};
