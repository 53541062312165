import { useAtomValue } from 'jotai';
import {
  boundsAtom,
  checkoutCheckinInnerAtom,
  destinationAtom,
  occupancyStorageAtom,
  placeIdAtom,
} from 'atoms/searchStateAtoms';

export const useSearchState = () => {
  const occupancy = useAtomValue(occupancyStorageAtom);
  const bounds = useAtomValue(boundsAtom);
  const placeId = useAtomValue(placeIdAtom);
  const destination = useAtomValue(destinationAtom);
  const { checkin = '', checkout = '' } = useAtomValue(checkoutCheckinInnerAtom) || {};

  return { occupancy, checkin, checkout, bounds, placeId, destination };
};
