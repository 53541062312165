/**
 * @category Authentication
 * @packageDocumentation
 */
import React, { useCallback, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { SignInDialogContext } from 'TopContexts';
import CustomAbstractModal from 'components/CustomAbstractModal';
import RecoverPasswordForm from 'components/signin/RecoverPasswordForm';
import ResetPasswordForm from 'components/signin/ResetPasswordForm';
import SignInByTripForm from 'components/signin/SignInByTripForm';
import Styled from 'components/signin/SignInDialog.styled';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import SignInForm, { SignInFormStep } from 'components/signin/SignInForm';
import SignupForm from 'components/signin/SignupForm';

/**
 * Sign-up/sign-in flow dialog, with ability to switch between its states.

 */
export const SignInDialog = () => {
  const [storedEmail, setStoredEmail] = useState('');
  const [signInFormStep, setSignInFormStep] = useState<SignInFormStep>(SignInFormStep.oauth);
  const { signInDialogMode, setSignInDialogMode } = useContext(SignInDialogContext);

  const backToSignIn = useCallback(
    (e?: React.MouseEvent) => {
      if (e) {
        e.preventDefault();
      }
      setSignInDialogMode(SignInDialogMode.SignIn);
      setSignInFormStep(SignInFormStep.email);
    },
    [setSignInDialogMode],
  );

  if (signInDialogMode === SignInDialogMode.SignUp) {
    return (
      <CustomAbstractModal visible={true} positionToCenter={true} paddingNone>
        <SignupForm
          signInMode={(e) => {
            e.preventDefault();
            setSignInDialogMode(SignInDialogMode.SignIn);
          }}
        />
      </CustomAbstractModal>
    );
  }

  if (signInDialogMode === SignInDialogMode.SignIn) {
    return (
      <CustomAbstractModal visible={true} positionToCenter={true} paddingNone>
        <SignInForm
          onForgotPassword={(email) => {
            setStoredEmail(email);
            setSignInDialogMode(SignInDialogMode.RecoverPassword);
          }}
          step={signInFormStep}
          setStep={setSignInFormStep}
        >
          <div className="uk-margin-large-top">
            <Trans i18nKey="login.no-account">Not a member?</Trans>{' '}
            <Styled.FooterLink
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setSignInDialogMode(SignInDialogMode.SignUp);
              }}
              data-testid="join-link"
            >
              <Trans i18nKey="login.join-now">Join Now!</Trans>
            </Styled.FooterLink>
          </div>
        </SignInForm>
      </CustomAbstractModal>
    );
  }

  if (signInDialogMode === SignInDialogMode.RecoverPassword) {
    return (
      <CustomAbstractModal visible={true} positionToCenter={true} paddingNone>
        <RecoverPasswordForm initialEmail={storedEmail} back={backToSignIn} />
      </CustomAbstractModal>
    );
  }

  if (signInDialogMode === SignInDialogMode.ResetPassword) {
    return (
      <CustomAbstractModal visible={true} positionToCenter={true} paddingNone>
        <ResetPasswordForm back={backToSignIn} />
      </CustomAbstractModal>
    );
  }

  return <SignInByTripForm />;
};
