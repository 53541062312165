/**
 * @category Errors
 * @packageDocumentation
 */

/**
 * Throw it from any place in case of need for translated errors
 * It will be caught and handled. See {@link setupErrorHandlers}
 */
export class I18nError extends Error {
  readonly i18key: string;

  readonly i18param?: Record<string, unknown>;

  constructor(i18key: string, i18params?: Record<string, unknown>, errorName?: string) {
    super(`${errorName || 'Error'}. Key: ${i18key}`);
    this.i18key = i18key;
    this.i18param = i18params;
  }
}

/**
 * This function is added to allow extraction of i18n keys for this case as well
 * Babel plugin, which does the extraction is smart enough to recognize more than one translation function
 * But it is not smart enough to understand the i18key passed to ValidationError constructor also relates to i18n
 * @param i18key - i18n key
 * @param _ - fallback value (see the description above)
 * @param i18params - i18n params object
 */
export default function throwI18nError(i18key: string, _: string, i18params?: Record<string, unknown>) {
  throw new I18nError(i18key, i18params);
}
