import styled from 'styled-components';
import { tripAdvisorColor } from 'style/variables';
/* eslint-disable no-magic-numbers */

const Logo = styled.img`
  margin-right: 10px;
  width: 21px;
  height: 14px;
`;

const TripAdvisorRating = styled.span<{ small?: boolean }>`
  display: inline-block;
  border-radius: 50%;
  box-sizing: border-box;
  color: transparent;

  width: ${(props) => (props.small ? 7 : 9)}px;
  height: ${(props) => (props.small ? 7 : 9)}px;
  margin-right: 5px;

  background: ${tripAdvisorColor};
  box-shadow: 0 0 0 0.15em ${tripAdvisorColor};
  font-size: 11px;
`;

const TripAdvisorRatingHalf = styled(TripAdvisorRating)`
  position: relative;
  background: white;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 50%;
    height: 100%;
    border-radius: 50% 0 0 50%;
    background: ${tripAdvisorColor};
  }
`;

const TripAdvisorRatingEmpty = styled(TripAdvisorRating)`
  background: #fff;
`;

export default {
  Logo,
  TripAdvisorRating,
  TripAdvisorRatingHalf,
  TripAdvisorRatingEmpty,
};
