/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import { ButtonSize } from 'components/common/Button/Button.types';
import { rem } from 'style/functions';
import { cornflower, pineGreen, warmBlue, white } from 'style/variables';

const Button = styled.button<{ size?: ButtonSize; fullWidth?: boolean }>`
  margin: 0;
  border: none;
  overflow: visible;
  font: inherit;
  color: inherit;
  text-transform: none;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${rem(50)};

  :not(:disabled) {
    cursor: pointer;
  }

  :hover {
    text-decoration: none;
  }

  :focus {
    outline: none;
  }

  ${({ fullWidth }) => fullWidth && 'width: 100%;'}

  ${({ size }) => {
    switch (size) {
      case ButtonSize.Small:
        return css`
          height: 36px;
          white-space: nowrap;
          padding: 0 20px;
          font-size: 14px;
          line-height: 18px;
        `;
      case ButtonSize.Large:
        return css`
          height: 48px;
          padding: 0 ${rem(24)};
          font-size: 18px;
          line-height: 20px;
        `;
      case ButtonSize.XLarge:
        return css`
          height: 54px;
          padding: 0 48px;
          font-size: 18px;
          line-height: 20px;
        `;
      case ButtonSize.Normal:
      default:
        return css`
          height: 50px;
          padding: 0 28px;
          font-size: 14px;
          line-height: 18px;
        `;
    }
  }}
`;

const DefaultButton = styled(Button)`
  background-color: ${white};
  color: ${pineGreen};
  border-radius: ${rem(4)};
  border: solid 1px ${cornflower};
  font-weight: 700;

  :hover,
  :focus {
    border-width: 2px;
  }
`;

const PrimaryButton = styled(Button)`
  border-radius: 4px;
  font-weight: 700;
  color: ${white};
  background-color: ${cornflower};

  :hover:not(:disabled) {
    background-color: ${warmBlue};
  }
`;

const TextButton = styled(Button)`
  padding: 0;
  line-height: 1.5;
  background: none;
  color: ${pineGreen};
  font-weight: 400;
  height: auto;

  :hover,
  :focus {
    color: ${pineGreen};
  }
`;

const LinkButton = styled(Button)`
  padding: 0;
  line-height: 1.5;
  background: none;
  color: ${warmBlue};
  text-decoration: underline;
  font-weight: 400;
  height: auto;

  :hover,
  :focus {
    color: ${warmBlue};
    text-decoration: underline;
  }
`;

export default { Button, DefaultButton, PrimaryButton, TextButton, LinkButton };
