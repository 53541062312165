import { atom } from 'jotai';
import { Campaign } from 'backend/api/general/generalModel';
import { CampaignRequest } from 'backend/api/general/generalRequest';
import { getDataProvider } from 'backend/dataProvider';

export const campaignAtom = atom<Campaign | undefined>(undefined);

export const campaignIsReadyAtom = atom<boolean>(false);

export const updateCampaignAtom = atom(null, async (get, set, payload: CampaignRequest) => {
  const dataProvider = await getDataProvider();

  return dataProvider.getCampaign(payload);
});
