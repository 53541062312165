import React, { SetStateAction, useCallback, useContext } from 'react';
import ModalWithClose from 'components/ModalWithClose';
import { LayoutContext } from 'components/contexts/LayoutContext';
import OffscreenMenu from 'components/offscreen/OffscreenMenu';
import OffscreenSlideAnimation from 'components/offscreen/OffscreenSlideAnimation';

type ModalInfoProps = {
  open: boolean;
  onOpenChange(action: SetStateAction<boolean>): void;
  smallCloseIcon?: boolean;
  children?: React.ReactNode;
};

const ModalInfo: React.FC<ModalInfoProps> = ({ open, onOpenChange, smallCloseIcon, children }) => {
  const { isMobileLayout } = useContext(LayoutContext);

  const handleClose = useCallback(() => onOpenChange(false), [onOpenChange]);

  return isMobileLayout ? (
    <OffscreenSlideAnimation show={open}>
      <OffscreenMenu onClose={handleClose}>{children}</OffscreenMenu>
    </OffscreenSlideAnimation>
  ) : (
    <ModalWithClose visible={open} onClose={handleClose} smallCloseIcon={smallCloseIcon}>
      {children}
    </ModalWithClose>
  );
};

export default ModalInfo;
