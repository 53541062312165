/**
 * @category Authentication
 * @packageDocumentation
 */

/**
 * States for the sign-up/sign-in dialog
 */
enum SignInDialogMode {
  SignIn,
  SignUp,
  ViewBooking,
  RecoverPassword,
  ResetPassword,
}

export default SignInDialogMode;
