/* eslint-disable no-magic-numbers */
import { baseFontSize, rem } from 'style/functions';

export { baseFontSize };

export const lineHeightSmall = rem(15);
export const fontSizeSmall = rem(13);
export const lineHeightLarge = rem(15);
export const fontSizeMeta = rem(12);
export const lineHeightMeta = rem(13);
export const fontSizeLarge = rem(15);
export const textDangerColor = '#e7174d';
export const textMutedColor = '#bcbcbc';
export const textPrimaryColor = '#3f54ea';

export const tinyText = rem(10);
export const brandColor = '#ec018c';
export const primaryBackground = '#FFF0F6';
export const pear = '#cbea59';
export const successColor = '#52C41A';
export const successBackground = '#F6FFED';
export const dangerColor = '#F90F35';
export const pinkishRed = '#ef2056';
export const purple = '#722ED1';
export const pineGreen = '#05131e';
export const gunmetal = '#595d60';

export const dangerBackground = '#FFF1F0';
export const buttonBackground = '#FFFFFFB7';

export const brandColorDark = '#B10169';
export const brandColorDarker = '#950057';
export const brandColorDarkFilter =
  'invert(19%) sepia(99%) saturate(7287%) hue-rotate(316deg) brightness(71%) contrast(104%)';
export const borderColor = '#CCCCCC';
export const borderColorLight = '#D9D9D9';
export const borderColorLightest = '#E8E8E8';
export const borderRadius = rem(4);

export const black = '#000000';
export const gray = '#999999';
export const veryLightGray = '#FAFAFA';
export const veryDarkGray = '#343434';
export const veryLightPink = '#e2e2e2';
export const veryLightPink2 = '#efefef';
export const lightGray = '#e5e5e5';
export const white = '#ffffff';
export const formRadioBorder = '#979797';
export const cornflower = '#6a74f6';
export const insuranceBorderColor = '#e3e3e3';
export const insuranceRadioButtonBorder = '#c5cad1';
export const insuranceMutedText = '#73777c';

export const warmBlue = '#3f54ea';
export const blueBlue = '#2642d1';
export const kiwi = '#99ea4b';
export const accessibleRedSmallText = '#e7174d';
export const raven = '#72777b';
export const tripAdvisorColor = '#01aa6c';
export const placeholder = '#eeeef5';
export const grayInsurance = '#bcbcbc';
export const grayColor = '#6c6c6c';
export const insuranceDisabledButton = '#d2d4d6';

export const blueMagenta = '#ECEDF9';
export const avuxiBorderColor = '#dddddd';
export const specialPromoColor = '#f5f6fb';
