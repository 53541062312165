import i18n from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchState } from 'atoms/hooks/useSearchState';
import { Text } from 'components/common/Text/Text';
import { TextAlignment, TextColor, TextSize } from 'components/common/Text/Text.types';
import useDateCallback from 'components/dates/useDateCallback';
import OffscreenMode from 'components/offscreen/OffscreenMode';
import { parseDate } from 'utils/dateUtils';
import { sumGuests } from 'utils/occupancyUtils';

interface OffscreenHeaderProps {
  offscreenMode: OffscreenMode;
}

const dateFormatOptions = { day: 'numeric', month: 'short' } as Intl.DateTimeFormatOptions;

const OffscreenHeader: React.FC<OffscreenHeaderProps> = ({ offscreenMode }) => {
  const [t] = useTranslation();
  const formatDate = useDateCallback({ lang: i18n.language, options: dateFormatOptions });
  const { checkin, checkout, occupancy } = useSearchState();

  switch (offscreenMode) {
    case OffscreenMode.Occupancy:
      return (
        <Text
          tag="div"
          size={TextSize.Small}
          color={TextColor.Emphasis}
          alignment={TextAlignment.Center}
          className="uk-margin-medium-top"
        >
          {t(
            'offscreen.occupancy-desc',
            '{ rooms, plural, =1 {# Room} other {# Rooms} } {guests, plural, =1 {# Guest} other {# Guests}}',
            {
              rooms: occupancy.length,
              guests: sumGuests(occupancy),
            },
          )}
        </Text>
      );
    case OffscreenMode.Datepicker: {
      return (
        <Text
          tag="div"
          size={TextSize.Small}
          color={TextColor.Emphasis}
          alignment={TextAlignment.Center}
          className="uk-margin-medium-top"
        >
          {formatDate(parseDate(checkin))} - {checkout && formatDate(parseDate(checkout))}
        </Text>
      );
    }
    case OffscreenMode.Destination:
    default:
      return <React.Fragment />;
  }
};

export default OffscreenHeader;
