import styled, { css } from 'styled-components';
import { flexGapColumn, flexGapRow } from 'style/polyfills/flexGap';
import { gunmetal } from 'style/variables';

export enum Theme {
  dark = 'dark',
  light = 'light',
}

const Rules = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGapColumn('2px')}
`;

const IconBox = styled.div<{ containerTheme: Theme }>`
  ${(props) => {
    switch (props.containerTheme) {
      case Theme.dark:
        return css`
          color: #a4abff;
        `;
      case Theme.light:
      default:
        return css`
          color: #cecece;
        `;
    }
  }};
`;

const Rule = styled.div<{ containerTheme: Theme; muted?: boolean }>`
  display: flex;
  ${flexGapRow('4px')}

  ${(props) => {
    if (props.muted) {
      switch (props.containerTheme) {
        case Theme.dark:
          return css`
            color: rgba(255, 255, 255, 0.5);
          `;
        case Theme.light:
        default:
          return css`
            color: ${gunmetal};
          `;
      }
    }

    return '';
  }};
`;

export default { Rules, IconBox, Rule };
