import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from 'TopContexts';
import { ServerErrorCode } from 'backend/serverError';
import { ToastType } from 'components/Toast.styled';
import { AvailableErrors } from 'errors/errorData';

const useOauthSignUpErrors = (): AvailableErrors => {
  const [t] = useTranslation();
  const { setToast } = useContext(ToastContext);

  return useMemo(
    () => ({
      known: [
        {
          serverCodes: [ServerErrorCode.Forbidden],
          action: () =>
            setToast(
              t('login-popup.recaptcha-failed', 'Captcha verification failed. Please try again'),
              ToastType.error,
            ),
        },
        {
          serverCodes: [ServerErrorCode.RecordAlreadyExists],
          action: () => setToast(t('oauth.user-already-exists', 'This user is already registered'), ToastType.error),
        },
      ],
      default: {
        action: () =>
          setToast(t('signup-popup.internal-error', 'Error signing up. Please try again later'), ToastType.error),
      },
    }),
    [setToast, t],
  );
};

export default useOauthSignUpErrors;
