import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Button } from 'components/common/Button/Button';
import { cornflower } from 'style/variables';

const Navbar = styled.nav<{ gradientBackground?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 12px 4px 12px;
  background-image: ${(props) =>
    props.gradientBackground ? 'linear-gradient(to bottom, black, rgba(0, 0, 0, 0))' : 'none'};

  a svg {
    height: 25px;
    width: 98px;
    color: ${cornflower};
  }
`;

const Left = styled(Button)`
  flex-wrap: wrap;
  display: flex;
  align-items: center;
`;

const Logo = styled(Link)<{ $isLeftPosition?: boolean }>`
  ${({ $isLeftPosition }) =>
    $isLeftPosition
      ? css`
          flex-wrap: wrap;
        `
      : css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: -webkit-max-content;
          box-sizing: border-box;
          z-index: 990;
          display: flex;
          align-items: center;
        `}
`;

const Right = styled.div`
  flex-wrap: wrap;
  margin-left: auto;
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 20px;
  }
`;

export default {
  Navbar,
  Left,
  Right,
  Logo,
};
