import jsc from 'js-cookie';
import { Campaign } from 'backend/api/general/generalModel';
import { env } from 'environments/environment';

/**
 * Name of the cookie to store campaign name
 */
const CAMPAIGN_NAME = 'CAMPAIGN_NAME';

class CookieBasedCampaignStorage {
  set(campaign: Campaign): void {
    jsc.set(CAMPAIGN_NAME, JSON.stringify(campaign), {
      expires: new Date(new Date().getTime() + env.times.campaignMaxAge),
      sameSite: 'Lax',
    });
  }

  remove(): void {
    jsc.remove(CAMPAIGN_NAME);
  }

  getAsString(): string | undefined {
    return jsc.get(CAMPAIGN_NAME);
  }

  get(): Campaign | undefined {
    const campaignStr = this.getAsString();

    try {
      return campaignStr && JSON.parse(campaignStr);
    } catch (err) {
      return undefined;
    }
  }
}

const cookieBasedCampaignStorage = new CookieBasedCampaignStorage();

export default cookieBasedCampaignStorage;
