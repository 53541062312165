import { OauthProvider } from 'backend/api/user/userModel';

const AUTH_PROVIDER = 'AUTH_PROVIDER';

export enum SplittyAuthProvider {
  Basic = 'basic',
  None = 'none',
}

export type AuthProvider = OauthProvider | SplittyAuthProvider;

class AuthProviderStorage {
  set(provider: AuthProvider): void {
    window.localStorage.setItem(AUTH_PROVIDER, provider);
  }

  remove(): void {
    window.localStorage.removeItem(AUTH_PROVIDER);
  }

  get(): AuthProvider | undefined {
    return window.localStorage.getItem(AUTH_PROVIDER) as AuthProvider;
  }
}

const authProviderStorage = new AuthProviderStorage();

export default authProviderStorage;
