import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SignInDialogContext, ToastContext } from 'TopContexts';
import { useCampaign } from 'atoms/hooks/useCampaign';
import { useSession } from 'atoms/hooks/useSession';
import { OauthProvider } from 'backend/api/user/userModel';
import { SignUpOauthRequest } from 'backend/api/user/userRequest';
import { ToastType } from 'components/Toast.styled';
import { OAuthContext } from 'components/contexts/OAuthContext';
import { processError } from 'errors/errorUtils';
import useOauthSignUpErrors from 'errors/useOauthSignUpErrors';
import guid from 'utils/guid';
import { AmplitudeLogging } from 'utils/logging/AmplitudeLogging';

const useOauthSignUp = (firstName: string, lastName: string, agreeMarketing: boolean) => {
  const [t] = useTranslation();
  const errors = useOauthSignUpErrors();

  const { setToast } = useContext(ToastContext);
  const { signUpOauth } = useSession();
  const { campaign, origin: campaignOrigin } = useCampaign();
  const { oauthPayload, clearOAuthPayload } = useContext(OAuthContext);
  const { setSignInDialogMode } = useContext(SignInDialogContext);

  const [working, setWorking] = useState(false);

  const name = useMemo(
    () =>
      oauthPayload?.provider === OauthProvider.Apple
        ? {
            firstName: oauthPayload.user?.firstName || firstName,
            lastName: oauthPayload.user?.firstName || lastName,
          }
        : {},
    [firstName, lastName, oauthPayload],
  );

  const signUp = useCallback(() => {
    if (!oauthPayload) {
      return;
    }

    setWorking(true);
    const payload: SignUpOauthRequest = {
      provider: oauthPayload.provider,
      token: oauthPayload.token,
      ...name,
      recaptcha: guid(), // TODO
      agreeTerms: true,
      agreePrivacy: true,
      agreeMarketing,
      originCampaign: campaignOrigin ? campaign?.name : undefined,
    };

    signUpOauth(payload)
      .then(() => {
        setSignInDialogMode(undefined);
        clearOAuthPayload();
        AmplitudeLogging.pushAccountCreatedEvent(payload.provider);
        setToast(t('login-popup.signed-up', 'You have successfully signed up!'), ToastType.success);
      })
      .catch((reason) => processError(reason, errors))
      .finally(() => setWorking(false));
  }, [
    oauthPayload,
    name,
    agreeMarketing,
    campaignOrigin,
    campaign,
    signUpOauth,
    setSignInDialogMode,
    clearOAuthPayload,
    setToast,
    t,
    errors,
  ]);

  return { signUpOauth: signUp, working };
};

export default useOauthSignUp;
