import { TFunction } from 'i18next';
import airConditioningIcon from 'assets/images/icons/amenities/air-conditioning.svg';
import airportShuttleIcon from 'assets/images/icons/amenities/airport-shuttle.svg';
import barIcon from 'assets/images/icons/amenities/bar.svg';
import breakfastIcon from 'assets/images/icons/amenities/breakfast.svg';
import familyFriendlyIcon from 'assets/images/icons/amenities/family-friendly.svg';
import freeWifiIcon from 'assets/images/icons/amenities/free-wifi.svg';
import gymIcon from 'assets/images/icons/amenities/gym.svg';
import parkingIcon from 'assets/images/icons/amenities/parking.svg';
import petFriendlyIcon from 'assets/images/icons/amenities/pet-friendly.svg';
import poolIcon from 'assets/images/icons/amenities/pool.svg';
import restaurantIcon from 'assets/images/icons/amenities/restaurant.svg';
import roomServiceIcon from 'assets/images/icons/amenities/room-service.svg';
import spaIcon from 'assets/images/icons/amenities/spa.svg';

import { HotelAmenity, HotelTopAmenity } from 'backend/api/hotel/hotelModel';
import { TopAmenity } from 'backend/dataModel';

export type FilterAmenity = {
  readonly amenity: TopAmenity;
  readonly name: string;
};

export function getFilterAmenities(t: TFunction, amenities: TopAmenity[] = []): FilterAmenity[] {
  const allFilterAmenities = [
    {
      amenity: TopAmenity.FreeWiFi,
      name: t('amenities.free-wifi', 'Free WiFi'),
    },
    {
      amenity: TopAmenity.AirportShuttle,
      name: t('amenities.airport-shuttle', 'Airport Shuttle'),
    },
    {
      amenity: TopAmenity.Parking,
      name: t('amenities.parking', 'Parking'),
    },
    {
      amenity: TopAmenity.PoolsAndWellness,
      name: t('amenities.pool', 'Pool'),
    },
    {
      amenity: TopAmenity.Gym,
      name: t('amenities.gym', 'Gym'),
    },
    {
      amenity: TopAmenity.Spa,
      name: t('amenities.spa', 'Spa'),
    },
    {
      amenity: TopAmenity.RoomService,
      name: t('amenities.room-service', 'Room Service'),
    },
    {
      amenity: TopAmenity.BreakfastAvailable,
      name: t('amenities.breakfast', 'Breakfast available'),
    },
    {
      amenity: TopAmenity.Restaurant,
      name: t('amenities.restaurant', 'Restaurant'),
    },
    {
      amenity: TopAmenity.Bar,
      name: t('amenities.bar', 'Bar'),
    },
    {
      amenity: TopAmenity.AirConditioning,
      name: t('amenities.air-conditioning', 'Air Conditioning'),
    },
    {
      amenity: TopAmenity.FamilyFriendly,
      name: t('amenities.family-friendly', 'Family Friendly'),
    },
    {
      amenity: TopAmenity.PetsFriendly,
      name: t('amenities.pet-friendly', 'Pets Friendly'),
    },
  ];

  return allFilterAmenities.map((v) => ({ ...v, checked: amenities.some((amenity) => amenity === v.amenity) }));
}

export function getTopLevelAmenityIcon(amenity: TopAmenity): string | undefined {
  switch (amenity) {
    case TopAmenity.Restaurant:
      return restaurantIcon;
    case TopAmenity.PetsFriendly:
      return petFriendlyIcon;
    case TopAmenity.AirportShuttle:
      return airportShuttleIcon;
    case TopAmenity.AirConditioning:
      return airConditioningIcon;
    case TopAmenity.FamilyFriendly:
      return familyFriendlyIcon;
    case TopAmenity.Bar:
      return barIcon;
    case TopAmenity.BreakfastAvailable:
      return breakfastIcon;
    case TopAmenity.PoolsAndWellness:
      return poolIcon;
    case TopAmenity.Spa:
      return spaIcon;
    case TopAmenity.Parking:
      return parkingIcon;
    case TopAmenity.Gym:
      return gymIcon;
    case TopAmenity.FreeWiFi:
      return freeWifiIcon;
    case TopAmenity.RoomService:
      return roomServiceIcon;
    default:
      return undefined;
  }
}

export function extractTopAmenities(t: TFunction, amenities?: HotelAmenity[]) {
  if (!amenities) {
    return [];
  }

  const topAmenities = amenities.reduce((previous, current) => {
    if (current.categoryTopOrder && !previous.has(current.categoryId)) {
      previous.set(current.categoryId, {
        categoryId: current.categoryId,
        categoryName: current.categoryName,
        categoryTopOrder: current.categoryTopOrder,
      });
    }

    return previous;
  }, new Map<TopAmenity, HotelTopAmenity>());

  return topAmenities ? [...topAmenities.values()].sort((a, b) => a.categoryTopOrder - b.categoryTopOrder) : [];
}
