import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import React, { useEffect, useState } from 'react';
import StyledOffscreen, {
  OFFSCREEN_FADE_ANIMATION_MS,
  OFFSCREEN_SLIDE_ANIMATION_MS,
} from 'components/offscreen/OffscreenSlideAnimation.styled';

interface OffscreenSlideAnimationProps {
  show: boolean;
  onFinishAnimation?: (visibility: boolean) => void;
  fade?: boolean;
  children?: React.ReactNode;
}

let countOpenedOffscreens = 0;

const OffscreenSlideAnimation: React.FC<OffscreenSlideAnimationProps> = ({
  show,
  onFinishAnimation,
  fade = true,
  children,
}) => {
  const [internalShow, setInternalShow] = useState(show);

  useEffect(() => {
    if (onFinishAnimation) {
      setTimeout(() => onFinishAnimation(show), fade ? OFFSCREEN_FADE_ANIMATION_MS : OFFSCREEN_SLIDE_ANIMATION_MS);
    }
  }, [fade, onFinishAnimation, show]);

  useEffect(() => {
    if (!show) {
      setTimeout(() => setInternalShow(show), fade ? OFFSCREEN_FADE_ANIMATION_MS : OFFSCREEN_SLIDE_ANIMATION_MS);
    } else {
      setInternalShow(show);
    }
  }, [fade, show]);

  useEffect(() => {
    if (show) {
      disableBodyScroll(document.body, {
        reserveScrollBarGap: true,
        allowTouchMove: (el) => el !== document.body,
      });
      ++countOpenedOffscreens;

      return () => {
        --countOpenedOffscreens;
        if (!countOpenedOffscreens) {
          enableBodyScroll(document.body);
        }
      };
    }

    return undefined;
  }, [show]);

  if (!internalShow) {
    return null;
  }

  return (
    <StyledOffscreen.AnimationOffscreen show={show} fade={fade}>
      {children}
    </StyledOffscreen.AnimationOffscreen>
  );
};

export default OffscreenSlideAnimation;
