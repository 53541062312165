import React from 'react';
import tripAdvisorIcon from 'assets/images/ratings/tripadvisor.svg';
import Styled from 'components/TripAdvisorScore.styled';
import { MAX_TRIP_ADVISOR_RATING } from 'components/hotelComponents/listFilters/filterUtils';

interface TripAdvisorScoreProps {
  score: number;
  small?: boolean;
  onlyRating?: boolean;
  icon?: string;
}

/* eslint-disable react/no-array-index-key */
const TripAdvisorScore: React.FC<TripAdvisorScoreProps> = ({ score, small, onlyRating, icon }) => (
  <span className="uk-flex uk-flex-middle">
    {!onlyRating && <Styled.Logo src={icon || tripAdvisorIcon} alt="TripAdvisor logo" />}
    {[...Array(Math.floor(score))].map((_, index) => (
      <Styled.TripAdvisorRating key={index} small={small} />
    ))}
    {score !== Math.floor(score) && <Styled.TripAdvisorRatingHalf small={small} />}
    {[...Array(Math.floor(MAX_TRIP_ADVISOR_RATING - Math.ceil(score)))].map((_, index) => (
      <Styled.TripAdvisorRatingEmpty key={index} small={small} />
    ))}
  </span>
);

export default TripAdvisorScore;
