import React, { Children, useRef } from 'react';
import Styled from 'components/widgets/Dropdown.styled';
import useOutsideClick from 'utils/useOutsideClick';

interface DropdownProps {
  opened: boolean;
  onClose: () => void;
  positionStart?: boolean;
  children?: React.ReactNode;
}

const Dropdown: React.FC<DropdownProps> = ({ opened, onClose, positionStart = true, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, onClose, false, !opened);

  return (
    <Styled.Dropdown hidden={!opened} ref={ref} positionStart={positionStart}>
      <Styled.Content>
        {Children.map(children, (child) => child && <Styled.Item onClick={onClose}>{child}</Styled.Item>)}
      </Styled.Content>
    </Styled.Dropdown>
  );
};

export default Dropdown;
