/**
 * @category Views
 * @packageDocumentation
 */
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SettingsContext } from 'TopContexts';
import { Icon } from 'components/Icon';
import { LayoutContext } from 'components/contexts/LayoutContext';
import StyledNavbar from 'components/header-footer/NavbarDesktop.styled';
import SelectDropdown from 'components/header-footer/SelectDropdown';
import useCurrentLanguage from 'components/header-footer/useCurrentLanguage';
import StyledBurgerMenu from 'components/offscreen/BurgerMenu.styled';
import OffscreenSelect from 'components/offscreen/OffscreenSelect';
import useTranslatedLanguageList from 'utils/translate/useTranslatedLanguageList';

/**
 * Shows current language.
 * Clicking on it opens dropdowns where the language can be changed
 * (the component only calls it and does nothing else)
 */
const LanguageSelector: React.FC = () => {
  const [t] = useTranslation();
  const lang = useCurrentLanguage();
  const { isMobileLayout } = useContext(LayoutContext);
  const [opened, setOpened] = useState(false);
  const { setLanguageCode } = useContext(SettingsContext);
  const languageList = useTranslatedLanguageList();

  const languages = useMemo(() => languageList.map((l) => ({ key: l.key, value: l.name })), [languageList]);

  const changeLanguage = useCallback(
    (langCode: string) => {
      setLanguageCode(langCode, true);
    },
    [setLanguageCode],
  );

  if (isMobileLayout) {
    return (
      <>
        <StyledBurgerMenu.NavbarItem onClick={() => setOpened(true)}>
          <Icon name={'language'} />
          <span data-testid="language-selector">
            <Trans i18nKey="mobile-menu.language" defaults="Language: {lang}" values={{ lang: lang.name }} />
          </span>
        </StyledBurgerMenu.NavbarItem>
        <OffscreenSelect
          opened={opened}
          title={t('common.misc.language', 'Language')}
          values={languages}
          selected={lang.key}
          onSelect={changeLanguage}
          onClose={() => setOpened(false)}
        />
      </>
    );
  }

  return (
    <>
      <input type="checkbox" hidden={true} checked={opened} readOnly={true} />
      <StyledNavbar.NavbarItem type="button" onClick={() => setOpened(true)}>
        <Icon name={'language'} />
        <span>{lang.name}</span>
      </StyledNavbar.NavbarItem>

      <SelectDropdown
        testId="language-selector"
        opened={opened}
        values={languages}
        selected={lang.key}
        onSelect={changeLanguage}
        onClose={() => setOpened(false)}
      />
    </>
  );
};

export default LanguageSelector;
