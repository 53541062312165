import { FirstDayOfWeek, MonthType } from '@datepicker-react/hooks';
import React, { useMemo, useRef } from 'react';
import { Trans } from 'react-i18next';
import { Icon } from 'components/Icon';
import { Button } from 'components/common/Button/Button';
import { ButtonSize, ButtonType } from 'components/common/Button/Button.types';
import StyledDatePicker from 'components/datePicker/customDatepicker/CustomDatepicker.styled';
import Month from 'components/datePicker/customDatepicker/Month';
import { generateKey } from 'components/datePicker/customDatepicker/dateUtils';
import { env } from 'environments/environment';
import { shiftDays, startOfMonth } from 'utils/dateUtils';
import useOutsideClick from 'utils/useOutsideClick';

interface DesktopDatepickerLayoutProps {
  prevNavButtonClick: () => void;
  nextNavButtonClick: () => void;
  monthsToWorkWith: MonthType[];
  firstDayOfWeek: FirstDayOfWeek;
  outsideClickCallback: () => void;
  isDatepickerSubmitDisabled: boolean;
  onDone: () => void;
  setStartMonthRef: (startMonth: HTMLDivElement | undefined) => void;
}

export const DesktopDatepickerLayout = ({
  monthsToWorkWith,
  firstDayOfWeek,
  prevNavButtonClick,
  nextNavButtonClick,
  outsideClickCallback,
  isDatepickerSubmitDisabled,
  onDone,
  setStartMonthRef,
}: DesktopDatepickerLayoutProps) => {
  const wrapperRef = useRef<HTMLUListElement>(null);

  const [isPrevMonthDisabled, isNextMonthDisabled] = useMemo(() => {
    if (!monthsToWorkWith[0] || !monthsToWorkWith[1]) {
      return [false, false];
    }

    const [now, firstMonth, secondMonth] = [new Date(), monthsToWorkWith[0].date, monthsToWorkWith[1].date];

    return [
      firstMonth.getTime() <= startOfMonth(now).getTime(),
      secondMonth.getTime() >= startOfMonth(shiftDays(new Date(), env.searchBar.searchDateRange)).getTime(),
    ];
  }, [monthsToWorkWith]);

  useOutsideClick(wrapperRef, outsideClickCallback, true);

  return (
    <StyledDatePicker.DatePickerPanel ref={wrapperRef}>
      <StyledDatePicker.Months className="uk-flex uk-flex-between">
        <StyledDatePicker.PrevNavButton
          styleType={ButtonType.Text}
          onClick={prevNavButtonClick}
          disabled={isPrevMonthDisabled}
        >
          <Icon name={'back'} />
        </StyledDatePicker.PrevNavButton>
        {monthsToWorkWith.map((month) => (
          <div key={generateKey(month)} className="uk-width-expand uk-child-width-expand">
            <Month
              year={month.year}
              month={month.month}
              firstDayOfWeek={firstDayOfWeek}
              setStartMonthRef={setStartMonthRef}
            />
          </div>
        ))}
        <StyledDatePicker.NextNavButton
          styleType={ButtonType.Text}
          onClick={nextNavButtonClick}
          disabled={isNextMonthDisabled}
        >
          <Icon name={'back'} />
        </StyledDatePicker.NextNavButton>
      </StyledDatePicker.Months>
      <div className="uk-flex uk-flex-right">
        <Button
          styleType={ButtonType.Primary}
          size={ButtonSize.Large}
          onClick={onDone}
          disabled={isDatepickerSubmitDisabled}
        >
          <Trans i18nKey="common.misc.done">Done</Trans>
        </Button>
      </div>
    </StyledDatePicker.DatePickerPanel>
  );
};
