import React, { useCallback, useContext, useEffect, useState } from 'react';
import { OffscreenContext } from 'TopContexts';
import { LayoutContext } from 'components/contexts/LayoutContext';
import OffscreenMode from 'components/offscreen/OffscreenMode';
import useSearchParameter from 'utils/useSearchParameter';
import OffscreenSearchMenu from 'views/offscreens/OffscreenSearchMenu/OffscreenSearchMenu';

interface OffscreenModeParams {
  offscreen: OffscreenMode;
}

const OffscreenProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { isMobileLayout } = useContext(LayoutContext);

  const [offscreenModeParam, setOffscreenModeParam] = useSearchParameter<OffscreenModeParams, OffscreenMode>(
    'offscreen',
    { raw: true },
  );

  const [offscreenMode, setOffscreenModeInternal] = useState<OffscreenMode>(offscreenModeParam || OffscreenMode.Hidden);

  const setOffscreenMode = useCallback(
    (mode: OffscreenMode) => {
      if (isMobileLayout) {
        if (mode !== OffscreenMode.Hidden) {
          setOffscreenModeParam(mode);
        } else {
          setOffscreenModeParam(undefined);
        }
      }
    },
    [isMobileLayout, setOffscreenModeParam],
  );

  const hideOffscreen = useCallback(() => {
    setOffscreenModeParam(undefined);
  }, [setOffscreenModeParam]);

  useEffect(() => {
    if (!isMobileLayout) {
      setOffscreenModeParam(undefined);
    }
  }, [isMobileLayout, setOffscreenModeParam]);

  useEffect(() => {
    setOffscreenModeInternal(offscreenModeParam || OffscreenMode.Hidden);
  }, [offscreenModeParam]);

  return (
    <OffscreenContext.Provider
      value={{
        offscreenMode,
        setOffscreenMode,
        hideOffscreen,
      }}
    >
      {children}

      {isMobileLayout && <OffscreenSearchMenu />}
    </OffscreenContext.Provider>
  );
};

export default OffscreenProvider;
