import styled from 'styled-components';
import StyledOccupancyPicker from 'components/searchForm/OccupancyPicker.styled';
import Styled from 'components/searchForm/SearchForm.styled';
import StyledField from 'components/searchForm/SuggestionField/SuggestionField.styled';

const SearchForm = styled(Styled.SearchForm)`
  position: relative;
  font-size: 16px;
`;

const SuggestionPicker = styled(Styled.SearchCriteria)`
  flex: 1;
  min-width: 1px;
  width: 100%;
  display: inline-block;
  position: relative;
  max-width: 100%;
  margin: 0;
  font-size: 17px;
  line-height: 20px;
  font-weight: 600;

  input {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  ${StyledField.ReadOnlyField} {
    padding-right: 51px;

    button:hover,
    &:hover,
    &:active,
    &:focus {
      background-color: #f7f7ff;
    }
  }

  button:hover + ${StyledField.ReadOnlyField} {
    background-color: #f7f7ff;
  }

  ${StyledField.IconWrapper} {
    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

const SearchButton = styled.div`
  height: 50px;
  margin-left: 12px;
  box-sizing: border-box;

  button {
    min-width: 120px;
  }
`;

const Container = styled(Styled.Container)<{ sticky?: boolean }>`
  padding-top: ${(props) => (props.sticky ? '12' : '10')}px;
  padding-bottom: ${(props) => (props.sticky ? '12' : '21')}px;

  ${Styled.DatePicker}, ${Styled.RoomPicker} {
    padding: 0 18px;
    height: 50px;
  }

  ${Styled.DatePicker} {
    width: 210px;
  }

  ${StyledOccupancyPicker.Dropdown} {
    left: -34px;
  }
`;

const Logo = styled.img`
  width: 138px;
  height: 36px;
  margin-top: 3px;
  margin-right: 20px;
`;

export default {
  SearchForm,
  Container,
  SuggestionPicker,
  SearchButton,
  Logo,
};
