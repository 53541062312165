export function getAppsFlyerIntegrationSnippet(bannerId: string, loadScriptId: string) {
  return (
    '!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){\n' +
    '(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},\n' +
    't.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,\n' +
    'o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),\n' +
    `o.id="${loadScriptId}",\n` + // we need this to remove the old script element if it exists
    `p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "${bannerId}"}});\n` +
    "// Smart Banners are by default set to the max z-index value, so they won't be hidden by the website elements. This can be changed if you want some website components to be on top of the banner.\n"
  );
}
