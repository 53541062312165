import { useAtom, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  checkinFromStorageAtom,
  checkoutCheckinInnerAtom,
  checkoutFromStorageAtom,
  boundsAtom,
  destinationAtom,
  occupancyStorageAtom,
  placeIdAtom,
} from 'atoms/searchStateAtoms';
import { Place } from 'backend/api/place/placeModel';
import { RoomOccupancy } from 'backend/api/trip/tripModel';
import { SEARCH_OCCUPANCY } from 'backend/localStorageKeys';
import useSearchFormCallback from 'components/searchForm/useSearchFormCallback';
import { shiftDays, stringifyDate } from 'utils/dateUtils';
import { AmplitudeLogging } from 'utils/logging/AmplitudeLogging';
import { occupancyAreEqual } from 'utils/occupancyUtils';
import { isDestinationRoute, isHomeRoute, isHotelRoute } from 'utils/uriUtils';
import useLocalStorage from 'utils/useLocalStorage';

export const useSearchStateCallbacks = () => {
  const { pathname } = useLocation();
  const [placeId, setPlaceId] = useAtom(placeIdAtom);
  const [destination, setDestination] = useAtom(destinationAtom);
  const [bounds, setBounds] = useAtom(boundsAtom);
  const setCheckinFromStorage = useSetAtom(checkinFromStorageAtom);
  const setCheckoutFromStorage = useSetAtom(checkoutFromStorageAtom);
  const [innerCheckinCheckout, setInnerCheckinCheckout] = useAtom(checkoutCheckinInnerAtom);
  const { checkin = '', checkout = '' } = innerCheckinCheckout || {};
  const [occupancy, setOccupancy] = useAtom(occupancyStorageAtom);
  const { onSearch } = useSearchFormCallback(placeId);
  const [, setOccupancyLocalStorage] = useLocalStorage<RoomOccupancy[]>(SEARCH_OCCUPANCY, []);

  const submit = useCallback(
    (
      place: Place | undefined,
      _bounds: string | undefined,
      _checkin: string,
      _checkout: string,
      _occupancy: RoomOccupancy[],
    ) => {
      const hotelPage = isHotelRoute(pathname);
      const destinationPage = isDestinationRoute(pathname);

      const fixedCheckin = _checkin || stringifyDate(new Date());
      const fixedCheckout = _checkout || stringifyDate(shiftDays(new Date(), 1));

      if (
        !(hotelPage || destinationPage) ||
        checkin !== fixedCheckin ||
        checkout !== fixedCheckout ||
        placeId !== place?.id ||
        bounds !== _bounds ||
        !occupancyAreEqual(occupancy, _occupancy)
      ) {
        if (isHomeRoute(pathname)) {
          AmplitudeLogging.pushSearchInitiatedEvent({
            checkin: fixedCheckin,
            checkout: fixedCheckout,
            occupancy: _occupancy,
            destination: place,
          });
        }

        setPlaceId(place?.id);
        setDestination(place);
        setBounds(_bounds);
        setInnerCheckinCheckout({ checkin: fixedCheckin, checkout: fixedCheckout });
        setCheckinFromStorage(fixedCheckin);
        setCheckoutFromStorage(fixedCheckout);
        setOccupancy(_occupancy);
        setOccupancyLocalStorage(_occupancy);
        onSearch(place, _bounds, fixedCheckin, fixedCheckout, _occupancy);
      }
    },
    [
      pathname,
      checkin,
      checkout,
      placeId,
      bounds,
      occupancy,
      setPlaceId,
      setDestination,
      setBounds,
      setInnerCheckinCheckout,
      setCheckinFromStorage,
      setCheckoutFromStorage,
      setOccupancy,
      setOccupancyLocalStorage,
      onSearch,
    ],
  );

  const submitDestination = useCallback(
    (_destination: Place) => {
      submit(_destination, undefined, checkin, checkout, occupancy);
    },
    [checkin, checkout, occupancy, submit],
  );

  const submitBounds = useCallback(
    (_bounds: string) => {
      submit(destination, _bounds, checkin, checkout, occupancy);
    },
    [destination, checkin, checkout, occupancy, submit],
  );

  return {
    submit,
    submitDestination,
    submitBounds,
    setPlaceId,
  };
};
