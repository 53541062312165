import React, { useCallback, useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { FilterSortContext } from 'TopContexts';
import { TopAmenity } from 'backend/dataModel';
import Badge from 'components/Badge';
import StarRating from 'components/StarRating';
import TripAdvisorScore from 'components/TripAdvisorScore';
import { MAX_STAR_RATING, StarRow } from 'components/hotelComponents/listFilters/filterUtils';
import { env } from 'environments/environment';
import Styled from 'views/hotels/AppliedFilters.styled';

interface AppliedFiltersProps {
  isMapMode?: boolean;
}

const AppliedFilters: React.FC<AppliedFiltersProps> = ({ isMapMode }) => {
  const {
    name,
    setName,
    stars,
    setStars,
    tripAdvisorStars,
    setTripAdvisorStars,
    freeCancellationOnly,
    setFreeCancellationOnly,
    amenities,
    checkedAmenities,
    setCheckedAmenities,
    budget,
    setBudget,
  } = useContext(FilterSortContext);

  const hasBudgetFilter = useMemo(() => budget.from !== 0 || !!budget.to, [budget.from, budget.to]);

  const resetName = useCallback(() => setName(''), [setName]);
  const resetAmenity = useCallback(
    (amenity: TopAmenity) => {
      setCheckedAmenities((prev) => prev.filter((a) => a !== amenity));
    },
    [setCheckedAmenities],
  );
  const resetStars = useCallback(
    (row: StarRow) => {
      setStars((prev) => prev.map((r) => (r.stars === row.stars ? { ...r, checked: false } : r)));
    },
    [setStars],
  );
  const resetTripAdvisorStars = useCallback(
    (row: StarRow) => {
      setTripAdvisorStars((prev) => prev.map((r) => (r.stars === row.stars ? { ...r, checked: false } : r)));
    },
    [setTripAdvisorStars],
  );
  const resetFreeCancellationOnly = useCallback(() => setFreeCancellationOnly(false), [setFreeCancellationOnly]);
  const resetBudget = useCallback(() => setBudget({ from: 0 }), [setBudget]);

  return (
    <div className="uk-container uk-container-large">
      <Styled.AppliedFilters isMapMode={isMapMode}>
        <Badge onRemove={resetName} hidden={name.length < env.searchBar.minimalTermLength}>
          {name}
        </Badge>

        {amenities.map((a) => (
          <Badge
            key={a.amenity}
            onRemove={() => resetAmenity(a.amenity)}
            hidden={!checkedAmenities.includes(a.amenity)}
          >
            {a.name}
          </Badge>
        ))}

        {stars.map((row) => (
          <Badge key={row.stars} onRemove={() => resetStars(row)} hidden={!row.checked}>
            <StarRating starRating={row.stars} onlyFull />
          </Badge>
        ))}

        {tripAdvisorStars.map((row) => (
          <Badge key={row.stars} onRemove={() => resetTripAdvisorStars(row)} hidden={!row.checked}>
            <TripAdvisorScore score={row.stars} />
            {row.stars !== MAX_STAR_RATING && (
              <span>
                &nbsp;
                <Trans i18nKey="common.misc.up">& up</Trans>
              </span>
            )}
          </Badge>
        ))}

        <Badge onRemove={resetFreeCancellationOnly} hidden={!freeCancellationOnly}>
          <Trans i18nKey="dictionary.refundable.true">Free Cancellation</Trans>
        </Badge>

        <Badge onRemove={resetBudget} hidden={!hasBudgetFilter}>
          <Trans i18nKey="filter.night-budget">Night Budget</Trans>
        </Badge>
      </Styled.AppliedFilters>
    </div>
  );
};

export default AppliedFilters;
