import { END_DATE, MonthType, START_DATE, useDatepicker, UseDatepickerProps } from '@datepicker-react/hooks';
import { OnDatesChangeProps } from '@datepicker-react/hooks/lib/useDatepicker/useDatepicker';
import { useAtom } from 'jotai';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { OffscreenContext, ToastContext } from 'TopContexts';
import { searchFormDatesAtom } from 'atoms/searchFormAtoms';
import { ToastType } from 'components/Toast.styled';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { DatepickerContext } from 'components/datePicker/customDatepicker/DatepickerContext';
import DatepickerWidgetButton from 'components/datePicker/customDatepicker/DatepickerWidgetButton';
import { DesktopDatepickerLayout } from 'components/datePicker/customDatepicker/DesktopDatepickerLayout';
import { MobileDatepickerLayout } from 'components/datePicker/customDatepicker/MobileDatepickerLayout';
import { areDatesEqual } from 'components/datePicker/customDatepicker/dateUtils';
import { DatepickerDate } from 'components/dates/useDatepickerDate';
import OffscreenMode from 'components/offscreen/OffscreenMode';
import { env } from 'environments/environment';
import {
  getPeriodOverMaxNightsMessage,
  isPeriodOverMaxNights,
  parseDate,
  shiftDays,
  stringifyDate,
} from 'utils/dateUtils';

// TODO move to env
const MOBILE_MONTHS = 16;
const DESKTOP_MONTHS = 2;
const MIN_BOOKING_DAYS = 1;

interface CustomDatepickerProps {
  submit?: (checkin: string, checkout: string) => void;
  offscreenMode?: OffscreenMode;
  children?: (checkin: DatepickerDate, checkout: DatepickerDate) => React.ReactNode;
}

const CustomDatepicker: React.FC<CustomDatepickerProps> = ({ submit, offscreenMode, children }) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [{ checkin, checkout }, setDates] = useAtom(searchFormDatesAtom);

  const { isMobileLayout } = useContext(LayoutContext);
  const { hideOffscreen, setOffscreenMode } = useContext(OffscreenContext);

  const parsedStart = useMemo<Date | null>(() => parseDate(checkin) || null, [checkin]);
  const parsedEnd = useMemo<Date | null>(() => parseDate(checkout) || null, [checkout]);

  const [displayedMonths, setDisplayedMonths] = useState<MonthType[]>([]);
  const [updateDisplayedMonths, setUpdateDisplayedMonths] = useState(false);

  const [state, setState] = useState<OnDatesChangeProps>({
    startDate: parsedStart,
    endDate: parsedEnd,
    focusedInput: null,
  });

  useEffect(() => {
    setState((prev) => ({
      startDate: parsedStart,
      endDate: parsedEnd,
      focusedInput: prev.focusedInput,
    }));
  }, [parsedStart, parsedEnd]);

  const isOpened = useMemo(() => !!state.focusedInput, [state.focusedInput]);
  const isMobileExpanded = useMemo(
    () => isMobileLayout && offscreenMode === OffscreenMode.Datepicker,
    [isMobileLayout, offscreenMode],
  );

  const wrapperRef = useRef<HTMLDivElement>(null);
  const widgetRef = useRef<HTMLDivElement>(null);

  const outsideClickCallback = useCallback(() => {
    if (offscreenMode !== OffscreenMode.Datepicker) {
      setState({
        startDate: parsedStart,
        endDate: parsedEnd,
        focusedInput: null,
      });

      if (widgetRef.current) {
        widgetRef.current.blur();
      }

      if (!isMobileLayout && isPeriodOverMaxNights(parsedStart, parsedEnd)) {
        setToast(getPeriodOverMaxNightsMessage(t), ToastType.error);
      }
    }
  }, [isMobileLayout, offscreenMode, parsedEnd, parsedStart, setToast, t]);

  const fixData = useCallback((data: OnDatesChangeProps): OnDatesChangeProps => {
    if (data.focusedInput === END_DATE) {
      return { ...data, endDate: null };
    }

    if (data.startDate && data.endDate && areDatesEqual(data.startDate, data.endDate)) {
      return { ...data, endDate: null, focusedInput: END_DATE };
    }

    if (data.focusedInput === null) {
      return { ...data, focusedInput: START_DATE };
    }

    return data;
  }, []);

  const assignChangedDate = useCallback(
    (data: OnDatesChangeProps) => {
      setState(data);

      if (data.startDate && data.endDate && !isMobileLayout) {
        if (data.focusedInput === null && isPeriodOverMaxNights(data.startDate, data.endDate)) {
          setToast(getPeriodOverMaxNightsMessage(t), ToastType.error);
        }
        setDates({ checkin: stringifyDate(data.startDate), checkout: stringifyDate(data.endDate) });
      }
    },
    [isMobileLayout, setDates, setToast, t],
  );

  const onDatesChange = useCallback(
    (data: OnDatesChangeProps) => {
      const fixedData = fixData(data);

      assignChangedDate(fixedData);
    },
    [assignChangedDate, fixData],
  );

  const objectForDatepicker = useMemo<UseDatepickerProps>(
    () => ({
      ...state,
      onDatesChange,
      minBookingDate: new Date(),
      numberOfMonths: isMobileLayout ? MOBILE_MONTHS : DESKTOP_MONTHS,
      minBookingDays: MIN_BOOKING_DAYS,
      initialVisibleMonth: (!isMobileLayout && parsedStart) || new Date(),
      maxBookingDate:
        state.focusedInput === START_DATE
          ? shiftDays(new Date(), env.searchBar.searchDateRange - 1)
          : shiftDays(new Date(), env.searchBar.searchDateRange),
    }),
    [state, onDatesChange, isMobileLayout, parsedStart],
  );

  const {
    firstDayOfWeek,
    activeMonths,
    goToDate,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonthsByOneMonth,
    goToNextMonthsByOneMonth,
  } = useDatepicker(objectForDatepicker);

  useEffect(() => {
    if (updateDisplayedMonths) {
      setDisplayedMonths(activeMonths);
      setUpdateDisplayedMonths(false);
    }
  }, [activeMonths, updateDisplayedMonths, goToDate, goToPreviousMonthsByOneMonth, isMobileLayout, parsedStart]);

  const confirmOk = useCallback(() => {
    if (!state.startDate || !state.endDate) {
      return;
    }

    if (isPeriodOverMaxNights(state.startDate, state.endDate)) {
      setToast(getPeriodOverMaxNightsMessage(t), ToastType.error);

      return;
    }

    if (submit) {
      submit(stringifyDate(state.startDate), stringifyDate(state.endDate));
    } else {
      setDates({ checkin: stringifyDate(state.startDate), checkout: stringifyDate(state.endDate) });
    }
    hideOffscreen();
  }, [state.startDate, state.endDate, submit, setDates, hideOffscreen, setToast, t]);

  const onDone = useCallback(() => {
    if (isPeriodOverMaxNights(state.startDate, state.endDate)) {
      setToast(getPeriodOverMaxNightsMessage(t), ToastType.error);
    }
    setState((prev) => ({ ...prev, focusedInput: null }));
  }, [setToast, state.endDate, state.startDate, t]);

  const [scrollFlag, setScrollFlag] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [startMonthRef, setStartMonthRef] = useState<HTMLDivElement>();

  const setCurrentDate = useCallback(() => {
    goToDate(isMobileLayout ? new Date() : parsedStart || new Date());
    setUpdateDisplayedMonths(true);
    setState({
      startDate: parsedStart,
      endDate: parsedEnd,
      focusedInput: START_DATE,
    });
  }, [goToDate, isMobileLayout, parsedStart, parsedEnd]);

  useEffect(() => {
    if (isMobileExpanded) {
      setCurrentDate();

      const currentDate = new Date();

      if (
        parsedStart &&
        (parsedStart.getFullYear() !== currentDate.getFullYear() || parsedStart.getMonth() !== currentDate.getMonth())
      ) {
        setScrollFlag(true);
      }
    }
  }, [isMobileExpanded, parsedEnd, parsedStart, setCurrentDate]);

  useEffect(() => {
    if (scrollFlag && startMonthRef && scrollRef.current) {
      const parentRect = scrollRef.current.getBoundingClientRect();
      const childRect = startMonthRef.getBoundingClientRect();

      scrollRef.current.scrollTop = childRect.top + scrollRef.current.scrollTop - parentRect.top;

      setScrollFlag(false);
    }
  }, [scrollFlag, startMonthRef]);

  const widgetButtonClick = useCallback(
    (e: FocusEvent<HTMLDivElement>) => {
      e.preventDefault();

      if (isOpened) {
        return;
      }

      if (isMobileLayout) {
        setOffscreenMode(OffscreenMode.Datepicker);
      } else {
        setCurrentDate();
      }
    },
    [isOpened, isMobileLayout, setCurrentDate, setOffscreenMode],
  );

  const prevNavButtonClick = useCallback(() => {
    setUpdateDisplayedMonths(true);
    goToPreviousMonthsByOneMonth();
  }, [goToPreviousMonthsByOneMonth]);

  const nextNavButtonClick = useCallback(() => {
    setUpdateDisplayedMonths(true);
    goToNextMonthsByOneMonth();
  }, [goToNextMonthsByOneMonth]);

  const isStartDateSelected = useCallback((date: Date) => areDatesEqual(state.startDate, date), [state.startDate]);
  const isEndDateSelected = useCallback((date: Date) => areDatesEqual(state.endDate, date), [state.endDate]);
  const isStartMonthSelected = useCallback(
    (year: number, month: number) =>
      (state.startDate && state.startDate.getFullYear() === year && state.startDate.getMonth() === month) || false,
    [state.startDate],
  );

  return (
    <div ref={wrapperRef} className="uk-height-1-1">
      <DatepickerContext.Provider
        value={{
          focusedDate,
          isDateFocused,
          isDateSelected,
          isDateHovered,
          isDateBlocked,
          isFirstOrLastSelectedDate,
          isStartDateSelected,
          isEndDateSelected,
          isStartMonthSelected,
          onDateSelect,
          onDateFocus,
          onDateHover,
        }}
      >
        {(!isMobileLayout || !isMobileExpanded) && (
          <DatepickerWidgetButton
            active={isMobileExpanded || isOpened}
            startDate={state.startDate}
            endDate={state.endDate}
            onFocus={widgetButtonClick}
            widgetRef={widgetRef}
          >
            {children}
          </DatepickerWidgetButton>
        )}
        {isMobileLayout && isMobileExpanded && (
          <MobileDatepickerLayout
            firstDayOfWeek={firstDayOfWeek}
            isDatepickerSubmitDisabled={!state.endDate || !state.startDate}
            confirmOk={confirmOk}
            scrollRef={scrollRef}
            monthsToWorkWith={displayedMonths}
            setStartMonthRef={setStartMonthRef}
          />
        )}
        {!isMobileLayout && isOpened && (
          <DesktopDatepickerLayout
            onDone={onDone}
            monthsToWorkWith={displayedMonths}
            isDatepickerSubmitDisabled={!state.endDate || !state.startDate}
            firstDayOfWeek={firstDayOfWeek}
            prevNavButtonClick={prevNavButtonClick}
            nextNavButtonClick={nextNavButtonClick}
            outsideClickCallback={outsideClickCallback}
            setStartMonthRef={setStartMonthRef}
          />
        )}
      </DatepickerContext.Provider>
    </div>
  );
};

export default CustomDatepicker;
