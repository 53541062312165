import React, { ReactElement, useCallback, useState, UIEvent } from 'react';
import { Icon } from 'components/Icon';
import { IconXLargeWrapper } from 'components/Icon.styled';
import StyledOffscreen from 'components/offscreen/OffscreenMenu.styled';
import Styled from 'style/Common.styled';
import { black } from 'style/variables';

interface OffscreenProps {
  header?: ReactElement;
  headerAtCenter?: boolean;
  setHeaderContent?: (element: HTMLDivElement | undefined) => void;
  onClose: () => void;
  shadowAlways?: boolean;
  contentRef?: React.RefObject<HTMLDivElement>;
  children?: React.ReactNode;
}

const OffscreenMenu: React.FC<OffscreenProps> = ({
  header,
  contentRef,
  headerAtCenter,
  setHeaderContent,
  onClose,
  shadowAlways,
  children,
}) => {
  const [shadow, setShadow] = useState(false);
  const setHeaderContentElement = useCallback(
    (element: HTMLDivElement | null) => {
      if (setHeaderContent) {
        if (element) {
          setHeaderContent(element);
        } else {
          setHeaderContent(undefined);
        }
      }
    },
    [setHeaderContent],
  );

  const handleScroll = useCallback((e: UIEvent<HTMLDivElement>) => {
    if (!e) {
      return;
    }
    e.stopPropagation();
    setShadow(e.currentTarget.scrollTop !== 0);
  }, []);

  return (
    <>
      <StyledOffscreen.Header shadow={shadowAlways || shadow} headerAtCenter={headerAtCenter}>
        <Styled.ButtonText data-testid="back-link" onClick={onClose}>
          <IconXLargeWrapper>
            <Icon name={'back'} color={black} />
          </IconXLargeWrapper>
        </Styled.ButtonText>
        <StyledOffscreen.HeaderContent ref={setHeaderContentElement}>{header}</StyledOffscreen.HeaderContent>
      </StyledOffscreen.Header>
      <StyledOffscreen.Content ref={contentRef} onScroll={handleScroll}>
        {children}
      </StyledOffscreen.Content>
    </>
  );
};

export default OffscreenMenu;
