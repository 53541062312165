import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LinkProps } from 'components/links/LinkProps';
import { PRICE_GUARANTEE_ROUTE } from 'routeList';

const PriceGuaranteeLink: React.FC<LinkProps> = ({ onClick, target = '_blank' }) => {
  const [t] = useTranslation();
  const linkText = useMemo(() => t('footer.best-price-guarantee', 'Best Price Guarantee'), [t]);

  return (
    <Link
      to={PRICE_GUARANTEE_ROUTE}
      target={target}
      onClick={() => onClick?.(linkText)}
      data-testid="price-quarantee-link"
    >
      {linkText}
    </Link>
  );
};

export default PriceGuaranteeLink;
