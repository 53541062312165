import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_LANGUAGES, SupportedLanguage } from 'i18n';

const useTranslatedLanguageList = () => {
  const { t } = useTranslation();

  return useMemo<SupportedLanguage[]>(
    () =>
      SUPPORTED_LANGUAGES.map((language) => ({
        ...language,
        name: t(`common.language.${language.countryCode.toLowerCase()}`, language.name),
      })).sort((a, b) => a.name.localeCompare(b.name)),
    [t],
  );
};

export default useTranslatedLanguageList;
