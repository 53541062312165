import styled from 'styled-components';
import { veryLightPink } from 'style/variables';

const BottomFixed = styled.div`
  z-index: 4;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 18px 24px;
  background: white;
  border-top: solid 1px ${veryLightPink};

  & > * {
    width: 100%;
  }
`;

const Placeholder = styled.div<{ height: number }>`
  min-height: ${(props) => props.height}px;
`;

export default {
  BottomFixed,
  Placeholder,
};
