/**
 * @category Backend
 * @packageDocumentation
 */
import { User, UserDetails, UserToken } from 'backend/api/user/userModel';
import {
  LoginOauthRequest,
  LoginRequest,
  SignUpOauthRequest,
  SignUpRequest,
  UpdatePasswordRequest,
} from 'backend/api/user/userRequest';
import { SplittyUserClient } from 'backend/splittyUserClient';

/**
 * This class is responsible for signin/signup flow and switching between the groups on high level
 */
export default class Authenticator {
  private readonly userApiClient: SplittyUserClient;

  constructor(userApiClient: SplittyUserClient) {
    this.userApiClient = userApiClient;
  }

  async signUp(payload: SignUpRequest): Promise<UserToken> {
    return this.userApiClient.signUp(payload).then((response) => response.data.user);
  }

  async login(payload: LoginRequest): Promise<UserToken> {
    return this.userApiClient.login(payload).then((response) => response.data.user);
  }

  async logout(): Promise<void> {
    return this.userApiClient.logout();
  }

  async getUserDetails(): Promise<UserDetails> {
    return this.userApiClient.getUserDetails().then((response) => response.data.user);
  }

  async updateUserDetails(payload: User): Promise<UserDetails> {
    return this.userApiClient.updateUserDetails(payload).then((response) => response.data.user);
  }

  async updatePassword(payload: UpdatePasswordRequest, userId?: number): Promise<UserToken> {
    return this.userApiClient.updateUserPassword(payload, userId).then((response) => response.data.user);
  }

  async forgotPassword(email: string): Promise<void> {
    return this.userApiClient.passwordRecovery(email);
  }

  async signUpOauth(payload: SignUpOauthRequest): Promise<UserToken> {
    return this.userApiClient.signUpOauth(payload).then((response) => response.data.user);
  }

  async loginOauth(payload: LoginOauthRequest): Promise<UserToken> {
    return this.userApiClient.loginOauth(payload).then((response) => response.data.user);
  }
}
