import React from 'react';

export interface DatepickerContextProps {
  focusedDate: Date | null;
  isDateFocused: (date: Date) => boolean;
  isDateSelected: (date: Date) => boolean;
  isDateHovered: (date: Date) => boolean;
  isDateBlocked: (date: Date) => boolean;
  isFirstOrLastSelectedDate: (date: Date) => boolean;
  isStartDateSelected: (date: Date) => boolean;
  isEndDateSelected: (date: Date) => boolean;
  isStartMonthSelected: (year: number, month: number) => boolean;
  onDateFocus: (date: Date) => void;
  onDateHover: (date: Date) => void;
  onDateSelect: (date: Date) => void;
}

export const DatepickerContext = React.createContext<DatepickerContextProps>({
  focusedDate: null,
  isDateFocused: () => false,
  isDateSelected: () => false,
  isDateHovered: () => false,
  isDateBlocked: () => false,
  isFirstOrLastSelectedDate: () => false,
  isStartDateSelected: () => false,
  isEndDateSelected: () => false,
  isStartMonthSelected: () => false,
  onDateFocus: () => undefined,
  onDateHover: () => undefined,
  onDateSelect: () => undefined,
});
