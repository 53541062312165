export type Checkable = {
  checked: boolean;
};

export type StarRow = Checkable & {
  stars: number;
};

export interface FilterByStarsProps {
  rows: StarRow[];
  setRows: (rows: StarRow[]) => void;
  setFiltering: (filtering: boolean) => void;
}

export interface FilterByStarRowProps {
  stars: number;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}

export interface BudgetBoundaries {
  from: number;
  to?: number;
}

export const MAX_STAR_RATING = 5;
export const MAX_TRIP_ADVISOR_RATING = 5;

export function fillTripAdvisorFilter() {
  return Array.from({ length: MAX_TRIP_ADVISOR_RATING - 1 }, (_, i) => ({
    stars: MAX_TRIP_ADVISOR_RATING - i,
    checked: false,
  }));
}

export function fillStarFilter() {
  return Array.from({ length: MAX_STAR_RATING }, (_, i) => ({ stars: MAX_STAR_RATING - i, checked: false }));
}

export enum HotelSorting {
  Popularity = 'Popularity',
  BestPrice = 'BestPrice',
  TripAdvisorRating = 'TripAdvisorRating',
  StarRating = 'StarRating',
}
