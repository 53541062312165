import { I18NLang } from 'environments/configuration';

const supportLanguages: I18NLang[] = [
  {
    key: 'en-us',
    name: 'English',
    countryCode: 'US',
    countryName: 'United States',
  },
  {
    key: 'fr-fr',
    name: 'French',
    countryCode: 'FR',
    countryName: 'France',
  },
  {
    key: 'de-de',
    name: 'German',
    countryCode: 'DE',
    countryName: 'Germany',
  },
  {
    key: 'it-it',
    name: 'Italian',
    countryCode: 'IT',
    countryName: 'Italy',
  },
  {
    key: 'pt-BR',
    name: 'Portuguese',
    countryCode: 'BR',
    countryName: 'Brazil',
  },
  {
    key: 'es-es',
    name: 'Spanish',
    countryCode: 'ES',
    countryName: 'Spain',
  },
];

export default supportLanguages;
