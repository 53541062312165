import { HotelDeal, HotelDealExtended, HotelRoom } from 'backend/api/hotel/dealModel';
import { HotelAmenity, HotelImage, HotelLocation, HotelRating } from 'backend/api/hotel/hotelModel';
import { HotelSearchResult } from 'backend/api/hotel/hotelResponse';
import { EnumIterator } from 'backend/enumIterator';
import { ServerErrorCode } from 'backend/serverError';
import { ClientErrorCode } from 'errors/clientError';

/**
 * @category Backend
 * @packageDocumentation
 *
 * Main model. Kind of data transfer object. Used by the UI.
 * Backend related model is converted to this (or extends this)
 */

/* eslint-disable no-magic-numbers */
export enum TopAmenity {
  FreeWiFi = 38,
  AirportShuttle = 35,
  Parking = 16,
  PoolsAndWellness = 21,
  Gym = 34,
  Spa = 37,
  RoomService = 32,
  BreakfastAvailable = 40,
  Restaurant = 30,
  Bar = 33,
  AirConditioning = 39,
  FamilyFriendly = 36,
  PetsFriendly = 31,
}

export interface HotelMapData {
  id: number;
  placeId: string;
  name: string;
  deals?: HotelDeal[];
  rating?: HotelRating;
  starRating: number;
  images?: HotelImage[];
  location: HotelLocation;
  popularity: number;
  amenities?: HotelAmenity[];
}

export interface HotelSearchResultContainer {
  readonly loading: boolean;

  readonly requestId?: string;

  readonly hotels?: HotelSearchResult[];
}

export interface CurrencyConf {
  code: string;
  symbol: string;
  name: string;
  symbolAtRight: boolean;
  maxForFilterByBudget: number;
}

export interface ValidatedPanelRef {
  validate: (focus: boolean) => string[];
}

export interface ExtendedValidatedPanelRef extends ValidatedPanelRef {
  invalidate: (reasons: ServerErrorCode | ClientErrorCode, i18nMessage: string) => void;
}

export interface FaqElement {
  question: string;
  answer: string;
}

export interface FaqSection {
  key: string;
  title: string;
  subtitle?: string;
  pairs: FaqElement[];
}

export enum TripDataCategory {
  Future = 1,
  Past,
  Cancelled,
}

export enum TripDataUserStatus {
  Confirmed = 1,
  PendingConfirmation,
  Cancelled,
  OnSale,
  Sold,
}

export const TripDataCategoryIterator = new EnumIterator(TripDataCategory);

export interface HotelDifferentRoomDealExtended extends HotelDealExtended {
  discount?: number;
}

export interface HotelRoomInfo {
  deals: HotelDealExtended[];
  room: HotelRoom;
}

export interface TranslatedCountry {
  code: string;
  name: string;
  area: string;
}

export interface TranslatedCountryState {
  code: string;
  name: string;
}
