import styled, { css } from 'styled-components';
import StyledSearchForm from 'components/searchForm/SearchForm.styled';
import StyledCommon from 'style/Common.styled';
import media from 'style/media';
import { raven, veryLightPink } from 'style/variables';

const Dropdown = styled(StyledSearchForm.Dropdown)`
  display: flex;
  flex-direction: column;
  padding: 9px 0 25px 0;
`;

const Destination = styled(StyledCommon.ButtonText)<{ selected?: boolean }>`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${media.desktop} {
    padding: 15px 40px;

    &:hover {
      background-color: #ecedf9;
    }

    ${(props) =>
      props.selected &&
      css`
        background-color: #ecedf9;
      `}
  }
`;

const Searches = styled.div`
  font-size: 14px;

  div:first-child {
    color: ${raven};
    padding-bottom: 18px;
  }

  & > ${Destination} + ${Destination} {
    margin-top: 23px;
  }
`;

const CurrentLocation = styled.div`
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-bottom: solid 1px ${veryLightPink};
`;

const Hint = styled.h4`
  font-size: 16px;
  line-height: 20px;
  outline: none;
  font-weight: 500;
  margin: 0;
  text-align: left;

  ${media.desktop} {
    padding: 16px 40px 0;
  }
`;

export default {
  Dropdown,
  Searches,
  CurrentLocation,
  Destination,
  Hint,
};
