import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsContext } from 'TopContexts';
import { useCampaign } from 'atoms/hooks/useCampaign';
import { useUserLocation } from 'atoms/hooks/useUserLocation';
import { useWatchCampaign } from 'atoms/hooks/useWatchCampaign';
import { useWatchSession } from 'atoms/hooks/useWatchSession';
import { CURRENT_COUNTRY, CURRENT_STATE } from 'backend/localStorageKeys';
import { useCurrencySettings } from 'contexts/settingsHooks/useCurrencySettings';
import { useLanguageSettings } from 'contexts/settingsHooks/useLanguageSettings';
import { env } from 'environments/environment';
import { isCurrentLanguage } from 'i18n';
import { LogInfoMemoryStorage } from 'utils/storage/memory/LogInfoMemoryStorage';
import useLocalStorage from 'utils/useLocalStorage';

const SettingsProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  useWatchSession();
  useWatchCampaign();

  const [, i18n] = useTranslation();
  const { campaign } = useCampaign();
  const { data: location } = useUserLocation();

  const { languageCode, setLanguageCode } = useLanguageSettings();
  const { currencyCode, setCurrencyCode } = useCurrencySettings();
  const [countryCode, setCountryToStorage] = useLocalStorage<string>(
    CURRENT_COUNTRY,
    location.countryCode || env.location.fallbackCountryCode,
  );
  const [stateCode, setStateCode] = useLocalStorage<string | undefined>(CURRENT_STATE, location.stateCode);

  const [campaignName, rateDisplayModel, priceRoundingMethod] = useMemo(
    () => [
      campaign?.name || env.campaign.fallbackName,
      campaign?.rateDisplayModel || env.campaign.fallbackRateDisplayModel,
      campaign?.uiSettings.priceRoundingMethod || env.campaign.fallbackPriceRoundingMethod,
    ],
    [campaign],
  );

  const setCountryCode = useCallback(
    (country: string) => {
      setCountryToStorage(country);
    },
    [setCountryToStorage],
  );

  useEffect(() => {
    if (languageCode && !isCurrentLanguage(languageCode)) {
      i18n.changeLanguage(languageCode);
    }
  }, [i18n, languageCode]);

  useEffect(() => {
    LogInfoMemoryStorage.campaignName = campaignName;
  }, [campaignName]);

  useEffect(() => {
    LogInfoMemoryStorage.currencyCode = currencyCode;
  }, [currencyCode]);

  useEffect(() => {
    LogInfoMemoryStorage.languageCode = languageCode;
  }, [languageCode]);

  useEffect(() => {
    LogInfoMemoryStorage.countryCode = countryCode;
  }, [countryCode]);

  useEffect(() => {
    LogInfoMemoryStorage.stateCode = stateCode;
  }, [stateCode]);

  return (
    <SettingsContext.Provider
      value={{
        countryCode,
        stateCode,
        languageCode,
        campaignName,
        currencyCode,
        rateDisplayModel,
        priceRoundingMethod,
        setCountryCode,
        setStateCode,
        setCurrencyCode,
        setLanguageCode,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
