import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSignOutCallback from 'components/useSignOutCallback';
import StyledCommon from 'style/Common.styled';

const SignOutLink: React.FC = () => {
  const [t] = useTranslation();
  const signOut = useSignOutCallback();
  const text = useMemo(() => t('common.menu.logout', 'Sign Out'), [t]);

  const onClickInternal = useCallback(() => {
    signOut();
  }, [signOut]);

  return <StyledCommon.ButtonLink onClick={onClickInternal}>{text}</StyledCommon.ButtonLink>;
};

export default SignOutLink;
