import React, { forwardRef } from 'react';
import icons from 'assets/images/icons/icons.svg';
import { Icon as StyledIcon } from 'components/Icon.styled';

export type IconName =
  | 'plus'
  | 'minus'
  | 'delete'
  | 'rooms'
  | 'guests'
  | 'back'
  | 'chevron-down'
  | 'x'
  | 'check'
  | 'settings'
  | 'search'
  | 'list'
  | 'close'
  | 'menu'
  | 'location'
  | 'star'
  | 'trip-advisor-logo'
  | 'logo'
  | 'language'
  | 'currency'
  | 'ellipsis';

interface IconProps {
  name: IconName;
  color?: string;
  className?: string;
}

// eslint-disable-next-line react/display-name
export const Icon = forwardRef<SVGSVGElement, IconProps>(({ name, color, className }, ref) => (
  <StyledIcon ref={ref} className={className} style={{ color }}>
    <use xlinkHref={`${icons}#${name}`} />
  </StyledIcon>
));
