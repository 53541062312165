import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SignInDialogContext } from 'TopContexts';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import StyledCommon from 'style/Common.styled';

const SignInLink: React.FC = () => {
  const [t] = useTranslation();
  const { setSignInDialogMode } = useContext(SignInDialogContext);
  const text = useMemo(() => t('common.signin', 'Sign in / Join'), [t]);

  const onClickInternal = useCallback(() => {
    setSignInDialogMode(SignInDialogMode.SignIn);
  }, [setSignInDialogMode]);

  return (
    <StyledCommon.ButtonLink data-testid="signin-button" onClick={onClickInternal}>
      {text}
    </StyledCommon.ButtonLink>
  );
};

export default SignInLink;
