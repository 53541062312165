/**
 * @category Utils
 * @packageDocumentation
 */
// eslint-disable-next-line max-classes-per-file
import { customAlphabet } from 'nanoid';
import { getHttpStatus, RawServerError, ServerError, ServerErrorCode } from 'backend/serverError';
import { ClientError, ClientErrorCode } from 'errors/clientError';
import { AvailableErrors, ErrorData } from 'errors/errorData';
import { log } from 'utils/logger';
import { AmplitudeLogging } from 'utils/logging/AmplitudeLogging';
import { DataLayerLogging } from 'utils/logging/DataLayerLogging';

function findServerError(searchCode: ServerErrorCode, knownErrors: ErrorData[]): ErrorData | undefined {
  return knownErrors.find((error) => error.serverCodes?.some((code) => code === searchCode));
}

function findClientError(searchCode: ClientErrorCode, knownErrors: ErrorData[]): ErrorData | undefined {
  return knownErrors.find((error) => error.clientCodes?.some((code) => code === searchCode));
}

function findErrorOrDefault(
  error: ServerError | ClientError | unknown,
  { known: knownErrors, default: defaultError }: AvailableErrors,
): ErrorData {
  if (error instanceof ServerError) {
    return findServerError(error.getCode(), knownErrors) || defaultError;
  }
  if (error instanceof ClientError) {
    return findClientError(error.getCode(), knownErrors) || defaultError;
  }

  return defaultError;
}

const errorIdGenerator = customAlphabet('ABCDEF1234567890', 10);

export interface NoticedError extends Error {
  isNoticed?: boolean;
}

export class UnknownError extends Error {
  error: unknown;

  constructor(message: string, error: unknown) {
    super(message);
    this.error = error;
  }
}

export class NetworkError extends Error {
  endpoint: string;

  retry: boolean;

  constructor(message: string, endpoint: string, retry: boolean) {
    super(message);
    this.endpoint = endpoint;
    this.retry = retry;
  }
}

export const logError = (error: Error | string) => {
  const errorId = errorIdGenerator();

  if (window.newrelic) {
    if (typeof error === 'object') {
      const noticedError = error as NoticedError;

      noticedError.isNoticed = true;
    }
    const customAttributes: Record<string, string | number> = {
      errorId,
    };

    if (error instanceof UnknownError) {
      customAttributes.unknownError = JSON.stringify(error.error);
    }
    if (error instanceof NetworkError) {
      customAttributes.endpoint = error.endpoint;
      customAttributes.retry = JSON.stringify(error.retry);
    }
    if (error instanceof RawServerError) {
      customAttributes.status = error.status;
      if (error.rawData !== undefined) {
        customAttributes.rawData = error.rawData;
      }
      customAttributes.endpoint = error.endpoint;
    }
    if (error instanceof ServerError) {
      customAttributes.status = error.getStatus();
      customAttributes.code = error.getCode();
      customAttributes.endpoint = error.endpoint;
    }
    if (error instanceof ClientError) {
      const messages = error.getMessages()?.join(',');

      if (messages) {
        customAttributes.messages = messages;
      }
    }

    window.newrelic.noticeError(error, customAttributes);
  }

  log(JSON.stringify(error));
  log(error);

  return errorId;
};

/**
 * Handles the error using the callbacks provided
 */
export const processError = (
  error: ServerError | ClientError | RawServerError | unknown,
  availableErrors: AvailableErrors,
) => {
  const currentError = findErrorOrDefault(error, availableErrors);

  const handledError =
    error instanceof Error || typeof error === 'string' ? error : new UnknownError('Unknown error', error);

  if (handledError instanceof Error) {
    handledError.message = handledError.message || currentError.message || 'Unknown error';
  }
  const errorId = logError(handledError);

  let interactionMessage: string | void;

  if (currentError.action) {
    const errorResponse = error instanceof ServerError ? error.getResponse() : undefined;

    interactionMessage = currentError.action(errorId, getHttpStatus(error), errorResponse);
  }

  if (error instanceof ServerError) {
    AmplitudeLogging.pushErrorEncounteredEvent(error.endpoint, error.message || 'unset', error.getCode());
  }

  DataLayerLogging.pushErrorEvent(error, interactionMessage);
};
