/**
 * @category Search Form
 * @packageDocumentation
 */
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import CustomScroll from 'react-custom-scroll';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { OffscreenContext } from 'TopContexts';
import { RoomOccupancy } from 'backend/api/trip/tripModel';
import BottomFixedButton from 'components/BottomFixedButton';
import { Icon } from 'components/Icon';
import { Button } from 'components/common/Button/Button';
import { ButtonSize, ButtonType } from 'components/common/Button/Button.types';
import { Text } from 'components/common/Text/Text';
import { TextTransform, TextWeight } from 'components/common/Text/Text.types';
import { LayoutContext } from 'components/contexts/LayoutContext';
import OffscreenMode from 'components/offscreen/OffscreenMode';
import Styled from 'components/searchForm/OccupancyPicker.styled';
import PeoplePicker from 'components/searchForm/PeoplePicker/PeoplePicker';
import StyledSearchForm from 'components/searchForm/SearchForm.styled';
import { env } from 'environments/environment';
import { createOccupancyOrDefault } from 'utils/occupancyUtils';
import { isHomeRoute } from 'utils/uriUtils';
import useOutsideClick from 'utils/useOutsideClick';

interface OccupancyPickerProps {
  occupancy: RoomOccupancy[];
  setOccupancy: (occupancy: RoomOccupancy[]) => void;
  submit?: () => void;
  children?: (occupancies: RoomOccupancy[]) => React.ReactNode;
  stickyHeader?: boolean;
}

/**
 * Allows to select a count of rooms and people.
 */
const OccupancyPicker: React.FC<OccupancyPickerProps> = ({
  occupancy,
  setOccupancy,
  submit,
  stickyHeader,
  children,
}) => {
  const location = useLocation();
  const { isMobileLayout } = useContext(LayoutContext);
  const { offscreenMode, setOffscreenMode } = useContext(OffscreenContext);
  const [expanded, setExpanded] = useState<boolean>(false);

  const scrollWidth = window.innerWidth - document.documentElement.clientWidth;

  const occupancyChanger = useCallback(
    (increase: boolean, index?: number) => {
      if (increase) {
        if (occupancy.length < env.searchBar.maxOccupancy) {
          setOccupancy([...occupancy, createOccupancyOrDefault(env.searchBar.defaultAdultsCount)]);
        }
      } else if (occupancy.length > 1) {
        setOccupancy(occupancy.filter((_, idx) => idx !== index));
      }
    },
    [occupancy, setOccupancy],
  );

  const adultChanger = useCallback(
    (index: number, increase: boolean) => {
      if (increase) {
        if (occupancy[index].adults < env.searchBar.maxAdults) {
          setOccupancy(occupancy.map((o, idx) => (idx === index ? { ...o, adults: o.adults + 1 } : o)));
        }
      } else if (occupancy[index].adults > 1) {
        setOccupancy(occupancy.map((o, idx) => (idx === index ? { ...o, adults: o.adults - 1 } : o)));
      }
    },
    [occupancy, setOccupancy],
  );

  const childrenChanger = useCallback(
    (index: number, increase: boolean) => {
      const _children = occupancy[index].children || [];

      if (increase) {
        if (_children.length < env.searchBar.maxChildren) {
          setOccupancy(
            occupancy.map((o, idx) =>
              idx === index
                ? {
                    ...o,
                    children: [..._children, env.searchBar.defaultChildAge],
                  }
                : o,
            ),
          );
        }
      } else if (_children.length > 0) {
        setOccupancy(
          occupancy.map((o, idx) =>
            idx === index
              ? {
                  ...o,
                  children: _children.slice(0, -1),
                }
              : o,
          ),
        );
      }
    },
    [occupancy, setOccupancy],
  );

  const setAge = useCallback(
    (index: number, childIndex: number, newAge: string) => {
      const newChildren = (occupancy[index].children || []).map((age, i) => (i === childIndex ? +newAge : age));

      setOccupancy(occupancy.map((o, i) => (i === index ? { ...o, children: newChildren } : o)));
    },
    [occupancy, setOccupancy],
  );

  const onOpen = useCallback(() => {
    if (isMobileLayout) {
      setOffscreenMode(OffscreenMode.Occupancy);
    } else {
      setExpanded(true);
    }
  }, [isMobileLayout, setOffscreenMode]);

  const overflow = useMemo(() => isMobileLayout || isHomeRoute(location.pathname), [isMobileLayout, location.pathname]);

  const main = (
    <Styled.Main isMobile={isMobileLayout} $overflow={overflow} stickyHeader={stickyHeader}>
      {occupancy.map((roomOccupancy, roomIdx) => (
        /* eslint-disable react/no-array-index-key */
        <Styled.Room key={`occupancy_${roomIdx}`}>
          <div className="uk-flex uk-flex-bottom uk-flex-between">
            <Text tag="h3" weight={TextWeight.Bold} transform={TextTransform.Uppercase} className="uk-margin-remove">
              <Trans i18nKey="search-bar.room-number" defaults="Room {roomIdx}" values={{ roomIdx: roomIdx + 1 }} />
            </Text>
            {roomIdx !== 0 && (
              <Button styleType={ButtonType.Link} onClick={() => occupancyChanger(false, roomIdx)}>
                <Trans i18nKey="search-bar.remove-room">Remove</Trans>
              </Button>
            )}
          </div>

          <PeoplePicker
            adults={roomOccupancy.adults}
            adultChanger={(increase) => adultChanger(roomIdx, increase)}
            childrenAges={roomOccupancy.children || []}
            childrenChanger={(increase) => childrenChanger(roomIdx, increase)}
            setAge={(index, age) => setAge(roomIdx, index, age)}
          />
        </Styled.Room>
      ))}
    </Styled.Main>
  );

  const content = isMobileLayout ? (
    <>
      <div className="uk-padding-large">
        {main}

        <Styled.Footer className="uk-flex uk-flex-middle uk-flex-between" isMobile>
          <Button
            styleType={ButtonType.Link}
            onClick={() => occupancyChanger(true)}
            disabled={occupancy.length === env.searchBar.maxOccupancy}
          >
            <Icon name={'plus'} />
            <span className="uk-margin-left">
              <Trans i18nKey="search-bar.add-room">Add room</Trans>
            </span>
          </Button>
        </Styled.Footer>
      </div>

      <BottomFixedButton onClick={submit}>
        <Trans i18nKey="common.continue">Continue</Trans>
      </BottomFixedButton>
    </>
  ) : (
    <>
      {overflow && main}
      {!overflow && <CustomScroll addScrolledClass={true}>{main}</CustomScroll>}
      <Styled.Footer className="uk-flex uk-flex-middle uk-flex-between">
        <Button
          styleType={ButtonType.Link}
          onClick={() => occupancyChanger(true)}
          disabled={occupancy.length === env.searchBar.maxOccupancy}
          data-testid="occupancy-rooms-add"
        >
          <Icon name={'plus'} />
          <span className="uk-margin-left">
            <Trans i18nKey="search-bar.add-room">Add room</Trans>
          </span>
        </Button>
        <Button styleType={ButtonType.Primary} size={ButtonSize.Large} onClick={() => setExpanded(false)}>
          <Trans i18nKey="common.misc.done">Done</Trans>
        </Button>
      </Styled.Footer>
    </>
  );

  const panelRef = useRef<HTMLDivElement>(null);

  useOutsideClick(panelRef, () => setExpanded(false), true);

  if (offscreenMode === OffscreenMode.Occupancy) {
    return content;
  }

  return (
    <div className="uk-position-relative uk-height-1-1" data-testid="occupancy-picker">
      <div className="uk-height-1-1" ref={panelRef}>
        <StyledSearchForm.RoomPicker
          active={expanded}
          tabIndex={3}
          onClick={onOpen}
          onFocus={onOpen}
          className="uk-flex uk-flex-middle uk-flex-left uk-width-expand uk-height-1-1"
        >
          {children?.(occupancy)}
        </StyledSearchForm.RoomPicker>
        {expanded && <Styled.Dropdown scrollWidth={scrollWidth}>{content}</Styled.Dropdown>}
      </div>
    </div>
  );
};

export default OccupancyPicker;
