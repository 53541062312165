import styled from 'styled-components';
import media from 'style/media';
import { flexGapColumn } from 'style/polyfills/flexGap';
import { gunmetal, pineGreen } from 'style/variables';

const Header = styled.header`
  font-size: 26px;
  font-weight: bold;
  line-height: 1.12;
  letter-spacing: ${(props) => props.theme.letterSpacing};
  color: ${pineGreen};
  text-transform: uppercase;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: ${gunmetal};
  margin: 0;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;

  ${media.mobile} {
    ${flexGapColumn('12px')}
  }

  ${media.desktop} {
    ${flexGapColumn('16px')}
  }
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;

  ${media.mobile} {
    padding: 32px 24px;

    ${Text} {
      margin-top: 24px;
      margin-bottom: 32px;
    }

    ${Inputs} {
      margin-bottom: 12px;
    }

    ${Footer} {
      margin-top: 40px;
    }
  }

  ${media.desktop} {
    max-width: 440px;
    box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.11);
    border-radius: 9px;
    padding: 50px 54px 46px;
    box-sizing: border-box;

    ${Text} {
      margin-top: 18px;
      margin-bottom: 26px;
    }

    ${Inputs} {
      margin-bottom: 26px;
    }

    ${Footer} {
      margin-top: 36px;
    }
  }
`;

export default {
  Header,
  Text,
  Inputs,
  Footer,
  Container,
};
