import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import UIkit from 'uikit';
import { AvailableErrors } from 'errors/errorData';
import { showError } from 'errors/errorHandlers';

const usePlaceErrors = (backDestinationsUrl: string | undefined, cb: () => void): AvailableErrors => {
  const [t] = useTranslation();
  const history = useHistory();

  return useMemo(
    () => ({
      known: [],
      default: {
        action: (errorId, status) => {
          if (backDestinationsUrl) {
            UIkit.modal.labels.ok = t('common.misc.try-again', 'Try again');
            UIkit.modal.labels.cancel = t('errors.place.back-hotel-results', 'Back to hotel results');

            UIkit.modal
              .confirm(
                t(
                  'errors.place.hotel-default',
                  "Sorry, we couldn't find the hotel requested.<br> Please try again or go back to hotel results.",
                ),
              )
              .then(
                () => window.location.reload(),
                () => {
                  cb();
                  history.push(backDestinationsUrl);
                },
              );
          } else {
            showError(errorId, status, t('errors.place.default', 'An error has occurred. Please try again later.'));
          }
        },
      },
    }),
    [backDestinationsUrl, cb, history, t],
  );
};

export default usePlaceErrors;
