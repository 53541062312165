/* eslint-disable no-magic-numbers */
import { CurrencyCode } from 'backend/api/model';

export enum AuthenticationState {
  Unauthenticated = 1,
  BasicAuth = 2,
  SocialAuth = 4,
  SmsAuth = 8,
}

export enum RateDisplayModel {
  Net = 1,
  Gross = 2,
  AllInclusive = 3,
  RoomAndFees = 4,
}

export enum PriceRoundingMethod {
  Floor = 1,
  Round = 2,
  Ceiling = 3,
}

export enum Provider {
  Google = 31, // partnerId for Holisto_Google_* campaign
}

export interface Campaign {
  id: number;
  name: string;
  partnerId: number;
  currencyCode: CurrencyCode;
  languageCode: string;
  rateDisplayModel: RateDisplayModel;
  uiSettings: {
    priceRoundingMethod?: PriceRoundingMethod;
  };
}

export interface Translation {
  id: number;
  pack: string;
  languageCode: string;
  dictionary: Record<string, unknown>;
}

export interface AbTestVariation {
  id: number;
  percentage: number;
}

export interface AbTest {
  testId: number;
  variations: AbTestVariation[];
}
