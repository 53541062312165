import { AuthenticationState } from 'backend/api/general/generalModel';
import { OauthProvider } from 'backend/api/user/userModel';
import SplittyPrincipal from 'backend/authModelPrincipal';

export default class SplittySession {
  readonly principal: SplittyPrincipal | undefined;

  readonly authenticationState: AuthenticationState;

  readonly initialized: boolean;

  constructor(principal?: SplittyPrincipal, initialized = true) {
    this.principal = principal;
    if (principal?.hasUser()) {
      switch (principal?.getProvider()) {
        case OauthProvider.Facebook:
        case OauthProvider.Google:
          this.authenticationState = AuthenticationState.SocialAuth;
          break;
        default:
          this.authenticationState = AuthenticationState.BasicAuth;
      }
    } else {
      this.authenticationState = AuthenticationState.Unauthenticated;
    }
    this.initialized = initialized;
  }

  get hasUser(): boolean {
    return !!this.principal?.hasUser();
  }
}
