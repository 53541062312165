import { FirstDayOfWeek, MonthType } from '@datepicker-react/hooks';
import React, { RefObject } from 'react';
import { Trans } from 'react-i18next';
import BottomFixedButton from 'components/BottomFixedButton';
import StyledDatePicker from 'components/datePicker/customDatepicker/CustomDatepicker.styled';
import Month from 'components/datePicker/customDatepicker/Month';
import { generateKey } from 'components/datePicker/customDatepicker/dateUtils';

interface MobileDatepickerLayoutProps {
  monthsToWorkWith: MonthType[];
  firstDayOfWeek: FirstDayOfWeek;
  isDatepickerSubmitDisabled: boolean;
  confirmOk: () => void;
  scrollRef: RefObject<HTMLDivElement>;
  setStartMonthRef: (startMonth: HTMLDivElement | undefined) => void;
}

export const MobileDatepickerLayout = ({
  monthsToWorkWith,
  firstDayOfWeek,
  isDatepickerSubmitDisabled,
  confirmOk,
  scrollRef,
  setStartMonthRef,
}: MobileDatepickerLayoutProps) => {
  return (
    <div className="uk-overflow-auto uk-flex uk-flex-column uk-height-1-1">
      <div className="uk-overflow-auto uk-child-width-expand uk-flex uk-flex-column uk-padding-large" ref={scrollRef}>
        {monthsToWorkWith.map((month) => (
          <StyledDatePicker.Month id={generateKey(month)} className="uk-width-1-1" key={generateKey(month)}>
            <Month
              year={month.year}
              month={month.month}
              firstDayOfWeek={firstDayOfWeek}
              setStartMonthRef={setStartMonthRef}
            />
          </StyledDatePicker.Month>
        ))}
      </div>
      <BottomFixedButton id="datepickerSubmit" disabled={isDatepickerSubmitDisabled} onClick={confirmOk}>
        <Trans i18nKey="common.continue">Continue</Trans>
      </BottomFixedButton>
    </div>
  );
};
