import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useContactTranslations } from 'components/hooks/useContactTranslations';
import OptionalHrefWrap from 'components/hotelComponents/listElement/OptionalHrefWrap';
import { LinkProps } from 'components/links/LinkProps';

const CareersLink: React.FC<LinkProps> = ({ onClick, asLink, className }) => {
  const [t] = useTranslation();
  const contactTranslations = useContactTranslations();
  const linkText = useMemo(() => t('footer.career', 'Careers'), [t]);

  return (
    <OptionalHrefWrap
      className={className}
      href={contactTranslations.career}
      target="_blank"
      onClick={() => onClick?.(linkText)}
      asLink={asLink}
    >
      {linkText}
    </OptionalHrefWrap>
  );
};

export default CareersLink;
