import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { Place } from 'backend/api/place/placeModel';
import { RoomOccupancy } from 'backend/api/trip/tripModel';
import { SEARCH_CHECKIN, SEARCH_CHECKOUT, SEARCH_DESTINATION } from 'backend/localStorageKeys';

export interface StoredDate {
  checkin: string;
  checkout: string;
}

export const placeIdAtom = atom<string | undefined>(undefined);
export const boundsAtom = atom<string | undefined>(undefined);

export const checkinFromStorageAtom = atomWithStorage<string>(SEARCH_CHECKIN, '');
export const checkoutFromStorageAtom = atomWithStorage<string>(SEARCH_CHECKOUT, '');
export const checkoutCheckinInnerAtom = atom<StoredDate | undefined>(undefined);
export const occupancyStorageAtom = atom<RoomOccupancy[]>([]);

const destinationInnerAtom = atom<Place | undefined>(undefined);

export const destinationAtom = atom(
  (get) => get(destinationInnerAtom),
  (get, set, value: Place | undefined) => {
    set(destinationInnerAtom, value);
    window.localStorage.setItem(SEARCH_DESTINATION, JSON.stringify(value));
  },
);
