import { useAtom } from 'jotai';
import React from 'react';
import { useSearchFormHandlers } from 'atoms/hooks/useSearchFormCallbacks';
import { searchFormOccupancyAtom } from 'atoms/searchFormAtoms';
import OccupancyPicker from 'components/searchForm/OccupancyPicker';

export const OccupancyPickerMobile = () => {
  const { submitForm } = useSearchFormHandlers();
  const [occupancy, setOccupancy] = useAtom(searchFormOccupancyAtom);

  return <OccupancyPicker occupancy={occupancy} setOccupancy={setOccupancy} submit={submitForm} />;
};
