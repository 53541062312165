import styled from 'styled-components';
import media from 'style/media';
import { cornflower, gunmetal, specialPromoColor } from 'style/variables';

const Wrapper = styled.div`
  padding: 9px 14px 10px 9px;
  transform: translateY(4px);
  border-radius: 5px;
  background-color: ${specialPromoColor};
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 20px;
  ${media.mobile} {
    display: grid;
    grid-template-columns: 30px 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'area1 area2 area2'
      'area1 area3 area3';

    margin-right: 0;
    margin-bottom: 24px;
    padding: 6px 14px;
  }
  > img {
    width: 22px;
    height: 22px;

    ${media.mobile} {
      grid-area: area1;
      width: 30px;
      height: 30px;
    }
  }
  > span {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${gunmetal};
    margin-right: 8px;
    ${media.mobile} {
      text-align: center;
      grid-area: area2;
    }
  }

  > a {
    color: ${cornflower} !important;
    font-size: 13px !important;
    line-height: 1.5;
    ${media.mobile} {
      text-align: center;
      grid-area: area3;
    }
  }
  > a:hover {
    text-decoration: underline !important;
  }
`;

export default {
  Wrapper,
};
