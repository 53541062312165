import styled, { css } from 'styled-components';
import Styled from 'components/signin/SignInDialog.styled';
import { gray } from 'style/variables';

const Modal = Styled.Modal;

const Logo = styled(Styled.Logo)`
  margin: 6px 0 30px;

  svg {
    height: 41px;
    width: 156px;
  }
`;

const Title = styled.div`
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 3.18px;
  color: #fff;

  text-transform: uppercase;
  margin-bottom: 48px;
`;

const AuthIcon = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;

  width: 26px;
  height: 26px;
  margin: 0 12px;
`;

const AuthText = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;

  flex: 1;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: black;
`;

const AuthButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-content: center;
  align-items: center;

  cursor: pointer;

  height: 46px;
  border-radius: 4px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;

  ${AuthText} {
    ${(props) => {
      if (props.disabled) {
        return css`
          color: ${gray};
        `;
      }

      return css``;
    }}
  }

  ${AuthIcon} {
    ${(props) =>
      props.disabled
        ? css`
            opacity: 0.8;
          `
        : ''};
  }
`;

const Form = styled(Styled.Form)`
  ${AuthButton} + ${AuthButton} {
    margin-top: 24px;
  }
`;

export default { Modal, Logo, Title, AuthButton, AuthIcon, AuthText, Form };
