/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { Campaign, PriceRoundingMethod, RateDisplayModel } from 'backend/api/general/generalModel';
import { User } from 'backend/api/user/userModel';
import {
  LoginOauthRequest,
  LoginRequest,
  SignUpOauthRequest,
  SignUpRequest,
  UpdatePasswordRequest,
} from 'backend/api/user/userRequest';
import SplittySession from 'backend/authModelSession';
import { TopAmenity } from 'backend/dataModel';
import { ToastType } from 'components/Toast.styled';
import { BudgetBoundaries, HotelSorting, StarRow } from 'components/hotelComponents/listFilters/filterUtils';
import { FilterAmenity } from 'components/hotelComponents/utils/amenityUtils';
import OffscreenMode from 'components/offscreen/OffscreenMode';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import { env } from 'environments/environment';

export interface ToastProps {
  message?: string;
  type?: ToastType;
  title?: string;
  clearMessage: () => void;
}

export interface ModalAlertProps {
  message: string;
  close: () => void;
}

export interface SplittySessionHolder {
  session: SplittySession;
  login: (payload: LoginRequest) => Promise<void>;
  signUp: (payload: SignUpRequest) => Promise<void>;
  logout: () => Promise<void>;
  updateUserDetails: (payload: User) => Promise<void>;
  updatePassword: (payload: UpdatePasswordRequest, userId?: number) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  signUpOauth: (payload: SignUpOauthRequest) => Promise<void>;
  loginOauth: (payload: LoginOauthRequest) => Promise<void>;
  campaign: Campaign | undefined;
  ready: boolean;
}

export const SignInDialogContext = React.createContext<{
  signInDialogMode: SignInDialogMode | undefined;
  setSignInDialogMode: (mode: SignInDialogMode | undefined, callbackSuccess?: () => void) => void;
  callbackSuccess?: () => void;
}>({
  signInDialogMode: undefined,
  setSignInDialogMode: () => undefined,
});

/**
 * Global context that holds debug mode flag
 */
export const DebugContext = React.createContext<{
  isDebugMode: boolean;
  setDebugMode: (m: boolean) => boolean;
}>({
  isDebugMode: false,
  setDebugMode: () => false,
});

export const OffscreenContext = React.createContext<{
  offscreenMode: OffscreenMode;
  setOffscreenMode: (mode: OffscreenMode) => void;
  hideOffscreen: () => void;
}>({
  offscreenMode: OffscreenMode.Hidden,
  setOffscreenMode: () => undefined,
  hideOffscreen: () => undefined,
});

export const PageTypeContext = React.createContext<{
  pageType: string;
  setPageType: (type: string) => void;
}>({
  pageType: 'homePage',
  setPageType: () => undefined,
});

export const ToastContext = React.createContext<{
  toast: ToastProps | undefined;
  setToast: (message: string | undefined, type?: ToastType, title?: string) => void;
}>({
  toast: undefined,
  setToast: () => undefined,
});

export const ModalAlertContext = React.createContext<{
  alert: ModalAlertProps | undefined;
  setAlert: (message: string, afterClose?: () => void) => void;
}>({
  alert: undefined,
  setAlert: () => undefined,
});

export const SettingsContext = React.createContext<{
  countryCode: string;
  stateCode: string | undefined;
  languageCode: string;
  campaignName: string;
  currencyCode: string;
  rateDisplayModel: RateDisplayModel;
  priceRoundingMethod: PriceRoundingMethod;
  setCountryCode: (countryCode: string) => void;
  setStateCode: (stateCode: string) => void;
  setCurrencyCode: (currencyCode: string, manually?: boolean) => void;
  setLanguageCode: (languageCode: string, manually?: boolean) => void;
}>({
  countryCode: env.location.fallbackCountryCode,
  stateCode: undefined,
  languageCode: env.i18n.fallbackLanguage,
  campaignName: env.campaign.fallbackName,
  currencyCode: env.currencies.defaultCurrencyCode,
  rateDisplayModel: env.campaign.fallbackRateDisplayModel,
  priceRoundingMethod: env.campaign.fallbackPriceRoundingMethod,
  setCountryCode: () => undefined,
  setStateCode: () => undefined,
  setCurrencyCode: () => undefined,
  setLanguageCode: () => undefined,
});

export const FilterSortContext = React.createContext<{
  name: string;
  stars: StarRow[];
  tripAdvisorStars: StarRow[];
  freeCancellationOnly: boolean;
  budget: BudgetBoundaries;
  amenities: FilterAmenity[];
  checkedAmenities: TopAmenity[];
  sorting: HotelSorting;
  filtering: boolean;
  resetFilters: () => void;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setStars: React.Dispatch<React.SetStateAction<StarRow[]>>;
  setTripAdvisorStars: React.Dispatch<React.SetStateAction<StarRow[]>>;
  setFreeCancellationOnly: React.Dispatch<React.SetStateAction<boolean>>;
  setBudget: React.Dispatch<React.SetStateAction<BudgetBoundaries>>;
  setCheckedAmenities: React.Dispatch<React.SetStateAction<TopAmenity[]>>;
  setSorting: React.Dispatch<React.SetStateAction<HotelSorting>>;
  setFiltering: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  name: '',
  stars: [],
  tripAdvisorStars: [],
  freeCancellationOnly: false,
  budget: { from: 0, to: undefined },
  amenities: [],
  checkedAmenities: [],
  sorting: HotelSorting.Popularity,
  filtering: false,
  resetFilters: () => undefined,
  setName: () => undefined,
  setStars: () => undefined,
  setTripAdvisorStars: () => undefined,
  setFreeCancellationOnly: () => undefined,
  setBudget: () => undefined,
  setCheckedAmenities: () => undefined,
  setSorting: () => undefined,
  setFiltering: () => undefined,
});

export const BodyContext = React.createContext<{ body: HTMLElement }>({
  body: document.body,
});

export const MetaContext = React.createContext<{
  setTitle: (title: string | undefined) => void;
  setDescription: (description: string) => void;
  setKeywords: (keywords: string[]) => void;
  isGoogleCrawler: boolean;
}>({
  setTitle: () => undefined,
  setDescription: () => undefined,
  setKeywords: () => undefined,
  isGoogleCrawler: false,
});
