import { Place } from 'backend/api/place/placeModel';
import { RoomOccupancy } from 'backend/api/trip/tripModel';
import { QUERY_STORAGE } from 'backend/localStorageKeys';
import { env } from 'environments/environment';
import { occupancyAreEqual } from 'utils/occupancyUtils';

export interface StoredQuery {
  place: Place;
  checkin: string;
  checkout: string;
  occupancy: RoomOccupancy[];
  expires: number;
}

function queryEquals(a: StoredQuery, b: StoredQuery) {
  return (
    a.place.id === b.place.id &&
    a.checkin === b.checkin &&
    a.checkout === b.checkout &&
    occupancyAreEqual(a.occupancy, b.occupancy)
  );
}

export default class SearchStorage {
  private list: StoredQuery[];

  private readonly count: number;

  static readonly instance = new SearchStorage(env.searchBar.queriesToKeepCount);

  constructor(count: number) {
    this.count = count;
    const stored = window.localStorage.getItem(QUERY_STORAGE);

    if (stored) {
      this.list = JSON.parse(stored);
      if (this.list.length > count) {
        this.list.splice(count);
        window.localStorage.setItem(QUERY_STORAGE, JSON.stringify(this.list));
      }
    } else {
      this.list = [];
    }
  }

  store(place: Place, checkin: string, checkout: string, occupancy: RoomOccupancy[]) {
    const newQuery: StoredQuery = {
      place,
      checkin,
      checkout,
      occupancy,
      expires: Date.now() + env.times.recentSearchMaxAge,
    };

    this.list = this.list.filter((q) => !queryEquals(q, newQuery));
    this.list.unshift(newQuery);
    if (this.list.length > this.count) {
      this.list.splice(-1);
    }
    window.localStorage.setItem(QUERY_STORAGE, JSON.stringify(this.list));
  }

  getRecentSearches(): StoredQuery[] {
    const oldLength = this.list.length;
    const now = Date.now();

    this.list = this.list.filter((q) => q.expires > now);
    if (oldLength !== this.list.length) {
      window.localStorage.setItem(QUERY_STORAGE, JSON.stringify(this.list));
    }

    return this.list;
  }
}
