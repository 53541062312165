/**
 * @category Mobile components
 * @packageDocumentation
 */

/**
 * States for mobile offscreens
 */
enum OffscreenMode {
  Hidden = '',
  Burger = 'burger',
  Destination = 'destination',
  Datepicker = 'datepicker',
  Occupancy = 'occupancy',
  ManageProfile = 'profile',
  ChangePassword = 'password',
}

export default OffscreenMode;
