import { useDay } from '@datepicker-react/hooks';
import React, { useContext, useRef } from 'react';
import { DatepickerContext } from 'components/datePicker/customDatepicker/DatepickerContext';
import DayStyle from 'components/datePicker/customDatepicker/Day/Day.styled';
import { isToday } from 'utils/dateUtils';

interface DayProps {
  dayLabel: string;
  date: Date;
}

const Day: React.FC<DayProps> = ({ dayLabel, date }) => {
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    isStartDateSelected,
    isEndDateSelected,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(DatepickerContext);

  const { isSelected, isWithinHoverRange, disabledDate, onClick, onKeyDown, onMouseEnter, tabIndex } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  if (!dayLabel) {
    return <div />;
  }

  if (isStartDateSelected(date)) {
    return (
      <DayStyle.DaySelectedFirst
        id={'datepickerFirstDay'}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
        tabIndex={tabIndex}
        type="button"
        ref={dayRef}
      >
        {dayLabel}
      </DayStyle.DaySelectedFirst>
    );
  }
  if (isEndDateSelected(date)) {
    return (
      <DayStyle.DaySelectedLast
        onClick={onClick}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
        tabIndex={tabIndex}
        type="button"
        ref={dayRef}
      >
        {dayLabel}
      </DayStyle.DaySelectedLast>
    );
  }
  if (disabledDate) {
    return (
      <DayStyle.DayDisabled
        isToday={isToday(date)}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
        tabIndex={tabIndex}
        type="button"
        ref={dayRef}
      >
        {dayLabel}
      </DayStyle.DayDisabled>
    );
  }

  return (
    <DayStyle.DayDefault
      isToday={isToday(date)}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      selected={isWithinHoverRange || isSelected}
      type="button"
      ref={dayRef}
    >
      {dayLabel}
    </DayStyle.DayDefault>
  );
};

export default Day;
