import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useState } from 'react';
import useWindowSize from 'components/hotelComponents/details/useWindowSize';
import { env } from 'environments/environment';

interface Context {
  isMobileLayout: boolean;
  isSmallMobile: boolean;
  isSuperSmallMobile: boolean;
}

export const LayoutContext = React.createContext<Context>({
  isMobileLayout: isMobile,
  isSmallMobile: isMobile,
  isSuperSmallMobile: isMobile,
});

export const LayoutProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [width] = useWindowSize();
  const [isMobileLayout, setIsMobileLayout] = useState<boolean>(isMobile);
  const [isSmallMobile, setIsSmallMobile] = useState<boolean>(isMobile);
  const [isSuperSmallMobile, setIsSuperSmallMobile] = useState<boolean>(isMobile);

  useEffect(() => {
    if (width > 0) {
      setIsMobileLayout(width < env.layouts.desktopWidth);
      setIsSmallMobile(width < env.layouts.smallSmartphoneWidth);
      setIsSuperSmallMobile(width < env.layouts.superSmallSmartphoneWidth);
    }
  }, [width]);

  return (
    <LayoutContext.Provider value={{ isMobileLayout, isSmallMobile, isSuperSmallMobile }}>
      {children}
    </LayoutContext.Provider>
  );
};
