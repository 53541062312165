import React, { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import eyeOff from 'assets/images/icons/eye/icons-eye-off.svg';
import eyeOn from 'assets/images/icons/eye/icons-eye-on.svg';
import InputField, { InputFieldRef } from 'components/InputField';
import PasswordValidationBox, { validationRule } from 'components/signin/PasswordValidationBox';
import { Theme } from 'components/signin/PasswordValidationBox.styled';
import Styled from 'components/signin/SignupForm.styled';
import { emailValidationRule, passwordErrorMessage } from 'utils/validation';

export interface SignupFormFirstStepProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  emailBusy: boolean;
  setEmailBusy: React.Dispatch<React.SetStateAction<boolean>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  repeatPassword: string;
  setRepeatPassword: React.Dispatch<React.SetStateAction<string>>;
  inputRefs: MutableRefObject<InputFieldRef | undefined>[];
  proceed: () => void;
}

const SignupFormFirstStep = ({
  email,
  setEmail,
  emailBusy,
  setEmailBusy,
  password,
  setPassword,
  repeatPassword,
  setRepeatPassword,
  inputRefs,
  proceed,
}: SignupFormFirstStepProps) => {
  const [t] = useTranslation();
  const [emailRef, passwordRef, repeatPasswordRef] = inputRefs;
  const [showPassword, setShowPassword] = useState(false);
  const passwordValidationRule = useCallback(() => validationRule(password), [password]);
  const repeatPasswordValidationRule = useCallback((p: string) => p === password, [password]);

  const emailValidator = useCallback(
    (email2validate: string) => {
      if (!emailValidationRule(email2validate)) {
        return false;
      }

      return !emailBusy;
    },
    [emailBusy],
  );

  const emailChanged = useCallback(
    (changedEmail: string) => {
      setEmailBusy(false);
      setEmail(changedEmail);
    },
    [setEmailBusy, setEmail],
  );

  const continueBtnText = useMemo(() => t('login.continue', 'Continue'), [t]);
  const title = useMemo(() => t('login.signup-email', 'Create Account'), [t]);
  const toggleShowPassword = useCallback(() => setShowPassword(!showPassword), [showPassword]);

  const eyeButton = (
    <Styled.EyeIcon onClick={toggleShowPassword}>
      {showPassword && <img src={eyeOn} alt="eye-on" />}
      {!showPassword && <img src={eyeOff} alt="eye-off" />}
    </Styled.EyeIcon>
  );

  return (
    <>
      <Styled.Form>
        <Styled.Title>{title}</Styled.Title>
        <InputField
          id={'id-signup-email'}
          dataTestid="signup-email-input"
          ref={emailRef}
          containerStyle="input-margin-top"
          label={t('login-popup.email', 'Email Address')}
          isRequired={true}
          inputType={'text'}
          inputMode="email"
          value={email}
          onChange={emailChanged}
          errorMessage={
            emailBusy
              ? t('login-popup.signup-email-taken', 'This email is already registered')
              : t('login-popup.invalid-email', 'Invalid email')
          }
          autocomplete={'email'}
          doBasicValidation={false}
          validationRule={emailValidator}
          allowHotJarRecording={true}
          errorHasBackground
          autoFocus
        />
        <InputField
          id={'id-signip-password'}
          dataTestid="signup-password-input"
          ref={passwordRef}
          containerStyle="input-margin-top"
          label={t('login-popup.password', 'Password')}
          isRequired={true}
          inputType={showPassword ? 'text' : 'password'}
          value={password}
          onChange={setPassword}
          errorMessage={passwordErrorMessage(t)}
          autocomplete={'off'}
          doBasicValidation={false}
          validationRule={passwordValidationRule}
          allowHotJarRecording={false}
          innerButton={eyeButton}
          errorHasBackground
        />
        <Styled.PasswordValidationBox>
          <PasswordValidationBox password={password} theme={Theme.dark} />
        </Styled.PasswordValidationBox>
        <InputField
          id={'id-signup-repeat-password'}
          dataTestid="signup-repeat-password-input"
          ref={repeatPasswordRef}
          containerStyle="input-margin-top"
          label={t('login-popup.confirm-password', 'Confirm password')}
          isRequired={true}
          inputType={showPassword ? 'text' : 'password'}
          value={repeatPassword}
          onChange={setRepeatPassword}
          errorMessage={t('login-popup.passwords-do-not-match', 'Passwords do not match')}
          autocomplete={'off'}
          doBasicValidation={false}
          validationRule={repeatPasswordValidationRule}
          allowHotJarRecording={false}
          innerButton={eyeButton}
          errorHasBackground
        />

        <Styled.Button onClick={proceed} data-testid="signup-continue-button">
          {continueBtnText}
        </Styled.Button>
      </Styled.Form>
    </>
  );
};

export default SignupFormFirstStep;
