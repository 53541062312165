import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BodyContext } from 'TopContexts';
import Styled from 'components/header-footer/StickyPanel.styled';
import useScrollListener, { ScrollDirection } from 'utils/useScrollListener';

interface StickyPanelProps {
  id?: string;
  off?: boolean;
  children: (active: boolean, stickyShortView: boolean) => React.ReactNode;
}

const StickyPanel: React.FC<StickyPanelProps> = ({ id, off, children }) => {
  const { body } = useContext(BodyContext);
  const ref = useRef<HTMLDivElement>(null);
  const [stickyActive, setStickyActive] = useState(false);
  const [height, setHeight] = useState<number>(0);
  const scrollDirection = useScrollListener();
  const stickyShortView = useMemo(
    () => stickyActive && scrollDirection === ScrollDirection.DOWN,
    [scrollDirection, stickyActive],
  );

  useEffect(() => {
    if (!off) {
      const onScroll = () => {
        const offsetTop = ref.current?.offsetTop;

        if (offsetTop !== undefined) {
          setStickyActive(window.scrollY > offsetTop + body.offsetTop);
        }
      };

      window.addEventListener('scroll', onScroll);

      return () => window.removeEventListener('scroll', onScroll);
    }

    return undefined;
  }, [body.offsetTop, off]);

  useEffect(() => {
    setHeight((prev) => {
      const newHeight = ref.current?.clientHeight || prev;

      if (newHeight > prev) {
        return newHeight;
      }

      return prev;
    });
  }, [stickyActive]);

  return (
    <>
      <Styled.Sticky id={id} stickyActive={stickyActive} ref={ref}>
        {children(stickyActive, stickyShortView)}
      </Styled.Sticky>
      <Styled.Placeholder stickyActive={stickyActive} height={height} />
    </>
  );
};

export default StickyPanel;
