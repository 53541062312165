import React from 'react';
import Styled from 'components/BottomFixedButton.styled';
import StyledCommon from 'style/Common.styled';

const BUTTON_HEIGHT = 86;

interface BottomFixedButtonProps {
  id?: string;
  onClick?: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

const BottomFixedButton: React.FC<BottomFixedButtonProps> = ({ id, onClick, disabled, children }) => (
  <>
    <Styled.Placeholder height={BUTTON_HEIGHT} />
    <Styled.BottomFixed>
      <StyledCommon.PrimaryButton id={id} onClick={onClick} disabled={disabled} type="button">
        {children}
      </StyledCommon.PrimaryButton>
    </Styled.BottomFixed>
  </>
);

export default BottomFixedButton;
